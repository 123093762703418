import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'removeAttacks'
})
export class RemoveAttacksPipe implements PipeTransform {

  transform(value: string): string {

    let newstr: string = '';
    if(value){
      const re = /<img.*?src="https:\/\/rasenalong.com[^\>]+>/g;
      newstr = value.replace(re, '');
    }
    return newstr;
  };

}
