import {Pipe, PipeTransform} from '@angular/core';
import {WebFormNode} from "../../core/models/webform";
import {DrupalNode} from "../../core/models/node";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";

@Pipe({
  name: 'resourceToWebform'
})
export class ResourceToWebformPipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe
  ) {
  }

  transform(value: any): WebFormNode {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    const webFormNode: WebFormNode = {
      ...drupalNode,
      webform: value.webform
    }
    return webFormNode;
  }

}
