import {Pipe, PipeTransform} from '@angular/core';
import {DrupalNode} from "../../core/models/node";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {Knowledge} from "../../core/models/knowledge";
import {Diaporama} from "../../contents/components/diaporama/diaporama.model";

@Pipe({
  name: 'resourceToKnowledge'
})
export class ResourceToKnowledgePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe
  ) {
  }

  transform(value: any): Knowledge {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    const isLexique: boolean = value.is_lexique;
    const hasSlideshow: boolean = value.has_slideshow;

    const slideshow: Diaporama = value.slideshow;

    return {
      ...drupalNode,
      isLexique,
      hasSlideshow,
      slideshow
    };
  }

}
