import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  aPropos,
  contactPath,
  credits,
  domainsPath,
  encyclopediaPath, encyclopedicalToolsPath,
  faq,
  genericOutlet,
  homePath,
  loginPath,
  mentionsLegales,
  myAccountPath,
  quizSiFfa,
  subscription,
  themePath,
  traineePath,
  trainingCheckIn,
  trainingPath,
  trainingToolsPath,
  tutoriels,
  userAccountOutlet,
  userLoginOutlet
} from "./route-constants";
import {SiFfaGuard} from "./si-ffa.guard";
import {HomeResolver} from "./home/home-resolver.service";
import {ContextResolver} from "./context-resolver.service";
import {AuthenticatedGuard} from "./authenticated.guard";
import {UserLoadedGuard} from "./user-loaded.guard";
import {ContentTypesConstants} from "./core/models/content-types-constants";
import {EncyclopediaResolver} from "./encyclopedia/encyclopedia-resolver.service";
import {TrainingGuard} from "./training.guard";
import {NidResolver} from "./nid-resolver.service";
import {PageComponent} from "./components/page/page.component";
import {SubscriptionResolver} from "./subscription/subscription-resolver.service";
import {TrainingCheckInGuard} from "./training-check-in.guard";
import {PasswordRecoveryGuard} from "./password-recovery.guard";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {OldFichePathGuard} from "./old-fiche-path.guard";
import {TemplatesService} from "./templates.service";


const routes: Routes = [
  {
    path: '',
    redirectTo: homePath,
    pathMatch: 'full'
  },
  {
    path: homePath,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [SiFfaGuard],
    resolve: {universe: HomeResolver},
    data: {
      isHome: true
    }
  },
  {
    path: themePath,
    loadChildren: () => import('./themes/themes.module').then(m => m.ThemesModule),
    data: {
      universe: ContentTypesConstants.universeTheme,
      isContent: true
    }
  },
  {
    path: traineePath,
    loadChildren: () => import('./trainee-area/trainee-area.module').then(m => m.TraineeAreaModule),
    resolve: {context: ContextResolver},
    canActivate: [AuthenticatedGuard, UserLoadedGuard],
    data: {
      universe: ContentTypesConstants.universeTraining,
      theme: 'training-theme',
      displayLoginIfAnonymous: true,
      context: 'trainee-area',
      hideSearchField: true,
      isHome: true
    }
  },
  {
    path: encyclopediaPath,
    loadChildren: () => import('./encyclopedia/encyclopedia.module').then(m => m.EncyclopediaModule),
    resolve: {data: EncyclopediaResolver},
    data: {
      universe: ContentTypesConstants.universeEncyclopedia,
      theme: 'encyclopedia-theme'
    }
  },
  {
    path: trainingPath,
    loadChildren: () => import('./encyclopedia/encyclopedia.module').then(m => m.EncyclopediaModule),
    canActivate: [AuthenticatedGuard, UserLoadedGuard, TrainingGuard],
    resolve: {data: EncyclopediaResolver},
    data: {
      universe: ContentTypesConstants.universeTraining,
      theme: 'training-theme',
      displayLoginIfAnonymous: true
    }
  },
  {
    path: myAccountPath,
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    canActivate: [AuthenticatedGuard],
    data: {
      outletName: userAccountOutlet,
      windowComponentId: TemplatesService.lzlUserWindow,
      panelClass: ['user-theme', 'user-account-window'],
      height: '100vh',
      maxHeight: '570px'
    },
    outlet: userAccountOutlet
  },
  {
    path: trainingToolsPath,
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    canActivate: [AuthenticatedGuard],
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlTrainingTools,
      panelClass: ['user-theme'],
      height: '100vh',
      width: '100vw',
      maxHeight: '800px',
      maxWidth: '1024px',
      nid: '26654'
    },
    outlet: genericOutlet
  },
  {
    path: encyclopedicalToolsPath,
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlTrainingTools,
      panelClass: ['user-theme'],
      height: '100vh',
      width: '100vw',
      maxHeight: '800px',
      maxWidth: '1024px',
      nid: '28017'
    },
    outlet: genericOutlet
  },
  {
    path: loginPath,
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: userLoginOutlet,
      windowComponentId: TemplatesService.lzlLogin,
      panelClass: ['user-theme'],
      width: '700px',
      height: 'auto',
      maxHeight: '600px',
      disableClose: true
    },
    outlet: userLoginOutlet
  },
  {
    path: domainsPath,
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlDomains,
      panelClass: ['user-theme', 'domains-window'],
      width: '600px',
      disableClose: true
    },
    outlet: genericOutlet
  },
  {
    path: contactPath,
    loadChildren: () => import('./webform/webform.module').then(m => m.WebformModule),
    data: {
      context: 'contact',
      nid: 12731,
      raw: true,
      hideSearchField: true,
      isHome: true,
      class: 'contact-group'
    },
    resolve: {contextData: NidResolver},
  },
  {
    path: aPropos,
    component: PageComponent,
    data: {
      nid: 7592,
      hideSearchField: true,
      isHome: true
    },
    resolve: {contextData: NidResolver},
  },
  {
    path: tutoriels,
    loadChildren: () => import('./tutorials/tutorials.module').then(m => m.TutorialsModule),
    data: {
      nid: 10129,
      hideSearchField: true,
      isHome: true
    },
    resolve: {contextData: NidResolver},
  },
  {
    path: faq,
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
    data: {
      hideSearchField: true,
      isHome: true
    }
  },
  {
    path: subscription,
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlSubscription,
      panelClass: ['user-theme', 'user-window'],
      minWidth: '800px',
      disableClose: true
    },
    resolve: {context: SubscriptionResolver},
    outlet: genericOutlet
  },
  {
    path: credits,
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlPage,
      panelClass: ['user-theme', 'user-window'],
      width: '600px',
      disableClose: false,
      nid: 7594
    },
    resolve: {contextData: NidResolver},
    outlet: genericOutlet
  },
  {
    path: mentionsLegales,
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlPage,
      panelClass: ['user-theme', 'user-window'],
      width: '600px',
      disableClose: false,
      nid: 7597,
      templates: {footer: 'cookieFooter'}
    },
    resolve: {contextData: NidResolver},
    outlet: genericOutlet
  },
  {
    path: trainingCheckIn,
    loadChildren: () => import('./components/si-ffa-landing-page/si-ffa-landing-page.component').then(m => m.SiFfaLandingPageModule),
    canActivate: [TrainingCheckInGuard],
    data: {
      hideSearchField: true,
      isHome: true
    }
  },
  {
    path: quizSiFfa,
    loadChildren: () => import('./quiz-session/quiz-session.module').then(m => m.QuizSessionModule)
  },
  {
    path: 'password-recovery/:uid/:timestamp/:token',
    canActivate: [PasswordRecoveryGuard],
    component: NotFoundComponent
  },
  {
    path: 'password-recovery/:uid/:timestamp/:token',
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlPasswordRecovery,
      panelClass: ['user-theme', 'user-window'],
      width: '800px',
      disableClose: true
    },
    outlet: genericOutlet
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlPaymentSuccess,
      panelClass: ['user-theme', 'user-window'],
      width: '300px',
      disableClose: false
    },
    outlet: genericOutlet
  },
  {
    path: 'payment-fail',
    loadChildren: () => import('./open-window/open-window.module').then(m => m.OpenWindowModule),
    data: {
      outletName: genericOutlet,
      windowComponentId: TemplatesService.lzlPaymentFailed,
      panelClass: ['user-theme', 'user-window'],
      width: '400px',
      disableClose: false
    },
    outlet: genericOutlet
  },
  {
    path: 'fiche/:nid',
    loadChildren: () => import('./components/old-paths-landing/old-paths-landing.component').then(m => m.OldPathsLandingModule),
    canActivate: [OldFichePathGuard],
    data: {
      hideSearchField: false,
      isHome: true
    }
  },
  {
    path: '**',
    loadChildren: () => import('./components/not-found/not-found.component').then(m => m.NotFoundModule),
    data: {
      hideSearchField: false,
      isHome: true
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
