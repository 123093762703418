import * as fromUser from '../actions/user.action';
import {
  ADD_BOOKMARK,
  AddBookmark,
  LOAD_BOOKMARKS_FULL_SUCCESS,
  LOAD_BOOKMARKS_SUCCESS,
  LOAD_ORDERS_SUCCESS,
  LOAD_TRAINEE_INFOS_SUCCESS,
  LoadBookmarksFullSuccess,
  LoadBookmarksSuccess,
  LoadOrdersSuccess,
  LoadTraineeInfosSuccess,
  LoadUserInfosSuccess,
  LoadUserMetaSuccess,
  REMOVE_BOOKMARK,
  RemoveBookmark,
  RequestPasswordFail,
  SET_ANONYMOUS_USER_SUCCESS,
  SET_PREFERRED_AGE_CRITERIA,
  SetAnonymousUserSuccess,
  SetAuthenticationRedirection, SetPreferredAgeCriteria,
  UPDATE_USER_SUCCESS,
  UpdateUserSuccess
} from '../actions/user.action';
import {HttpErrorResponse} from '@angular/common/http';
import {AppError} from "@madeinlune/components/lib/models/app-error.model";
import {User, UserMeta} from "../../models/user";
import {Order} from "../../models/order";
import {TraineeInfos} from "../../models/trainee-infos";

export enum AgeTypes {
  age = 'age',
  ffa = 'ffa',
  en = 'en'
}

export type AgeType = AgeTypes.age | AgeTypes.ffa | AgeTypes.en;

export class UserState {
  loading: boolean;
  loaded: boolean;
  error: AppError;
  accountCreationError: AppError;
  passwordRecoveryLoaded: boolean;
  passwordRecoveryLoading: boolean;
  passwordRecoveryError?: any;
  userInfos?: User;
  userMeta?: UserMeta;
  orders?: Order[];
  userLoading?: boolean;
  userLoaded?: boolean;
  bookmarks?: any[];
  userCreationSuccess: boolean;
  authenticationRedirection?: string;
  traineeInfos: TraineeInfos;
  bookmarksFull: any[];
  preferredAgeCriteria: AgeType;
}

const initialState: UserState = {
  loaded: false,
  loading: false,
  error: null,
  accountCreationError: null,
  passwordRecoveryLoaded: false,
  passwordRecoveryLoading: false,
  userInfos: null,
  userMeta: null,
  orders: null,
  userLoading: false,
  userLoaded: false,
  bookmarks: null,
  userCreationSuccess: false,
  authenticationRedirection: null,
  traineeInfos: null,
  bookmarksFull: null,
  preferredAgeCriteria: AgeTypes.ffa
};

export function reducer(state: UserState = initialState, action: fromUser.UserAction) {

  switch (action.type) {


    case fromUser.RESET_STATE: {
      return initialState;
    }

    case fromUser.INIT_CREATE_ACCOUNT: {
      return {
        ...state,
        accountCreationError: null,
        error: null
      };
    }

    case fromUser.CREATE_ACCOUNT: {
      return {
        ...state,
        accountCreationError: null,
        loading: true,
        loaded: false,
        error: null
      };
    }

    case fromUser.SET_AUTHENTICATION_REDIRECTION: {
      return {
        ...state,
        authenticationRedirection: (action as SetAuthenticationRedirection).url
      };
    }

    case fromUser.CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
        userCreationSuccess: true
      };
    }

    case fromUser.CREATE_ACCOUNT_FAIL: {
      const httpErrorResponse: HttpErrorResponse = (action as fromUser.CreateAccountFail).error;
      let error: AppError;
      if (httpErrorResponse.status === 406) {
        error = {title: 'Oups', message: 'Un compte utilisateur existe déjà avec cet identifiant. Avez-vous oublié votre mot de passe ?'};
      } else {
        error = {title: 'Oups', message: 'Une erreur est survenue. Merci d’essayer à nouveau.'};
      }
      error.status = httpErrorResponse.status;
      return {
        ...state,
        error: error,
        loading: false,
        loaded: true
      };
    }
    case fromUser.REQUEST_PASSWORD: {
      return {
        ...state,
        passwordRecoveryLoaded: false,
        passwordRecoveryLoading: true,
      };
    }
    case fromUser.REQUEST_PASSWORD_SUCCESS: {
      return {
        ...state,
        passwordRecoveryLoaded: true,
        passwordRecoveryLoading: false,
        passwordRecoveryError: null,
      };
    }
    case fromUser.REQUEST_PASSWORD_FAIL: {
      return {
        ...state,
        passwordRecoveryLoaded: false,
        passwordRecoveryLoading: false,
        passwordRecoveryError: (action as RequestPasswordFail).error,
      };
    }

    case fromUser.RESET_REQUEST_PASSWORD: {
      return {
        ...state,
        passwordRecoveryLoaded: false,
        passwordRecoveryLoading: false,
        passwordRecoveryError: null
      };
    }

    case fromUser.LOAD_USER_INFOS: {
      return {
        ...state,
        userLoading: true,
        userLoaded: false
      };
    }

    case fromUser.UPDATE_USER: {
      return {
        ...state,
        userLoading: true,
        userLoaded: false,
        loading: true,
        loaded: false
      };
    }

    case fromUser.LOAD_USER_INFOS_SUCCESS: {
      return {
        ...state,
        userInfos: (action as LoadUserInfosSuccess).user,
        userLoading: false,
        userLoaded: true,
        loading: false,
        loaded: true,
      };
    }

    case fromUser.LOAD_USER_INFOS_FAIL: {
      return {
        ...state,
        userInfos: null,
        userLoading: false,
        userLoaded: false,
        loading: false,
        loaded: false,
      };
    }

    case fromUser.LOAD_USER_META_SUCCESS: {
      return {
        ...state,
        userMeta: (action as LoadUserMetaSuccess).userMeta
      };
    }

    case UPDATE_USER_SUCCESS:{
      return {
        ...state,
        userInfos: (action as UpdateUserSuccess).userInfos,
        userLoading: false,
        userLoaded: true,
        loading: false,
        loaded: true
      };
    }

    case LOAD_BOOKMARKS_FULL_SUCCESS:{
      return {
        ...state,
        bookmarksFull: (action as LoadBookmarksFullSuccess).bookmarks
      };
    }

    case SET_ANONYMOUS_USER_SUCCESS:{
      return {
        ...state,
        userInfos: (action as SetAnonymousUserSuccess).userInfos
      };
    }

    case LOAD_ORDERS_SUCCESS:{
      return {
        ...state,
        orders: (action as LoadOrdersSuccess).orders
      };
    }

    case LOAD_BOOKMARKS_SUCCESS:{
      return {
        ...state,
        bookmarks: (action as LoadBookmarksSuccess).bookmarks
      };
    }

    case ADD_BOOKMARK:{
      return {
        ...state,
        bookmarks: [...state.bookmarks, {id: (action as AddBookmark).contentId}]
      };
    }

    case REMOVE_BOOKMARK:{
      return {
        ...state,
        bookmarks: [...state.bookmarks.filter(bookmark => bookmark.id != (action as RemoveBookmark).contentId)]
      };
    }

    case LOAD_TRAINEE_INFOS_SUCCESS:{
      return {
        ...state,
        traineeInfos: (action as LoadTraineeInfosSuccess).traineeInfos
      };
    }

    case SET_PREFERRED_AGE_CRITERIA:{
      return {
        ...state,
        preferredAgeCriteria: (action as SetPreferredAgeCriteria).ageType
      };
    }

  }

  return state;

}

export const getUserAccountCreationError = (state: UserState) => state.accountCreationError;
export const getUserAccountCreationLoading = (state: UserState) => state.loading;

export const getUserInfos = (state: UserState) => state.userInfos;

export const getUserIsFormation = (state: UserState) => {
  let userIsFormation: boolean = (state?.userInfos?.roles?.indexOf(User.roleFormateur) > -1);
  return userIsFormation;
};


export const getUserInfosLoaded = (state: UserState) => {
  return state.userLoaded;
};

export const getBookmarkIds= (state: UserState) => {
  return state.bookmarks && state.bookmarks.map(bookmark => bookmark.id);
};

export const getBookmarksFull= (state: UserState) => {
  return state.bookmarksFull;
};

export const getBookmarksCount= (state: UserState) => {
  return state.bookmarks && state.bookmarks.length;
};

export const getUserAccountCreationSuccess= (state: UserState) => {
  return state.userCreationSuccess;
};

export const changePasswordSuccess = (state: UserState) => {
  return state.passwordRecoveryLoaded;
};

export const getUserLoading = (state: UserState) => {
  return state.loading;
};

export const isStagiaire = (state: UserState) => {
  //return true;
  return !!state.traineeInfos;
};

export const getTraineeInfos = (state: UserState) => {
  return state.traineeInfos;
};

export const getPreferredAgeCriteria = (state: UserState) => {
  return state.preferredAgeCriteria;
};
