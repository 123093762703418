import {Pipe, PipeTransform} from '@angular/core';
import {SearchFilter, SearchFilterOption} from "../search/models/search-result";

@Pipe({
  name: 'flattenFilter'
})
export class FlattenFilterPipe implements PipeTransform {

  transform(filter: SearchFilter, args?: any): any {

    let options: { [key: string]: SearchFilterOption } = {};
    filter.options.forEach((option: SearchFilterOption) => {
      let hasChildren = option.children;
      options[option.key] = option;
      if(hasChildren){
        this.flatten(option.children, options);
      }
    });

    return options;
  }

  flatten(children, options) {
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      options[child.key] = child;
      if (child.children) {
        this.flatten(child.children, options);
      }
    }
  }

}
