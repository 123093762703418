import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from "@ngrx/effects";
import {SubscriptionEffects} from "./store/effects/subscription.effects";
import {SubscriptionResolver} from "./subscription-resolver.service";
import {StoreModule} from "@ngrx/store";
import * as SubscriptionReducer from './store/reducers/subscription.reducers';

@NgModule({
  declarations: [
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([SubscriptionEffects]),
    StoreModule.forFeature('subscription', SubscriptionReducer.reducer),
  ],
  providers: [
    SubscriptionResolver
  ]
})
export class SubscriptionModule {
}
