<mat-list class="user-select-text">

  <ng-template let-order ngFor [ngForOf]="orders">

    <mat-card *ngIf="order?.orderType == 'FFA-1Y'" class="mb-4">

      <mat-card-title>{{order.title}}</mat-card-title>
      <mat-card-content>
        <span class="d-block">commandé le {{order?.created | date}} </span>
        <span class="d-block">activé le {{order?.license?.granted | date}} </span>
        <span class="d-block">expire le {{order?.license?.expires | date}} </span>
        <span class="d-block">montant payé {{order?.total_formatted}}</span>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Renouveler mon abonnement</button>
      </mat-card-actions>

    </mat-card>

    <mat-card *ngIf="order?.orderType == 'FFA-GC'" class="mb-2">

      <mat-card-title>{{order.title}}</mat-card-title>

      <mat-card-content>

        <p matLine>
          <span class="d-block">commandé le {{order?.created | date}} </span>
          <span class="d-block">{{order?.coupons.length}} coupons</span>
          <span class="d-block"> {{order?.total_formatted}} </span>
        </p>

        <p matLine>
          Un texte d’aide à l'utilisation des coupons est à rédiger
        </p>

        <ul class="list-style-type-none p-0">
          <ng-template let-coupon ngFor [ngForOf]="order.coupons">
            <li>
              <p matLine>
                <strong class="d-block">{{coupon.code}}</strong>
                <span class="d-block">ce coupon est valide jusqu'au {{coupon.ffa_expiration_date | date}}</span>
                <span class="d-block" *ngIf="!(coupon.ffa_used)">ce coupon n’est pas encore utilisé</span>
              </p>
            </li>
          </ng-template>
        </ul>
      </mat-card-content>

    </mat-card>

  </ng-template>

</mat-list>
