import {Pipe, PipeTransform} from '@angular/core';
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {Theme, ThemeCategory} from "../../core/models/theme";
import {DrupalNode} from "../../core/models/node";
import {Video} from "../../core/models/video";

@Pipe({
  name: 'resourceToTheme'
})
export class ResourceToThemePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToMediaPipe: ResourceToMediaPipe
  ) {
  }

  transform(value: any): Theme {

    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);

    const theme_category: ThemeCategory[] = value.theme_category;
    const tags: any = value.tags;
    const theme_contents: any[] = value.theme_contents;
    const video: Video = this.resourceToMediaPipe.transform(value?.theme_slide_video) as Video;

    return {
      ...drupalNode,
      theme_category,
      tags,
      theme_contents,
      video
    };
  }

}
