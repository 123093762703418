<form [formGroup]="form" class="col-12" *ngIf="form">

  <div class="row">

    <div class="col-xs-12 col-md-12 d-flex flex-column">

      <mat-form-field>
        <input
          matInput
          placeholder="Numéro de licence"
          required
          formControlName="ffaLicenseNumber"
          autocomplete="given-name">
        <mat-error *ngIf="form.controls.ffaLicenseNumber.errors?.required">
          Votre numéro de licence est obligatoire
        </mat-error>
      </mat-form-field>

    </div>

  </div>

  <ng-content></ng-content>

</form>
