import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './core/containers/app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {
  DefaultRouterStateSerializer,
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule
} from '@ngrx/router-store';
import {EffectsModule} from '@ngrx/effects';
import {META_REDUCERS, StoreModule} from '@ngrx/store';

import {StoreDevtoolsModule} from '@ngrx/store-devtools';


import {bootEffects, metaReducers, offlineZonesReducerFactory} from './store';
import {CustomRouterStateSerializer} from './shared/utils';
import {CoreModule} from './core/core.module';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {AppQueries} from './store/query/index';
import {AppConfigLoader, BrowserAppConfigLoader} from './app-config-loader';
import {WebformsService} from "./core/services/webforms.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS} from "@angular/material/core";
import {SearchContextResolver} from "./shared/search-context-resolver.service";


import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from "@angular/common";
import {EncyclopediaResolver} from "./encyclopedia/encyclopedia-resolver.service";
import {OAuthStorage} from 'angular-oauth2-oidc';
import {AnalyticsService} from "./analytics.service";
import {CookiebotService} from "./cookiebot.service";
import {WindowRef} from "./window-ref";
import {ContextResolver} from "./context-resolver.service";
import {NidResolver} from "./nid-resolver.service";

import {TermPagesResolver} from "./term-pages-resolver.service";
import {MomentDateAdapter} from "./moment-date-adaptater";
import {HomeResolver} from "./home/home-resolver.service";
import {VERSION_INFO} from '../environments/version';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS} from "@angular/material-moment-adapter";
import {DbService} from "./services/db.service";
import {SwBypassInterceptor} from "./interceptors/sw-bypass-interceptor";
import {AppRoutingModule} from "./app-routing.module";
import {searchReducer} from "./search/store";

import * as Sentry from "@sentry/angular";
import {SentryErrorInterceptor} from "./sentry-error-interceptor";
import {DATE_PROVIDERS} from "./date.providers";


// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

if (environment.production) {

  Error.stackTraceLimit = 100;
  Sentry.init({
    dsn: 'https://6589cda8b9b242d8bf75e67c401870fa@sentry.io/1391547',
    release: VERSION_INFO.sentryRelease,
    ignoreErrors: ['ResizeObserver loop limit exceeded']
  });
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule.withServerTransition({appId: 'ngrx-formation-athle'}),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    StoreModule.forRoot({
      router: routerReducer,
      search: searchReducer
    }, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      stateKey: 'router',
    }),
    ...(!environment.production ? [
      StoreDevtoolsModule.instrument({
        name: 'NgRx FFA Store DevTools',
        logOnly: environment.production,
      })] : []),
    EffectsModule.forRoot(bootEffects),
    CoreModule.forRoot(),
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: META_REDUCERS,
      deps: [DbService],
      useFactory: offlineZonesReducerFactory,
      multi: true,
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    AnalyticsService,
    CookiebotService,
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    WindowRef,
    {provide: OAuthStorage, useValue: localStorage},
    {provide: LOCALE_ID, useValue: 'fr'},
    AppQueries,
    {
      provide: AppConfigLoader,
      useClass: BrowserAppConfigLoader
    },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    },
    SearchContextResolver,
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}},
    WebformsService,
    EncyclopediaResolver,
    ContextResolver,
    NidResolver,
    TermPagesResolver,
    HomeResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SentryErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SwBypassInterceptor,
      multi: true
    },
    ...(environment.production ? [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
    ] : []),
    DATE_PROVIDERS
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
