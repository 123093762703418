import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AppConfigService} from '../../app-config.service';
import {catchError, map} from "rxjs/operators";
import {Store} from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private store: Store<any>
  ) {

  }

  public getNode(nid: string): Observable<any> {
    return this.http
      .get<{ node: any }>(this.appConfig.getConfig().apiBaseUrl + '/content/' + nid);
  }

  public getNodeByContextId(contextId: string): Observable<any> {
    return this.http
      .get<any[]>(this.appConfig.getConfig().adminUrl + '/api/1.0/node/?parameters[app_context_id]=' + contextId)
      .pipe(
        map(nodes => {
          return nodes.map(node => {
            node.path = 'node/' + node.nid;
            node.id = node.nid;
            return node;
          })
        })
      );
  }

  public getNodesByTerm(termId: string, vocabularyId: string): Observable<any> {
    return this.http
      .get<any[]>(this.appConfig.getConfig().adminUrl + '/api/1.0/node/?parameters[' + vocabularyId + ']=' + termId).pipe(map(nodes => {
        return nodes.map(node => {
          node.path = 'node/' + node.nid;
          return node;
        })
      }));
  }

  public getNodeByType(type: string, promoted: boolean = null, limit: number = null): Observable<any> {
    let url: string = this.appConfig.getConfig().adminUrl + '/api/1.0/node/?sort=changed&direction=DESC&parameters[type]=' + type + (promoted ? '&parameters[promote]=1' : '');
    if(typeof limit == 'number' && limit >= 0){
      url = url + '&limit=' + limit;
    }
    return this.http
      .get<any[]>(url)
      .pipe(
        map(nodes => {
          return nodes.map(node => {
            node.path = 'node/' + node.nid;
            return node;
          })
        }),
        catchError(this.handleError<any[]>('getNodeByType', []))
      );
  }

  public getRawNode(nid: string): Observable<any> {
    return this.http
      .get<{ node: any }>(this.appConfig.getConfig().adminUrl + '/api/1.0/node/' + nid);
  }

  public getNodeRelated(nid: string): Observable<any> {
    return this.http
      .get<{ node: any }>(this.appConfig.getConfig().apiBaseUrl + '/content/' + nid + '/related');
  }

  //parameters[nid][0]=12688&parameters[nid][1]=12699

  public getRawNodeCollection(nids: string[]): Observable<any> {
    let request: string[] = nids.map((nid, index) => {
      return 'parameters[nid][' + index + ']=' + nid;
    });
    return this.http
      .get<{ node: any }>(this.appConfig.getConfig().adminUrl + '/api/1.0/node?' + request.join('&'));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  ///api/content/3639/related

  /*public getNodeList(nids: string[]): Observable<{ nodes: any[] }> {
    return this.http
      .get<{ nodes: any[] }>('https://back.entrainement-athle.fr.dev.s3.moonscale.net/api/1.0/node/?parameters[type]=article&pagesize=30&page=0&fields=nid,path,title,display_date&parameters[status]=1&sort=display_date&direction=DESC&parameters[pr_language]=fr&status=1');
  }*/

}
