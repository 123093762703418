import {Injectable, Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'swByPass'
})
@Injectable()
export class SwByPassPipe implements PipeTransform {

  constructor(
  ) {
  }


  transform(url: string): string {
    if (!url) {
      return null;
    }
    const urlObj = new URL(url);
    if(!urlObj.searchParams.has('ngsw-bypass')){
      urlObj.searchParams.append('ngsw-bypass', '1');
    }
    return urlObj.href;
  }
}
