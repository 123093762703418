import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {getSearchLoaded, getSearchLoading, getSearchUserRequestTotal, SearchState, selectAllResults} from "./store";

@Injectable({
  providedIn: 'root'
})
export class SearchResultService {

  searchLoading$: Observable<boolean> = this.store.pipe(select(getSearchLoading));

  searchLoaded$: Observable<boolean> = this.store.pipe(select(getSearchLoaded));

  searchResult$: Observable<any[]> = this.store.pipe(select(selectAllResults));

  public totalCount$: Observable<number> = this.store.pipe(select(getSearchUserRequestTotal));

  constructor(
    private store: Store<SearchState>
  ) { }
}
