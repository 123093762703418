import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

export abstract class AppConfigLoader {
  abstract load();
}

@Injectable()
export class BrowserAppConfigLoader implements AppConfigLoader {

  constructor(private http: HttpClient) {
  }

  load() {
    //console.log('client config loader');
    return this.http.get<any>('/config/appConfig.json').pipe(map(config => {
      const defaultConfigKey = config.defaultConfig;
      let customConfigKey = null;
      try {
        const urlParams = new URLSearchParams(window.location.search);
        customConfigKey = urlParams.get('appConfigKey');
      }catch(e) {}
      if(!customConfigKey && localStorage){
        customConfigKey = localStorage.getItem('app_config_key');
      }
      if(customConfigKey){
        console.warn('Using a custom config key (' + customConfigKey + ') instead of default (' + defaultConfigKey + ')');
      }
      const configKey = customConfigKey ? customConfigKey : defaultConfigKey;
      return config.configs[configKey];
    })).toPromise();
  }
}

@Injectable()
export class ServerAppConfigLoader implements AppConfigLoader {

  constructor(@Inject('ServerAppConfig') private config:any){}

  load() {
    return new Promise((resolve, fault) => {
      resolve(this.config);
    });
  }
}
