import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  get metaTags(): any {
    return this._metaTags;
  }

  set metaTags(value: any) {
    this._metaTags = value;
    this.invalidateMetaTags();
  }

  private _metaTags: any;

  private _currentMetaDefinitions: any[];

  constructor(private meta: Meta,
              private title: Title) {

  }

  invalidateMetaTags() {
    if (this._currentMetaDefinitions) {
      for (let j = 0; j < this._currentMetaDefinitions.length; j++) {
        const existingTagName: string = this._currentMetaDefinitions[j];
        if (this.meta.getTag(existingTagName)) {
          this.meta.removeTag(existingTagName);
        }
      }
    }

    this._currentMetaDefinitions = [];


    if (this.metaTags) {
      for (const name in this.metaTags) {
        const isOg: boolean = name.indexOf('og:') > -1;
        let tagKey: string;
        if (isOg) {
          this.meta.addTag({ property: name, content: this.metaTags[name] });
          tagKey = 'property';
        } else if ( name !== 'title' ) {
          this.meta.addTag({ name: name, content: this.metaTags[name] });
          tagKey = 'name';
        }
        this._currentMetaDefinitions.push( tagKey + '=' + '"' + name + '"');
      }
    }
    if (this.metaTags && this.metaTags.title) {
      this.title.setTitle(this.metaTags.title);
    }
  }

}
