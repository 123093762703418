import { createAction, props } from '@ngrx/store';
import {ContentType, RawContent} from "../../models/node";

export const loadNode = createAction(
  '[Node] Load Node'
);

export const loadNodeSuccess = createAction(
  '[Node] Load Node Success',
  props<{ data: RawContent }>()
);

export const loadNodeFailure = createAction(
  '[Node] Load Node Failure',
  props<{ error: any }>()
);

export const loadNodesByType = createAction(
  '[Node] Load Nodes by type',
  props<{ contentType: ContentType, limit?: number, promoted?: boolean }>()
);

export const loadNodesByTypeSuccess = createAction(
  '[Node] Load Nodes by type Succes',
  props<{ data: RawContent[] }>()
);

export const loadNodesByTypeFailure = createAction(
  '[Node] Load Nodes by type Failure',
  props<{ error: any }>()
);

export const loadNodesSuccess = createAction(
  '[Node] Load Nodes Succes',
  props<{ data: RawContent[], itemFormat: boolean }>()
);

export const loadNodesFailure = createAction(
  '[Node] Load Nodes Failure',
  props<{ error: any }>()
);
