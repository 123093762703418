import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WebformSubmissionResult} from '../store/actions/webforms.action';
import {map} from 'rxjs/operators';
import {AppConfigService} from "../../app-config.service";
import {VERSION_INFO} from "../../../environments/version";

@Injectable()
export class WebformsService {

  constructor(private appConfig: AppConfigService,
              private httpClient: HttpClient) {
  }

  submitForm(webformId: string, submission: any) {

    if (submission && submission.message) {
      submission = {
        ...submission,
        message: submission.message + '\n\nApplication version : ' + VERSION_INFO.raw
      }
    }

    return this.httpClient.post(this.appConfig.getConfig().adminUrl + '/api/1.0/webform-submission', {
      ...submission,
      nid: webformId
    }).pipe(
      map((result): WebformSubmissionResult => {
        return result as WebformSubmissionResult;
      })
    );
  }

}
