/**
 * Created by benoitplatre on 14/08/2018.
 */
import {ContentTypesConstants} from "./content-types-constants";
import {FieldInfos} from "./field-infos";

export class RoutesConstants {

  static readonly homePath: string = 'bienvenue';
  static readonly trainingPath: string = 'espace-formation';
  static readonly encyclopediaPath: string = 'espace-encyclopedique';
  static readonly themePath: string = 'espace-thematique';
  static readonly myAccountPath: string = 'mon-compte';
  static readonly loginPath: string = 'me-connecter';
  static readonly domainsPath: string = 'mes-domaines-et-filieres';

  static readonly userAccountOutlet: string = 'userAccountOutlet';
  static readonly userLoginOutlet: string = 'userLoginOutlet';
  static readonly genericOutlet: string = 'popUp';

  static readonly situationList: string = 'toutes-les-situations';
  static readonly enchainementList: string = 'tous-les-enchainements';
  static readonly progressionList: string = 'toutes-les-progressions';
  static readonly circuitList: string = 'toutes-les-circuits';
  static readonly situationJeunesJugesList: string = 'toutes-les-situations-de-jury';
  static readonly seanceList: string = 'toutes-les-seances';
  static readonly cycleList: string = 'toutes-les-cycles';
  static readonly savoirList: string = 'toutes-les-fiches-savoir';
  static readonly quizList: string = 'tous-les-quiz';
  static readonly videoList: string = 'toutes-les-video';
  static readonly diplomeList: string = 'tous-les-diplomes';
  static readonly moduleList: string = 'tous-les-modules';
  static readonly faceAFaceList: string = 'tous-les-contenus-face-a-face';
  static readonly themeList: string = 'tous-les-themes';
  static readonly themeDetail: string = 'theme';

  static readonly contentTypeRoutesMap: {[path: string]: RouteProperties} = {
    fiche_peda:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.situationList, detail:'situation'},
    enchainement_situations:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.enchainementList, detail:'enchainement'},
    situation_jeunes_juges:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.situationJeunesJugesList, detail:'situation-de-jury'},
    seance:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.seanceList, detail:'seance'},
    cycle:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.cycleList, detail:'cycle'},
    theoretical_content:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.savoirList, detail:'savoir'},
    quiz:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.quizList, detail:'quiz'},
    ffa_video_athle_tv:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.videoList, detail:'video'},
    formation:{universe:RoutesConstants.trainingPath, list:RoutesConstants.faceAFaceList, detail:'face-a-face'},
    formation_module:{universe:RoutesConstants.trainingPath, list:RoutesConstants.moduleList, detail:'module'},
    formation_diplome:{universe:RoutesConstants.trainingPath, list:RoutesConstants.diplomeList, detail:'diplome'},
    ffa_theme:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.themeList, detail:RoutesConstants.themeDetail},
    progression:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.progressionList, detail:'progression'},
    circuit:{universe:RoutesConstants.encyclopediaPath, list:RoutesConstants.circuitList, detail:'circuit'},
  };

  static readonly routesContentTypesMap:{[route: string]: FieldInfos} = {
    [RoutesConstants.situationList]: ContentTypesConstants.situation,
    [RoutesConstants.enchainementList]: ContentTypesConstants.enchainement,
    [RoutesConstants.situationJeunesJugesList]: ContentTypesConstants.situationJeunesJuges,
    [RoutesConstants.seanceList]: ContentTypesConstants.seance,
    [RoutesConstants.cycleList]: ContentTypesConstants.cycle,
    [RoutesConstants.savoirList]: ContentTypesConstants.savoir,
    [RoutesConstants.quizList]: ContentTypesConstants.quiz,
    [RoutesConstants.videoList]: ContentTypesConstants.video,
    [RoutesConstants.faceAFaceList]: ContentTypesConstants.faceAface,
    [RoutesConstants.moduleList]: ContentTypesConstants.module,
    [RoutesConstants.diplomeList]: ContentTypesConstants.diplome,
    [RoutesConstants.themeList]: ContentTypesConstants.theme,
    [RoutesConstants.circuitList]: ContentTypesConstants.circuit,
    [RoutesConstants.progressionList]: ContentTypesConstants.progression

  }

}

export class RouteProperties {
  universe: string;
  list: string;
  detail: string;
}
