/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {of} from "rxjs";
import {ContentTypesConstants} from "../core/models/content-types-constants";
import {Universe} from "../core/models/universe";


@Injectable()
export class SearchContextResolver implements Resolve<any> {

  constructor(
    private store: Store<any>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {

    let componentParams: any = {};
    let universes: Universe[];

    if (route.data.universe == ContentTypesConstants.universeEncyclopedia) {

      universes = [
        {
          label: 'Pratique',
          name: 'practice',
          contentTypes:
            [
              ContentTypesConstants.situation,
              ContentTypesConstants.enchainement,
              ContentTypesConstants.enchainement,
              ContentTypesConstants.seance,
              ContentTypesConstants.cycle,
              ContentTypesConstants.situationJeunesJuges
            ],
          requests: [
            {
              contentType:ContentTypesConstants.situation,
              name:ContentTypesConstants.situation.name,
              label: ContentTypesConstants.situation.label,
              filters: []
            },
            {
              contentType:ContentTypesConstants.enchainement,
              name:'progressions',
              label: 'Progressions',
              filters: [{name:'practical_type', options:[{key:'progression'}]}]
            },
            {
              contentType:ContentTypesConstants.enchainement,
              name:'circuits',
              label: 'Circuits',
              filters: [{name:'practical_type', options:[{key:'circuit'}]}]
            },
            {
              contentType:ContentTypesConstants.seance,
              name:ContentTypesConstants.seance.name,
              label: ContentTypesConstants.seance.label,
              filters: []
            },
            {
              contentType:ContentTypesConstants.cycle,
              name:ContentTypesConstants.cycle.name,
              label: ContentTypesConstants.cycle.label,
              filters: []
            },
            {
              contentType:ContentTypesConstants.situationJeunesJuges,
              name:ContentTypesConstants.situationJeunesJuges.name,
              label: ContentTypesConstants.situationJeunesJuges.label,
              filters: []
            },
          ],
          params: {ct: ContentTypesConstants.situation.name}
        },
        {
          label: 'Savoir',
          name: 'theory',
          contentTypes: [
            ContentTypesConstants.savoir
          ],
          requests: [
            {
              contentType:ContentTypesConstants.savoir,
              name:'savoir',
              label: 'Fiches Savoir',
              filters: []
            },
            {
              contentType:ContentTypesConstants.savoir,
              name:'lexique',
              label: 'Lexique',
              filters:
                [
                  {name:'field_difficulte', options:[{key:'434'}]}
                ]
            },
            {
              contentType:ContentTypesConstants.savoir,
              name:'historic',
              label: 'Historique',
              filters:
                [
                  {name:'field_contenu_historique', options:[{key:'1'}]}
                ]
            }
          ],
          params: {ct: ContentTypesConstants.savoir.name}
        },
        {
          label: 'Quiz',
          name: 'quiz',
          contentTypes: [
            ContentTypesConstants.quiz
          ],
          requests: [
            {
              contentType:ContentTypesConstants.quiz,
              name:'facile',
              label: 'Facile',
              filters: [
                {name:'field_quiz_level', options:[{key:'590'}]}
              ]
            },
            {
              contentType:ContentTypesConstants.quiz,
              name:'moyen',
              label: 'Moyen',
              filters: [
                {name:'field_quiz_level', options:[{key:'591'}]}
              ]
            },
            {
              contentType:ContentTypesConstants.quiz,
              name:'facile',
              label: 'Diabolique',
              filters: [
                {name:'field_quiz_level', options:[{key:'593'}]}
              ]
            }
          ],
          params: {ct: ContentTypesConstants.quiz.name}
        },
        {
          label: 'Video',
          name: 'video',
          contentTypes: [
            ContentTypesConstants.video
          ],
          requests: [
            {
              contentType:ContentTypesConstants.video,
              name:'video_high_speed',
              label: 'Séquence haute vitesse (100 i/s ou +)',
              filters: [
                {name:'field_type_de_vid_o', options:[{key:'698'}]}
              ]
            },
            {
              contentType:ContentTypesConstants.video,
              name:'video_normal_speed',
              label: 'Séquence vitesse normale (25 i/s)',
              filters:
                [
                  {name:'field_type_de_vid_o', options:[{key:'557'}]}
                ]
            },
            {
              contentType:ContentTypesConstants.video,
              name:'video_clip',
              label: 'Clip (< à 3\')',
              filters:
                [
                  {name:'field_type_de_vid_o', options:[{key:'558'}]}
                ]
            },
            {
              contentType:ContentTypesConstants.video,
              name:'video_reportage',
              label: 'Reportage (< à 12\')',
              filters:
                [
                  {name:'field_type_de_vid_o', options:[{key:'559'}]}
                ]
            },
            {
              contentType:ContentTypesConstants.video,
              name:'video_film',
              label: 'Film',
              filters:
                [
                  {name:'field_type_de_vid_o', options:[{key:'560'}]}
                ]
            }
          ],
          params: {ct: ContentTypesConstants.video.name}
        }
      ];

    } else if (route.data.universe == ContentTypesConstants.universeTraining) {

      universes = [
        {
          label: 'Types de contenus Formation',
          name: 'practice',
          contentTypes:
            [
              ContentTypesConstants.diplome,
              ContentTypesConstants.module,
              ContentTypesConstants.faceAface,
              ContentTypesConstants.theme,
              ContentTypesConstants.quiz
            ],
          params: {ct: ContentTypesConstants.situation.name}
        }
      ];

    }

    componentParams.universes = universes;

    return of(componentParams);

  }

}


