import {Component, NgModule, OnInit} from '@angular/core';
import {LoadUserMeta, UpdateUser} from "../../../../../core/store/actions";
import {select, State, Store} from "@ngrx/store";
import {getUserInfos, getUserMeta} from "../../../../../core/store/reducers";
import {User, UserMeta, UserMetaProfileField, UserMetaProfileFieldValue} from "../../../../../core/models/user";
import {filter, map} from "rxjs/operators";
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {combineLatest, Observable} from "rxjs";
import {CommonModule} from "@angular/common";
import {MatRadioModule} from "@angular/material/radio";
import {MatCheckboxModule} from "@angular/material/checkbox";

@Component({
  selector: 'ea-interest-form',
  templateUrl: './interest-form.component.html',
  styleUrls: ['./interest-form.component.scss']
})
export class InterestFormComponent implements OnInit {

  form: FormGroup;

  formKeys: string[];

  fieldLabels: any = {
    field_public_cible: 'Public cible',
    field_specialite: 'Spécialités',
    field_fonction_principale: 'Fonction principale',
    field_niveau_de_pratique_scolair: 'Niveau de pratique'
  };

  userMeta$ = this.store.pipe(
    select(getUserMeta),
    filter(userMeta => !!userMeta)
  );

  userMeta: UserMeta;
  userInfos: User;

  userInfos$ = this.store.pipe(
    select(getUserInfos),
    filter(userInfos => !!userInfos),
    map(userInfos => {
      return {...userInfos};
    })
  );

  userInfosMeta$: Observable<any>;

  fieldUserMetaCorrespondances: any = {
    'field_public_cible': 'targetAudience',
    'field_specialite': 'specialties',
    'field_fonction_principale': 'mainFunction',
    'field_niveau_de_pratique_scolair': 'practiceLevel'
  }

  constructor(
    private store: Store<State<any>>,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {

    this.store.dispatch(new LoadUserMeta());

    this.userInfosMeta$ = combineLatest(
      this.userMeta$,
      this.userInfos$,
    ).pipe(
      map(([userMeta, userInfos]) => {
        return {userMeta: userMeta, userInfos: userInfos};
      })
    );
    //TODO unscubscribe
    this.userInfosMeta$.subscribe((result) => {
      this.userMeta = result.userMeta;
      this.userInfos = result.userInfos;
      this.createForm();
    });

  }

  createForm() {

    const form: any = {};

    const whiteList: string[] = [
      'field_public_cible',
      'field_specialite',
      'field_fonction_principale',
      'field_niveau_de_pratique_scolair'

    ];

    this.form = null;
    this.formKeys = [];

    let checkboxes: any = ['field_public_cible', 'field_specialite'];
    let radios: any = ['field_fonction_principale', 'field_niveau_de_pratique_scolair'];

    for (const key in this.userMeta.metaProfile) {
      let value: UserMetaProfileField = this.userMeta.metaProfile[key];
      if (whiteList.indexOf(key) > -1) {
        this.formKeys.push(key);
        if(this.isCheckBoxes(key)){
          const formItem: FormArray = this.buildArrayField(key, value, this.userInfos[this.fieldUserMetaCorrespondances[key]]);
          form[key] = formItem;
        }else if(this.isRadios(key)){
          const formItem: any = this.fb.control(this.userInfos[this.fieldUserMetaCorrespondances[key]]);
          form[key] = formItem;
        }
      }
    }

    this.form = this.fb.group(form);
  }

  isRadios(key: string){
    let radios: any = ['field_fonction_principale', 'field_niveau_de_pratique_scolair'];
    return radios.indexOf(key) > -1;
  }

  isCheckBoxes(key: string){
    let checkboxes: any = ['field_public_cible', 'field_specialite'];
    return checkboxes.indexOf(key) > -1;
  }

  buildArrayField(fieldName: string, field: UserMetaProfileField, userFieldData: string[]) {
    const arr = field.allowed_values.map((value: UserMetaProfileFieldValue) => {
      let formItemValue: string = (userFieldData.indexOf(value.key) > -1 ? value.key : '');
      return this.fb.control(formItemValue);
    });
    return this.fb.array(arr);
  }

  submit() {
    let formValue: any = this.form.value;
    for (const key in formValue) {
      if(this.isCheckBoxes(key)){
        if (formValue[key]) {
          formValue[key] = formValue[key].filter(item => item !== '');
        }
      }
    }
    for (const key in formValue) {
      this.userInfos[this.fieldUserMetaCorrespondances[key]] = formValue[key];
    }
    this.store.dispatch(new UpdateUser(this.userInfos));
  }

  onCheckBoxChange(event, formControl, key, i) {
    event.checked ? formControl.patchValue(this.userMeta.metaProfile[key].allowed_values[i].key) : formControl.patchValue('');
    this.submit();
  }

  onRadioChange() {
    this.submit();
  }

}

@NgModule({
  declarations: [
    InterestFormComponent
  ],
  exports: [
    InterestFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule
  ]
})
export class InterestFormModule { }
