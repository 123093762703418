// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {ContentTypesConstants} from "../core/models/content-types-constants";

export const UNIVERSE_ENCYCLOPEDIA_CONTENT_TYPES = new InjectionToken<string>(
  'The Content Types of the encylopedia universe'
);

export const UNIVERSE_TRAINING_CONTENT_TYPES = new InjectionToken<string>(
  'The Content Types of the training universe'
);


export const CONTENTS_PROVIDERS: Provider[] = [
  {
    provide: UNIVERSE_ENCYCLOPEDIA_CONTENT_TYPES,
    useValue: [
      ContentTypesConstants.situation.contentType,
      ContentTypesConstants.savoir.contentType,
      ContentTypesConstants.enchainement.contentType,
      ContentTypesConstants.video.contentType,
      ContentTypesConstants.quiz.contentType,
      ContentTypesConstants.seance.contentType,
      ContentTypesConstants.cycle.contentType,
      ContentTypesConstants.situationJeunesJuges.contentType
    ]
  },
  {
    provide: UNIVERSE_TRAINING_CONTENT_TYPES,
    useValue: [
      ContentTypesConstants.diplome.contentType,
      ContentTypesConstants.module.contentType
    ]
  }
];


