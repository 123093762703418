/**
 * Created by benoitplatre on 25/06/2018.
 */
import {Action, ActionReducer, ActionReducerMap, INIT, MetaReducer,} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {RouterStateUrl} from '../../shared/utils';
import * as fromRouter from '@ngrx/router-store';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import {storeFreeze} from 'ngrx-store-freeze';
import {DbService} from "../../services/db.service";
import {
  ContextsActionTypes,
  LoadContextSuccess,
  LoadRelatedSuccess,
  LoadSuccess,
  MenuActionTypes,
  MenuLoadSuccess,
  NodeActionTypes
} from "../../core/store/actions";
import {LOAD_PROMOTED_CONTENTS_SUCCESS, PromotedContentsLoadSuccess} from "../../promoted-contents/store";
import {searchReducer, SearchState} from "../../search/store";

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */


/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  search: SearchState;
}


/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  search: searchReducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function (state: State, action: any): State {
    return reducer(state, action);
  };
}


/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger, storeFreeze]
  : [];

export function offlineZonesReducerFactory(dbService: DbService): MetaReducer<any> {
  return (reducer: ActionReducer<any>) => (state, action: Action) => {
    const syncData: boolean = false;
    if (action.type === INIT) {
    }
    if (syncData) {
      if (action.type === NodeActionTypes.LoadSuccess) {
        const nodeLoadSuccess: LoadSuccess = action as LoadSuccess;
        const node: any = nodeLoadSuccess.payload;
        const nid: string = node.id;
        dbService.storage.set('node-' + nid, node).subscribe(() => {
        });
      } else if (action.type === ContextsActionTypes.LoadContextSuccess) {
        const loadContextSuccess: LoadContextSuccess = action as LoadContextSuccess;
        dbService.storage.set('context-' + loadContextSuccess.contextId, loadContextSuccess.payload).subscribe(() => {
        });
      } else if (action.type === LOAD_PROMOTED_CONTENTS_SUCCESS) {
        const promotedContentsLoadSuccess: PromotedContentsLoadSuccess = action as PromotedContentsLoadSuccess;
        dbService.storage.set('home-promoted-' + promotedContentsLoadSuccess.contentTypeName, promotedContentsLoadSuccess.result).subscribe(() => {
        });
      } else if (action.type === NodeActionTypes.LoadRelatedSuccess) {
        const loadRelatedSuccess: LoadRelatedSuccess = action as LoadRelatedSuccess;
        dbService.storage.set('node-related-' + loadRelatedSuccess.nid, loadRelatedSuccess.payload).subscribe(() => {
        });
      } else if(action.type === MenuActionTypes.LoadSuccess){
        const menuLoadSuccess: MenuLoadSuccess = action as MenuLoadSuccess;
        dbService.storage.set('menu-' + menuLoadSuccess.payload.name, menuLoadSuccess.payload).subscribe(() => {
        });
      }
    }
    return reducer(state, action);
  };
}
