import {createFeatureSelector, createSelector} from "@ngrx/store";
import {QuizState} from "./quiz.reducer";
import {Question} from "../models/question";
import {QuizTake, Results, TimerInfos} from "../models/quiz-take";

export const getQuizState = createFeatureSelector<QuizState>('quiz');


export const getQuizNode = createSelector(
  getQuizState,
  (state: QuizState) => state && state.node
);

export const getQuizNodeId = createSelector(
  getQuizNode,
  (node: any) => node ? node.id : null
);

export const getQuizTake = createSelector(
  getQuizState,
  (state: QuizState) => state && state.quizTake
);

export const needToLoadQuizTake = createSelector(
  getQuizState,
  (state: QuizState) => {
    return state.quizTake === null;
  }
);

export const getQuizId = createSelector(
  getQuizState,
  (state: QuizState) => state ? state.quizId : null
);

export const getQuizTakeId = createSelector(
  getQuizTake,
  (quizTake: QuizTake) => quizTake && quizTake.result_id
);

export const getCurrentQuestion = createSelector(
  getQuizState,
  (state: QuizState) => state.question
);

export const getQuestionIndex = createSelector(
  getQuizState,
  (state: QuizState) => state.questionIndex
);

export const getNextQuizUrl = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.questionIndex < state.quizTake.layout.length ? state.questionIndex : 'resultat';
    }else{
      return null;
    }
  }
);

export const isQuizSession = createSelector(
  getQuizState,
  (state: QuizState) => {
    return state.quizSession === true;
  }
);

export const showResultat = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.questionIndex == state.quizTake.layout.length;
    }else{
      return false;
    }
  }
);

export const hasDisplayScore = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.quizTake.review_options && state.quizTake.review_options.end && state.quizTake.review_options.end.score == true;
    }
  }
);

export const hasDisplayCorrect = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.quizTake.review_options && state.quizTake.review_options.end && state.quizTake.review_options.end.correct == true;
    }
  }
);

export const hasQuestionFeedback = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.quizTake.review_options && state.quizTake.review_options.end && state.quizTake.review_options.end.question_feedback == true;
    }
  }
);

export const hasAnswerFeedback = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.quizTake.review_options && state.quizTake.review_options.end && state.quizTake.review_options.end.answer_feedback == true;
    }
  }
);

export const showSolution = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.quizTake.review_options && state.quizTake.review_options.end && state.quizTake.review_options.end.solution == true;
    }
  }
);

export const showUserChoice = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.quizTake.review_options && state.quizTake.review_options.end && state.quizTake.review_options.end.choice == true;
    }
  }
);

export const showQuestions = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake){
      return state.quizTake.review_options && state.quizTake.review_options.end && state.quizTake.review_options.end.quiz_question_view_full == true;
    }
  }
);

export const hideResult = createSelector(
  hasDisplayScore,
  hasDisplayCorrect,
  (hasDisplayScore: boolean, hasDisplayCorrect: boolean) => {
    return hasDisplayScore === false && hasDisplayCorrect === false;
  }
);

export const getQuizResults = createSelector(
  getQuizState,
  (state: QuizState) => {
    return state.quizTake && state.quizTake.results;
  }
);

export const getScore = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake && state.quizTake.results){
      const results: Results = state.quizTake.results;
      return results.score ? parseInt(results.score)/5 : null;
    }
  }
);

export const isSuccess = createSelector(
  getQuizState,
  (state: QuizState) => {
    if(state.quizTake && state.quizTake.results){
      const results: Results = state.quizTake.results;
      return results.success === 1;
    }
  }
);

export const getAnsweredQuestion = (questionId: string) => createSelector(
  getQuizState,
  (state: QuizState) => state.answeredQuestions[questionId]
);

//OLD

export const getQuestions = createSelector(
  getQuizState,
  (state: QuizState) => state.questions
);

export const getIndex = createSelector(
  getQuizState,
  (state: QuizState) => state && state.questionIndex
);

export const getQuestionLength = createSelector(
  getQuizState,
  (state: QuizState) => state.questions ? state.questions.length : 0
);

export const getCurrentAnswers = createSelector(
  getCurrentQuestion,
  (question: Question) => question ? question.alternatives : null
);

export const getQuestionType = createSelector(
  getCurrentQuestion,
  (question: Question) => question ? question.type : null
);

export const getQuestionAnswers = createSelector(
  getQuizState,
  (state: QuizState) => state.answeredQuestions
);

export const getStarted = createSelector(
  getIndex,
  (index: number) => {
    return index > -1;
  }
);


export const isMultipleAnswers = createSelector(
  getCurrentQuestion,
  (question: Question) => {
    return question ? question.type == Question.multichoice : null;
  }
);

export const hasMultipleSelection = createSelector(
  getCurrentQuestion,
  (question: Question) => {
    return question && question.type == Question.multichoice && question.multiple !== '0';
  }
);

export const isTrueFalseAnswers = createSelector(
  getCurrentQuestion,
  (question: Question) => {
    return question.type == Question.truefalse;
  }
);

export const currentQuizStep = createSelector(
  getQuizState,
  (state: QuizState) => state.step
);

export const showResult = createSelector(
  getQuizState,
  (state: QuizState) => state.showResult
);

export const showEndingScreen = createSelector(
  getQuizState,
  (state: QuizState) => state.showEndingScreen
);

export const getTimerInfos = createSelector(
  getQuizState,
  (state: QuizState) => state.quizTake && state.quizTake.time_tracking
);

export const quizEvaluated = createSelector(
  getQuizState,
  (state: QuizState) => state.quizTake && state.quizTake.is_evaluated
);


export const isTimedQuiz = createSelector(
  getTimerInfos,
  (timerInfos: TimerInfos) => timerInfos && timerInfos.is_timed_quiz == 1
);

export const quizDuration = createSelector(
  getTimerInfos,
  (timerInfos: TimerInfos) => timerInfos && timerInfos.is_timed_quiz == 1 ? timerInfos.time_left : null
);

export const quizQuestionCount = createSelector(
  getQuizTake,
  (quizTake: QuizTake) => quizTake && quizTake.layout ? quizTake.layout.length : 0
);

export const allowSkipping = createSelector(
  getQuizTake,
  (quizTake: QuizTake) => quizTake && quizTake.allow_skipping === 1
);

export const isQuizTimeOut = createSelector(
  getQuizTake,
  (quizTake: QuizTake) => {
    if(quizTake){
      const quizFinished: boolean = quizTake.time_tracking.is_timed_quiz === 1 && quizTake.time_tracking.time_left <= 0;
      return quizFinished;
    }
    return null;
  }
);

export const quizTakeLoading = createSelector(
  getQuizState,
  (state: QuizState) => state.quizTakeLoading
);

export const quizTakeLoaded = createSelector(
  getQuizState,
  (state: QuizState) => state.quizTakeLoaded
);
