// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {ContentTypesConstants} from "../core/models/content-types-constants";
import {select, Store} from "@ngrx/store";
import {getContext} from "../core/store";

export type Contexts = 'themes' | 'trainee-area' | 'contact' | 'encyclopedia' | 'training' | 'home' | 'tutorials';

export const CONTEXT_THEME = new InjectionToken<any>(
  'CONTEXT_THEME'
);

export const CONTEXT_TRAINING = new InjectionToken<any>(
  'CONTEXT_TRAINING'
);

export const CONTEXT_TRAINEE = new InjectionToken<any>(
  'CONTEXT_TRAINEE'
);

export const CONTEXT_TEST = new InjectionToken<any>(
  'CONTEXT_TEST'
);

export const CONTEXTS_PROVIDERS: Provider[] = [
  {
    provide: CONTEXT_THEME,
    deps: [Store],
    useFactory: (store: Store<any>) => {
      return store.pipe(select(getContext('themes')))
    }
  },
  {
    provide: CONTEXT_TRAINING,
    deps: [Store],
    useFactory: (store: Store<any>) => {
      return store.pipe(select(getContext('training')))
    }
  },
  {
    provide: CONTEXT_TRAINEE,
    deps: [Store],
    useFactory: (store: Store<any>) => {
      return store.pipe(select(getContext('trainee-area')))
    }
  }
];


