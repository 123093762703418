import {Pipe, PipeTransform} from '@angular/core';
import {Module, TimedNodeReference} from "../../core/models/module";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode, NodeReference} from "../../core/models/node";
import {Referential} from "../../core/models/trainee-infos";
import {ResourceToDiplomePipe} from "./resource-to-diplome.pipe";
import {Responsible} from "../../core/models/face-a-face";

@Pipe({
  name: 'resourceToModule'
})
export class ResourceToModulePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe
  ) {
  }

  transform(value: any): Module {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    const siFfaCode: string = value.formation_siffa_code;
    const responsable: Responsible = ResourceToDiplomePipe.parseResponsible(value.formation_responsable);
    const referentials: Referential[] = ResourceToDiplomePipe.parseReferentials(value.referential);
    const quizPosition: NodeReference = this.parseReference(value.formation_quiz_position);
    const quizEvaluation: NodeReference = this.parseReference(value.formation_quiz_evaluation);
    const quizCertificate: NodeReference = this.parseReference(value.formation_quiz_certificate);
    const foad: TimedNodeReference = this.parseTimedReference(value.formation_theme_foad, parseInt(value.heures_foad));
    const stage: TimedNodeReference = this.parseTimedReference(value.formation_theme_stage, parseInt(value.heures_stage));
    const faceAface: TimedNodeReference = this.parseTimedReference(value.formation_face_a_face, parseInt(value.heures_face_face));
    return {
      siFfaCode,
      responsable,
      referentials,
      quizPosition,
      quizEvaluation,
      quizCertificate,
      foad,
      stage,
      faceAface,
      ...drupalNode
    };
  }

  parseReference(value: any): NodeReference {
    if (value) {
      return {
        id: value.id,
        label: value.label,
        path: value.path
      }
    }
    return null;
  }

  parseTimedReference(value: any, hours: number): TimedNodeReference {
    if (value) {
      return {
        ...this.parseReference(value),
        hours
      }
    }
    return null;
  }

}
