import {Injectable} from '@angular/core';
import {Action} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {EMPTY, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {PathActionTypes, PathLoadFail, PathLoadSuccess} from '../actions/path';
import {PathService} from '../../services/path.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class PathEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */


  loadPath$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(PathActionTypes.Load),
    map((action: PathLoadSuccess) => action.payload),
    switchMap((query) => {
        if (query === null) {
          return EMPTY;
        }
        let link: string;
        if (query.indexOf('?') > -1) {
          link = query.split('?')[0];
        } else {
          link = query;
        }
        return this.pathService.load(link)
          .pipe(
            map((pathInfos: any) => new PathLoadSuccess(pathInfos)),
            catchError((error: HttpErrorResponse) => {
              return of(new PathLoadFail(error));
            })
          );
      }
    )
  ));

  constructor(private actions$: Actions, private pathService: PathService) {
  }
}
