import {AppCoreActions} from '../actions';

export class Preferences{
  domains: string[];
}

export class CookieBotConsent {

  marketing: boolean;
  necessary: boolean;
  preferences: boolean;
  stamp: string;
  statistics: boolean;

}

export interface State {
  defaultLanguage?: string;
  currentLanguage?: string;
  userPreferredLanguage?: string;
  breakpoint?: string;
  cookieBotConsent?: CookieBotConsent;
  preferences?: Preferences;
  headerTitle: string;
}

const initialState: State = {
  defaultLanguage: null,
  currentLanguage: null,
  userPreferredLanguage: null,
  breakpoint: null,
  preferences: null,
  headerTitle: null
};

export function reducer(
  state: State = initialState,
  action: AppCoreActions.AppCoreActionsUnion
): State {
  switch (action.type) {

    case AppCoreActions.AppCoreActionTypes.SetCookieBotConsent:
      return {
        ...state,
        cookieBotConsent: action.cookieBotConsent
      };

    case AppCoreActions.AppCoreActionTypes.SetPreference:
      return {
        ...state,
        preferences: {...state.preferences, [action.name]:action.value}
      };

    case AppCoreActions.AppCoreActionTypes.SetHeaderTitle:
      return {
        ...state,
        headerTitle: action.title
      };

    default:
      return state;
  }
}

export const getMarketingConsent = (state: State) => state.cookieBotConsent ?  state.cookieBotConsent.marketing : null;

export const getPreferencesConsent = (state: State) => state.cookieBotConsent ?  state.cookieBotConsent.preferences : null;

export const getStatisticsConsent = (state: State) => state.cookieBotConsent ?  state.cookieBotConsent.statistics : null;

export const getCorePreferences = (state: State) => state.cookieBotConsent ?  state.cookieBotConsent.statistics : null;

export const getHeaderTitle = (state: State) => state.headerTitle;
