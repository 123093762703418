import {Pipe, PipeTransform} from '@angular/core';
import {DrupalFile, MediaType, MediaTypes} from "../../core/models/file";

@Pipe({
  name: 'resourceToFile'
})
export class ResourceToFilePipe implements PipeTransform {

  transform(rawData: any): DrupalFile {

    const rawFile: any = rawData?.file ? rawData?.file : rawData;
    const name: string = rawFile?.label || rawData?.name;
    const id: string = rawFile?.id || rawData?.id;
    const entity: any = rawFile?.entity;
    const url: string = entity?.url || rawData?.url;
    const mime: string = entity?.mime || rawData?.mime;
    let weight: number;

    if(entity?.size){
      weight = parseInt(entity?.size);
    }else if(rawData?.size){
      weight = parseInt(rawData?.size);
    }

    let type: MediaType;

    if (entity?.type) {
      type = entity.type
    } else if (rawData?.playableFiles || rawFile?.playableFiles || entity?.playableFiles) {
      type = MediaTypes.video
    } else if (rawData?.mime?.indexOf('image') > -1) {
      type = MediaTypes.image
    }

    let file: DrupalFile = {
      type,
      id,
      name,
      url,
      mime,
      weight
    };

    return file;
  }

}
