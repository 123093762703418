import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {catchError, map, mergeMap} from 'rxjs/operators';
import * as fromRoot from '../../../store/reducers';
import {of} from 'rxjs';
import {empty} from 'rxjs/observable/empty';
import {HttpErrorResponse} from '@angular/common/http';
import {VocabularyActionTypes, VocabularyLoad, VocabularyLoadFail, VocabularyLoadSuccess} from "../actions";
import {TaxonomyService} from "../../services/taxonomy.service";
import {DrupalVocabulary} from "../../models/vocabulary";

@Injectable()
export class TaxonomyEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  
  loadVocabulary$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(VocabularyActionTypes.Load),
    map((action: VocabularyLoad) => action.payload),
    mergeMap((query) => {
        if (query === null) {
          return empty();
        }
        return this.taxonomyService.getVocabulary(query)
          .pipe(
            map((vocabulary: DrupalVocabulary) => new VocabularyLoadSuccess(vocabulary)),
            catchError((error: HttpErrorResponse) => {
              console.error('error', error);
              return of(new VocabularyLoadFail(error.message));
            })
          );
      }
    )
  ));

  constructor(private actions$: Actions, private taxonomyService: TaxonomyService, private store: Store<fromRoot.State>) {
  }
}
