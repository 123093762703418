<div class="col-12">

  <div *ngIf="isContributeur">
    <h3>Contributeur</h3>
    <p>Vous avez un rôle Contributeur sur la plateforme. Ce rôle vous permet de créer et modifier certains contenus selon
      les accès qui vous ont été créés.<br>Merci pour votre implication et votre participation active à l'enrichissement de
      la plateforme !</p>
  </div>

  <div *ngIf="isFormateur">
    <h3>Formateur</h3>
    <p>Vous avez un rôle Formateur sur la plateforme. Ce rôle vous permet d’avoir accès à tout ou partie des contenus de
      Formation de la FFA.</p>
  </div>

  <div *ngIf="isClientAdmin">
    <h3>Client Admin</h3>
    <p>Vous avez un rôle Client Admin sur la plateforme. Ce rôle vous donne accès à presque toutes les fonctionnalités
      sur le back-office, vous êtes quasiment le Maître des clés !</p>
  </div>


</div>
