import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {SearchService} from '../search.service';
import * as actions from './search.action';
import {
  FindContentById,
  LoadFacetsForUniverse,
  LoadFacetsForUniverseSuccess,
  LoadMetaDataSuccess,
  SearchClear,
  SearchUpdateFilters,
  SearchUpdateQuery,
  SetExtendedTypes,
  SetFiltersfromQueryParams
} from './search.action';
import * as fromCore from '../../store/reducers';
import {select, Store} from '@ngrx/store';
import {getPagerLimit, getSearchUserRequest} from './search.reducer';
import {SearchUserRequest} from '../models/search-user-request';
import {catchError, filter, map, mergeMap, switchMap, take, withLatestFrom} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {getDomains, selectRouteUniverse} from "../../core/store/reducers";
import {SearchFilter, SearchFilterOption, SearchPager, SearchResult} from "../models/search-result";
import {FiltersConstants} from "../../core/models/filters-constants";
import {DrupalNode} from "../../core/models/node";
import {Router} from "@angular/router";
import {RouteProperties, RoutesConstants} from "../../core/models/routes-constants";
import {ErrorWindowComponent} from "../../components/error-window/error-window.component";
import {MatDialog} from "@angular/material/dialog";
import {FlattenFilterPipe} from "../../pipes/flatten-filter.pipe";

@Injectable()
export class SearchEffects {

  constructor(private actions$: Actions,
              private searchService: SearchService,
              private store: Store<fromCore.State>,
              private router: Router,
              private dialog: MatDialog,
              private flattenFilter: FlattenFilterPipe) {
  }


  search$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SearchAction>(actions.SEARCH),
    withLatestFrom(
      this.store.pipe(
        select(getSearchUserRequest),
        filter(searchUserRequest => {
          return !!searchUserRequest && (searchUserRequest.query !== null || searchUserRequest.filters !== null || searchUserRequest.contentTypes !== null)
        })),
      this.store.pipe(select(getDomains)),
      this.store.pipe(select(selectRouteUniverse)),
      this.store.pipe(select(getPagerLimit))
    ),
    switchMap(([action, searchUserRequest, domains, universe, pagerLimit]) => {
      if(action.reset === true){
        this.store.dispatch(new SearchClear());
      }
      return this.search(searchUserRequest, domains, universe, pagerLimit, action.reset).pipe(
        map((result: [SearchResult, SearchResult]) => {
          if(result[1]){
            const extendedTypeFilters: SearchFilter = result[1].filters.filter(filter => filter.name === 'extended_type').shift();
            const extendedTypes:SearchFilter = extendedTypeFilters;
            const videoOption: SearchFilterOption = extendedTypes.options.filter(option => option.key === 'ffa_video_athle_tv:default').shift();
            if(videoOption){
              videoOption.label = 'Vidéo';
            }
            const savoirOption: SearchFilterOption = extendedTypes.options.filter(option => option.key === 'theoretical_content:default').shift();
            if(savoirOption){
              savoirOption.label = 'Savoir';
            }
            const moduleOption: SearchFilterOption = extendedTypes.options.filter(option => option.key === 'formation_module:default').shift();
            if(moduleOption){
              moduleOption.label = 'Modules';
            }
            const diplomeOption: SearchFilterOption = extendedTypes.options.filter(option => option.key === 'formation_diplome:default').shift();
            if(diplomeOption){
              diplomeOption.label = 'Diplômes';
            }
            this.store.dispatch(new SetExtendedTypes(extendedTypeFilters));
          }
          let filters: SearchFilter[] = result[0].filters;
          filters.forEach((filter: SearchFilter) => {
            if (FiltersConstants.labels[filter.name]) {
              filter.label = FiltersConstants.labels[filter.name];
            }
          });
          let options: { [key: string]: SearchFilterOption } = {};
          let optionsCount: { [key: string]: number } = {};
          let filtersMap: { [filterName: string]: SearchFilter } = {};
          if (filters) {
            // black list filters
            filters = filters.filter((filter: SearchFilter) => FiltersConstants.blackList.indexOf(filter.name) == -1);
            filters = this.filterSingleOption(filters);
            filters.forEach(filter => {
              filtersMap[filter.name] = filter;
              let processedOptions: { [key: string]: SearchFilterOption } = this.flattenFilter.transform(filter);
              options = {...options, ...processedOptions};
            });
            for (let key in options) {
              optionsCount[key] = options[key].count;
            }
          }

          return new actions.SearchActionSuccess(result[0], filters, filtersMap, options, optionsCount);
        }),
        catchError(error => {
          return of(new actions.SearchActionFail(error));
        }));
    })
  ));


  findContentById$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SearchActions>(actions.FIND_CONTENT_BY_ID),
    map((action: FindContentById) => {
      return this.findContentById(action.payload).pipe(
        map((searchResult: SearchResult) => {
          let node: DrupalNode;
          let nodeType: string;
          if (searchResult.count === 1 && searchResult.items.length > 0) {
            node = searchResult.items[0];
            nodeType = node.type;
          }
          let routes: RouteProperties = RoutesConstants.contentTypeRoutesMap[nodeType];
          if (routes) {
            let universePath: string = routes.universe;
            let collectionPath: string = routes.list;
            let detailPath: string = routes.detail;
            this.router.navigateByUrl(universePath + '/' + detailPath + '/' + node.id);
          } else {
            this.dialog.open(ErrorWindowComponent, {
              width: '500px',
              panelClass: 'user-theme',
              data: {
                title: 'Aucun contenu pour cet ID',
                message: 'Désolé, aucun contenu corresponds à cet ID : ' + action.payload,
                actions: ['OK']
              }
            });
          }
        }),
        take(1)
      ).subscribe();
    })
  ), {dispatch: false});

  /*@Effect()
  searchQueryUpdate$ = this.actions$.pipe(
    filter(action => action.type === actions.SEARCH_UPDATE_QUERY || action.type === actions.SEARCH_UPDATE_FILTERS || action.type === actions.SEARCH_UPDATE_CONTENT_TYPES || action.type === actions.SEARCH_REMOVE_FILTER_OPTION),
    withLatestFrom(
      this.store.pipe(select(hasSearchUserCriteria)),
    ),
    filter(([action, hasCriteria]) => hasCriteria),
    withLatestFrom(
      this.store.pipe(select(getSearchUserRequest))
    ),
    map(([[action, hasCriteria], searchRequest]) => {
      return new SearchAction();
    })
  );*/


  setFiltersFromQueryParams = createEffect(() => this.actions$.pipe(
    ofType<actions.SetFiltersfromQueryParams>(actions.SET_FILTERS_FROM_QUERY_PARAMS),
    withLatestFrom(
      this.store.pipe(select(getSearchUserRequest))
    ),
    map(([action, userRequest]: [SetFiltersfromQueryParams, SearchUserRequest]) => {
      let queryParams: any = {...action.payload};
      let isQueryParamsEmpty: boolean = Object.keys(queryParams).length === 0 && queryParams.constructor === Object;
      let freeTextQuery: string;
      //console.log('isQueryParamsEmpty', isQueryParamsEmpty);
      //console.log('queryParams.q', queryParams.q);
      if (!isQueryParamsEmpty && (queryParams.q || queryParams.q === '')) {
        //console.log('delete queryParams.q', queryParams.q);
        freeTextQuery = queryParams.q;
        delete queryParams.q;
      } else {
        //console.log('don’t delete queryParams.q', queryParams.q);
      }
      let pager: SearchPager;

      if (!isQueryParamsEmpty) {

        if (!isNaN(queryParams.limit)) {
          pager = {};
          pager.limit = Math.min(100, queryParams.limit);
        }

        if (!isNaN(queryParams.offset)) {
          if (!pager) {
            pager = {};
          }
          pager.offset = queryParams.offset;
        }

        delete queryParams.limit;
        delete queryParams.offset;

      }


      this.store.dispatch(new SearchUpdateFilters(queryParams));
      this.store.dispatch(new SearchUpdateQuery(freeTextQuery));
      /*let userFilters: {[filterId: string]:string[]|string} = userRequest && userRequest.filters;
      if(!userFilters){
        //if(!isQueryParamsEmpty){
          this.store.dispatch(new SearchUpdateFilters(queryParams));
        //}
        //if(freeTextQuery){
          this.store.dispatch(new SearchUpdateQuery(freeTextQuery));
        //}
        if(pager){
          this.store.dispatch(new SetPagerInfos(pager));
        }
      }else if(isQueryParamsEmpty) {
        this.store.dispatch(new ClearAllFilters());
        //this.store.dispatch(new SearchAction());
      }else{
        //this.store.dispatch(new SearchAction());
      }*/
    })
  ), {dispatch: false})


  loadMetadata$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SearchAction>(actions.LOAD_META_DATA),
    mergeMap(() => {
      return this.loadMetadata().pipe(
        map((result: any) => {
          return new LoadMetaDataSuccess(result.facets);
        }))
    })
  ));


  loadFacetsForUniverse$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SearchActions>(actions.LOAD_FACETS_FOR_UNIVERSE),
    mergeMap((action: LoadFacetsForUniverse) => {
      return this.getFacets(action.universe).pipe(
        map(facets => {
            let filters: any[] = facets.filters.filter(filter => filter.options.length > 0);
            filters = this.filterSingleOption(filters);
            let options: { [key: string]: SearchFilterOption } = {};
            facets.filters.forEach(filter => {
              if(filter.name === 'extended_type'){
              }
              let processedOptions: { [key: string]: SearchFilterOption } = this.flattenFilter.transform(filter);
              options = {...options, ...processedOptions};
            });
            return new LoadFacetsForUniverseSuccess(action.universe, filters, options);
          }
        )
      )
    })
  ));

  search(searchUserRequest: SearchUserRequest, domains: string[], universe: string, limit: number, reset: boolean): Observable<[SearchResult, SearchResult]> {
    return this.searchService.search(searchUserRequest, limit, reset, domains, universe);
  }

  getFacets(universe: string) {
    return this.searchService.getFacets(universe);
  }

  findContentById(nid: string) {
    return this.searchService.findContentById(nid);
  }

  loadMetadata() {
    return this.searchService.getFacetsMetaData();
  }

  filterSingleOption(filters: SearchFilter[]): SearchFilter[] {
    return filters.filter(filter => {
      if (filter.options.length == 0) {
        return false;
      } else if (filter.options.length == 1) {
        return filter.options[0].label !== 'faux';
      }
      return true;
    });
  }

}
