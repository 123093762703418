<div class="left align-self-stretch align-items-center pl-3">
  <mat-icon svgIcon="icon-search" color="primary"></mat-icon>
</div>

<div class="middle d-flex">

  <form [formGroup]="form">

    <input
      #searchInput
      class="flex-fill"
      formControlName="search"
      [placeholder]="placeholder$|async"
      autocomplete="off"/>

  </form>

</div>

<div class="right justify-content-center align-self-stretch align-items-center">
  <ng-container *ngIf="!!form.get('search').value">
    <mat-icon class="d-flex mr-2 cursor-pointer" svgIcon="icon-reset-search-text-input" (click)="reset()"></mat-icon>
  </ng-container>
</div>
