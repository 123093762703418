<ea-dialog [title]="'Réglages de l’application'">

  <div class="content-group">

    <mat-card>
      <mat-card-title>Environnements</mat-card-title>
      <mat-card-subtitle>Cette Application charge ses contenus depuis Drupal. Drupal permet à la fois de consolider
        toutes
        les données et de mettre à disposition de nombreuses fonctionnalités orientées métier.
      </mat-card-subtitle>
      <mat-card-content>
        <p>Dans la chaîne de développement de l’Application, il existe plusieurs environnements afin de pouvoir mener
          les tests. En choisissant un des environnements ci-dessous, vous allez pouvoir choisir sur quel environnement
          Drupal l'Application va redémarrer.</p>
        <form [formGroup]="form">
          <label>Sélectionner un environnement</label>
          <mat-radio-group formControlName="environment" aria-label="Sélectionner un environnement" class="d-flex flex-column">
            <mat-radio-button class="my-1" value="dev" color="primary">Développement</mat-radio-button>
            <mat-radio-button class="my-1" value="staging" color="primary">Pré-production / Staging</mat-radio-button>
            <mat-radio-button class="my-1" value="prod" color="primary">Production</mat-radio-button>
          </mat-radio-group>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button (click)="reload()" color="primary">Recharger</button>
      </mat-card-actions>
    </mat-card>

  </div>

</ea-dialog>

