/**
 * Created by benoitplatre on 27/06/2018.
 */

export class DrupalVocabulary {

  static readonly domains: string = 'domains';
  static readonly themes: string = 'ffa_theme_category';
  static readonly ageLevel: string = 'age_level';

  name: string;
  items?: DrupalVocabularyTerm[];
}

export class DrupalVocabularyTerm {
  children?: DrupalVocabularyTerm[];
  depth?: number;
  description?: string;
  format?: string;
  name?: string;
  parents?: string[];
  path?: string;
  tid?: string;
  vid?: string;
  vocabulary_machine_name?: string;
  weight?: number;
  status?: string|boolean;
}
