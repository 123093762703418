import {Component, Input, OnInit} from '@angular/core';
import {Order} from "../../../core/models/order";

@Component({
  selector: 'ea-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.scss']
})
export class UserOrdersComponent implements OnInit {

  @Input()
  orders: Order[];

  constructor() { }

  ngOnInit() {
  }

}
