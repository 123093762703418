import {
  CLEAR_PROMOTED_CONTENTS,
  LOAD_PROMOTED_CONTENTS,
  LOAD_PROMOTED_CONTENTS_FAIL,
  LOAD_PROMOTED_CONTENTS_SUCCESS,
  PromotedContentsAction
} from "./promoted-contents.action";
import {createFeatureSelector, createSelector} from "@ngrx/store";

/**
 * Created by benoitplatre on 25/06/2018.
 */

export class PromotedContentsState {
  [contentTypeName: string]: ContentTypePromotedContentsState;
}

export class ContentTypePromotedContentsState {
  loading: boolean;
  loaded: boolean;
  items: any[];
  success: boolean;
  error: any;
}

const initialState: PromotedContentsState = {};
const initialContentTypePromotedContentsState: ContentTypePromotedContentsState = {
  loading: false,
  loaded: false,
  items: [],
  success: false,
  error: null,
};


export function promotedContentsStateReducer(state: PromotedContentsState = initialState, action: PromotedContentsAction): PromotedContentsState {

  if (action.searchUserRequest || action.contentTypeName) {

    let contentTypeName: string;
    if (action.searchUserRequest && action.searchUserRequest.contentTypes && action.searchUserRequest.contentTypes.length > 0) {
      contentTypeName = action.searchUserRequest.contentTypes[0];
    } else if (action.contentTypeName) {
      contentTypeName = action.contentTypeName;
    }

    return {
      ...state,
      [contentTypeName]: contentTypePromotedContentsReducer(state[contentTypeName], action)
    };
  } else if(action.type === CLEAR_PROMOTED_CONTENTS){

    return initialState;

  }

  return state;

}

export function contentTypePromotedContentsReducer(
  state: ContentTypePromotedContentsState = initialContentTypePromotedContentsState,
  action: PromotedContentsAction
): ContentTypePromotedContentsState {

  switch (action.type) {

    case LOAD_PROMOTED_CONTENTS:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };

    case LOAD_PROMOTED_CONTENTS_SUCCESS:
      return {
        ...state,
        items: action.result.items,
        loading: false,
        loaded: true,
        error: false
      };

    case LOAD_PROMOTED_CONTENTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        items: [],
        error: true
      };
  }

  return state;

}

export const getPromotedContentsState = createFeatureSelector<PromotedContentsState>('promotedContents');

// export const getWebFormById = (state: WebformsState, nid: string) => state[nid];

export const getPromotedContentsContentTypeState = (contentTypeName: string) => createSelector(
  getPromotedContentsState,
  (state: PromotedContentsState) => {
    return state[contentTypeName];
  }
);

export const getPromotedContentsContentTypeStateItems = (contentTypeName: string) => createSelector(
  getPromotedContentsContentTypeState(contentTypeName),
  (state: ContentTypePromotedContentsState) => {
    return state ? state.items : [];
  }
);
