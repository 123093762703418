import {Component, HostBinding, Inject, Input, OnInit} from '@angular/core';
import {DrupalMenu} from '../../models/menu';
import {CURRENT_YEAR} from "../../../date.providers";

@Component({
  selector: 'ea-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

  @Input()
  legalMenu: DrupalMenu;

  @Input()
  secondaryMenu: DrupalMenu;

  @Input()
  @HostBinding('class.home-active')
  isHome: boolean;

  constructor(
    @Inject(CURRENT_YEAR) public currentYear: string
  ) {
  }

  ngOnInit() {
  }

}
