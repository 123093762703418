import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {ContextsActionTypes, LoadContext, LoadContextFail, LoadContextSuccess} from "../actions";
import {NodeService} from "../../services/node.service";
import {loadNodeSuccess} from "../actions/node.actions";

@Injectable()
export class ContextsEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  loadContextNode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContextsActionTypes.LoadContext),
      map((action: LoadContext) => {
        return action.payload
      }),
      mergeMap((context: string) => {
          if (context === null) {
            return EMPTY;
          }
          return this.nodeService.getNodeByContextId(context).pipe(
            switchMap(result => {
              if (result?.length > 0) {
                return [
                  new LoadContextSuccess(context, result[0]),
                  loadNodeSuccess({data: result[0]})
                ];
              }
              return [new LoadContextFail('no result')];
            }),
            catchError((error: HttpErrorResponse) => {
              return of(new LoadContextFail(error.message));
            })
          );
        }
      )
    )
  );

  constructor(
    private actions$: Actions,
    private nodeService: NodeService
  ) {
  }
}
