import {Pipe, PipeTransform} from '@angular/core';
import {ComponentEntity, Total} from "../core/models/cart";

@Pipe({
  name: 'cartAmountVat'
})
export class CartAmountVatPipe implements PipeTransform {

  transform(total: Total): any {
    let vatInfos: ComponentEntity = total.components.filter(component => component.name.indexOf('vat') > -1).shift();
    //console.log('vatInfos', vatInfos);
    return 'dont ' + (vatInfos.price.amount/100).toFixed(2) + '€ de TVA à '+((vatInfos.price.data.vat_rate_info.rate)*100)+'%';
  }

}
