import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {AppConfigService} from "../../app-config.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {DrupalVocabulary, DrupalVocabularyTerm} from "../models/vocabulary";

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {

  constructor(
    private http: HttpClient,
    private appConfig:AppConfigService
  ) { }

  /*let url: string = appConfig.getConfig().apiBaseUrl + '/1.0/vocabulary/domains/tree';

  let params = new HttpParams().set('load_entities', '1');
  httpClient.get(url, {params: params}).subscribe(result => console.log('result', result));*/

  public getVocabulary(vid: string): Observable<DrupalVocabulary> {
    let params = new HttpParams().set('load_entities', '1');
    return this.http
      .get(this.appConfig.getConfig().adminUrl + '/api/1.0/vocabulary/' + vid + '/tree', {params:params}).pipe(
        map((result: any[]) => {
          const processedResult: DrupalVocabularyTerm[] = result.map(term => {
            return {
              ...term,
              weight: parseInt(term.weight, 10)
            }
          });
          return {name:vid, items:processedResult};
        })
      );
  }

}
