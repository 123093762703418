import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {catchError, map, mergeMap} from 'rxjs/operators';
import * as fromRoot from '../../../store/reducers';
import {of} from 'rxjs';
import {empty} from 'rxjs/observable/empty';
import {HttpErrorResponse} from '@angular/common/http';
import {MenuActionTypes, MenuLoad, MenuLoadFail, MenuLoadSuccess} from '../actions/menu';
import {MenuService} from '../../services/menu.service';

@Injectable()
export class MenuEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  
  loadMenu$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(MenuActionTypes.Load),
    map((action: MenuLoad) => action.payload),
    mergeMap((query) => {
        if (query === null) {
          return empty();
        }
        return this.menuService.getMenu(query)
          .pipe(
            map((pathInfos: any) => new MenuLoadSuccess(pathInfos)),
            catchError((error: HttpErrorResponse) => {
              return of(new MenuLoadFail(error.message));
            })
          );
      }
    )
  ));

  constructor(
    private actions$: Actions,
    private menuService: MenuService,
    private store: Store<fromRoot.State>
  ) {
  }
}
