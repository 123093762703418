import {DrupalReference} from "./node";

/**
 * Created by benoitplatre on 27/06/2018.
 */

export enum MediaTypes {
  image = 'image',
  video = 'video',
  document = 'document'
}

export type MediaType = MediaTypes.image | MediaTypes.document | MediaTypes.video;

export interface DrupalFile{

  type?: MediaType;
  id?: string;
  mime?: string;
  name?: string;
  weight?: number;
  url?: string;

}

export interface File extends DrupalReference{

  entity:FileEntity;
  file_type: string;
  file_uri: string;
  file_url: string;

}



export interface FileEntity {

  fid: string;
  mime: string;
  name: string;
  size: string;
  timestamp: string;
  type: string;
  url: string;

}

export interface FileFile {

  id: string; //"48544"
  imageStyles: null
  mime: string;// "audio/mpeg"
  name: string;// "Bande son Nordic'fit cardio Niveau 1.mp3"
  size: string;// "13260104"
  styles: string;// null
  uri: string;// "public://bande_son_nordicfitcardio_niveau_1.mp3"
  url: string;// "https://branch-develop.ffa-drupal.moonscale.net/sites/default/files/bande_son_nordicfitcardio_niveau_1.mp3"

}
