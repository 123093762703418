/**
 * Created by benoitplatre on 03/08/2018.
 */
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import 'rxjs/add/operator/do';

@Injectable()
export class BootEffects {

  
  readonly initApplication$ = createEffect(() => this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    tap((action) => {
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions
  ) {
  }
}
