import {Pipe, PipeTransform} from '@angular/core';
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode} from "../../core/models/node";
import {VideoNode} from "../../core/models/video-node";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {Video} from "../../core/models/video";

@Pipe({
  name: 'resourceToVideoNode'
})
export class ResourceToVideoNodePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToMediaPipe: ResourceToMediaPipe
  ) {
  }

  transform(value: any): VideoNode {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);

    let video: Video = null;
    if (value.video) {
      video = this.resourceToMediaPipe.transform(value.video) as Video;
    }

    const videoNode: VideoNode = {
      ...drupalNode
    }

    if(video){
      videoNode.video = video;
    }

    return videoNode;
  }

}
