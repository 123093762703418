import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeHtmlPipe, StripHtmlPipe} from "./pipes";
import {ReferentialTypePipe} from './referential-type.pipe';
import {MenuPathToRoutePipe} from './menu-path-to-route.pipe';
import {ContentTypeToRoutePipe} from './content-type-to-route.pipe';
import {RouteToContentTypePipe} from './route-to-content-type.pipe';
import {ContentTypeToLabelPipe} from './content-type-to-label.pipe';
import {DifficultyLabelToLevelPipe} from './difficulty-label-to-level.pipe';
import {UniverseKeyToLabelPipe} from './universe-key-to-label.pipe';
import {ParseIntPipe} from './parse-int.pipe';
import {FileIconPipe} from './file-icon.pipe';
import {MediaUrlPipe} from "./media-url-pipe";
import {AgeLevelPipe} from './age-level.pipe';
import {ResourcesPipe} from './resources.pipe';
import {BookmarkPipe} from "./bookmark-pipe";
import {TableWrapperPipe} from './table-wrapper.pipe';
import {DynamicQueryParamsPipe} from './dynamic-query-params.pipe';
import {FlattenFilterPipe} from './flatten-filter.pipe';
import {WebformSelectorOptionsPipe} from './webform-selector-options.pipe';
import {FilterLabelPipe} from './filter-label.pipe';
import {CartAmountVatPipe} from "./cart-amount-vat.pipe";
import {QuizTypePipe} from "./quiz-type.pipe";
import {EditUrlPipe} from "./edit-url.pipe";
import {MinuteSecondPipe} from './minute-second.pipe';
import {ContentTypeThemePipe} from "./content-theme.pipe";
import {SwByPassPipe} from "./sw-bypass-pipe";
import {CleanTextPipe} from "./clean-texts.pipe";
import {LinesPipe, NgArrayPipesModule, NgMathPipesModule, NgObjectPipesModule, NgStringPipesModule} from "ngx-pipes";
import {HackedTextPipe} from "./hacked-text.pipe";
import {ReplaceNbspPipe} from "./replace-nbsp.pipe";
import {PunctuationPipe} from "./punctuation-pipe";
import {CorrectAnswerPipe} from "./correct-answer-pipe";
import {BookmarksByUniversePipe} from "./bookmarks-by-universe.pipe";
import {FormationDurationPipe} from "./formation-duration.pipe";
import {QueryParamsMultiPipe} from "./query-params-multi.pipe";
import {QueryParamsActivePipe} from "./query-params-active.pipe";
import {ContentToExtendedTypePipe} from "./content-to-extended-type.pipe";
import {RemoveAttacksPipe} from "./remove-attacks.pipe";
import {ResourceToPagePipe} from "./data/resource-to-page.pipe";
import {ResourceToDocumentPipe} from "./data/resource-to-document.pipe";
import {ResourceToDrupalNodePipe} from "./data/resource-to-drupal-node.pipe";
import {ResourceToImagePipe} from "./data/resource-to-image.pipe";
import {ResourceToMediaPipe} from "./data/resource-to-media.pipe";
import {ResourceToTutorialPipe} from "./data/resource-to-tutorial.pipe";
import {ResourceToVideoPipe} from "./data/resource-to-video.pipe";
import {ResourceToFilePipe} from "./data/resource-to-file.pipe";
import {ResourceToNewsPipe} from "./data/resource-to-news.pipe";
import {ResourceToKnowledgePipe} from "./data/resource-to-knowledge.pipe";
import {ResourceToQuizPipe} from "./data/resource-to-quiz.pipe";
import {ResourceToVideoNodePipe} from "./data/resource-to-video-node.pipe";
import {ResourceToSituationPipe} from "./data/resource-to-situation.pipe";
import {ResourceToTermsPipe} from "./data/resource-to-terms.pipe";
import {ResourceToTermPipe} from "./data/resource-to-term.pipe";
import {HtmlCleanupPipe} from "./html-cleanup.pipe";
import {ResourceToCyclePipe} from "./data/resource-to-cycle.pipe";
import {ResourceToMultiSituationsPipe} from "./data/resource-to-multi-situations.pipe";
import {ResourceToSeancePipe} from "./data/resource-to-seance.pipe";
import {ResourceToJugePipe} from "./data/resource-to-juge.pipe";
import {ResourceToDiplomePipe} from "./data/resource-to-diplome.pipe";
import {ResourceToModulePipe} from "./data/resource-to-module.pipe";
import {ResourceToFaceAFacePipe} from "./data/resource-to-face-a-face.pipe";
import {ResourceToThemePipe} from "./data/resource-to-theme.pipe";
import {ResourceToFaqQuestionPipe} from "./data/resource-to-faq-question.pipe";
import {ResourceToWebformPipe} from "./data/resource-to-webform.pipe";

export const CONTENT_PIPES = [
  AgeLevelPipe,
  ResourceToPagePipe,
  ResourceToDocumentPipe,
  ResourceToDrupalNodePipe,
  ResourceToImagePipe,
  ResourceToMediaPipe,
  ResourceToTutorialPipe,
  ResourceToVideoPipe,
  ResourceToFilePipe,
  ResourceToNewsPipe,
  ResourceToKnowledgePipe,
  ResourceToQuizPipe,
  ResourceToVideoNodePipe,
  ResourceToSituationPipe,
  ResourceToTermsPipe,
  ResourceToTermPipe,
  ResourceToCyclePipe,
  ResourceToMultiSituationsPipe,
  ResourceToSeancePipe,
  ResourceToJugePipe,
  HtmlCleanupPipe,
  RemoveAttacksPipe,
  HackedTextPipe,
  CleanTextPipe,
  ReplaceNbspPipe,
  ResourceToDiplomePipe,
  ResourceToModulePipe,
  ResourceToFaceAFacePipe,
  ResourceToThemePipe,
  ResourceToFaqQuestionPipe,
  ResourceToWebformPipe
]

export const PIPES = [
  StripHtmlPipe,
  SafeHtmlPipe,
  ReferentialTypePipe,
  MenuPathToRoutePipe,
  CartAmountVatPipe,
  CleanTextPipe,
  HackedTextPipe,
  ReplaceNbspPipe,
  ContentTypeToRoutePipe,
  ContentTypeThemePipe,
  RouteToContentTypePipe,
  ContentTypeToLabelPipe,
  DifficultyLabelToLevelPipe,
  UniverseKeyToLabelPipe,
  ParseIntPipe,
  FileIconPipe,
  MediaUrlPipe,
  AgeLevelPipe,
  ResourcesPipe,
  BookmarkPipe,
  EditUrlPipe,
  SwByPassPipe,
  TableWrapperPipe,
  DynamicQueryParamsPipe,
  FlattenFilterPipe,
  WebformSelectorOptionsPipe,
  FilterLabelPipe,
  QuizTypePipe,
  MinuteSecondPipe,
  PunctuationPipe,
  CorrectAnswerPipe,
  BookmarksByUniversePipe,
  FormationDurationPipe,
  QueryParamsMultiPipe,
  QueryParamsActivePipe,
  ContentToExtendedTypePipe,
  RemoveAttacksPipe,
  ...CONTENT_PIPES
];

@NgModule({
  imports: [
    CommonModule,
    NgArrayPipesModule,
    NgMathPipesModule,
    NgObjectPipesModule,
    NgStringPipesModule,
  ],
  declarations: PIPES,
  exports: [
    ...PIPES,
    NgArrayPipesModule,
    NgMathPipesModule,
    NgObjectPipesModule,
    NgStringPipesModule
  ],
  providers: [
    LinesPipe
  ]
})
export class PipesModule {
}
