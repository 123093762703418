import {ContentTypesConstants} from "./content-types-constants";

export class BreadcrumbsMap {
  level: number;
  children: string[]
}

export class BreadcrumbsDefinition {

  static simpleContents: string[] = [
    ContentTypesConstants.situation.contentType,
    ContentTypesConstants.circuit.contentType,
    ContentTypesConstants.cycle.contentType,
    ContentTypesConstants.progression.contentType,
    ContentTypesConstants.situationJeunesJuges.contentType,
    ContentTypesConstants.video.contentType,
    ContentTypesConstants.savoir.contentType,
    ContentTypesConstants.quiz.contentType
  ];

  static breadcrumbs: { [key: string]: BreadcrumbsMap } = {
    'home': {
      level: -1,
      children: []
    },
    'trainee-area': {
      level: 0,
      children: [
        ContentTypesConstants.theme.contentType
      ]
    },
    'encyclopedia': {
      level: 0,
      children: [
        ...BreadcrumbsDefinition.simpleContents,
        ContentTypesConstants.theme.contentType
      ]
    },
    'training': {
      level: 0,
      children: [
        ...BreadcrumbsDefinition.simpleContents,
        ContentTypesConstants.theme.contentType
      ]
    },
    [ContentTypesConstants.theme.contentType]: {
      level: 3,
      children: [
        ...BreadcrumbsDefinition.simpleContents
      ]
    },
    [ContentTypesConstants.faceAface.contentType]: {
      level: 3,
      children: []
    },
    [ContentTypesConstants.diplome.contentType]: {
      level: 1,
      children: [
        ContentTypesConstants.module.contentType
      ]
    },
    [ContentTypesConstants.module.contentType]: {
      level: 2,
      children: [
        ContentTypesConstants.theme.contentType,
        ContentTypesConstants.faceAface.contentType
      ]
    },
    [ContentTypesConstants.situation.contentType]: {
      level: 4,
      children: []
    },
    [ContentTypesConstants.circuit.contentType]: {
      level: 4,
      children: []
    },
    [ContentTypesConstants.cycle.contentType]: {
      level: 4,
      children: []
    },
    [ContentTypesConstants.progression.contentType]: {
      level: 4,
      children: []
    },
    [ContentTypesConstants.situationJeunesJuges.contentType]: {
      level: 4,
      children: []
    },
    [ContentTypesConstants.video.contentType]: {
      level: 4,
      children: []
    },
    [ContentTypesConstants.savoir.contentType]: {
      level: 4,
      children: []
    },
    [ContentTypesConstants.quiz.contentType]: {
      level: 4,
      children: []
    }
  }

}
