import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from "@ngrx/store";
import * as fromCore from "./core/store/reducers";
import {getAuthenticated} from "@madeinlune/components";
import {map} from "rxjs/operators";
import {Go} from "./store/actions";
import {SetAuthenticationRedirection} from "./core/store/actions";
import {LoginService} from "./services/login.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private store: Store<fromCore.State>,
    private loginService: LoginService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(getAuthenticated),
      map(authenticated => {
        if (authenticated) {
          return true;
        }
        //console.log('next', next);
        if(next?.data?.anonymousMessage){
          this.loginService.loginMessage = next.data.anonymousMessage;
        }
        this.store.dispatch(new Go({
          path: [
            {
              outlets: {
                primary: 'bienvenue',
                userLoginOutlet: 'me-connecter'
              }
            }
          ]
        }));
        this.store.dispatch(new SetAuthenticationRedirection(state.url.substring(1)));
        return false;
      }));
  }
}
