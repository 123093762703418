/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {LoadContext} from "./core/store/actions";
import {getContext} from "./core/store/reducers";
import {filter, map, take} from "rxjs/operators";
import {Contexts} from "./providers/contexts.providers";


@Injectable()
export class ContextResolver implements Resolve<any> {

  constructor(
    private store: Store<any>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {

    let context: Contexts = route.data.context;
    this.store.dispatch(new LoadContext(context));

    return this.store.pipe(
      select(getContext(context)),
      filter(node => !!node),
      map(node => JSON.parse(JSON.stringify(node))),
      take(1)
    )

  }

}
