import {Injectable} from '@angular/core';
import {Action, select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
  Load,
  LoadFail,
  LoadNodeByContext,
  LoadNodesByTerm,
  LoadRelated,
  LoadRelatedFail,
  LoadRelatedSuccess,
  LoadSuccess,
  NodeActionTypes,
  NodeCollectionLoadSuccess
} from '../actions/node';
import {NodeService} from '../../services/node.service';
import * as fromRoot from '../../../store/reducers';
import {EMPTY, of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {DrupalNode} from "../../models/node";
import {getCurrentNode} from "../reducers/index";
import {loadNodeSuccess} from "../actions/node.actions";

@Injectable()
export class NodeEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  
  loadNode$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(NodeActionTypes.Load),
    mergeMap((action: Load) => {
        //console.log('loadNode$ action', action);
        let id: string = action.payload;
        if (id === null) {
          return EMPTY;
        }
        return this.nodeService.getNode(id)
          .pipe(
            switchMap((node: DrupalNode) => {
              node.path = 'node/' + node.id;
              // console.log('loadNode$ node', node);
              return [
                new LoadSuccess(node),
                loadNodeSuccess({data: node})
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              return of(new LoadFail(error));
            })
          );
      }
    )
  ));

  
  loadNodeRelated$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(NodeActionTypes.LoadRelated),
    mergeMap((action: LoadRelated) => {
        //console.log('loadNode$ action', action);
        let id: string = action.nid;
        if (id === null) {
          return EMPTY;
        }
        return this.nodeService.getNodeRelated(action.nid).pipe(
          map((result) => {
            const relatedNodes: any[] = result ? result.referenced_contents : [];
            return new LoadRelatedSuccess(action.nid, relatedNodes);
          }),
          catchError((error: HttpErrorResponse) => {
            return of(new LoadRelatedFail(action.nid, error.message));
          })
        );
      }
    )
  ));

  
  loadRawNode$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(NodeActionTypes.LoadRaw),
    map((action: Load) => {
      return action.payload
    }),
    mergeMap((id: string) => {
        if (id === null) {
          return EMPTY;
        }
        return this.nodeService.getRawNode(id)
          .pipe(
            map((node: DrupalNode) => {
              node.path = 'node/' + node.id;
              return new LoadSuccess(node);
            }),
            catchError((error: HttpErrorResponse) => {
              return of(new LoadFail(error.message));
            })
          );
      }
    )
  ));

  
  loadRawNodeCollection$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(NodeActionTypes.LoadRawCollection),
    map((action: Load) => {
      return action.payload
    }),
    mergeMap((id: string) => {
        if (id === null) {
          return EMPTY;
        }
        return this.nodeService.getRawNode(id)
          .pipe(
            map((node: DrupalNode) => {
              node.path = 'node/' + node.id;
              return new LoadSuccess(node);
            }),
            catchError((error: HttpErrorResponse) => {
              return of(new LoadFail(error.message));
            })
          );
      }
    )
  ));

  
  reloadNode$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(NodeActionTypes.NodeReload),
    withLatestFrom(
      this.store.pipe(select(getCurrentNode)),
    ),
    filter(actionNode => {
      return !!actionNode[1];
    }),
    map(([action, node]) => {
      return new Load(node.id);
    })
  ));

  
  loadNodeByContext$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(NodeActionTypes.LoadNodeByContext),
    map((action: LoadNodeByContext) => {
      return action.payload
    }),
    mergeMap((id: string) => {
        if (id === null) {
          return EMPTY;
        }
        return this.nodeService.getNodeByContextId(id)
          .pipe(
            map((node: DrupalNode) => {
              node.path = 'node/' + node.id;
              return new LoadSuccess(node);
            }),
            catchError((error: HttpErrorResponse) => {
              return of(new LoadFail(error.message));
            })
          );
      }
    )
  ));

  //parameters[product_category][tid][0]=235
  
  loadNodesByTerm$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(NodeActionTypes.LoadNodesByTerm),
    mergeMap((action: LoadNodesByTerm) => {
        if (action.termId === null || action.vocabularyId === null) {
          return EMPTY;
        }
        return this.nodeService.getNodesByTerm(action.termId, action.vocabularyId)
          .pipe(
            map((nodes: DrupalNode[]) => {
              //console.log('loadNodesByTerm$ nodes', nodes);
              nodes.map(node => node.path = 'node/' + node.nid);
              return new NodeCollectionLoadSuccess(nodes, action.termId);
            }),
            catchError((error: HttpErrorResponse) => {
              return of(new LoadFail(error.message));
            })
          );
      }
    )
  ));

  constructor(
    private actions$: Actions,
    private nodeService: NodeService,
    private store: Store<fromRoot.State>
  ) {
  }
}
