import {Pipe, PipeTransform} from '@angular/core';
import {RoutesConstants} from "../core/models/routes-constants";

@Pipe({
  name: 'contentTypeToRoute'
})
export class ContentTypeToRoutePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return RoutesConstants.contentTypeRoutesMap[value] && RoutesConstants.contentTypeRoutesMap[value][args] ? RoutesConstants.contentTypeRoutesMap[value][args]:value;
  }

}
