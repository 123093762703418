/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {LoadNodesByTerm} from "./core/store/actions";


@Injectable()
export class TermPagesResolver implements Resolve<any> {

  constructor(
    private store: Store<any>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {

    let tid: string = route.params.termId;
    let vid: string = route.data.vocabularyId;
    //console.log('tid', tid);
    this.store.dispatch(new LoadNodesByTerm(tid, vid));
    //parameters[product_category][tid][0]=235
    return true;

  }

}


