import {Pipe, PipeTransform} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {combineLatest, Observable} from "rxjs";
import {getRouterIsBookmark, getRouterUrl} from "../core/store/reducers";
import {map} from "rxjs/operators";
import {getSearchUniverse} from "../search/store";
import {ContentTypesConstants} from "../core/models/content-types-constants";

@Pipe({
  name: 'bookmarksByUniverse'
})
export class BookmarksByUniversePipe implements PipeTransform {

  isBookmarkRoute$: Observable<boolean> = this.store.pipe(select(getRouterIsBookmark));
  universe$: Observable<string> = this.store.pipe(select(getSearchUniverse));
  url$: Observable<string> = this.store.pipe(select(getRouterUrl));

  constructor(private store: Store<any>) {
  }


  transform(data: any[]): Observable<any[]> {
    return combineLatest([this.isBookmarkRoute$, this.universe$, this.url$])
      .pipe(
        map(([isBookmarkRoute, universe, url]: [boolean, string, string]) => {
          if (isBookmarkRoute) {
            if(universe === ContentTypesConstants.universeEncyclopedia || url.indexOf('espace-encyclopedique') > -1){
              return data.filter(item => item.type !== ContentTypesConstants.module.contentType && item.type !== ContentTypesConstants.diplome.contentType);
            }else if(universe === ContentTypesConstants.universeTraining || url.indexOf('espace-formation') > -1){
              return data.filter(item => item.type === ContentTypesConstants.module.contentType || item.type === ContentTypesConstants.diplome.contentType);
            }
          } else {
            return data;
          }
        })
      );
  }

}
