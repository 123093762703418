import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ea-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit {

  @Input()
  isContributeur:boolean;

  @Input()
  isFormateur:boolean;

  @Input()
  isClientAdmin:boolean;

  constructor() { }

  ngOnInit() {
  }

}
