import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { HomePromotedContent } from './home-promoted-content.model';

export const loadHomePromotedContents = createAction(
  '[HomePromotedContent/API] Load HomePromotedContents', 
  props<{ homePromotedContents: HomePromotedContent[] }>()
);

export const addHomePromotedContent = createAction(
  '[HomePromotedContent/API] Add HomePromotedContent',
  props<{ homePromotedContent: HomePromotedContent }>()
);

export const upsertHomePromotedContent = createAction(
  '[HomePromotedContent/API] Upsert HomePromotedContent',
  props<{ homePromotedContent: HomePromotedContent }>()
);

export const addHomePromotedContents = createAction(
  '[HomePromotedContent/API] Add HomePromotedContents',
  props<{ homePromotedContents: HomePromotedContent[] }>()
);

export const upsertHomePromotedContents = createAction(
  '[HomePromotedContent/API] Upsert HomePromotedContents',
  props<{ homePromotedContents: HomePromotedContent[] }>()
);

export const updateHomePromotedContent = createAction(
  '[HomePromotedContent/API] Update HomePromotedContent',
  props<{ homePromotedContent: Update<HomePromotedContent> }>()
);

export const updateHomePromotedContents = createAction(
  '[HomePromotedContent/API] Update HomePromotedContents',
  props<{ homePromotedContents: Update<HomePromotedContent>[] }>()
);

export const deleteHomePromotedContent = createAction(
  '[HomePromotedContent/API] Delete HomePromotedContent',
  props<{ id: string }>()
);

export const deleteHomePromotedContents = createAction(
  '[HomePromotedContent/API] Delete HomePromotedContents',
  props<{ ids: string[] }>()
);

export const clearHomePromotedContents = createAction(
  '[HomePromotedContent/API] Clear HomePromotedContents'
);
