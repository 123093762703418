import {
  CREATE_QUIZ_TAKE,
  CREATE_QUIZ_TAKE_FAIL,
  CREATE_QUIZ_TAKE_SUCCESS,
  CreateQuizTake,
  CreateQuizTakeFail,
  CreateQuizTakeSuccess,
  GO_TO_QUESTION_INDEX,
  GoToQuestionIndex,
  LOAD_QUESTION,
  LOAD_QUIZ_TAKE,
  LOAD_QUIZ_TAKE_FAIL,
  LOAD_QUIZ_TAKE_SUCCESS,
  LoadQuestion,
  LoadQuestionFail,
  LoadQuestionSuccess,
  LoadQuizTake,
  LoadQuizTakeFail,
  LoadQuizTakeSuccess,
  NEXT_QUESTION,
  QuizAction,
  SAVE_QUIZ_ANSWER,
  SAVE_QUIZ_ANSWER_SUCCESS,
  SaveQuizAnswer,
  SaveQuizAnswerFail,
  SaveQuizAnswerSuccess,
  SetQuestionIndex,
  SetQuizStep
} from './quiz.action';
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {select, State, Store} from "@ngrx/store";
import {QuizService} from "../quiz.service";
import {Injectable} from "@angular/core";
import {catchError, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs/observable/of";
import {getIndex, getQuestionIndex, getQuizTake, isQuizTimeOut} from "./quiz.queries";
import {getRouterUrl} from '../../core/store/reducers';
import {Go} from "../../store/actions";
import {QuizTake, QuizTakeQuestion} from "../models/quiz-take";
import {Question} from "../models/question";
import {Router} from "@angular/router";
import {SaveAnswer} from "../models/save-answer";
import {Image} from "../../core/models/image";
import {Video} from "../../core/models/video";

@Injectable()
export class QuizEffects {

  constructor(
    private actions$: Actions,
    private quizService: QuizService,
    private store: Store<State<any>>,
    private router: Router
  ) {
  }


  
  loadQuestion$ = createEffect(() => this.actions$.pipe(
    ofType<QuizAction>(LOAD_QUESTION),
    switchMap((action: LoadQuestion) => {
      return this.loadQuestion(action.resultId, action.questionIndex).pipe(
        switchMap((result) => {

          const rawQuestionImages: any[] = result.alternative_pictures;
          let questionImages: Image[];

          if (rawQuestionImages) {
            questionImages = rawQuestionImages.map(rawImage => {
              const rawEntity: any = rawImage?.file?.entity;
              return {
                id: rawEntity.fid,
                imageStyles: rawEntity.image_entity_styles
              }
            });
          }

          const question: Question = {
            id: result.nid,
            description: result.body ? result.body.value : '',
            type: result.type,
            multiple: result.choice_multi,
            edit_url: result.edit_url,
            answersImageMode: questionImages?.length > 0
          };

          const rawImage: any = result?.image?.file?.entity;
          if(rawImage){

            const image: Image = {
              imageStyles: rawImage.image_entity_styles
            };
            question.image = image;

          }

          const rawVideo: any = result?.video;
          if(rawVideo && !Array.isArray(rawVideo)){

            const video: Video = {
              id: rawVideo.file?.id,
              file: rawVideo.file,
              playableFiles: rawVideo.playable_files.map(playableFile => playableFile.entity),
              thumbnail: rawVideo.thumbnail
            };

            question.video = video;

          }

          if (result?.alternatives) {
            question.alternatives = result.alternatives.map((alternative, index) => {
              let associatedImage: Image;
              if (questionImages && questionImages?.length > index) {
                associatedImage = questionImages[index];
              }
              return {
                ...alternative,
                image: associatedImage
              }
            });
          }

          return [
            new LoadQuestionSuccess(question),
            new SetQuestionIndex(parseInt(action.questionIndex)),
            new SetQuizStep('questions')
          ];
        }),
        catchError(error => {
          return of(new LoadQuestionFail(error));
        })
      );
    }),
  ));

  
  nextQuestion$ = createEffect(() => this.actions$.pipe(
    ofType<QuizAction>(NEXT_QUESTION),
    withLatestFrom(this.store.pipe(select(getIndex))),
    map(([action, index]) => {
      return new GoToQuestionIndex(index + 1);
    }),
  ));

  /*@Effect()
  setAnswersToQuestion = this.actions$.pipe(
    ofType<QuizAction>(SET_ANSWERS_TO_QUESTION),
    map((action) => {
      //return new GoToQuestionIndex(index+1);
    }),
  );*/

  /*@Effect()
  loadResult = this.actions$.pipe(
    ofType<QuizAction>(LOAD_RESULT),
    withLatestFrom(this.store.pipe(select(getQuestions))),
    map(result => result[1]),
    withLatestFrom(this.store.pipe(select(getQuestionAnswers))),
    map(([questions, answers]) => {
      return this.quizService.loadResult(questions, answers);
    }),
    map(result => new LoadResultSuccess(result))
  );*/

  
  createQuizTake = createEffect(() => this.actions$.pipe(
    ofType<CreateQuizTake>(CREATE_QUIZ_TAKE),
    switchMap((action: CreateQuizTake) => {
      return this.quizService.createQuizTake(action.quizId).pipe(
        map(result => new CreateQuizTakeSuccess(result)),
        catchError(error => of(new CreateQuizTakeFail(error)))
      );
    }),
  ));

  
  loadQuizTake = createEffect(() => this.actions$.pipe(
    ofType<LoadQuizTake>(LOAD_QUIZ_TAKE),
    switchMap((action: LoadQuizTake) => {
      return this.quizService.loadQuizTake(action.quizTakeId).pipe(
        map(result => {
          return new LoadQuizTakeSuccess(result);
        }),
        catchError(error => of(new LoadQuizTakeFail(error)))
      );
    }),
  ));

  
  loadQuizTakeFail = createEffect(() => this.actions$.pipe(
    ofType<LoadQuizTakeFail>(LOAD_QUIZ_TAKE_FAIL),
    map((action: LoadQuizTakeFail) => {
      return new Go({path: ['/']});
    })
  ));

  
  createQuizTakeFail = createEffect(() => this.actions$.pipe(
    ofType<CreateQuizTakeFail>(CREATE_QUIZ_TAKE_FAIL),
    map((action: CreateQuizTakeFail) => {
      return new Go({path: ['/']});
    })
  ));

  
  onCreateQuizTakeSuccess = createEffect(() => this.actions$.pipe(
    ofType<CreateQuizTakeSuccess>(CREATE_QUIZ_TAKE_SUCCESS),
    switchMap((action) => {
      let createQuizTakeSuccess: CreateQuizTakeSuccess = action as CreateQuizTakeSuccess;
      let quizTake: any = createQuizTakeSuccess.quizTake;
      if (quizTake && quizTake.layout) {
        return [
          new GoToQuestionIndex(0)
        ];
      } else {
        catchError(error => of(new CreateQuizTakeFail(error)))
      }
    })
  ));

  
  onLoadQuizTakeSuccess = createEffect(() => this.actions$.pipe(
    ofType<CreateQuizTakeSuccess>(LOAD_QUIZ_TAKE_SUCCESS),
    tap((action: CreateQuizTakeSuccess) => {
      const quizTake: QuizTake = action.quizTake;
      if (quizTake.time_tracking.time_left <= 0) {
        //const url = '/quiz-session/' + quizTake.result_id + '/session/resultat';
        //this.store.dispatch(new Go({path: url.split('/')}));
      }
    })
  ), {dispatch: false});

  
  saveQuizAnswer = createEffect(() => this.actions$.pipe(
    ofType<SaveQuizAnswer>(SAVE_QUIZ_ANSWER),
    withLatestFrom(this.store.pipe(select(getQuizTake)), this.store.pipe(select(getQuestionIndex))),
    switchMap(([action, quizTake, questionIndex]: [SaveQuizAnswer, QuizTake, number]) => {
      return this.quizService.saveQuizAnswer(quizTake.result_id, action.answerSubmission, (questionIndex)).pipe(
        map((result: SaveAnswer) => new SaveQuizAnswerSuccess(result, action.answerSubmission.nid)),
        catchError(error => of(new SaveQuizAnswerFail(error)))
      );
    })));


  
  onSaveQuizAnswerSuccess = createEffect(() => this.actions$.pipe(
    ofType<SaveQuizAnswerSuccess>(SAVE_QUIZ_ANSWER_SUCCESS),
    map(action => action),
    tap((action: SaveQuizAnswerSuccess) => {
      const quizTake: QuizTake = action.result.quiz_result;
      if (quizTake.time_tracking.time_left <= 0) {
        //this.router.navigateByUrl('/quiz-session/' + quizTake.result_id + '/session/resultat');
      }
    })
  ), {dispatch: false});

  
  goToQuestionIndex = createEffect(() => this.actions$.pipe(
    ofType<GoToQuestionIndex>(GO_TO_QUESTION_INDEX),
    withLatestFrom(
      this.store.pipe(select(getQuizTake)),
      this.store.pipe(select(getRouterUrl)),
      this.store.pipe(select(getQuestionIndex)),
      this.store.pipe(select(isQuizTimeOut))
    ),
    switchMap(([action, quizTake, routerUrl, questionIndex, isQuizTimeOut]: [GoToQuestionIndex, QuizTake, string, number, boolean]) => {
      const quizTakeId: string = quizTake.result_id;
      const quizTakeQuestion: QuizTakeQuestion = quizTake.layout[action.index];
      const quizTakeQuestionIndex: string = quizTakeQuestion.number;
      const url: string = routerUrl.substring(1);
      const urlWithoutParams: string = url.split('?')[0];
      const urlSplit: string[] = urlWithoutParams.split('/');
      const quizTakeIndex: number = urlSplit.indexOf(quizTakeId);

      if (quizTakeIndex > -1) {
        urlSplit.length = quizTakeIndex + 1;
      } else {
        urlSplit.push(quizTakeId);
      }
      if (!isQuizTimeOut) {
        urlSplit.push(quizTakeQuestionIndex);
      } else if (isQuizTimeOut === true) {
        urlSplit.push('resultat');
      }

      return [
        new Go({path: urlSplit})
      ];
    })
  ));


  loadQuestion(resultId: string, questionIndex: string) {
    return this.quizService.loadQuestion(resultId, questionIndex);
  }

}
