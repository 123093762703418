/**
 * Created by benoitplatre on 25/06/2018.
 */
import {Action} from '@ngrx/store';
import {BreadCrumb} from "../../models/breadcrumb";

export enum UIActionTypes {
  HomeActive = '[UIActionTypes] Active',
  UpdateBreakpoint = '[UIActionTypes] Update Breakpoint',
  UpdateMainMenuOpen = '[UIActionTypes] Update Main Menu Open',
  UpdateBookmarksMenuOpen = '[UIActionTypes] Update Bookmarks Menu Open',
  ShowDomainWindow = '[UIActionTypes] Show Domain Window',
  HideDomainWindow = '[UIActionTypes] Hide Window',
  AddBreadCrumb = '[UIActionTypes] Add BreadCrumb',
  ResetBreadCrumb = '[UIActionTypes] Reset BreadCrumb',
  UpdateBreadCrumb = '[UIActionTypes] Update BreadCrumb',
  InsertBreadCrumb = '[UIActionTypes] Insert BreadCrumb',
  ShowLoginWindow = '[UIActionTypes] Show Login Window',
  HideLoginWindow = '[UIActionTypes] Hide Login Window',
  SetUniverse = '[UIActionTypes] Set Universe',
  LoadModuleThemes = '[UIActionTypes] Set Module Themes',
  LoadModuleThemesSuccess = '[UIActionTypes] Load Module Themes Success',
  ClearNavigatedModule = '[UIActionTypes] Clear Navigated Module',
  LoadNavigatedThemeNodes = '[UIActionTypes] Load Navigated Theme Nodes',
  LoadNavigatedThemeNodesSuccess = '[UIActionTypes] Load Navigated Theme Nodes Success',
  ClearStaticContents = '[UIActionTypes] Clear Static Contents',
  UpdateStaticContents = '[UIActionTypes] Update Static Contents',
  AddToHistory = '[UIActionTypes] Add to History',
  ClearHistory = '[UIActionTypes] Clear History',
  LoadNavigatedModuleNodes = '[UIActionTypes] Load Navigated Module Nodes',
  LoadNavigatedModuleNodesSuccess = '[UIActionTypes] Load Navigated Module Nodes Success',
  LoadNavigatedDiplomeNodes = '[UIActionTypes] Load Navigated Diplome Nodes',
  LoadNavigatedDiplomeNodesSuccess = '[UIActionTypes] Load Navigated Diplome Nodes Success',
}

export class HomeActive implements Action {

  readonly type = UIActionTypes.HomeActive;

  constructor(public payload: boolean) {
  }

}

export class UpdateBreakpoint implements Action {

  readonly type = UIActionTypes.UpdateBreakpoint;

  constructor(public payload: string) {
  }

}

export class UpdateMainMenuOpen implements Action {

  readonly type = UIActionTypes.UpdateMainMenuOpen;

  constructor(public payload: boolean) {
  }

}

export class UpdateBookmarksMenuOpen implements Action {

  readonly type = UIActionTypes.UpdateBookmarksMenuOpen;

  constructor(public payload: boolean) {
  }

}

export class ShowDomainsWindow implements Action {

  readonly type = UIActionTypes.ShowDomainWindow;

  constructor() {
  }

}

export class HideDomainWindow implements Action {

  readonly type = UIActionTypes.HideDomainWindow;

  constructor() {
  }

}

export class AddBreadCrumb implements Action {

  readonly type = UIActionTypes.AddBreadCrumb;

  constructor(public payload: BreadCrumb, public level: number) {
  }

}

export class InsertBreadCrumb implements Action {

  readonly type = UIActionTypes.InsertBreadCrumb;

  constructor(public payload: BreadCrumb, public index: number) {
  }

}

export class UpdateBreadCrumb implements Action {

  readonly type = UIActionTypes.UpdateBreadCrumb;

  constructor(public payload: BreadCrumb) {
  }

}

export class ResetBreadCrumb implements Action {

  readonly type = UIActionTypes.ResetBreadCrumb;

  constructor(public payload: BreadCrumb = null) {
  }

}

export class AddToHistory implements Action {

  readonly type = UIActionTypes.AddToHistory;

  constructor(public content: any) {
  }

}

export class ClearHistory implements Action {

  readonly type = UIActionTypes.ClearHistory;

  constructor() {
  }

}

export class ShowLoginWindow implements Action {

  readonly type = UIActionTypes.ShowLoginWindow;

  constructor() {
  }

}

export class HideLoginWindow implements Action {

  readonly type = UIActionTypes.HideLoginWindow;

  constructor() {
  }

}

export class SetUniverse implements Action {

  readonly type = UIActionTypes.SetUniverse;

  constructor(public universe: string) {
  }

}

export class LoadModuleThemes implements Action {

  readonly type = UIActionTypes.LoadModuleThemes;

  constructor(public moduleId: string) {
  }

}

export class LoadModuleThemesSuccess implements Action {

  readonly type = UIActionTypes.LoadModuleThemesSuccess;

  constructor(public moduleId: string, public module: any) {
  }

}

export class ClearNavigatedModule implements Action {

  readonly type = UIActionTypes.ClearNavigatedModule;

  constructor() {
  }

}

export class LoadNavigatedThemeNodes implements Action {

  readonly type = UIActionTypes.LoadNavigatedThemeNodes;

  constructor(public themeId: string) {
  }

}

export class LoadNavigatedThemeNodesSuccess implements Action {

  readonly type = UIActionTypes.LoadNavigatedThemeNodesSuccess;

  constructor(public themeId: string, public theme: any) {
  }

}

export class LoadNavigatedModuleNodes implements Action {

  readonly type = UIActionTypes.LoadNavigatedModuleNodes;

  constructor(public moduleId: string) {
  }

}

export class LoadNavigatedModuleNodesSuccess implements Action {

  readonly type = UIActionTypes.LoadNavigatedModuleNodesSuccess;

  constructor(public moduleId: string, public module: any) {
  }

}

export class LoadNavigatedDiplomeNodes implements Action {

  readonly type = UIActionTypes.LoadNavigatedDiplomeNodes;

  constructor(public diplomeId: string) {
  }

}

export class LoadNavigatedDiplomeNodesSuccess implements Action {

  readonly type = UIActionTypes.LoadNavigatedDiplomeNodesSuccess;

  constructor(public diplomeId: string, public diplome: any) {
  }

}


export class ClearStaticContents implements Action {

  readonly type = UIActionTypes.ClearStaticContents;

  constructor() {
  }

}

export class UpdateStaticContents implements Action {

  readonly type = UIActionTypes.UpdateStaticContents;

  constructor(public contents: any[]) {
  }

}

export type UIActions =
  HomeActive
  | UpdateBreakpoint
  | UpdateMainMenuOpen
  | ShowDomainsWindow
  | HideDomainWindow
  | AddBreadCrumb
  | ResetBreadCrumb
  | ShowLoginWindow
  | HideLoginWindow
  | SetUniverse
  | LoadModuleThemes
  | LoadModuleThemesSuccess
  | ClearNavigatedModule
  | LoadNavigatedThemeNodes
  | LoadNavigatedThemeNodesSuccess
  | ClearStaticContents
  | UpdateStaticContents
  | AddToHistory
  | ClearHistory
  | UpdateBreadCrumb
  | InsertBreadCrumb
  | LoadNavigatedModuleNodes
  | LoadNavigatedModuleNodesSuccess
  | LoadNavigatedDiplomeNodes
  | LoadNavigatedDiplomeNodesSuccess
  | UpdateBookmarksMenuOpen;
