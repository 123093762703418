import { Pipe, PipeTransform } from '@angular/core';
import {FaqQuestion} from "../../core/models/faq-question";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode} from "../../core/models/node";

@Pipe({
  name: 'resourceToFaqQuestion'
})
export class ResourceToFaqQuestionPipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe
  ) {
  }

  transform(value: unknown): FaqQuestion {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    return drupalNode;
  }

}
