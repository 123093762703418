/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Load, LoadRaw} from "./core/store/actions";


@Injectable()
export class NidResolver implements Resolve<any> {

  constructor(
    private store: Store<any>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {

    let nid: string = route.data.nid;
    if(nid){
      if(route.data.raw){
        this.store.dispatch(new LoadRaw(nid));
      }else{
        this.store.dispatch(new Load(nid));
      }
    }

    return nid;

  }

}


