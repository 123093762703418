/**
 * Created by benoitplatre on 25/06/2018.
 */
import {Action} from '@ngrx/store';

export enum PathActionTypes {
  Load = '[Path] Load',
  LoadSuccess = '[Path] Load Success',
  LoadFail = '[Path] Load Fail',
}

export class PathLoad implements Action {

  readonly type = PathActionTypes.Load;

  constructor(public payload: string) {
  }

}

export class PathLoadSuccess implements Action {

  readonly type = PathActionTypes.LoadSuccess;

  constructor(public payload: any) {
  }
}

export class PathLoadFail implements Action {

  readonly type = PathActionTypes.LoadFail;

  constructor(public payload: any) {
  }

}

export type PathActions = PathLoad | PathLoadSuccess | PathLoadFail;
