<div class="back-layer position-absolute d-flex align-items-center justify-content-center">

  <a [routerLink]="'/'" class="big-logo d-flex position-relative align-items-center justify-content-center">
    <img class="logo-ffa d-none d-sm-block" [src]="logoUrls['logo-formation-blanc']"/>
    <img class="min-logo-ffa d-block d-sm-none" [src]="logoUrls['logo-a']"/>
  </a>

  <a [routerLink]="'/'" class="small-logo d-flex position-relative align-items-center justify-content-center">
    <img class="min-logo-ffa" [src]="logoUrls['logo-a']"/>
  </a>

</div>

<div class="d-flex user-theme user-login-group position-absolute align-items-center">

  <button
    class="header-button text-uppercase ffa-icon-button mr-2 d-none d-lg-flex"
    mat-button
    (click)="openBookmarks()">
    <!--
    <mat-icon class="pr-2 m-0" svgIcon="icon-preferences"></mat-icon>
    -->
    <span class="d-none d-xl-inline mr-2" [matBadge]="bookmarksCount" matBadgeOverlap="false" matBadgeSize="small">Mes Favoris</span>
    <mat-icon [svgIcon]="'icon-bookmark'" color="primary" class="bookmark-icon"></mat-icon>

  </button>

  <ng-container *ngIf="subscriber">

    <a
      class="header-button text-uppercase ffa-icon-button mr-2 d-none d-lg-flex"
      mat-button
      [routerLink]="[{outlets:{popUp:domainsPath}}]"
      queryParamsHandling="preserve">
      <!--
      <mat-icon class="pr-2 m-0" svgIcon="icon-preferences"></mat-icon>
      -->
      <span
        class="d-none d-xl-inline mr-2"
        [class.mr-4]="(userFilieres$|async)?.length > 0"
        [matBadgeHidden]="(userFilieres$|async)?.length === 0"
        [matBadge]="(userFilieres$|async)?.length"
        matBadgeOverlap="false"
        matBadgeSize="small">Domaines et filières</span>
      <mat-icon svgIcon="icon-preferences" color="primary"></mat-icon>

    </a>

  </ng-container>

  <ng-container *ngIf="!authenticated">

    <a
      class="header-button ffa-icon-button text-uppercase d-none d-lg-flex"
      mat-button
      [routerLink]="[{outlets:{userLoginOutlet:loginPath}}]"
      [skipLocationChange]="true">
      <span class="d-none d-xl-inline mr-2">Connexion</span>
      <mat-icon svgIcon="icon-user" color="primary"></mat-icon>
    </a>

  </ng-container>

  <ng-container *ngIf="authenticated">

    <button
      class="header-button ffa-icon-button text-uppercase d-none d-lg-flex"
      mat-button
      [matMenuTriggerFor]="userMenu">
      <span class="d-none d-xl-inline mr-2 default-font font-weight-normal" [innerHTML]="userName"></span>
      <mat-icon svgIcon="icon-user" color="primary"></mat-icon>
    </button>

  </ng-container>

  <ng-container *ngIf="subscriber === false">

    <a
      class="ffa-button ffa-icon-button ambiant-font text-uppercase subscribe-btn font-weight-bold"
      mat-flat-button
      [routerLink]="[{outlets:{popUp:subscriptionPath}}]"
      [skipLocationChange]="true"
      color="primary">
      <span>Je m’abonne</span>
      <img class="position-absolute icon" src="assets/common/icone-abonne.svg"/>
    </a>

  </ng-container>

  <!--
  <button class="default-font" mat-button color="primary" *ngIf="authenticated">{{userName}}</button>
  -->

  <mat-menu #userMenu="matMenu">
    <a
      *ngIf="userId"
      [routerLink]="[{outlets:{userAccountOutlet:'mon-compte'}}]"
      mat-menu-item
      class="ambiant-font user-menu-item">
      <mat-icon class="pr-2 m-0" svgIcon="icon-user-account" color="primary"></mat-icon>
      <span>Mon Compte</span>
    </a>
    <button
      (click)="logoutEmitter.emit()"
      mat-menu-item
      class="ambiant-font user-menu-item">
      <mat-icon class="pr-2 m-0" svgIcon="icon-logout" color="primary"></mat-icon>
      <span>Me déconnecter</span>
    </button>
  </mat-menu>

</div>

<div class="position-absolute loading-icon-container" *ngIf="loading">
  <div class="loading-icon-group">
    <mat-icon svgIcon="icon-loading" color="accent"></mat-icon>
  </div>
</div>

<div class="d-flex flex-fill align-items-center position-relative justify-content-between pointer-events-none">

  <button mat-button (click)="openCloseMenu.emit()" class="pointer-events-auto">
    <div class="d-flex align-items-center">
      <mat-icon svgIcon="icon-menu" color="base" class="d-flex"></mat-icon>
      <span [innerHTML]="headerTitle$|async" class="d-none ml-1 d-sm-flex text-uppercase font-size-headline"></span>
    </div>
  </button>

</div>

<ng-content select=".search-field"></ng-content>
