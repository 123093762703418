import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minuteSecond'
})
export class MinuteSecondPipe implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = Math.floor((value - minutes * 60));

    const minutesText: string = (minutes > 0 ? minutes.toString().padStart(2, '0') + (minutes > 1 ? ' minutes' : ' minute') : '');
    const secondsText: string = (seconds > 0 ? seconds.toString().padStart(2, '0') + (seconds > 1 ? ' secondes' : ' seconde') : '');
    const separator: string = (minutes > 0 && seconds > 0) ? ' et ' : '';

    const durationText: string = minutesText + separator + secondsText;

    return  durationText;
  }

}
