import {Action} from '@ngrx/store';
import {Question} from "../models/question";
import {QuizTake} from "../models/quiz-take";
import {AnswerSubmission} from "../models/answer-submission";
import {SaveAnswer} from "../models/save-answer";
import {HttpErrorResponse} from "@angular/common/http";
import {QuizSteps} from "./quiz.reducer";

export const LOAD_QUESTION = '[Quiz] Load question';
export const LOAD_QUESTION_FAIL = LOAD_QUESTION + ' > Fail';
export const LOAD_QUESTION_SUCCESS = LOAD_QUESTION + ' > Success';

export const SELECT_QUESTION = '[Quiz] Select question';

export const ADD_QUESTIONS = '[Quiz] Add questions';

export const PLAY_QUIZ = '[Quiz] Play Quiz';

export const NEXT_QUESTION = '[Quiz] Next Question';
export const GO_TO_QUESTION_INDEX = '[Quiz] Go to question Index';
export const SET_QUESTION_INDEX = '[Quiz] Set question Index';

export const SHOW_RESULT = '[Quiz] Show Result';

export const SHOW_ENDING_SCREEN = '[Quiz] Show Ending Screen';

export const TRY_AGAIN = '[Quiz] Try Again';

export const RESET_QUIZ = '[Quiz] Reset';

export const RESET_QUIZ_TAKE = '[Quiz] Reset Quiz Take';

export const SET_ANSWERS_TO_QUESTION = '[Quiz] Set Answers to Question';

export const LOAD_RESULT = '[Quiz] Load result';

export const LOAD_RESULT_SUCCESS = LOAD_RESULT + ' > Success';

export const CREATE_QUIZ_TAKE = '[Quiz] Create take';
export const CREATE_QUIZ_TAKE_SUCCESS = CREATE_QUIZ_TAKE + ' > Success';
export const CREATE_QUIZ_TAKE_FAIL = CREATE_QUIZ_TAKE + ' > Fail';

export const SAVE_QUIZ_ANSWER = '[Quiz] Save answer';
export const SAVE_QUIZ_ANSWER_SUCCESS = SAVE_QUIZ_ANSWER + ' > Success';
export const SAVE_QUIZ_ANSWER_FAIL = SAVE_QUIZ_ANSWER + ' > Fail';

export const SET_QUIZ_NODE = '[Quiz] Set Quiz node';

export const LOAD_QUIZ_TAKE = '[Quiz] Load QuizTake';
export const LOAD_QUIZ_TAKE_SUCCESS = '[Quiz] Load QuizTake > Success';
export const LOAD_QUIZ_TAKE_FAIL = '[Quiz] Load QuizTake > Fail';

export const SET_QUIZ_ID = '[Quiz] Set Quiz Id';

export const SET_QUIZ_SESSION = '[Quiz] Set Quiz Session';

export const SET_QUIZ_STEP = '[Quiz] Set Quiz Step';

export interface QuizAction extends Action {
  id?: string;
  data?: Question;
  questions?: Question[];
  error?: any;
  index?: number;
  questionId?: string;
  answerIds?: string[];
  result?: any;
}

export class SetQuizId implements QuizAction {
  readonly type = SET_QUIZ_ID;

  constructor(public quizId: string) {
  }
}

export class SetQuizSession implements QuizAction {
  readonly type = SET_QUIZ_SESSION;

  constructor() {
  }
}

export class SetQuizNode implements QuizAction {
  readonly type = SET_QUIZ_NODE;

  constructor(public node: any) {
  }
}

export class LoadQuestion implements QuizAction {

  readonly type = LOAD_QUESTION;

  constructor(public resultId: string, public questionIndex: string) {
  }
}

export class ResetQuiz implements QuizAction {
  readonly type = RESET_QUIZ;

  constructor() {
  }
}

export class ResetQuizTake implements QuizAction {

  readonly type = RESET_QUIZ_TAKE;

  constructor() {
  }
}

export class SelectQuestion implements QuizAction {
  readonly type = SELECT_QUESTION;

  constructor(public data: Question) {
  }
}

export class NextQuestion implements QuizAction {
  readonly type = NEXT_QUESTION;

  constructor() {
  }
}

export class SetQuestionIndex implements QuizAction {
  readonly type = SET_QUESTION_INDEX;

  constructor(public index: number) {
  }
}

export class GoToQuestionIndex implements QuizAction {
  readonly type = GO_TO_QUESTION_INDEX;

  constructor(public index: number) {
  }
}

export class ShowEndingScreen implements QuizAction {
  readonly type = SHOW_ENDING_SCREEN;

  constructor() {
  }
}


export class ShowResult implements QuizAction {
  readonly type = SHOW_RESULT;

  constructor() {
  }
}

export class AddQuestions implements QuizAction {
  readonly type = ADD_QUESTIONS;

  constructor(public questions: Question[]) {
  }
}

export class PlayQuiz implements QuizAction {
  readonly type = PLAY_QUIZ;

  constructor() {
  }
}

export class LoadQuestionSuccess implements QuizAction {
  readonly type = LOAD_QUESTION_SUCCESS;

  constructor(public data: Question) {
  }
}

export class LoadQuestionFail implements QuizAction {
  readonly type = LOAD_QUESTION_FAIL;

  constructor(public error: any) {
  }
}

export class SetAnswersToQuestion implements QuizAction {
  readonly type = SET_ANSWERS_TO_QUESTION;

  constructor(public questionId: string, public answerIds: string[]) {
  }
}

export class LoadResult implements QuizAction {
  readonly type = LOAD_RESULT;

  constructor() {
  }
}

export class LoadResultSuccess implements QuizAction {
  readonly type = LOAD_RESULT_SUCCESS;

  constructor(public result: any) {
  }

}

export class QuizTryAgain implements QuizAction {

  readonly type = TRY_AGAIN;

  constructor() {
  }
}

export class LoadQuizTake implements QuizAction {

  readonly type = LOAD_QUIZ_TAKE;

  constructor(public quizTakeId: string) {
  }
}

export class LoadQuizTakeSuccess implements QuizAction {

  readonly type = LOAD_QUIZ_TAKE_SUCCESS;

  constructor(public quizTake: QuizTake) {
  }
}

export class LoadQuizTakeFail implements QuizAction {

  readonly type = LOAD_QUIZ_TAKE_FAIL;

  constructor(public error: HttpErrorResponse) {
  }
}

export class CreateQuizTake implements Action {
  readonly type = CREATE_QUIZ_TAKE;

  constructor(public quizId: string) {
  }
}

export class CreateQuizTakeSuccess implements Action {
  readonly type = CREATE_QUIZ_TAKE_SUCCESS;

  constructor(public quizTake: QuizTake) {
  }
}

export class CreateQuizTakeFail implements Action {
  readonly type = CREATE_QUIZ_TAKE_FAIL;

  constructor(public error: HttpErrorResponse) {
  }
}

export class SaveQuizAnswer implements Action {
  readonly type = SAVE_QUIZ_ANSWER;

  constructor(public answerSubmission: AnswerSubmission) {
  }
}

export class SaveQuizAnswerSuccess implements Action {
  readonly type = SAVE_QUIZ_ANSWER_SUCCESS;

  constructor(public result: SaveAnswer, public questionNid: string) {
  }
}

export class SaveQuizAnswerFail implements Action {
  readonly type = SAVE_QUIZ_ANSWER_FAIL;

  constructor(public error: any) {
  }
}

export class SetQuizStep implements Action {
  readonly type = SET_QUIZ_STEP;

  constructor(public step: QuizSteps) {
  }
}
