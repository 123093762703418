import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dynamicQueryParams'
})
export class DynamicQueryParamsPipe implements PipeTransform {

  transform(key: number, filterName: string, isActive: boolean): any {
    return {[filterName]: isActive ? null : key};
  }

}
