<header class="d-flex flex-none justify-content-between align-items-center px-3 py-2">
  <h1 mat-dialog-title [innerHTML]="title" class="m-0 title ambiant-font text-uppercase font-weight-bold"></h1>
  <button [matDialogClose]="false" class="d-flex p-0">
    <mat-icon class="d-flex close-icon" svgIcon="icon-close" aria-label="Fermer la fenêtre" color="basic"></mat-icon>
  </button>
</header>

<mat-dialog-content class="p-4 d-flex overflow-hidden flex-fill">
  <ng-content select=".content-group"></ng-content>
</mat-dialog-content>

<mat-dialog-actions *ngIf="actions">
  <ng-content select=".actions-group"></ng-content>
</mat-dialog-actions>
