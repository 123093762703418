import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'referentialType'
})
export class ReferentialTypePipe implements PipeTransform {

  referentialTypes = {
    se: 'Savoir-Être',
    sa: 'Savoir',
    sf: 'Savoir-Faire',
    prer: 'Pré-requis',
    comp: 'Compétences visées'
  };

  transform(value: any): any {
    let lowerCaseValue: string = (value as string).toLowerCase();
    let type: string;
    if(this.referentialTypes[lowerCaseValue]){
      type = this.referentialTypes[lowerCaseValue];
    }
    return type;
  }

}
