/**
 * Created by benoitplatre on 25/06/2018.
 */
import {Action} from '@ngrx/store';

export enum MenuActionTypes {
  Load = '[Menu] Load',
  LoadSuccess = '[Menu] Load Success',
  LoadFail = '[Menu] Load Fail',
}

export class MenuLoad implements Action {
  readonly type = MenuActionTypes.Load;
  constructor(public payload: any = null) {
  }
}

export class MenuLoadSuccess implements Action {

  readonly type = MenuActionTypes.LoadSuccess;

  constructor(public payload: any) {
  }
}

export class MenuLoadFail implements Action {

  readonly type = MenuActionTypes.LoadFail;

  constructor(public payload: any) {
  }

}

export type MenuActions = MenuLoad | MenuLoadSuccess | MenuLoadFail;
