<form *ngIf="form" [formGroup]="form">

  <div *ngFor="let key of formKeys" class="mb-3">

    <h3 [innerHTML]="fieldLabels[key]"></h3>

    <ng-container *ngIf="isCheckBoxes(key)">
      <ul
        class="list-style-type-none d-flex flex-wrap p-0 m-0">

        <li class="col-12 col-sm-4 d-flex" *ngFor="let formControl of form.controls[key].controls; let i=index">

          <mat-checkbox
            [color]="'primary'"
            [formControl]="formControl"
            (change)="onCheckBoxChange($event, formControl, key, i)">
            <span>{{(userMeta$|async)?.metaProfile[key].allowed_values[i].label}}</span>
          </mat-checkbox>

        </li>

      </ul>
    </ng-container>

    <ng-container *ngIf="isRadios(key)">

      <mat-radio-group
        [formControlName]="key"
        (change)="onRadioChange()">
        <mat-radio-button
          *ngFor="let radioObject of (userMeta$|async)?.metaProfile[key].allowed_values"
          class="col-12 col-sm-4 mb-2"
          [value]="radioObject.key"
          [checked]="form.value[key] == radioObject.key"
          [color]="'primary'">
          <span>{{radioObject.label}}</span>
        </mat-radio-button>
      </mat-radio-group>

    </ng-container>

  </div>

</form>
