import {Action} from '@ngrx/store';
import {LicenceInfos} from "../../models/licence-infos";
import {TokenResponse} from "angular-oauth2-oidc";
import {QuizAccess, QuizAccessFull} from "../../models/quiz-access";

export enum SiFfaActionTypes {
  CheckLicence = '[SI-FFA] Check licence',
  CheckLicenceSuccess = '[SI-FFA] Check licence > Success',
  CheckLicenceFail = '[SI-FFA] Check licence > Fail',
  CheckLicenceWrong = '[SI-FFA] Check licence > Wrong Licence',
  SetLicenceInfos = '[SI-FFA] Set licence Infos',
  AuthenticateSiFfaLink = '[SI-FFA] Authenticate SI-FFA Link',
  AuthenticateSiFfaLinkSuccess = '[SI-FFA] Authenticate SI-FFA Link > Success',
  AuthenticateSiFfaLinkFail = '[SI-FFA] Authenticate SI-FFA Link > Fail',
  LoadContentSecureLinkInfos = '[SI-FFA] Load Content SI-FFA Link Infos',
  LoadContentSecureLinkInfosSuccess = '[SI-FFA] Load Content SI-FFA Link Infos > Success',
  LoadContentSecureLinkInfosFail = '[SI-FFA] Load Content SI-FFA Link Infos > Fail',
  LoadQuizAccess = '[SI-FFA] Load Quiz Access',
  LoadQuizAccessSuccess = '[SI-FFA] Load Quiz Access > Success',
  LoadQuizAccessFail = '[SI-FFA] Load Quiz Access > Fail',
  Reset = '[SI-FFA] Reset'
}

export enum AuthenticateSiFfaLinkMode {
  Authenticate = 'auth',
  AccountCreation = 'account_creation',
}

export class CheckLicence implements Action {

  readonly type = SiFfaActionTypes.CheckLicence;

  constructor(public licenceInfos: LicenceInfos) {
  }
}

export class CheckLicenceSuccess implements Action {

  readonly type = SiFfaActionTypes.CheckLicenceSuccess;

  constructor() {

  }
}

export class CheckLicenceWrong implements Action {

  readonly type = SiFfaActionTypes.CheckLicenceWrong;

  constructor() {

  }
}

export class CheckLicenceFail implements Action {

  readonly type = SiFfaActionTypes.CheckLicenceFail;

  constructor(public error: any) {

  }
}

export class SetLicenceInfos implements Action {

  readonly type = SiFfaActionTypes.SetLicenceInfos;

  constructor(public licenceInfos: LicenceInfos) {

  }
}

export class AuthenticateSiFfaLink implements Action {

  readonly type = SiFfaActionTypes.AuthenticateSiFfaLink;

  constructor(public payload: string, public mail: string = null, public password: string = null, public mode: AuthenticateSiFfaLinkMode) {
  }
}

export class AuthenticateSiFfaLinkSuccess implements Action {

  readonly type = SiFfaActionTypes.AuthenticateSiFfaLinkSuccess;

  constructor(public tokenResponse: TokenResponse) {
  }

}

export class AuthenticateSiFfaLinkFail implements Action {

  readonly type = SiFfaActionTypes.AuthenticateSiFfaLinkFail;

  constructor(public error: any) {
  }

}

export class LoadContentSecureLinkInfos implements Action {

  readonly type = SiFfaActionTypes.LoadContentSecureLinkInfos;

  constructor(public payload: string) {
  }
}


export class LoadContentSecureLinkInfosSuccess implements Action {

  readonly type = SiFfaActionTypes.LoadContentSecureLinkInfosSuccess;

  constructor(public quizAccess: QuizAccess) {
  }

}

export class LoadContentSecureLinkInfosFail implements Action {

  readonly type = SiFfaActionTypes.LoadContentSecureLinkInfosFail;

  constructor(public error: any) {
  }

}

export class LoadQuizAccess implements Action {

  readonly type = SiFfaActionTypes.LoadQuizAccess;

  constructor(public quizAccessId: string) {
  }
}


export class LoadQuizAccessSuccess implements Action {

  readonly type = SiFfaActionTypes.LoadQuizAccessSuccess;

  constructor(public quizAccessFull: QuizAccessFull) {
  }

}

export class LoadQuizAccessFail implements Action {

  readonly type = SiFfaActionTypes.LoadQuizAccessFail;

  constructor(public error: any) {
  }

}

export class ResetSiFfa implements Action {

  readonly type = SiFfaActionTypes.Reset;

  constructor() {

  }
}

export type SiFfaActionsUnion =
  CheckLicence
  | CheckLicenceSuccess
  | CheckLicenceFail
  | CheckLicenceWrong
  | SetLicenceInfos
  | ResetSiFfa
  | AuthenticateSiFfaLink
  | AuthenticateSiFfaLinkSuccess
  | AuthenticateSiFfaLinkFail
  | LoadContentSecureLinkInfos
  | LoadContentSecureLinkInfosSuccess
  | LoadContentSecureLinkInfosFail
  | LoadQuizAccess
  | LoadQuizAccessSuccess
  | LoadQuizAccessFail;
