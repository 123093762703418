// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {getBookmarksMenuOpen} from "../core/store/reducers";

export const IS_BOOKMARK_MENU_OPEN = new InjectionToken<boolean>(
  'A stream to know if the Bookmark Menu is open or closed'
);

export const MENU_STATES_PROVIDERS: Provider[] = [
  {
    deps: [Store],
    provide: IS_BOOKMARK_MENU_OPEN,
    useFactory: idBookmarksMenuOpen
  }
];

export function idBookmarksMenuOpen(
  store: Store<any>
): Observable<boolean> {
  return store.pipe(
    select(getBookmarksMenuOpen)
  );
}

