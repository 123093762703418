import {Pipe, PipeTransform} from '@angular/core';
import {DrupalFile, FileEntity} from "../../core/models/file";
import {Video} from "../../core/models/video";
import {Image} from "../../core/models/image";
import {ResourceToFilePipe} from "./resource-to-file.pipe";
import {ResourceToImagePipe} from "./resource-to-image.pipe";

@Pipe({
  name: 'resourceToVideo'
})
export class ResourceToVideoPipe implements PipeTransform {

  constructor(
    private resourceToFilePipe: ResourceToFilePipe,
    private resourceToImagePipe: ResourceToImagePipe
  ) {
  }

  transform(file: DrupalFile, rawVideo: any): Video {
    const playableFiles: FileEntity[] = rawVideo?.playableFiles;
    const thumbnailFile: DrupalFile = this.resourceToFilePipe.transform(rawVideo?.thumbnail);
    const thumbnail: Image = this.resourceToImagePipe.transform(thumbnailFile, rawVideo?.thumbnail) as Image;
    return {
      ...file,
      playableFiles,
      thumbnail
    };
  }

}
