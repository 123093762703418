import {Action} from '@ngrx/store';

export const WEBFORM_SUBMIT = '[Webform] Submit';
export const WEBFORM_SUBMIT_FAIL = WEBFORM_SUBMIT + ' > Fail';
export const WEBFORM_SUBMIT_ERROR = WEBFORM_SUBMIT + ' > Error';
export const WEBFORM_SUBMIT_SUCCESS = WEBFORM_SUBMIT + ' > Success';
export const WEBFORM_CLEAR_STATE = '[Webform] Clear State';
export const WEBFORM_RESET_ALL = '[Webform] Reset All';

export class WebformSubmissionResult {
  success: boolean;
  errors: WebformErrors;
}

export class WebformErrors {
  [fieldName: string]: {
    message: string;
  }
}

export class WebformResetAll {

  readonly type = WEBFORM_RESET_ALL;

  constructor() {
  }

}


export class WebformClearState {

  readonly type = WEBFORM_CLEAR_STATE;

  constructor(public webformId: string) {
  }

}

export class SubmitAction implements Action {
  readonly type = WEBFORM_SUBMIT;

  constructor(public webformId: string, public submission: any) {
  }
}

export class SubmitActionSuccess implements Action {
  readonly type = WEBFORM_SUBMIT_SUCCESS;

  constructor(public webformId: string) {

  }
}

export class SubmitActionError implements Action {
  readonly type = WEBFORM_SUBMIT_ERROR;

  constructor(public webformId: string, public validationErrors: WebformErrors) {
  }
}

export class SubmitActionFail implements Action {
  readonly type = WEBFORM_SUBMIT_FAIL;

  constructor(public webformId: string, public error: any) {
  }
}

export type WebformAction =
  | SubmitAction
  | SubmitActionSuccess
  | SubmitActionError
  | SubmitActionFail
  | WebformClearState
  ;

