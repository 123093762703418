import {ContentTypesConstants} from "./content-types-constants";

export class AppUniverses{

  static readonly training: string = ContentTypesConstants.universeTraining;
  static readonly encyclopedia: string = ContentTypesConstants.universeEncyclopedia;
  static readonly themes: string = ContentTypesConstants.universeTheme;
  static readonly home: string = ContentTypesConstants.universeHome;

}
