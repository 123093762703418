import {Question} from "../models/question";
import {
  ADD_QUESTIONS,
  CREATE_QUIZ_TAKE,
  CREATE_QUIZ_TAKE_SUCCESS,
  CreateQuizTakeSuccess,
  LOAD_QUESTION,
  LOAD_QUESTION_FAIL,
  LOAD_QUESTION_SUCCESS,
  LOAD_QUIZ_TAKE,
  LOAD_QUIZ_TAKE_SUCCESS,
  LOAD_RESULT_SUCCESS,
  LoadQuizTakeSuccess,
  PLAY_QUIZ,
  QuizAction,
  RESET_QUIZ,
  RESET_QUIZ_TAKE,
  SAVE_QUIZ_ANSWER_SUCCESS,
  SaveQuizAnswerSuccess,
  SELECT_QUESTION,
  SET_ANSWERS_TO_QUESTION,
  SET_QUESTION_INDEX,
  SET_QUIZ_ID,
  SET_QUIZ_NODE,
  SET_QUIZ_SESSION,
  SET_QUIZ_STEP,
  SetQuizId,
  SetQuizNode,
  SetQuizStep,
  SHOW_ENDING_SCREEN,
  SHOW_RESULT,
  TRY_AGAIN
} from './quiz.action';
import {QuizResult} from "../models/quiz-result";
import {QuizTake} from "../models/quiz-take";

/**
 * Created by benoitplatre on 25/06/2018.
 */

export type QuizSteps = 'start'|'try-again'|'questions'|'result';

export interface QuizState {
  loading?: boolean;
  loaded?: boolean;
  quizId: string;
  question?: Question;
  questions?: Question[];
  questionCount?: number;
  questionIndex?: number;
  error?: any;
  showResult?: boolean;
  showEndingScreen?: boolean;
  answeredQuestions?: any;
  result?: QuizResult;
  node?: any;
  quizTake?: QuizTake;
  quizTakeLoading?: boolean,
  quizTakeLoaded?: boolean,
  saveQuestionsMap: {[questionId: string]: string},
  quizSession: boolean,
  step: QuizSteps
}


const initialState: QuizState = {
  loading: false,
  quizId: null,
  loaded: false,
  question: null,
  questions: null,
  questionCount: null,
  questionIndex: -1,
  error: null,
  showResult: false,
  showEndingScreen: false,
  answeredQuestions: {},
  result: null,
  node: null,
  quizTake: null,
  quizTakeLoading: false,
  quizTakeLoaded: false,
  saveQuestionsMap: {},
  quizSession: false,
  step: 'start'
};

export function quizReducer(state: QuizState = initialState,
                            action: QuizAction): QuizState {
  switch (action.type) {

    case LOAD_QUESTION :
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
        question: null
      };
    //TODO clear questions too, create a try again action
    case RESET_QUIZ :
      return initialState;

    case TRY_AGAIN:
      return {
        ...state,
        loading: false,
        loaded: false,
        question: null,
        questionIndex: -1,
        error: null,
        showResult: false,
        showEndingScreen: false,
        answeredQuestions: {},
        quizTake: null,
        quizTakeLoading: false,
        quizTakeLoaded: false,
        step: 'try-again'
      };
      break;

    case SET_QUIZ_NODE:
      return {
        ...state,
        node: (action as SetQuizNode).node
      };
      break;

    case SET_QUIZ_ID:
      return {
        ...state,
        quizId: (action as SetQuizId).quizId
      };
      break;

    case SET_ANSWERS_TO_QUESTION:
      let questionId: string = action.questionId;
      let answerIds: string[] = action.answerIds;
      let questionAnswers = {};
      questionAnswers[questionId] = answerIds;
      return {
        ...state,
        answeredQuestions: {...state.answeredQuestions, ...questionAnswers}
      };

    case PLAY_QUIZ :
      return {
        ...state,
        questionIndex: 0
      };

    case SHOW_RESULT :
      return {
        ...state,
        showResult: true
      };

    case LOAD_RESULT_SUCCESS :
      return {
        ...state,
        result: action.result
      };

    case SHOW_ENDING_SCREEN :
      return {
        ...state,
        showResult: false,
        showEndingScreen: true
      };

    case SET_QUESTION_INDEX :
      return {
        ...state,
        questionIndex: action.index
      };

    case LOAD_QUESTION_SUCCESS :
      return {
        ...state,
        loaded: false,
        loading: true,
        question: action.data
      };

    case SELECT_QUESTION :
      return {
        ...state,
        loaded: false,
        loading: true,
        question: action.data
      };

    case ADD_QUESTIONS :
      return {
        ...state,
        loaded: false,
        loading: true,
        questions: action.questions
      };

    case LOAD_QUESTION_FAIL :
      return {
        ...state,
        loaded: false,
        loading: true,
        error: action.error,
        question: null
      };

    case CREATE_QUIZ_TAKE:
    case LOAD_QUIZ_TAKE:
      return {
        ...state,
        quizTakeLoading: true,
        quizTakeLoaded: false
      };

    case CREATE_QUIZ_TAKE_SUCCESS:
    case LOAD_QUIZ_TAKE_SUCCESS:
      return {
        ...state,
        quizTakeLoading: false,
        quizTakeLoaded: true,
        quizTake: (action as CreateQuizTakeSuccess|LoadQuizTakeSuccess).quizTake
      };

    case SAVE_QUIZ_ANSWER_SUCCESS:
      return {
        ...state,
        answeredQuestions: {...state.answeredQuestions, [(action as SaveQuizAnswerSuccess).questionNid]:true},
        quizTake: (action as SaveQuizAnswerSuccess).result.quiz_result
      }

    case SET_QUIZ_SESSION:
      return {
        ...state,
        quizSession: true
      };

    case SET_QUIZ_STEP:
      return {
        ...state,
        step: (action as SetQuizStep).step
      };

    case RESET_QUIZ_TAKE:
      return {
        ...state,
        quizTakeLoading: false,
        quizTakeLoaded: false,
        answeredQuestions: {},
        quizTake: null
      };


    default:
      return state;
  }
}

