import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {WindowRef} from "../../window-ref";
import {AppConfigService} from "../../app-config.service";

@Component({
  selector: 'ea-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit, OnDestroy {

  form: FormGroup;

  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private appConfig: AppConfigService,
    private windowRef: WindowRef
  ) {


    let environmentKey: string = 'prod';
    if(localStorage && localStorage.getItem('app_config_key')){
      environmentKey = localStorage.getItem('app_config_key');
    }
    this.form = this.fb.group({
        environment: new FormControl(environmentKey)
      }
    );

    let sub: Subscription;
    sub = this.form.valueChanges.subscribe(form => {
      if(localStorage){
        localStorage.setItem('app_config_key', form.environment);
      }
    });
    this.subs.push(sub);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if(this.subs){
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  reload(){
    if(this.windowRef && this.windowRef.nativeWindow){
      (this.windowRef.nativeWindow as Window).location.reload();
    }
  }

}
