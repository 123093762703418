import {PathActions, PathActionTypes} from '../actions/path';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {HttpErrorResponse} from '@angular/common/http';
import {PathInfos} from '../../models/path';

/**
 * Created by benoitplatre on 25/06/2018.
 */

export interface PathState extends EntityState<any> {
  pathInfos: PathInfos;
  loading: boolean;
  loaded: boolean;
  error: HttpErrorResponse|null;
  metaTags: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (pathInfos: PathInfos) => pathInfos.id,
  sortComparer: false,
});

const initialState: PathState = adapter.getInitialState({
  pathInfos: null,
  loading: false,
  loaded: false,
  error: null,
  metaTags: null
});

export function reducer(state: PathState = initialState,
                        action: PathActions): PathState {
  switch (action.type) {

    case PathActionTypes.Load:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
        metaTags: null
      };

    case PathActionTypes.LoadSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        pathInfos: action.payload,
        loaded: true,
        loading: false,
        error: null,
        metaTags: (action.payload as PathInfos).metatags
      });

    case PathActionTypes.LoadFail:
      return {
        ...state,
        pathInfos: null,
        loaded: false,
        loading: false,
        error: action.payload,
        metaTags: null
      };

    default:
      return state;
  }
}

export const getPathInfos = (state: PathState) => state.pathInfos;
export const getPathInfosLoading = (state: PathState) => state.loading;
export const getPathInfosLoaded = (state: PathState) => state.loaded;
export const getPathInfosFail = (state: PathState) => state.error;
export const getPathMetaTags = (state: PathState) => state.metaTags;
