import {PathNode} from './path';
import {Image} from "./image";
import {Document} from "./document";

/**
 * Created by benoitplatre on 27/06/2018.
 */
export interface RawContent {
  type: string;
  practicalType?: PracticalType
}

export enum ContentTypes {
  page = 'page',
  tutoriel = 'tutoriel',
  news = 'news',
  knowledge = 'theoretical_content',
  quiz = 'quiz',
  video = 'ffa_video_athle_tv',
  situation = 'fiche_peda',
  cycle = 'cycle',
  term = 'term',
  enchainementSituations = 'enchainement_situations',
  circuit = 'circuit',
  progression = 'progression',
  jeunesJuges = 'situation_jeunes_juges',
  seance = 'seance',
  diplome = 'formation_diplome',
  module = 'formation_module',
  faceAface = 'formation',
  theme = 'ffa_theme',
  faqQuestion = 'faq_question',
  webform = 'webform'
}

export enum PracticalTypes {
  circuit = 'circuit',
  progression = 'progression'
};
export type PracticalType = PracticalTypes.circuit | PracticalTypes.progression;

export type ContentType = ContentTypes.page |
  ContentTypes.tutoriel |
  ContentTypes.news |
  ContentTypes.knowledge |
  ContentTypes.quiz |
  ContentTypes.video |
  ContentTypes.situation |
  ContentTypes.cycle |
  ContentTypes.term |
  ContentTypes.enchainementSituations |
  ContentTypes.circuit |
  ContentTypes.progression |
  ContentTypes.jeunesJuges;

export interface DrupalNode extends PathNode {
  body: string;
  summary: string;
  description?: string;
  status?: number;
  promote?: number;
  promoted: boolean;
  sticky: boolean;
  published: boolean;
  changed: number;
  language?: string;
  editable: boolean;
  edit_url?: string;
  editUrl: string;
  id: string;
  nid?: string;
  restricted?: boolean;
  free?: boolean;
  path?: string;
  extended_type?: string;
  extendedType?: string;
  thumbnail?: Image;
  author?: string;
  associatedContents?: DrupalNode[];
  files: Document[];

  /** allow additional properties */
  // [prop: string]: any;
}

export interface Body {
  value: any;
  summary: string;
  format: string;
}

export interface DrupalReference {
  path: string;
  label: string;
  resource?: string;
  id: string;
}

export interface NodeReference extends DrupalReference {
}

export interface TaxonomyTermReference extends DrupalReference {

}
