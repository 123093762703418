import {Injectable} from '@angular/core';
import {WebformsService} from '../../services/webforms.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  SubmitAction,
  SubmitActionError,
  SubmitActionFail,
  SubmitActionSuccess,
  WEBFORM_SUBMIT,
  WebformSubmissionResult
} from '../actions/webforms.action';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class WebformsEffect {

  constructor(private actions$: Actions,
              private webformsService: WebformsService) {
  }

  
  webformSubmit$ = createEffect(() => this.actions$.pipe(
    ofType<SubmitAction>(WEBFORM_SUBMIT),
    switchMap(action => {
      return this.webformsService.submitForm(action.webformId, action.submission).pipe(
        map((result: WebformSubmissionResult) =>
          result.success
            ? new SubmitActionSuccess(action.webformId)
            : new SubmitActionError(action.webformId, result.errors)),
        catchError(error => of(new SubmitActionFail(action.webformId, error)))
      );
    })
  ));

}
