import {Injectable} from '@angular/core';
import {StorageMap} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(
    public storage: StorageMap
  ) {
  }


  hydrateSchemas$(): Observable<any> {
    return this.storage.get('drupal-schema-loader').map(data => {
      return data;
    });
  }

  hydrateSearch$(): Observable<any> {
    return this.storage.get('search').map(data => {
      return data;
    });
  }

  hydrateDrupal$(): Observable<any> {
    return this.storage.get('drupal').map(data => {
      return data;
    });
  }

  hydrateDrupalPathInfos$(): Observable<any> {
    return this.storage.get('drupalPath').map(data => {
      return data;
    });
  }

  getRedirectUrl$(): Observable<string> {
    return this.storage.get('redirectUrl').map(data => {
      return data as string;
    });
  }

  getKey(key: string): Observable<any> {

    return this.storage.get(key).pipe(
      map(data => {
        return data;
      })
    );

  }

  clearAll(all: boolean) {
    this.storage.keys().subscribe(key => {
      if (all === true) {
        this.storage.clear().subscribe(
          () => {
          }
        );
      } else {
        if (key !== 'syncData' && key !== 'language') {
          this.storage.delete(key).subscribe(() => {
          });
        }
      }
    });
  }

}
