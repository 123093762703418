import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {HttpErrorResponse} from '@angular/common/http';
import * as actions from './search.action';
import {
  AddFilter,
  LoadFacetsForUniverse,
  LoadFacetsForUniverseSuccess,
  LoadMetaDataSuccess,
  RemoveFilter,
  SearchActionFail,
  SearchActions,
  SearchActionSuccess,
  SearchNextPageAction,
  SearchUpdateContentTypesAction,
  SearchUpdateFilters,
  SearchUpdateQuery,
  SetContentTypes,
  SetExtendedTypes,
  SetPagerInfos,
  SetQuery
} from './search.action';
import {DrupalNode} from '../../core/models/node';
import {SearchFilter, SearchFilterOption, SearchPager, SearchResult, UserSearchFilter} from '../models/search-result';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {FiltersMap, SearchUserRequest} from '../models/search-user-request';
import {PageEvent} from "@angular/material/paginator";
import {FiltersConstants} from "../../core/models/filters-constants";

/**
 * Created by benoitplatre on 25/06/2018.
 */

export interface SearchState extends EntityState<DrupalNode> {
  loading?: boolean;
  loaded?: boolean;
  query?: string;
  pager?: SearchPager;
  error?: HttpErrorResponse | null;
  userRequest?: SearchUserRequest;
  filters?: SearchFilter[];
  filtersMap?: { [filterName: string]: SearchFilter };
  filtersLabels?: { [id: string]: string };
  selectedOptionKeys?: { [key: string]: boolean };
  metadata?: { [key: string]: any };
  facets?: { [universe: string]: SearchFilter[] };
  universe?: string;
  options?: { [optionKey: string]: SearchFilterOption };
  optionsCount?: { [optionKey: string]: number };
  selectedOptionKeysToFilterName?: { [optionKey: string]: any };
  extendedTypes: SearchFilter
}

export const adapter: EntityAdapter<DrupalNode> = createEntityAdapter<DrupalNode>({
  selectId: (node: any) => node.id,
  sortComparer: false,
});

const initialState: SearchState = adapter.getInitialState({
  loading: false,
  loaded: false,
  query: null,
  pager: {limit: 40, offset: 0},
  error: null,
  userRequest: {pager: {limit: 40, offset: 0}, query: null, filters: null, contentTypes: null},
  filters: null,
  filtersMap: null,
  filtersLabels: null,
  selectedOptionKeys: null,
  metadata: null,
  facets: {},
  universe: null,
  options: {},
  optionsCount: {},
  selectedOptionKeysToFilterName: {},
  extendedTypes: null
});

export function searchReducer(state: SearchState = initialState,
                              action: SearchActions): SearchState {

  let contentTypes: string[];
  let query: string;
  // App filter is coming from Drupal with all properties
  let appFilter: SearchFilter;

  // filter(UserSearchFilter) is a minimized version of the filter used to build the url
  let filter: UserSearchFilter;
  let filters: SearchFilter[];
  let index: number;
  let pager: SearchPager;
  let optionKeys: { [id: string]: boolean };

  switch (action.type) {

    case actions.SEARCH :
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null
      };

    case actions.FIND_CONTENT_BY_ID :
      return adapter.removeAll({
        ...state,
        loaded: false,
        loading: true,
        error: null
      });

    case actions.SET_CONTENT_TYPES :
      contentTypes = (action as SetContentTypes).payload;
      return {
        ...state,
        userRequest: {...state.userRequest, filters: {...state.userRequest.filters, extended_type: contentTypes}},
      };

    case actions.SET_QUERY :
      query = (action as SetQuery).payload;
      return {
        ...state,
        userRequest: {...state.userRequest, query: query},
      };

    case actions.CLEAR_ALL_FILTERS :
      return {
        ...state,
        userRequest: {...state.userRequest, filters: null},
        selectedOptionKeys: null
      };

    case actions.ADD_FILTER :
      // we get the Drupal filter, and we extract the key option to build later the url version of the filter
      // https://stackoverflow.com/questions/41610756/angular-2-ngrx-how-to-update-an-array-which-is-embedded-in-one-the-the-items-o/41615065
      appFilter = (action as AddFilter).payload as SearchFilter;

      let userFilters: { [filterId: string]: string[] | string } = {...state.userRequest.filters};
      let coreFilters: { [filterId: string]: string[] | string } = {...state.userRequest.coreFilters};

      optionKeys = {...state.selectedOptionKeys};
      //TODO mutualize filter parsing between filters and coreFilters
      if (appFilter.core) {
        if (appFilter.options) {
          coreFilters[appFilter.name] = appFilter.options.map(option => {
            return option.key;
          });
          appFilter.options.forEach(option => {
            optionKeys[option.key] = true;
          });
        }
      } else {
        if (appFilter.options) {
          userFilters[appFilter.name] = appFilter.options.map(option => {
            return option.key;
          });
          appFilter.options.forEach(option => {
            optionKeys[option.key] = true;
          });
        }
      }

      return {
        ...state,
        userRequest: {
          ...state.userRequest,
          filters: userFilters,
          coreFilters: coreFilters
        },
        selectedOptionKeys: optionKeys
      };

    case actions.REMOVE_FILTER :

      let rawFilter: UserSearchFilter = (action as RemoveFilter).payload;

      let storeFilters = {...state.userRequest.filters};
      let storeCoreFilters = {...state.userRequest.coreFilters};
      let selectedOptionKeys = {...state.selectedOptionKeys};
      let storeFilterValue;

      //TODO mutualize remove filters and core filters

      if (rawFilter.optionKey && selectedOptionKeys[rawFilter.optionKey]) {
        delete selectedOptionKeys[rawFilter.optionKey];
      }

      storeFilterValue = storeFilters[rawFilter.name];
      if (Array.isArray(storeFilterValue)) {
        storeFilters[rawFilter.name] = storeFilterValue.filter(optionKey => optionKey !== rawFilter.optionKey);
        if (storeFilters[rawFilter.name].length == 0) {
          delete storeFilters[rawFilter.name];
        }
      } else {
        delete storeFilters[rawFilter.name];
      }

      //if name only then means we want to remove everything
      if (!rawFilter.optionKey && !rawFilter.options && rawFilter.name) {
        delete storeFilters[rawFilter.name];
      }

      if (rawFilter.core) {
        if (rawFilter.optionKey && selectedOptionKeys[rawFilter.optionKey]) {
          delete selectedOptionKeys[rawFilter.optionKey];
        }
        storeFilterValue = storeCoreFilters[rawFilter.name];
        if (Array.isArray(storeFilterValue)) {
          storeCoreFilters[rawFilter.name] = storeFilterValue.filter(optionKey => optionKey !== rawFilter.optionKey);
          if (storeCoreFilters[rawFilter.name].length == 0) {
            delete storeCoreFilters[rawFilter.name];
          }
        } else {
          delete storeCoreFilters[rawFilter.name];
        }

        //if name only then means we want to remove everything
        if (!rawFilter.optionKey && !rawFilter.options && rawFilter.name) {
          delete storeCoreFilters[rawFilter.name];
        }

        // filters has to be null if no filter is selected. To decide if we trigger a navigate() function or not
        if (JSON.stringify(storeFilters) == '{}') {
          storeFilters = null;
        }

      }

      return {
        ...state,
        userRequest: {
          ...state.userRequest,
          filters: storeFilters,
          coreFilters: storeCoreFilters
        },
        selectedOptionKeys: selectedOptionKeys
      };

    case actions.SEARCH_WELCOME :
      return {
        ...state,
        loaded: false,
        loading: false,
        error: null
      };

    case actions.SET_EXTENDED_TYPES :
      const extendedTypes:SearchFilter = (action as SetExtendedTypes).payload;
      return {
        ...state,
        extendedTypes: extendedTypes
      };

    case actions.SEARCH_UPDATE_QUERY :
      query = (action as SearchUpdateQuery).payload;
      return {
        ...state,
        userRequest: {...state.userRequest, query: query}
      };

    case actions.SET_PAGER_INFOS :
      pager = (action as SetPagerInfos).payload;
      return {
        ...state,
        userRequest: {
          ...state.userRequest,
          pager: {
            ...state.userRequest.pager,
            ...pager
          }
        }
      };

    case actions.SEARCH_UPDATE_FILTERS :
      let newFilters: { [filterId: string]: string[] | string } = (action as SearchUpdateFilters).payload;

      optionKeys = {};

      let selectedOptionKeysToFilterName: { [optionKey: string]: any } = {};

      for (let filterName in newFilters) {
        let options = newFilters[filterName];
        selectedOptionKeysToFilterName[filterName] = [];
        if (Array.isArray(options)) {
          let filterOptions: any[] = selectedOptionKeysToFilterName[filterName];
          options.forEach(option => {
            optionKeys[option] = true;
            filterOptions.push(parseInt(option));
          })
        } else {
          optionKeys[options] = true;
          selectedOptionKeysToFilterName[filterName] = (parseInt(options));
        }
      }

      //const userFilters: SearchFilter[] = searchUserRequest.filters ? searchUserRequest.filters : [];
      //const stateFilters: SearchFilter[] = state.userRequest.filters ? state.userRequest.filters : [];
      return {
        ...state,
        userRequest: {...state.userRequest, filters: newFilters},
        selectedOptionKeys: optionKeys,
        selectedOptionKeysToFilterName: selectedOptionKeysToFilterName
      };

    /*case actions.SEARCH_REMOVE_FILTER_OPTION :
      const searchFilter: SearchFilter = (action as SearchActionRemoveFilterOption).searchFilter;
      const searchFilterOption: SearchFilterOption = (action as SearchActionRemoveFilterOption).searchFilterOption;
      return {
        ...state,
        userRequest: {
          ...state.userRequest,
          filters: [...state.userRequest.filters.filter((filter: SearchFilter) => filter.name !== searchFilter.name)]
        },
        welcome: false
      };*/

    case actions.SEARCH_NEXT_PAGE :
      const pageEvent: PageEvent = (action as SearchNextPageAction).pageEvent;
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
        userRequest: {
          ...state.userRequest,
          pager: {offset: pageEvent.pageIndex * pageEvent.pageSize, limit: pageEvent.pageSize}
        }
      };

    case actions.SEARCH_SUCCESS:
      const searchActionSuccess: SearchActionSuccess = (action as SearchActionSuccess);
      const searchResult: SearchResult = searchActionSuccess.result;
      return adapter.addMany(searchResult.items, {
        ...state,
        loaded: true,
        loading: false,
        filters: searchActionSuccess.filters,
        filtersMap: searchActionSuccess.filtersMap,
        options: {...state.options, ...searchActionSuccess.options},
        pager: searchResult.pager,
        userRequest: {
          ...state.userRequest,
          pager: {
            total: searchResult.pager ? searchResult.pager.total : initialState.pager.total,
            limit: searchResult.pager ? searchResult.pager.limit : initialState.pager.limit,
            offset: searchResult.pager ? searchResult.pager.offset : initialState.pager.offset
          }
        },
        error: null,
        optionsCount: searchActionSuccess.optionsCount
      });

    case actions.SEARCH_FAIL:
      const error: any = (action as SearchActionFail).payload;
      return {
        ...state,
        loaded: false,
        loading: false,
        pager: null,
        error: error,
        query: null
      };

    case actions.SEARCH_UPDATE_CONTENT_TYPES :
      contentTypes = (action as SearchUpdateContentTypesAction).payload;
      return adapter.removeAll({
        ...state,
        userRequest: {...state.userRequest, contentTypes: contentTypes}
      });

    case actions.SEARCH_CLEAR :
      return adapter.removeAll(state);

    case actions.SEARCH_RESET :
      return initialState;

    case actions.ADD_CORE_FILTER :
      return state;

    case actions.REMOVE_CORE_FILTER :
      return state;

    case actions.LOAD_META_DATA_SUCCESS :
      return {
        ...state,
        metadata: (action as LoadMetaDataSuccess).payload
      };

    case actions.LOAD_FACETS_FOR_UNIVERSE:
      let loadFacetsForUniverse: LoadFacetsForUniverse = action as LoadFacetsForUniverse;
      return {
        ...state,
        universe: loadFacetsForUniverse.universe
      };

    case actions.LOAD_FACETS_FOR_UNIVERSE_SUCCESS:
      let loadFacetsForUniverseSuccess: LoadFacetsForUniverseSuccess = action as LoadFacetsForUniverseSuccess;
      return {
        ...state,
        facets: {[loadFacetsForUniverseSuccess.universe]: loadFacetsForUniverseSuccess.filters},
        universe: loadFacetsForUniverseSuccess.universe,
        options: {...state.options, ...loadFacetsForUniverseSuccess.options}
      };

    default:
      return state;
  }
}


// get the selectors
const {selectAll} = adapter.getSelectors();

export const getSearchState = createFeatureSelector<SearchState>('search');

export const selectAllResults = createSelector(
  getSearchState,
  selectAll
);

export const getSearchLoading = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state?.loading;
  }
);

export const getSearchLoaded = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state?.loaded;
  }
);

export const getPager = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state?.userRequest?.pager;
  }
);

export const getQuery = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.query;
  }
);

export const getPagerLimit = createSelector(
  getPager,
  (pager: SearchPager) => {
    return pager ? pager.limit : initialState.pager.limit;
  }
);

export const getPagerOffset = createSelector(
  getPager,
  (pager: SearchPager) => {
    return pager ? pager.offset : initialState.pager.offset;
  }
);

export const getSearchUserRequest = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state ? state.userRequest : null;
  }
);

export const getSearchUserRequestTotal = createSelector(
  getSearchUserRequest,
  (userRequest: SearchUserRequest) => {
    return userRequest?.pager?.total ? userRequest.pager.total : 0;
  }
);

export const getSearchFilters = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.filters;
  }
);

export const getUserQuery = createSelector(
  getSearchUserRequest,
  (searchUserRequest: SearchUserRequest) => {
    return searchUserRequest ? searchUserRequest.query : null;
  }
);


export const getUserSearchFilters = createSelector(
  getSearchUserRequest,
  (searchUserRequest: SearchUserRequest) => {
    return searchUserRequest.filters;
  }
);

export const getUserSearchOptions = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.selectedOptionKeys;
  }
);

export const hasSearchUserCriteria = createSelector(
  getSearchUserRequest,
  (userRequest: SearchUserRequest) => {
    if (userRequest) {
      const hasQuery = userRequest && (userRequest.query !== null);
      const hasFilters = userRequest.filters != null;
      const hasContentTypes = (userRequest.contentTypes && userRequest.contentTypes.length > 0) === true;
      return hasQuery || hasFilters || hasContentTypes;
    }
    return false;
  }
);

export const getResultEmpty = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state && state.ids && state.ids.length === 0;
  }
);

export const getNoResult = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state && state.loaded == true && state.ids && state.ids.length === 0;
  }
);

export const getFilterLabel = (id: any, filterName: string) => createSelector(
  getSearchState,
  (state: SearchState) => {
    let isBoolean: boolean = id == 0 || id == 1;
    let label: string;
    if (isBoolean) {
      if(filterName === 'is_lexique'){
        label = 'Lexique';
      }else if(filterName === 'has_slideshow'){
        label = 'Diaporama';
      }else if (state.filtersMap && state.filtersMap[filterName]) {
        label = state.filtersMap[filterName].label;
      } else if (FiltersConstants.map[filterName]) {
        label = FiltersConstants.map[filterName].label;
      } else {
        label = "no name";
      }
    } else if ((filterName === 'field_difficulte' && id === FiltersConstants.lexiqueOptionId.toString(10))) {
      label = 'Lexique';
    } else {
      label = state.options && state.options[id] ? state.options[id].label : null;
    }
    return label;
  }
);

export const getOptionCount = (key: any) => createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.optionsCount[key] ? state.optionsCount[key] : 0;
  }
);

export const isOptionActive = (key: any) => createSelector(
  getSearchState,
  (state: SearchState) => {
    return state && state.selectedOptionKeys ? state.selectedOptionKeys[key] : false;
  }
);

export const isAvailableFilter = (filterName: any) => createSelector(
  getSearchState,
  (state: SearchState) => {
    return state && state.filtersMap && state.filtersMap[filterName] ? true : false;
  }
);

export const getFilterDescription = (filterName: string) => createSelector(
  getSearchState,
  (state: SearchState) => {
    //console.log('state', state);
    return state && state.metadata && state.metadata[filterName] && state.metadata[filterName].description;
  }
);

export const getSelectedOptions = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.selectedOptionKeys;
  }
);

export const getSelectedOptionsByFilter = (filterName: string) => createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.selectedOptionKeysToFilterName ? state.selectedOptionKeysToFilterName[filterName] : null;
  }
);

export const isSelectedOption = (key: string) => createSelector(
  getUserSearchOptions,
  (options: { [key: string]: boolean }) => {
    return options && options[key] === true;
  }
);

export const getAgeMinValues = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.filtersMap ? state.filtersMap[FiltersConstants.ageFrom.name] : null;
  }
);

export const getAgeMinValue = createSelector(
  getAgeMinValues,
  (ageMinValues) => {

    if (ageMinValues?.options?.length > 0) {
      return ageMinValues?.options[0];
    }
    return null;

  }
);

export const getAgeMinFilter = createSelector(
  getSearchState,
  getUserSearchFilters,
  getAgeMinValue,
  (
    state: SearchState,
    userFilters: { [filterId: string]: string },
    ageMin: SearchFilterOption
  ) => {
    const ageMinUserValue = userFilters ? userFilters[FiltersConstants.ageFrom.name] : null;
    return ageMinUserValue ? state.options[ageMinUserValue] : ageMin;
  }
);

export const getAgeMaxValues = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.filtersMap ? state.filtersMap[FiltersConstants.ageTo.name] : null;
  }
);

export const getAgeMaxValue = createSelector(
  getAgeMaxValues,
  (ageMaxValues) => {

    if (ageMaxValues?.options?.length > 0) {
      return ageMaxValues?.options[ageMaxValues.options.length - 1];
    }
    return null;

  }
);

export const getAgeLevelUserFilter = createSelector(
  getUserSearchFilters,
  (filters: FiltersMap) => {
    return (filters?.[FiltersConstants.ageLevel.name]) ?? null;
  }
);
export const getAgeLevelFilter = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.filtersMap ? state.filtersMap[FiltersConstants.ageLevel.name] : null;
  }
);

export const getAgeMaxFilter = createSelector(
  getSearchState,
  getUserSearchFilters,
  getAgeMaxValue,
  (
    state: SearchState,
    userFilters: { [filterId: string]: string },
    ageMax: SearchFilterOption
  ) => {
    const ageMaxUserValue = userFilters ? userFilters[FiltersConstants.ageTo.name] : null;
    return ageMaxUserValue ? state.options[ageMaxUserValue] : ageMax;
  }
);

/*export const isAvailableFilter = (filterName: string) => createSelector(
  getSearchState,
  (state: SearchState) => {
    let filter: SearchFilter = state && state.filtersMap && state.filtersMap[filterName];
    if (filter) {
      const isBoolean = filter.options[0].label === 'vrai' || filter.options[0].label === 'faux';
      if (isBoolean) {
        const hasOneOption = filter.options.length === 1;
        if (hasOneOption) {
          return filter.options[0].key == 1;
        }
      }
      return state && state.filtersMap && state.filtersMap[filterName] && state.filtersMap[filterName].options.length > 0;
    }
    return false;
  }
);*/

export const isLexiqueAvailable = createSelector(
  getSearchState,
  (state: SearchState) => {
    let filter: SearchFilter = state && state.filtersMap && state.filtersMap[FiltersConstants.format.name];
    if (filter) {
      return filter.options && filter.options.length > 0 && filter.options[0].key == FiltersConstants.lexiqueOptionId;
    }
    return false;
  }
);

export const getLexiqueCount = createSelector(
  getSearchState,
  (state: SearchState) => {
    let filter: SearchFilter = state && state.filtersMap && state.filtersMap[FiltersConstants.format.name];
    if (filter) {
      if (filter.options && filter.options.length > 0 && filter.options[0].key == FiltersConstants.lexiqueOptionId) {
        return filter.options[0].count;
      }
    }
    return 0;
  }
);

export const getSearchUniverse = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state.universe;
  }
);

export const getFacetsForUniverse = (universe: string) => createSelector(
  getSearchState,
  (state: SearchState) => {
    return state && state.facets && state.facets[universe];
  }
);

export const getFacets = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state && state.filters;
  }
);

export const getExtendedTypes = createSelector(
  getSearchState,
  (state: SearchState) => {
    return state && state.extendedTypes;
  }
);

export const getBooleanFilterCount = (filterName: string) => createSelector(
  getSearchState,
  (state: SearchState) => {
    let filter: SearchFilter = state && state.filtersMap && state.filtersMap[filterName];
    if (filter) {
      const trueFilterOption: SearchFilterOption = filter.options.filter((option: SearchFilterOption) => option.key == 1).shift();
      let count;
      if (trueFilterOption) {
        count = trueFilterOption.count;
      } else {
        count = 0;
      }
      return count;
    }
    return null;
  }
);

export const hasAdvancedFilters = createSelector(
  getSearchState,
  (state: SearchState) => {
    let filters: { [filterName: string]: SearchFilter } = state && state.filtersMap;
    let hasAdvancedFilters: boolean = false;
    if (filters) {
      let advancedFilters: SearchFilter[] = [];
      FiltersConstants.filtersDefinition.advanced.forEach(filter => {
        if (filters[filter.name]) {
          advancedFilters.push(filter);
        }
        hasAdvancedFilters = advancedFilters.length > 0;
      });
    }
    return hasAdvancedFilters;
  }
);
