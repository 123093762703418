import {Inject, Injectable, Pipe, PipeTransform} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {BOOKMARK_IDS} from "../providers/user.providers";

@Pipe({
  name: 'bookmark'
})
@Injectable()
export class BookmarkPipe implements PipeTransform {

  constructor(
    @Inject(BOOKMARK_IDS) private readonly bookmark$: Observable<string[]>
  ) {
  }


  transform(id: string): Observable<boolean> {
    return this.bookmark$.pipe(map((bookmarks: string[]) => {
      if (bookmarks && id) {
        return bookmarks.indexOf(id) > -1;
      } else {
        return false;
      }
    }));
  }
}
