import {Injectable} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {map, mergeMap} from 'rxjs/operators';
import * as fromRoot from '../../../store/reducers';
import {CheckOut, ECommerceActionTypes, LoadCart, LoadCartSuccess} from "../actions/e-commerce.actions";
import {CheckOutEntity, EcommerceService} from "../../services/e-commerce.service";
import {OAuthService} from "angular-oauth2-oidc";
import {WindowRef} from "../../../window-ref";

@Injectable()
export class ECommerceEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  
  loadCart$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ECommerceActionTypes.LoadCart),
    mergeMap((action: LoadCart) => {
      return this.eCommerceService.loadCart(action.cartType).pipe(
        map(result => {
          return new LoadCartSuccess(result);
        })
      );
    })
  ));

  
  checkOut$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(ECommerceActionTypes.CheckOut),
    mergeMap((action: CheckOut) => {
      return this.eCommerceService.checkOut(action.orderId);
    }),
    map((checkOutEntity: CheckOutEntity) => {
      if(checkOutEntity && checkOutEntity.redirectUrl){
        this.windowRef.nativeWindow.location.href = checkOutEntity.redirectUrl + '&access_token=' + this.oauthService.getAccessToken();
      }
      return null;
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private eCommerceService: EcommerceService,
    private oauthService: OAuthService,
    private store: Store<fromRoot.State>,
    private windowRef: WindowRef
  ) {
  }
}
