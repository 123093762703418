import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef, SimpleSnackBar} from "@angular/material/snack-bar";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ea-app-snackbar',
  templateUrl: './app-snackbar.component.html',
  styleUrls: ['./app-snackbar.component.scss']
})
export class AppSnackbarComponent implements OnInit {

  platformLogo: string = '../../../icons/apple-touch-icon.png';

  constructor(
    public snackBarRef: MatSnackBarRef<SimpleSnackBar>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {
  }

  reload(){
    if(this.document){
      this.document.location.reload();
    }
  }

  action(): void {
    this.snackBarRef.dismissWithAction();
  }

}
