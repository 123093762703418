import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {passwordConfirming} from "../user-infos-form/user-infos-form.component";
import {select, Store} from "@ngrx/store";
import * as fromCore from "../../../core/store/reducers";
import {changePasswordSuccess} from "../../../core/store/reducers";
import {RequestPassword, ResetRequestPassword} from "../../../core/store/actions";
import {ChangePassword} from "../../../core/models/change-password";
import {Observable, Subscription} from "rxjs";
import {filter} from "rxjs/operators";

@Component({
  selector: 'ea-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit, OnDestroy {

  @ViewChild('formDirective') formDirective:FormGroupDirective;

  form: FormGroup;

  changePasswordSuccess$: Observable<boolean> = this.store.pipe(
    select(changePasswordSuccess),
    filter(success => success == true)
  );

  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<fromCore.State>
  ) {

    this.form = this.fb.group({
        oldPassword: new FormControl('', [
          Validators.required,
        ]),
        password: new FormControl('', [
          Validators.required,
        ]),
        passwordConfirm: new FormControl('', [
          passwordConfirming, Validators.required
        ])
      }
    );

    let sub: Subscription;

    sub = this.changePasswordSuccess$.subscribe(() => {
      this.formDirective.resetForm();
      this.form.reset();
      this.store.dispatch(new ResetRequestPassword()) ;
    });

    this.subs.push(sub);

  }

  ngOnInit() {

  }

  ngOnDestroy(){
    if(this.subs){
      this.subs.forEach(sub => sub.unsubscribe());
    }
    this.store.dispatch(new ResetRequestPassword()) ;
  }

  onSubmitForm() {
    if(this.form.valid){
      const formValue: any = this.form.value;
      const oldPassword: string = formValue.oldPassword;
      const newPassword: string = formValue.password;
      const changePassword: ChangePassword = {oldPassword: oldPassword, newPassword: newPassword};
      this.store.dispatch(new RequestPassword(changePassword)) ;
    }
  }

}
