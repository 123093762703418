import {Component, NgModule, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'ea-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
  ) {

  }

  ngOnInit() {
  }

}

@NgModule({
  declarations: [
    NotFoundComponent
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: NotFoundComponent
      }
    ]),
    MatButtonModule
  ]
})
export class NotFoundModule {
}
