import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'webformSelectorOptions'
})
export class WebformSelectorOptionsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let values: string[] = value.split('\n');
    let options: any[] = values.map(option => {
      let optionSplit: string[] = option.split('|');
      return {value: optionSplit[0], label: optionSplit[1]}
    });
    return options;
  }

}
