import {EventEmitter, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {shareReplay, startWith, take} from "rxjs/operators";
import {OpenCloseState} from "../search/search.service";

@Injectable({
  providedIn: 'root'
})
export class ContentsService {

  private openCloseSearch: EventEmitter<OpenCloseState> = new EventEmitter<OpenCloseState>();
  public openCloseSearch$: Observable<OpenCloseState> = this.openCloseSearch.asObservable()
    .pipe(
      startWith('closed' as OpenCloseState),
      shareReplay(1)
    );

  constructor() {
    //console.log('ContentsService');
  }

  toggleSearchPanel() {
    this.openCloseSearch$.pipe(take(1)).subscribe(openClose => {
      if (openClose === 'closed') {
        this.openCloseSearch.emit('open');
      } else {
        this.openCloseSearch.emit('closed');
      }
    });
  }

}
