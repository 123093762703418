import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {HttpErrorResponse} from '@angular/common/http';
import {MenuActions, MenuActionTypes} from '../actions/menu';
import {DrupalMenu} from '../../models/menu';

/**
 * Created by benoitplatre on 25/06/2018.
 */

export interface State extends EntityState<DrupalMenu> {
  loading: boolean;
  loaded: boolean;
  error: HttpErrorResponse|null;
}

export const adapter: EntityAdapter<DrupalMenu> = createEntityAdapter<DrupalMenu>({
  selectId: (menu: DrupalMenu) => menu.name,
  sortComparer: false
});

const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

export function reducer(state: State = initialState,
                        action: MenuActions): State {
  switch (action.type) {

    case MenuActionTypes.Load:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null
      };

    case MenuActionTypes.LoadSuccess:
      return adapter.setOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });

    case MenuActionTypes.LoadFail:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal

} = adapter.getSelectors();

export const getMenuLoading = (state: State) => state.loading;
export const getMenuLoaded = (state: State) => state.loaded;
export const getMenuFail = (state: State) => state.error;
