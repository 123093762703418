import {SiFfaActions} from '../actions';
import {LicenceInfos} from "../../models/licence-infos";
import {QuizAccessFull} from "../../models/quiz-access";

export interface State {
  checkLicenceLoading?: boolean;
  checkLicenceLoaded?: boolean;
  checkLicenceError?: boolean;
  checkLicenceValid?: boolean;
  licenceInfos?: LicenceInfos;
  secureLink?: string;
  quizAccess?: QuizAccessFull;
}

const initialState: State = {
  checkLicenceLoading: false,
  checkLicenceLoaded: false,
  checkLicenceError: null,
  checkLicenceValid: null,
  licenceInfos: null,
  secureLink: null,
  quizAccess: null
};

export function reducer(
  state: State = initialState,
  action: SiFfaActions.SiFfaActionsUnion
): State {
  switch (action.type) {

    case SiFfaActions.SiFfaActionTypes.CheckLicence:
      return {
        ...state,
        checkLicenceLoaded: false,
        checkLicenceLoading: true,
        checkLicenceValid: false,
        checkLicenceError: false
      };

    case SiFfaActions.SiFfaActionTypes.CheckLicenceSuccess:
      return {
        ...state,
        checkLicenceLoaded: true,
        checkLicenceLoading: false,
        checkLicenceValid: true,
        checkLicenceError: false
      };

    case SiFfaActions.SiFfaActionTypes.CheckLicenceWrong:
      return {
        ...state,
        checkLicenceLoaded: true,
        checkLicenceLoading: false,
        checkLicenceValid: false,
        checkLicenceError: true
      };

    case SiFfaActions.SiFfaActionTypes.CheckLicenceFail:
      return {
        ...state,
        checkLicenceLoaded: true,
        checkLicenceLoading: false,
        checkLicenceValid: false,
        checkLicenceError: true
      };

    case SiFfaActions.SiFfaActionTypes.SetLicenceInfos:
      return {
        ...state,
        licenceInfos: action.licenceInfos
      };

    case SiFfaActions.SiFfaActionTypes.AuthenticateSiFfaLink:
      return {
        ...state,
        secureLink: action.payload
      };

    case SiFfaActions.SiFfaActionTypes.LoadQuizAccessSuccess:
      return {
        ...state,
        quizAccess: action.quizAccessFull
      };

    case SiFfaActions.SiFfaActionTypes.Reset:
      return initialState;

    default:
      return state;

  }
}

export const getLicenceValid = (state: State) => state.checkLicenceLoaded ?  state.checkLicenceValid : null;

export const getLicenceError = (state: State) => state.checkLicenceLoaded && state.checkLicenceError;

export const getLicenceInfos = (state: State) => state.licenceInfos;

export const getSecureLink = (state: State) => state.secureLink;

export const getQuizAccess = (state: State) => state.quizAccess;
