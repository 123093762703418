import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {AppConfigService} from '../app-config.service';
import {SearchResult} from './models/search-result';
import {SearchUserRequest} from './models/search-user-request';
import {ContentTypesConstants} from "../core/models/content-types-constants";
import {FiltersConstants} from "../core/models/filters-constants";
import {combineLatest} from "rxjs";
import {shareReplay, startWith, take} from "rxjs/operators";

export type OpenCloseState = 'open' | 'closed';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  openCloseFilters: EventEmitter<OpenCloseState> = new EventEmitter<OpenCloseState>();
  openCloseFilters$: Observable<OpenCloseState> = this.openCloseFilters.asObservable()
    .pipe(
      startWith('closed' as OpenCloseState),
      shareReplay(1)
    );

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService
  ) {
  }

  toggleFilterPanel() {
    this.openCloseFilters$.pipe(take(1)).subscribe(openClose => {
      if (openClose === 'closed') {
        this.openCloseFilters.emit('open');
      } else {
        this.openCloseFilters.emit('closed');
      }
    });
  }

  parseFilters(
    searchUserRequest: SearchUserRequest,
    domains: string[] = null,
    universe: string = null
  ): any[] {

    //TODO mutualize filter parsing between filters and coreFilters
    let baseFilters: any[] = [];
    let apiFilter: any;
    let hasStatus: boolean;
    if (searchUserRequest.filters) {
      for (let filterName in searchUserRequest.filters) {
        let filterValue: any = searchUserRequest.filters[filterName];
        if (filterName !== 'extended_type') {
          let options = Array.isArray(filterValue) ? filterValue : filterValue.split(',');
          if (filterName == 'status') {
            hasStatus = true;
          }
          options.map((option: string) => {
            apiFilter = {n: filterName, v: option};
            if (filterName == FiltersConstants.ageFrom.name) {
              apiFilter.o = 'lte';
            } else if (filterName == FiltersConstants.ageTo.name) {
              apiFilter.o = 'gte';
            }
            baseFilters.push(apiFilter);
          });
        } else {
          baseFilters.push({n: 'extended_type', v: filterValue});
        }
      }
    }

    if (!hasStatus) {
      baseFilters.push({n: 'status', v: '1'});
    }

    if (searchUserRequest.coreFilters) {
      for (let filterName in searchUserRequest.coreFilters) {
        let filterValue: any = searchUserRequest.coreFilters[filterName];
        let options = Array.isArray(filterValue) ? filterValue : filterValue.split(',');
        options.map((option: string) => {
          apiFilter = {n: filterName, v: option};
          baseFilters.push(apiFilter);
        });
      }
    }

    const contentTypes: string[] = searchUserRequest.filters?.type as string[];
    if (domains && universe == ContentTypesConstants.universeEncyclopedia) {
      apiFilter = {n: FiltersConstants.domain.name, v: []};
      for (let i = 0; i < domains.length; i++) {
        let domainId: string = domains[i];
        apiFilter.v.push(parseInt(domainId));
      }
      baseFilters.push(apiFilter);
    }

    if (contentTypes && contentTypes.length > 0) {
    } else {
      if (universe == ContentTypesConstants.universeEncyclopedia) {
        baseFilters.push({n: 'universe', v: ContentTypesConstants.universeEncyclopedia});
      } else if (universe == ContentTypesConstants.universeTraining) {
        baseFilters.push({n: 'universe', v: ContentTypesConstants.universeTraining});
      }

    }

    if (searchUserRequest.promoted === true) {
      baseFilters.push({n: 'promote', v: '1'});
    }

    baseFilters.push({n: 'field_public_quiz', v: '0', o: 'ne'});

    return baseFilters;

  }

  public search(
    searchUserRequest: SearchUserRequest,
    limit: number,
    reset: boolean,
    domains: string[] = null,
    universe: string = null,
  ): Observable<[SearchResult, SearchResult]> {

    let baseFilters: any[] = this.parseFilters(searchUserRequest, domains, universe);

    const filtersWithoutExtendedTypes: any[] = baseFilters.filter(filter => filter.n !== 'extended_type');

    const stringifiedFilters: string = JSON.stringify(baseFilters);
    const stringifiedFiltersWithoutExtendedTypes: string = JSON.stringify(filtersWithoutExtendedTypes);

    const query: string = searchUserRequest.query ? searchUserRequest.query : '';
    const offset: number = reset ? 0 : !isNaN(searchUserRequest?.pager?.offset) ? searchUserRequest?.pager?.offset : 0;

    let sort: string = 'changed';
    let order: string = 'DESC';
    if (universe === 'training') {
      sort = 'formation_siffa_code';
      order = 'ASC';
    }

    const searchWithExtendedTypes$: Observable<any> = this.httpClient
      .get<any>(this.appConfig.getConfig().apiBaseUrl + '/search?q=' + query + '&limit=' + limit + '&offset=' + offset + '&filters=' + stringifiedFilters + '&sort=' + sort + '&order='+order);

    const searchWithoutExtendedTypes$: Observable<any> = this.httpClient
      .get<any>(this.appConfig.getConfig().apiBaseUrl + '/search?q=' + query + '&limit=' + 0 + '&offset=' + offset + '&filters=' + stringifiedFiltersWithoutExtendedTypes + '&sort=' + sort + '&order=DESC');

    return combineLatest([searchWithExtendedTypes$, searchWithoutExtendedTypes$]);

  }

  public searchBookmarks(userId: string): Observable<SearchResult> {
    const baseFilters: any[] = [{n: FiltersConstants.bookmarks.name, v: userId}];
    return this.httpClient
      .get<any>(this.appConfig.getConfig().apiBaseUrl + '/search?limit=' + 50 + '&offset=' + 0 + '&filters=' + JSON.stringify(baseFilters));
  }

  public searchHomePromoted(searchUserRequest: SearchUserRequest): Observable<SearchResult> {
    let baseFilters: any[] = this.parseFilters(searchUserRequest);
    return this.httpClient
      .get<any>(this.appConfig.getConfig().apiBaseUrl + '/search?limit=' + 12 + '&offset=' + 0 + '&filters=' + JSON.stringify(baseFilters));
  }

  public getFacets(universe: string): Observable<SearchResult> {
    return this.httpClient
      .get<any>(this.appConfig.getConfig().apiBaseUrl + '/search?limit=0&size=0&offset=0&sort=changed&order=DESC&filters=[{"n":"universe","v":' + universe + '}]');
  }

  public findContentById(
    id: string
  ): Observable<SearchResult> {
    const baseFilters: any[] = [{n: 'nid', v: id}];
    const stringifiedFilters: string = JSON.stringify(baseFilters);
    const url: string = this.appConfig.getConfig().apiBaseUrl + '/search?filters=' + stringifiedFilters;
    return this.httpClient
      .get<any>(url);
  }

  public getFacetsMetaData() {
    /*/api/1.0/search-api-index-meta/ffa_search*/
    const url: string = this.appConfig.getConfig().apiBaseUrl + '/1.0/search-api-index-meta/ffa_search';
    return this.httpClient
      .get<any>(url);
  }

}
