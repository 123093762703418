<ng-container *ngIf="(userReady$|async); else loadingTemplate">

  <mat-sidenav-container class="d-flex flex-fill root-group">

    <mat-sidenav #appNav [mode]="'over'"
                 position="start"
                 (openedChange)="onMainMenuOpenedChange($event)"
                 [opened]="openedMainMenu$|async"
                 [fixedInViewport]="(breakPoint$|async) === 'xs'">

      <ea-app-menu
        class="d-flex flex-column flex-fill"
        [mainMenu]="mainMenu$|async"
        [secondaryMenu]="secondaryMenu$|async"
        [legalMenu]="legalMenu$|async"
        [isTrainee]="(isTraineeUser$|async) == true"
        [isFormation]="(isFormationUser$|async)==true">
      </ea-app-menu>

      <small class="px-5 align-self-center mb-2 user-select-text" [innerHTML]="(versionInfo$|async)"></small>

    </mat-sidenav>

    <mat-sidenav #bookmarksNav [mode]="'over'"
                 (closed)="closeBookmarks()"
                 [opened]="isBookmarkMenuOpen$|async"
                 [fixedTopGap]="48"
                 [fixedInViewport]="true"
                 class="bookmarks-side-nav"
                 position="end">

      <ng-template [ngComponentOutlet]="templatesService.getComponent$('bookmarks')|async">
      </ng-template>

    </mat-sidenav>

    <mat-sidenav-content
      class="d-flex flex-fill flex-column content-group"
      [class.home-active]="isHome$|async"
      [class.content-active]="isContent$|async"
      [class.search-active]="isSearch$|async"
      [class.session]="isSession$|async">

      <header>

        <ea-app-header
          #appHeader
          [userId]="(userInfos$|async)?.id"
          [universeTitle]="universe$|async"
          [isHome]="isHome$|async"
          [isSearch]="isSearch$|async"
          [isContent]="isContent$|async"
          [loading]="globalLoading$|async"
          [userName]="(userInfos$|async)?.firstName ? (userInfos$|async)?.firstName : (userInfos$|async)?.email"
          [authenticated]="(authenticated$|async)"
          [subscriber]="(userSubscriber$|async) === true"
          [bookmarksCount]="(bookmarksCount$|async)"
          (logoutEmitter)="onLogout()"
          (openCloseMenu)="appNav.toggle()"
          (openWindow)="onOpenWindow($event)">
        </ea-app-header>

        <div class="search-field-group d-flex flex-column align-items-center px-2 px-md-5">

          <ea-transversal-search-field
            *ngIf="!(hideSearchField$|async)"
            class="search-field mat-elevation-z3"
            [query]="(userQuery$|async)"
            (searchQueryChange)="onSearchQueryChange($event)">
          </ea-transversal-search-field>

        </div>

        <ng-container *ngIf="showSearchBar$|async">

          <div
            class="search-infos-bar d-flex flex-shrink-0 mat-elevation-z2 flex-none align-items-center mat-background-card justify-content-between justify-content-sm-start"
            [class.search-context]="(isSearch$|async)"
            [class.training-theme]="(universe$|async)=='training'"
            [class.encyclopedia-theme]="(universe$|async)=='encyclopedia'">

            <ng-container *ngIf="(breakPoint$|async)!='xs'">

              <ng-container *ngIf="isContent$|async">

                <ea-back-search-button class="px-3">
                </ea-back-search-button>

                <mat-divider [vertical]="true"></mat-divider>

              </ng-container>

              <ng-container *ngIf="(searchResultService?.totalCount$|async)">

                <div class="flex-grow-0 d-flex align-items-center ml-2">
                  <mat-spinner diameter="20" color="primary"
                               [mode]="(searchResultService.searchLoading$|async)?'indeterminate':'determinate'"
                               class="mr-2"></mat-spinner>
                  <span [innerHTML]="(searchResultService?.searchResult$|async)?.length" class="slash-after"></span>
                  <span [innerHTML]="(searchResultService?.totalCount$|async)+' résultats'"></span>
                </div>

              </ng-container>

            </ng-container>

            <ng-container *ngIf="(breakPoint$|async)=='xs'">

              <ng-container *ngIf="isSearch$|async">

                <button
                  [class.active]="(searchService.openCloseFilters$|async) === 'open'"
                  mat-flat-button class="filters-button"
                  (click)="openSearchFilters()">
                  <mat-icon [svgIcon]="'icon-filters'"></mat-icon>
                  <span class="ml-2">Filtres</span>
                </button>

              </ng-container>

              <ng-container *ngIf="isContent$|async">

                <ea-back-search-button class="px-3">
                </ea-back-search-button>

                <mat-divider [vertical]="true"></mat-divider>

                <ng-container *ngIf="(searchResultService?.totalCount$|async)">

                  <div>

                    <a
                      (click)="contentsService.toggleSearchPanel()"
                      class="back-search d-flex flex-none d-sm-none px-2 py-2">
                      <mat-icon svgIcon="icon-open-right-menu" class="m-1"></mat-icon>
                      <div class="d-flex flex-column flex-fill label-group pl-2 justify-content-center">
                        <span class="font-size-caption">Résultat de recherche</span>
                        <span class="font-weight-bold count">{{searchResultService?.totalCount$|async}} fiches</span>
                      </div>
                    </a>

                  </div>

                </ng-container>

              </ng-container>

              <ng-container *ngIf="(searchResultService?.totalCount$|async) && isSearch$|async">

                <div class="flex-grow-0 d-flex align-items-center mr-3">
                  <mat-spinner diameter="20" color="primary"
                               [mode]="(searchResultService.searchLoading$|async)?'indeterminate':'determinate'"
                               class="mr-2"></mat-spinner>
                  <span [innerHTML]="(searchResultService?.searchResult$|async)?.length" class="slash-after"></span>
                  <span [innerHTML]="(searchResultService?.totalCount$|async)+' résultats'"></span>
                </div>

              </ng-container>

            </ng-container>

          </div>

        </ng-container>

      </header>

      <main class="d-flex flex-column flex-fill position-relative px-0"
            [@routerTransition]="getState(o)">

        <router-outlet #o="outlet"></router-outlet>

        <!--
        <ea-loading *ngIf="contextLoading$|async"></ea-loading>
        -->

      </main>

      <footer *ngIf="(breakPoint$|async) != 'xs'" class="mat-background-card">
        <ea-app-footer
          [isHome]="isHome$|async"
          [legalMenu]="legalMenu$|async"
          [secondaryMenu]="secondaryMenu$|async"
          class="flex-shrink-0">
        </ea-app-footer>
      </footer>

    </mat-sidenav-content>

  </mat-sidenav-container>

  <router-outlet name="userLoginOutlet"></router-outlet>
  <router-outlet name="userAccountOutlet"></router-outlet>
  <router-outlet name="popUp"></router-outlet>

</ng-container>

<ng-template #cookieFooter>
  <p *ngIf="(preferencesConsent$|async)">J'ai choisi d’accepter les cookies, cela me permet notamment de garder en
    mémoire mes préférences.</p>
  <p *ngIf="!(preferencesConsent$|async)">J'ai choisi de refuser les cookies. À chaque connexion, mes préférences seront
    à définir à nouveau.</p>
  <button mat-raised-button (click)="renewCookies()">Modifier mon consentement aux Cookies</button>
</ng-template>

<ng-container *ngIf="isNotProd">
  <h1 class="test-flag">TEST</h1>
</ng-container>

<ng-template #loadingTemplate>

  <div class="d-flex flex-fill justify-content-center align-items-center flex-column">

    <img [src]="'assets/logos/logo-formation-bleu.svg'" class="loading-logo">

    <mat-spinner [diameter]="30" color="accent" class="mt-5"></mat-spinner>

  </div>

</ng-template>
