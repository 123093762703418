import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '../../app-config.service';

@Injectable({
  providedIn: 'root'
})
export class PathService {

  constructor(
    private http: HttpClient,
    private appConfig:AppConfigService
  ) {
  }

  public load(url: string): Observable<any> {
    return this.http
      .get<{ pathInfos: any }>(this.appConfig.getConfig().adminUrl + '/api/1.0/path?path=' + url);
  }

}
