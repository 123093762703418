import {Injectable} from '@angular/core';
import {AppConfigLoader} from './app-config-loader';

export class AppConfig{
  oauth: AppOauthConfig;
  adminUrl: string;
  apiBaseUrl: string;
  googleAnalyticsAccount: string;
}

export class AppOauthConfig{
  clientId: string;
  issuer: string;
  tokenUrl: string;
  userInfoUrl: string;
}

@Injectable()
export class AppConfigService {
  private appConfig;

  constructor(private loader: AppConfigLoader) {
  }

  loadAppConfig() {
    return this.loader.load().then(data => {
      this.appConfig = data;
    }, error => {
      console.error('load config error', error);
    });
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }
}
