import {Pipe, PipeTransform} from '@angular/core';
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode} from "../../core/models/node";
import {
  Situation,
  SituationOrganisationParagraphs,
  SituationParagraph,
  SituationParagraphs,
  SituationsConstants,
  SituationVariant
} from "../../core/models/situation";
import {Video} from "../../core/models/video";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {Image} from "../../core/models/image";
import {FieldInfos} from "../../core/models/field-infos";
import {HtmlCleanupPipe} from "../html-cleanup.pipe";

@Pipe({
  name: 'resourceToSituation'
})
export class ResourceToSituationPipe implements PipeTransform {


  mainParagraphs: FieldInfos[] = [
    SituationsConstants.description,
    SituationsConstants.consignes,
    SituationsConstants.fautes,
    SituationsConstants.but,
    SituationsConstants.criteres,
    SituationsConstants.comportement,
    SituationsConstants.evolution
  ];

  secondaryParagraphs: FieldInfos[] = [
    SituationsConstants.materiel,
    SituationsConstants.astuces,
    SituationsConstants.securite,
    SituationsConstants.infos
  ];

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToMediaPipe: ResourceToMediaPipe,
    private htmlCleanupPipe: HtmlCleanupPipe
  ) {
  }

  transform(value: any): Situation {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);

    let videos: Video[] = [];
    if (value.videos) {
      value.videos.forEach(rawVideo => {
        videos.push(this.resourceToMediaPipe.transform(rawVideo) as Video);
      })
    }

    let video: Video = null;
    if (value.video) {
      video = this.resourceToMediaPipe.transform(value.video) as Video;
    }

    let images: Image[] = [];
    if (value.images) {
      value.images.forEach(rawImage => {
        images.push(this.resourceToMediaPipe.transform(rawImage) as Image);
      })
    }
    const goal: string = this.htmlCleanupPipe.transform(value.description);

    let infos: SituationParagraphs = this.parseParagraph(this.mainParagraphs, value);
    let organisationInfos: SituationOrganisationParagraphs = this.parseParagraph(this.secondaryParagraphs, value);

    let variants: SituationVariant[] = [];

    if (Array.isArray(value.labelledVideos)) {
      variants = value.labelledVideos.map(variant => {
        return {
          label: variant.label,
          consigne: variant.video_consigne,
          critere: variant.video_critere,
          video: variant.video,
        }
      })
    }

    let thumbnail: Image = null;

    if (images.length > 0) {
      thumbnail = images[0];
    } else if (videos.length > 0) {
      thumbnail = videos[0]?.thumbnail;
    } else if (video) {
      thumbnail = video.thumbnail;
    }

    return {
      ...drupalNode,
      goal,
      video,
      videos,
      images,
      infos,
      organisationInfos,
      thumbnail,
      variants
    };
  }

  parseParagraph(reference: FieldInfos[], source: any): any {
    let infos: any = null;
    reference.forEach((fieldInfo, index) => {
      if (source.hasOwnProperty(fieldInfo.name) && !!source[fieldInfo.name]) {
        const paragraph: SituationParagraph = {
          index,
          label: fieldInfo.label,
          content: this.htmlCleanupPipe.transform(source[fieldInfo.name])
        }
        if(paragraph.content){
          if (!infos) {
            infos = {};
          }
          infos[fieldInfo.finalName] = paragraph;
        }
      }
    });
    return infos;
  }

}
