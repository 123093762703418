import {Injectable, Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'correctAnswer'
})
@Injectable()
export class CorrectAnswerPipe implements PipeTransform {

  constructor() {
  }


  transform(answer: string, answers: string[]): boolean {
    return answers.indexOf(answer) > -1;
  }
}
