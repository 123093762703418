<form [formGroup]="form" class="col-12" *ngIf="form">

  <div class="row">
    <div class="col">
      <ng-content select=".header-group"></ng-content>
    </div>
  </div>

  <div class="row">

    <div class="col-xs-12 col-md-6 mb-2">

      <mat-radio-group formControlName="civility" required class="d-flex">
        <mat-radio-button value="m" class="mr-3" [color]="'primary'">M.</mat-radio-button>
        <mat-radio-button value="f" [color]="'primary'">Mme</mat-radio-button>
      </mat-radio-group>
      <mat-error class="custom-mat-error" *ngIf="form.touched && form.controls.civility.errors?.required">
        Votre civilité est obligatoire
      </mat-error>

    </div>

  </div>

  <div class="row">

    <div class="col-xs-12 col-md-12 d-flex flex-column">

      <div class="row">

        <mat-form-field class="col col-sm-6">
          <input
            matInput
            placeholder="Prénom"
            required
            formControlName="firstname"
            autocomplete="given-name">
          <mat-error *ngIf="form.controls.firstname.errors?.required">
            Votre prénom est obligatoire
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col col-sm-6">
          <input
            matInput
            placeholder="Nom"
            required
            formControlName="lastname"
            autocomplete="family-name">
          <mat-error *ngIf="form.controls.lastname.errors?.required">
            Votre nom est obligatoire
          </mat-error>
        </mat-form-field>

      </div>


      <div class="row">
        <mat-form-field class="col-12">
          <input
            matInput
            placeholder="Adresse e-mail"
            formControlName="email"
            autocomplete="email">
          <mat-error *ngIf="form.controls.email.errors?.email && !form.controls.email.errors?.required">
            Merci de saisir une adresse e-mail valide
          </mat-error>
          <mat-error *ngIf="form.controls.email.errors?.required">
            Votre adresse e-mail est obligatoire
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="creationMode">

          <mat-form-field class="col-12 col-sm-6">
            <input
              matInput
              placeholder="Mot de passe"
              required
              type="password"
              formControlName="password"
              autocomplete="off">
            <mat-error *ngIf="form.controls.password.errors?.required">
              Merci de choisir votre mot de passe
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-12 col-sm-6">
            <input
              matInput
              placeholder="Confirmez votre mot de passe"
              type="password"
              formControlName="passwordConfirm"
              autocomplete="off">
            <mat-error *ngIf="form.controls.passwordConfirm.hasError('invalid')">
              Votre mot de passe ne corresponds pas
            </mat-error>
          </mat-form-field>

        </ng-container>

      </div>

    </div>

    <div class="col d-flex flex-column mb-3">


      <div class="d-flex">
        <label class="mr-3">Je suis licencié FFA</label>
        <mat-radio-group formControlName="hasLicence" color="primary">
          <mat-radio-button class="mr-2" [value]="true" color="primary">Oui</mat-radio-button>
          <mat-radio-button [value]="false" color="primary">Non</mat-radio-button>
        </mat-radio-group>
      </div>

      <ng-container *ngIf="form.controls.hasLicence.value === true">

        <div class="d-flex row align-items-start">

          <mat-form-field class="flex-grow-1 col col-sm-6">
            <input
              matInput
              placeholder="Numéro de licence"
              required
              formControlName="ffaLicenseNumber"
              autocomplete="given-name"
              type="number">
            <mat-error *ngIf="form.controls.ffaLicenseNumber.errors?.required">
              Votre numéro de licence est obligatoire
            </mat-error>
          </mat-form-field>


          <mat-form-field
            class="flex-grow-1 col col-sm-6 date-button"
            floatLabel="always">

            <mat-label>Date de naissance</mat-label>

            <input
              matInput
              [matDatepicker]="datepicker"
              formControlName="birthDate">

            <button
              mat-flat-button
              [color]="birthDateControl.value ? null : 'accent'"
              class="w-100"
              (click)="datepicker.open()">
              <div class="d-flex align-items-center justify-content-center flex-fill">

                <mat-icon svgIcon="icon-calendar" class="d-flex mr-2"></mat-icon>

                <ng-container *ngIf="birthDateControl.value">
                  <span [innerHTML]="birthDateControl.value|date:'d MMMM y'"></span>
                </ng-container>

                <ng-container *ngIf="!birthDateControl.value">
                  <span [innerHTML]="'Sélectionnez une date'"></span>
                </ng-container>

              </div>
            </button>

            <mat-datepicker
              [startView]="'multi-year'"
              [startAt]="birthDateControl.value?birthDateControl.value:birthDateStart"
              #datepicker>
            </mat-datepicker>

          </mat-form-field>

        </div>

      </ng-container>

      <ng-container
        *ngIf="!form.dirty && userInfos && form.controls.hasLicence.value && userInfos.ffaLicenseValid===false && userInfos.birthDate">
        <p>Vos informations de licence ne sont pas correctes. Merci de vérifier vos coordonnées. Si le problème persiste
          bien que vous pensez avoir renseigné correctement vos informations, merci de contacter la FFA.</p>
      </ng-container>

      <ng-container *ngIf="userInfos && userInfos.ffaLicenseValid===true">
        <p>En tant que licencié, vous avez accès à tous les contenus de la plateforme !</p>
      </ng-container>

    </div>

  </div>

  <div class="app-version">
    <small>{{version}}</small>
  </div>

  <ng-content select=".footer-group"></ng-content>

</form>

<ng-container *ngIf="(loading$|async)">
  <div class="loading-icon-group">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
</ng-container>

