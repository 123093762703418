/**
 * Created by benoitplatre on 26/06/2018.
 */

import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromContexts from './context.reducer';
import {ContextsState} from './context.reducer';
import * as fromPath from './path';
import {PathState} from './path';
import * as fromNode from './node';
import {NodeState} from './node';
import * as fromMenu from './menu';
import * as fromTaxonomy from './taxonomy.reducer';
import {TaxonomyState} from './taxonomy.reducer';
import * as fromUser from './user.reducer';
import {UserState} from './user.reducer';
import * as fromUi from './ui.reducers';
import {UiState} from './ui.reducers';
import * as fromRoot from '../../../store/reducers';
import * as fromWebform from './webforms.reducer';
import * as appCore from './app-core.reducers';
import {RouterReducerState} from '@ngrx/router-store';
import {RouterStateUrl} from '../../../shared/utils';
import {DrupalNode} from "../../models/node";
import {User} from "../../models/user";
import * as fromECommerce from './e-commerce.reducers';
import * as fromSiFFA from './si-ffa.reducers';
import {encyclopediaPath, homePath, trainingPath} from "../../../route-constants";
import {HttpErrorResponse} from "@angular/common/http";
import {selectDrupalNodeEntities} from "../../drupal-node/drupal-node.reducer";
import {Contexts} from "../../../providers/contexts.providers";
import {Preferences} from "./app-core.reducers";

export interface CoreState {
  contexts: fromContexts.ContextsState;
  path: fromPath.PathState;
  node: fromNode.NodeState;
  menu: fromMenu.State;
  taxonomy: fromTaxonomy.TaxonomyState;
  user: fromUser.UserState;
  ui: fromUi.UiState;
  webforms: fromWebform.WebformsState;
  cookie: appCore.State;
  eCommerce: fromECommerce.State;
}

export interface State extends fromRoot.State {
  core: CoreState;
  siFFA: fromSiFFA.State;
}

export const reducers: ActionReducerMap<CoreState> = {
  contexts: fromContexts.contextsReducer,
  path: fromPath.reducer,
  node: fromNode.reducer,
  menu: fromMenu.reducer,
  taxonomy: fromTaxonomy.reducer,
  user: fromUser.reducer,
  ui: fromUi.uiStateReducer,
  webforms: fromWebform.reducer,
  cookie: appCore.reducer,
  eCommerce: fromECommerce.reducer
};
export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectRouteParameters = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    const flatParams = router && router.state ? router.state.flatParams : null;
    const params = router && router.state ? router.state.params : null;
    return flatParams && params ? Object.assign(JSON.parse(JSON.stringify(flatParams)), JSON.parse(JSON.stringify(params))) : null;
  }
);

export const selectRouteData = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    const data = router && router.state ? router.state.data : null;
    return data;
  }
);

export const selectRouteUniverse = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router?.state?.data?.universe || router?.state?.flatData?.universe;
  }
);

export const selectRouteHideSearchField = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    const data = router && router.state ? router.state.data : null;
    return data ? data.hideSearchField : false;
  }
);

export const getRouterData = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router && router.state ? router.state.data : null;
  }
);

export const getRouterIsHome = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router && router.state && router.state.data ? router.state.data.isHome : false;
  }
);

export const getRouterIsSearch = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router && router.state && router.state.data ? router.state.data.isSearch : false;
  }
);

export const getRouterIsContent = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router?.state?.flatData?.isContent;
  }
);

export const getRouterIsSession = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router?.state?.flatData?.isSession;
  }
);

export const getRouterIsBookmark = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router && router.state && router.state.data ? router.state.data.isBookmark : false;
  }
);

export const getRouterFlatData = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router?.state?.flatData;
  }
);

export const getShowSearchBar = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router?.state?.flatData?.showSearchBar;
  }
);

export const getRouterUrl = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return (router && router.state) ? router.state.url : null;
  }
);

export const getRouterQueryParams = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router && router.state ? router.state.queryParams : null;
  }
);

export const getQuizAccessId = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router && router.state && router.state.flatParams ? router.state.flatParams.quizAccessId : null;
  }
);

export const getRouterQuizTakeId = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router && router.state && router.state.flatParams ? router.state.flatParams.quizTakeId : null;
  }
);

export const getRouterDataShowContentSearch = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router && router.state && router.state.data ? !router.state.data.hideSearch : true;
  }
);

export const getRouterNavigationId = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router.navigationId;
  }
);

export const getRouterQueryParamsBc = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router.state.queryParams?.bc;
  }
);

export const getRouterQueryParamsModule = createSelector(
  selectRouterState,
  (router: RouterReducerState<RouterStateUrl>) => {
    return router.state.queryParams?.module;
  }
);

export const getCoreState = createFeatureSelector<CoreState>('core');

export const getPathState = createSelector(
  getCoreState,
  (state: CoreState) => state.path
);

export const getPathInfos = createSelector(
  getPathState,
  fromPath.getPathInfos
);

export const getPathInfosEntities = createSelector(
  getPathState,
  (state: PathState) => state.entities
);

export const getPathInfosByPath = (path: string) => createSelector(
  getPathState,
  (state: PathState) => {
    return state.entities[path];
  }
);


export const getPathInfosLoading = createSelector(
  getPathState,
  fromPath.getPathInfosLoading
);


export const getPathInfosLoaded = createSelector(
  getPathState,
  fromPath.getPathInfosLoaded
);

export const getPathInfosFail = createSelector(
  getPathState,
  fromPath.getPathInfosFail
);

export const getPathInfosMetaTags = createSelector(
  getPathState,
  fromPath.getPathMetaTags
);

export const getCurrentOverlayPathInfos = createSelector(
  getPathInfosEntities,
  selectRouteParameters,
  (pathInfosEntities, routeParams) => routeParams ? pathInfosEntities[routeParams.oId] : null
);

export const getNodeState = createSelector(
  getCoreState,
  (state: CoreState) => state.node
);

export const getNodeEntities = createSelector(
  getNodeState,
  (state: NodeState) => state ? state.entities : null
);

export const getNodeIdsByTermId = (termId: string) => createSelector(
  getNodeState,
  (state: NodeState) => {
    return state && state.termsMap ? state.termsMap[termId] : null;
  }
);

export const getNodeRelated = (nid: string) => createSelector(
  getNodeState,
  (state: NodeState) => {
    return state && state.nodeRelatedMap ? state.nodeRelatedMap[nid] : null;
  }
);

export const getNodesByNodeIds = (nodeIds: string[]) => createSelector(
  getNodeEntities,
  (nodeEntities) => {
    let nodes: DrupalNode[];
    if (nodeIds) {
      nodes = nodeIds.map(id => nodeEntities[id]);
    } else {
      nodes = [];
    }
    return nodes;
  }
);

export const getNodeById = (id: string) => createSelector(
  getNodeEntities,
  entities => {
    return entities ? entities[id] : null;
  }
);


export const getNodeLoading = createSelector(
  getNodeState,
  fromNode.getNodeLoading
);


export const getNodeLoaded = createSelector(
  getNodeState,
  fromNode.getNodeLoaded
);

export const getNodeFail = createSelector(
  getNodeState,
  fromNode.getNodeFail
);

export const isNode403Error = createSelector(
  getNodeFail,
  (error: HttpErrorResponse) => error && error.status === 403
);

export const getMenuState = createSelector(
  getCoreState,
  (state: CoreState) => state.menu
);

export const getMenuByName = (menuName: string) => createSelector(
  getMenuState,
  menuState => menuState?.entities && menuState.entities[menuName]
);

export const getTrainingToolsMenuItem = createSelector(
  getMenuByName('main-menu'),
  menu => menu?.items?.filter(menuItem => {
    return menuItem?.role === 'formation'
  }).shift()?.children?.filter(item => item?.title?.indexOf('Outils') > -1).shift()
);

export const getContextsState = createSelector(
  getCoreState,
  (state: CoreState) => state?.contexts
);

export const getCurrentNode = createSelector(
  selectDrupalNodeEntities,
  selectRouteParameters,
  selectRouteData,
  getContextsState,
  getRouterUrl,
  (nodeEntities, routeParams, routeData, contexts, routerUrl) => {
    let nid: string;
    let contextNode: any;
    if (routeParams && routeParams.nid) {
      nid = routeParams.nid;
    } else if (routeData && routeData.nid) {
      nid = routeData.nid;
    } else if (routerUrl === '/' + homePath) {
      contextNode = contexts.contexts['home'];
      if (contextNode) {
        nid = contextNode.nid;
      }
    } else if (routeData && routeData.context === 'trainee-area') {
      contextNode = contexts.contexts['trainee-area'];
      if (contextNode) {
        nid = contextNode.nid;
      }
    } else if (routeData && routeData.context === 'contact') {
      contextNode = contexts.contexts['contact'];
      if (contextNode) {
        nid = contextNode.nid;
      }
    } else if (routeData && routeData.isSearch) {
      const isEncyclopedia = routerUrl.indexOf(encyclopediaPath) > -1;
      const isTraining = routerUrl.indexOf(trainingPath) > -1;
      if (isEncyclopedia) {
        contextNode = contexts.contexts['encyclopedia'];
        if (contextNode) {
          nid = contextNode.id;
        }
      } else if (isTraining) {
        contextNode = contexts.contexts['training'];
        if (contextNode) {
          nid = contextNode.id;
        }
      }
    }
    return (nodeEntities && nid && nodeEntities[nid]) ? nodeEntities[nid] : contextNode ? contextNode : null;
  }
);

export const isCurrentNodeRestricted = createSelector(
  getCurrentNode,
  (node: DrupalNode|null) => node?.restricted
);

export const getCurrentOverlayNode = createSelector(
  getNodeEntities,
  selectRouteParameters,
  (nodeEntities, routeParams) => routeParams ? nodeEntities[routeParams.oId] : null
);

export const getUiState = createSelector(
  getCoreState,
  (state: CoreState) => state?.ui
);

export const isHomeActive = createSelector(
  getUiState,
  (state: UiState) => state.homeActive
);

export const getBreakpoint = createSelector(
  getUiState,
  (state: UiState) => state.breakPoint
);

export const showDomainWindow = createSelector(
  getUiState,
  (state: UiState) => state.showDomainWindow
);

export const showLoginWindow = createSelector(
  getUiState,
  (state: UiState) => state.showLoginWindow
);

export const getBreadCrumbs = createSelector(
  getUiState,
  (state: UiState) => state.breadcrumbs
);


export const getMainMenuOpen = createSelector(
  getUiState,
  (state: UiState) => state.mainMenuOpen
);

export const getBookmarksMenuOpen = createSelector(
  getUiState,
  (state: UiState) => state.bookmarksMenuOpen
);

export const getNavigatedModule = createSelector(
  getUiState,
  (state: UiState) => state.navigatedModule
);

export const getStaticContentList = createSelector(
  getUiState,
  (state: UiState) => state.staticContentList
);

export const getModuleThemes = createSelector(
  getUiState,
  (state: UiState) => state.navigatedModule && state.navigatedModule.internal_themes
);

export const getNavigatedTheme = createSelector(
  getUiState,
  (state: UiState) => state.navigatedTheme
);

export const getNavigatedThemeNodes = createSelector(
  getUiState,
  (state: UiState) => state.navigatedTheme && state.navigatedTheme.internalRelated
);

export const getBackBreadCrumb = createSelector(
  getUiState,
  (state: UiState) => {
    const breadcrumbs = state.breadcrumbs;
    return (breadcrumbs && breadcrumbs.length > 1) ? state.breadcrumbs[state.breadcrumbs.length - 2] : null;
  }
);

export const hasBackBreadCrumbWithNid = (nid: string) => createSelector(
  getUiState,
  (state: UiState) => {
    const breadcrumbs = state.breadcrumbs;
    return (breadcrumbs && breadcrumbs.length > 0) ? state.breadcrumbs.filter(bc => bc.node.id === nid).length > 0 : false;
  }
);

export const getUniverse = createSelector(
  getUiState,
  selectRouterState,
  (uiState: UiState, router: RouterReducerState<RouterStateUrl>) => {
    const universe: string = router?.state?.flatData?.universe;
    return universe;
  }
);

export const getUserState = createSelector(
  getCoreState,
  (state: CoreState) => {
    return state ? state.user : null;
  }
);

export const getUserInfos = createSelector(
  getUserState,
  (state: UserState) => {
    return state ? state.userInfos : null;
  }
);

export const getUserFirstName = createSelector(
  getUserInfos,
  (user: User) => {
    return user ? user.firstName : null;
  }
);

export const getUserMail = createSelector(
  getUserInfos,
  (user: User) => {
    return user ? user.email : null;
  }
);


export const getUserAuthenticationRedirection = createSelector(
  getUserState,
  (state: UserState) => {
    return state ? state.authenticationRedirection : null;
  }
);

export const getUserIsSubscriber = createSelector(
  getUserState,
  (state: UserState) => {
    const hasValidSubscription: boolean = state?.userInfos?.subscription?.valid ?? false;
    let manualSubscriber: boolean = false;
    if(state?.userInfos?.roles?.indexOf(User.roleAbonneManuel) > -1){
      manualSubscriber = true;
    }
    let ffaSubscriber: boolean = false;
    if(state?.userInfos?.roles?.indexOf(User.roleAbonneLicence) > -1){
      ffaSubscriber = true;
    }
    return hasValidSubscription || manualSubscriber || ffaSubscriber;
  }
);

export const getUserIsTrainee = createSelector(
  getUserState,
  fromUser.isStagiaire
);

export const getUserIsContributor = createSelector(
  getUserState,
  (state: UserState) => {
    return state ? state.userInfos && state.userInfos.roles && state.userInfos.roles.indexOf(User.roleContributeur) > -1 : null;
  }
);

export const getUserIsClientAdmin = createSelector(
  getUserState,
  (state: UserState) => {
    return state ? state.userInfos && state.userInfos.roles.indexOf(User.roleClientAdmin) > -1 : null;
  }
);

export const getUserIsAdmin = createSelector(
  getUserState,
  (state: UserState) => {
    return state ? state.userInfos && state.userInfos.roles.indexOf(User.roleAdmin) > -1 : null;
  }
);

export const showPromotionalContents = createSelector(
  getUserIsSubscriber,
  getUserIsContributor,
  getUserIsClientAdmin,
  getUserIsAdmin,
  (isSubscriber, isContributor, isClientAdmin, isAdmin) => {
    return !(isSubscriber || isContributor || isClientAdmin);
  }
);

export const getUserOrders = createSelector(
  getUserState,
  (state: UserState) => {
    return state ? state.orders : null;
  }
);

export const getUserMeta = createSelector(
  getUserState,
  (state: UserState) => state.userMeta
);


export const getUserIsFormation = createSelector(
  getUserState,
  fromUser.getUserIsFormation
);

export const getUserAccountCreationSuccess = createSelector(
  getUserState,
  fromUser.getUserAccountCreationSuccess
);

export const getBookmarkIds = createSelector(
  getUserState,
  fromUser.getBookmarkIds
);

export const getBookmarksFull = createSelector(
  getUserState,
  fromUser.getBookmarksFull
);


export const getBookmarksCount = createSelector(
  getUserState,
  fromUser.getBookmarksCount
);

export const selectUserIsFormation = createSelector(
  getUserState,
  fromUser.getUserIsFormation
);

export const getTraineeInfos = createSelector(
  getUserState,
  fromUser.getTraineeInfos
);


export const isUserInfosLoaded = createSelector(
  getUserState,
  fromUser.getUserInfosLoaded
);

export const getUserLoading = createSelector(
  getUserState,
  fromUser.getUserLoading
);

export const changePasswordSuccess = createSelector(
  getUserState,
  fromUser.changePasswordSuccess
);

export const getPreferredAgeCriteria = createSelector(
  getUserState,
  fromUser.getPreferredAgeCriteria
);

export const getTaxonomyState = createSelector(
  getCoreState,
  (state: CoreState) => state.taxonomy
);

export const getTaxonomyDomains = createSelector(
  getTaxonomyState,
  (state: TaxonomyState) => state && state.entities && state.entities['domains']
);

export const getTaxonomyAgeLevels = createSelector(
  getTaxonomyState,
  (state: TaxonomyState) => state && state.entities && state.entities['age_level']
);


export const getVocabularyById = (id: string) => createSelector(
  getTaxonomyState,
  (taxonomyState) => taxonomyState.entities[id]
);

export const getRouterDataTheme = createSelector(
  selectUserIsFormation,
  getRouterData,
  (isUserFormation, data) => {
    return data && data.theme ? data.theme : 'default-theme';
  }
);

export const getContext = (contextId: Contexts) => createSelector(
  getContextsState,
  (state: ContextsState) => state?.contexts?.[contextId]
);

export const getContextPath = (contextId: Contexts) => createSelector(
  getContext(contextId),
  (context: any) => context ? context.path : null
);

export const getCookieState = createSelector(
  getCoreState,
  (state: CoreState) => state.cookie
);

export const getHeaderTitle = createSelector(
  getCookieState,
  (state: appCore.State) => state.headerTitle
);

export const getMarketingConsent = createSelector(
  getCookieState,
  (state: appCore.State) => state.cookieBotConsent ? state.cookieBotConsent.marketing : null
);

export const getPreferencesConsent = createSelector(
  getCookieState,
  (state: appCore.State) => state.cookieBotConsent ? state.cookieBotConsent.preferences : null
);

export const getStatisticsConsent = createSelector(
  getCookieState,
  (state: appCore.State) => state.cookieBotConsent ? state.cookieBotConsent.statistics : null
);

export const getCorePreferences = createSelector(
  getCookieState,
  (state: appCore.State) => state ? state.preferences : null
);

export const getCorePreferencesDomains = createSelector(
  getCorePreferences,
  (preferences: Preferences) => preferences?.domains ?? null
);

export const getUserPreferences = createSelector(
  getUserInfos,
  getCorePreferences,
  getVocabularyById('domains'),
  (user, corePreferences, domains) => {
    if (domains) {
      let domainIds: string[];
      if (user) {
        domainIds = user.domains;
      } else {
        if (corePreferences && corePreferences.domains) {
          domainIds = corePreferences.domains;
        }
      }
      let domainTerms = domains.items;
      let userTerms = [];

      if (domainIds) {
        for (let i: number = 0; i < domainIds.length; i++) {
          let domainId: string = domainIds[i];
          let term = domainTerms.filter(item => item.tid === domainId).shift();
          userTerms.push(term);
        }
      }

      return userTerms && userTerms.length > 0 ? userTerms : null;
    }
    return null;
  }
);

export const getDomains = createSelector(
  getUserInfos,
  getCorePreferencesDomains,
  (user: User, domains: string[]) => user ? user.domains : domains
);


export const getECommerceState = createSelector(
  getCoreState,
  (state: CoreState) => state.eCommerce
);

export const getCart = createSelector(
  getECommerceState,
  (state: fromECommerce.State) => state.cart
);

export const getSiFfaState = createFeatureSelector<fromSiFFA.State>('siFFA');

export const getLicenceValid = createSelector(
  getSiFfaState,
  fromSiFFA.getLicenceValid
);

export const getLicenceError = createSelector(
  getSiFfaState,
  fromSiFFA.getLicenceError
);

export const getLicenceInfos = createSelector(
  getSiFfaState,
  fromSiFFA.getLicenceInfos
);

export const getSecureLink = createSelector(
  getSiFfaState,
  fromSiFFA.getSecureLink
);

export const getQuizAccess = createSelector(
  getSiFfaState,
  fromSiFFA.getQuizAccess
);

export const getWebformsState = createSelector(
  getCoreState,
  (state: CoreState) => state.webforms
);

export const getFormLoading = (nid: string) => createSelector(
  getWebformsState,
  (state: fromWebform.WebformsState) => {
    return fromWebform.getWebFormLoading(state, nid);
  }
);

export const getFormSuccess = (nid: string) => createSelector(
  getWebformsState,
  (state: fromWebform.WebformsState) => {
    return fromWebform.getWebFormSuccess(state, nid);
  }
);
