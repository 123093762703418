import {Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnInit, Output} from '@angular/core';
import {select, Store} from "@ngrx/store";
import * as fromCore from "../../store/reducers";
import {getHeaderTitle} from "../../store/reducers";
import {Observable} from "rxjs";
import {domainsPath, encyclopediaPath, loginPath, myBookmarksPath, subscription} from "../../../route-constants";
import {UpdateBookmarksMenuOpen} from "../../store/actions";
import {FILIERES_ONLY} from "../../../providers/user.providers";

@Component({
  selector: 'ea-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  @Input()
  userName: string;

  @Input()
  userId: string;

  @Input()
  authenticated: boolean;

  @Input()
  subscriber: boolean;

  @Input()
  loading: boolean;

  @Input()
  universeTitle: string;

  @Input()
  bookmarksCount: number;

  @Input()
  @HostBinding('class.home-active')
  isHome: boolean;

  @Input()
  @HostBinding('class.home-scrolling')
  isHomeScrolling: boolean;

  @Input()
  @HostBinding('class.search-active')
  isSearch: boolean;

  @Input()
  @HostBinding('class.content-active')
  isContent: boolean;

  @Output()
  logoutEmitter: EventEmitter<any> = new EventEmitter();

  @Output()
  openCloseMenu: EventEmitter<any> = new EventEmitter();

  @Output()
  openWindow: EventEmitter<string> = new EventEmitter();

  domainsPath: string = domainsPath;

  loginPath: string = loginPath;

  subscriptionPath: string = subscription;

  myBookmarksPath: string = myBookmarksPath;

  encyclopediaPath: string = encyclopediaPath;

  logoUrls = {
    'logo-a': '../../../../assets/logos/logo-a.svg',
    'logo-formation-blanc': '../../../../assets/logos/logo-formation-blanc.svg',
  };

  headerTitle$: Observable<string> = this.store.pipe(select(getHeaderTitle));

  constructor(
    private store: Store<fromCore.State>,
    public elementRef: ElementRef,
    @Inject(FILIERES_ONLY) public readonly userFilieres$: Observable<any[]>
  ) {
  }

  ngOnInit() {
  }

  openBookmarks() {
    this.store.dispatch(new UpdateBookmarksMenuOpen(true));
  }

  openPreferences() {
    this.openWindow.emit('domains');
  }

}
