import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from './store/reducers';
import {AnalyticsService} from './analytics.service';

import {WindowRef} from "./window-ref";
import {SetCookieBotConsent} from "./core/store/actions/app-core.actions";
import {getMarketingConsent, getPreferencesConsent, getStatisticsConsent} from "./core/store/reducers";
import {CookieBotConsent} from "./core/store/reducers/app-core.reducers";

@Injectable({
  providedIn: 'root'
})
export class CookiebotService {

  marketingConsent$ = this.store.pipe(select(getMarketingConsent));

  statisticsConsent$ = this.store.pipe(select(getStatisticsConsent));

  preferencesConsent$ = this.store.pipe(select(getPreferencesConsent));

  cookieConsent$: Observable<CookieBotConsent>;

  constructor(
    private store: Store<fromRoot.State>,
    private windowRef: WindowRef,
    private analyticsService: AnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  init() {

    this.initializeCookieBot();

  }

  initializeCookieBot() {

    if (isPlatformBrowser(this.platformId) && this.windowRef.nativeWindow.Cookiebot && this.windowRef.nativeWindow.Cookiebot.consent) {

      this.windowRef.nativeWindow.addEventListener('CookiebotOnAccept', () => {
        this.sendSettingsToStore();

      });
      this.windowRef.nativeWindow.addEventListener('CookiebotOnDecline', () => {
        this.sendSettingsToStore();
      });

      this.marketingConsent$.subscribe(hasMarketingConsent => {
        //console.log('hasMarketingConsent', hasMarketingConsent);
      });

      this.preferencesConsent$.subscribe(hasPreferencesConsent => {
        //console.log('hasPreferencesConsent', hasPreferencesConsent);
      });

      this.statisticsConsent$.subscribe(hasStatisticsConsent => {
        //console.log('hasStatisticsConsent', hasStatisticsConsent);
        if(hasStatisticsConsent){
          this.analyticsService.enable();
        }else{
          //console.log('TODO : disable analytics');
        }
      });

    } else {
      setTimeout(() => {
        this.initializeCookieBot()
      }, 100);
    }

  }

  sendSettingsToStore(){
    this.store.dispatch(new SetCookieBotConsent({...this.windowRef.nativeWindow.Cookiebot.consent} as CookieBotConsent));
  }

  renew() {
    if (isPlatformBrowser && this.windowRef.nativeWindow.Cookiebot && this.windowRef.nativeWindow.Cookiebot.renew) {
      this.windowRef.nativeWindow.Cookiebot.renew();
    }
  }

}
