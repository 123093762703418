import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import {DrupalNode} from "../models/node";

export const loadDrupalNodes = createAction(
  '[DrupalNode/API] Load DrupalNodes',
  props<{ drupalNodes: DrupalNode[] }>()
);

export const addDrupalNode = createAction(
  '[DrupalNode/API] Add DrupalNode',
  props<{ drupalNode: DrupalNode }>()
);

export const upsertDrupalNode = createAction(
  '[DrupalNode/API] Upsert DrupalNode',
  props<{ drupalNode: DrupalNode }>()
);

export const addDrupalNodes = createAction(
  '[DrupalNode/API] Add DrupalNodes',
  props<{ drupalNodes: DrupalNode[] }>()
);

export const upsertDrupalNodes = createAction(
  '[DrupalNode/API] Upsert DrupalNodes',
  props<{ drupalNodes: DrupalNode[] }>()
);

export const updateDrupalNode = createAction(
  '[DrupalNode/API] Update DrupalNode',
  props<{ drupalNode: Update<DrupalNode> }>()
);

export const updateDrupalNodes = createAction(
  '[DrupalNode/API] Update DrupalNodes',
  props<{ drupalNodes: Update<DrupalNode>[] }>()
);

export const deleteDrupalNode = createAction(
  '[DrupalNode/API] Delete DrupalNode',
  props<{ id: string }>()
);

export const deleteDrupalNodes = createAction(
  '[DrupalNode/API] Delete DrupalNodes',
  props<{ ids: string[] }>()
);

export const clearDrupalNodes = createAction(
  '[DrupalNode/API] Clear DrupalNodes'
);
