import {InjectionToken, Provider} from "@angular/core";
import {DateTime} from "luxon";

export const CURRENT_YEAR = new InjectionToken<string>('CURRENT_YEAR');

export const DATE_PROVIDERS: Provider[] = [
  {
    provide: CURRENT_YEAR,
    useValue: DateTime.now().year
  }
];
