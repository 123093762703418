import {Pipe, PipeTransform} from '@angular/core';
import {Diplome, DiplomeModuleRef} from "../../core/models/diplome";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode} from "../../core/models/node";
import {Referential} from "../../core/models/trainee-infos";
import {Responsible} from "../../core/models/face-a-face";

@Pipe({
  name: 'resourceToDiplome'
})
export class ResourceToDiplomePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe
  ) {
  }

  transform(value: any): Diplome {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);

    const siFfaCode: string = value.formation_siffa_code;
    const responsable: Responsible = ResourceToDiplomePipe.parseResponsible(value.formation_responsable);
    const modules: DiplomeModuleRef[] = this.parseModuleRefs(value.formation_diplome_modules);
    const referentials: Referential[] = ResourceToDiplomePipe.parseReferentials(value.referential);
    return {
      siFfaCode,
      responsable,
      referentials,
      modules,
      ...drupalNode
    };
  }

  public static parseReferentials(value: any[]):Referential[]{
    if (Array.isArray(value) && value.length > 0) {
      return value.map(item => {
        return {
          id: item.id,
          referentialType: item.referential_type,
          siFfaCode: item.formation_siffa_code,
          description: item.description
        }
      })
    }
    return null;
  }

  public static parseResponsible(value: any): Responsible {
    if (value) {
      return {
        firstName: value.first_name,
        lastName: value.last_name,
        mail: value.mail,
        phone: value.phone
      }
    }
    return null;
  }

  parseModuleRefs(value: any[]): DiplomeModuleRef[] {
    if (Array.isArray(value) && value.length > 0) {
      return value.map(item => {
        return {
          condition: item.formation_condition,
          module: item.formation_module
        }

      })
    }
    return null;
  }


}
