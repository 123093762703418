import {Injectable} from '@angular/core';
import {Action, select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import * as fromRoot from '../../../store/reducers';
import {OAuthService, TokenResponse} from "angular-oauth2-oidc";
import {WindowRef} from "../../../window-ref";
import {SiFFAService} from "../../services/si-ffa.service";
import {
  AuthenticateSiFfaLink,
  AuthenticateSiFfaLinkFail,
  AuthenticateSiFfaLinkSuccess,
  CheckLicence,
  CheckLicenceFail,
  CheckLicenceSuccess,
  CheckLicenceWrong,
  LoadContentSecureLinkInfos,
  LoadContentSecureLinkInfosFail,
  LoadContentSecureLinkInfosSuccess,
  LoadQuizAccess,
  LoadQuizAccessFail,
  LoadQuizAccessSuccess,
  SiFfaActionTypes
} from "../actions/si-ffa.actions";
import {HttpErrorResponse} from "@angular/common/http";
import {getRouterQueryParams, getSecureLink, getUserInfos} from "../reducers";
import {User} from "../../models/user";
import {of} from "rxjs";
import {LicenceResponse} from "../../models/licence-response";
import {SecureLinkService} from "../../services/secure-link-service";
import {AUTHENTICATE_SUCCESS, AuthenticateActionSuccess, InitAuthenticationState} from "@madeinlune/components";
import {QuizAccess, QuizAccessFull} from "../../models/quiz-access";
import {Go} from "../../../store/actions";
import {ErrorWindowComponent} from "../../../components/error-window/error-window.component";
import {MatDialog} from "@angular/material/dialog";
import {LoadQuizTake, SetQuizId} from "../../../quiz/store";
import {Load, LoadUserInfos} from "../actions";
import {Params} from "@angular/router";
import {genericOutlet, quizSiFfa} from "../../../route-constants";

@Injectable()
export class SiFfaEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  
  checkLicence$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SiFfaActionTypes.CheckLicence),
    withLatestFrom(this.store.pipe(select(getUserInfos), filter(userInfos => !!userInfos), take(1))),
    mergeMap(([action, user]: [CheckLicence, User]) => {
      //console.log('mergeMap user', user);
      return this.siFFAService.checkLicence(user, action.licenceInfos).pipe(
        switchMap((licenceResponse: LicenceResponse) => {
          //console.log('checkLicence$ licenceResponse', licenceResponse);
          if (licenceResponse.valid) {
            return [new CheckLicenceSuccess(), new LoadUserInfos()];
          }
          return [new CheckLicenceWrong()];
        }),
        catchError((error: HttpErrorResponse) => {
          //console.log('checkLicence$ error', error);
          return of(new CheckLicenceFail(error));
        })
      );
    })
  ));

  
  authenticateSiFfaLink$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SiFfaActionTypes.AuthenticateSiFfaLink),
    mergeMap((action: AuthenticateSiFfaLink) => {
      return this.secureLinkService.fetchToken(action.payload, action.mail, action.password, action.mode).pipe(
        map((response: TokenResponse) => {
          return new AuthenticateSiFfaLinkSuccess(response);
        }),
        catchError((error: HttpErrorResponse) => {
          return of(new AuthenticateSiFfaLinkFail(error));
        })
      );
    })
  ));

  
  authenticateSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AUTHENTICATE_SUCCESS),
    withLatestFrom(this.store.pipe(select(getRouterQueryParams))),
    switchMap(([action, params]: [AuthenticateActionSuccess, Params]) => {
      if(params.pass){
        return [new LoadContentSecureLinkInfos(params.pass)];
      }else{
        return [];
      }
    })
  ));

  
  authenticateSiFfaLinkSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SiFfaActionTypes.AuthenticateSiFfaLinkSuccess),
    mergeMap((action: AuthenticateSiFfaLinkSuccess) => {
      let tokenResponse: TokenResponse = action.tokenResponse;
      this.storeAccessTokenResponse(
        tokenResponse.access_token,
        tokenResponse.refresh_token,
        tokenResponse.expires_in,
        tokenResponse.scope
      );
      return this.oauthService.loadUserProfile();
    }),
    withLatestFrom(this.store.pipe(select(getSecureLink))),
    switchMap(([user, secureLink]) => {
      return [new LoadContentSecureLinkInfos(secureLink), new InitAuthenticationState()];
    })
  ));

  
  loadContentSecureLinkInfos$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SiFfaActionTypes.LoadContentSecureLinkInfos),
    mergeMap((action: LoadContentSecureLinkInfos) => {
      return this.secureLinkService.loadContentSecureLinkInfos(action.payload).pipe(
        map((quizAccess: QuizAccess) => {
          return new LoadContentSecureLinkInfosSuccess(quizAccess);
        }),
        catchError((error: HttpErrorResponse) => {
          return of(new LoadContentSecureLinkInfosFail(error));
        })
      );
    })
  ));

  
  loadContentSecureLinkInfosSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SiFfaActionTypes.LoadContentSecureLinkInfosSuccess),
    switchMap((action: LoadContentSecureLinkInfosSuccess) => {
      const quizAccess: QuizAccess = action.quizAccess;
      const quizAccessId: string = quizAccess.quiz_access.id;
      return [
        new Go({path: [{outlets: {primary: quizSiFfa + '/' + quizAccessId + '/session', [genericOutlet]: null}}]})
      ];
    })
  ));

  
  authenticateSiFfaLinkFail$ = createEffect(() => this.actions$.pipe(
    ofType(SiFfaActionTypes.AuthenticateSiFfaLinkFail),
    withLatestFrom(this.store.pipe(select(getSecureLink))),
    tap(([action, secureLink]: [AuthenticateSiFfaLinkFail, string]) => {
      if (action.error.status == 412) {
        // return new Go({path: [{outlets: {[genericOutlet]: subscription}}], query: {pass: secureLink}});
      }
      let message: string;
      if(action?.error?.error?.error_description == 'Link is not yet availlable'){
        message = 'Désolé, ce lien n’est pas encore actif';
      }else if(action?.error?.error?.error_description == 'Link is outdated') {
        message = 'Désolé, ce lien n’est plus valable';
      }else if(action?.error?.error?.error_description == 'Invalid username and password combination'){
        message = 'Votre identifiant et votre mot de passe ne semblent pas correspondre.<br>Merci de vérifier vos informations.';
      }else if(action?.error?.error?.error_description === 'Actor ID does not match'){
        message = 'Une erreur nous empêche de vous donner accès à ce lien.<br>Merci de contacter le support si le problème persiste.';
      }else{
        message = 'Une erreur nous empêche de vous donner accès à ce lien.<br>Merci de contacter le support si le problème persiste.';
      }

      message = message + '<br><small>' + action?.error?.error?.error_description + '</small>';

      this.dialog.open(ErrorWindowComponent, {
        width: '500px',
        panelClass: 'user-theme',
        data: {
          title: 'Lien non reconnu',
          message: message,
          actions: ['OK']
        }
      });
    })
  ), {dispatch: false});

  
  loadQuizAccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SiFfaActionTypes.LoadQuizAccess),
    mergeMap((action: LoadQuizAccess) => {
      return this.secureLinkService.loadQuizAccess(action.quizAccessId).pipe(
        switchMap((quizAccess: QuizAccessFull) => {
          //console.log('quizAccess', quizAccess);
          const quizId: string = quizAccess.quiz_access_quiz.id;
          return [
            new LoadQuizAccessSuccess(quizAccess),
            new SetQuizId(quizId),
            new Load(quizId),
            new LoadQuizTake(quizAccess.quiz_access_results.id)
          ];
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('error', error);
          return of(new LoadQuizAccessFail(error));
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private siFFAService: SiFFAService,
    private oauthService: OAuthService,
    private store: Store<fromRoot.State>,
    private windowRef: WindowRef,
    private secureLinkService: SecureLinkService,
    private dialog: MatDialog
  ) {
  }

  protected storeAccessTokenResponse(
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
    grantedScopes: String
  ): void {
    //console.log('storeAccessTokenResponse()');
    localStorage.setItem('access_token', accessToken);
    if (grantedScopes) {
      localStorage.setItem('granted_scopes', JSON.stringify(grantedScopes.split('+')));
    }
    localStorage.setItem('access_token_stored_at', '' + Date.now());
    if (expiresIn) {
      const expiresInMilliSeconds = expiresIn * 1000;
      const now = new Date();
      const expiresAt = now.getTime() + expiresInMilliSeconds;
      localStorage.setItem('expires_at', '' + expiresAt);
    }

    if (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken);
    }
  }

}
