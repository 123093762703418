import {Component, NgModule, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {select, State, Store} from "@ngrx/store";
import {
  getBreakpoint,
  getUserInfos,
  getUserIsClientAdmin,
  getUserIsContributor,
  getUserIsFormation,
  getUserOrders
} from "../../core/store/reducers";
import {filter, map} from "rxjs/operators";
import {zip} from "rxjs";
import {LoadOrders} from "../../core/store/actions";
import {TemplatesService} from "../../templates.service";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {UserInfosFormModule} from "./user-infos-form/user-infos-form.component";
import {UserLicenceFormComponent} from "./user-licence-form/user-licence-form.component";
import {UserChangePasswordComponent} from "./user-change-password/user-change-password.component";
import {UserRolesComponent} from "./user-roles/user-roles.component";
import {UserOrdersComponent} from "./user-orders/user-orders.component";
import {MatCardModule} from "@angular/material/card";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButtonModule} from "@angular/material/button";
import {InterestFormModule} from "../preference-window/contents-preferences/forms/interest-form/interest-form.component";
import {DialogModule} from "../dialog/dialog.component";

export class UserMenuItem {

  public label?: string;
  public name?: string;
  public template?: TemplateRef<any>;

}

@Component({
  selector: 'ea-user-window',
  templateUrl: './user-window.component.html',
  styleUrls: ['./user-window.component.scss']
})
export class UserWindowComponent implements OnInit {

  @ViewChild('personalInfos', { static: true })
  personalInfosTemplate: TemplateRef<any>;

  @ViewChild('licence', { static: true })
  licenceTemplate: TemplateRef<any>;

  @ViewChild('changePassword', { static: true })
  changePasswordTemplate: TemplateRef<any>;

  @ViewChild('roles', { static: true })
  rolesTemplate: TemplateRef<any>;

  @ViewChild('orders', { static: true })
  ordersTemplate: TemplateRef<any>;

  @ViewChild('interests', { static: true })
  interestsTemplate: TemplateRef<any>;

  @ViewChild('privateData', { static: true })
  privateDataTemplate: TemplateRef<any>;

  currentUserMenuItem: UserMenuItem;

  breakPoint$ = this.store.pipe(select(getBreakpoint));


  userInfos$ = this.store.pipe(
    select(getUserInfos),
    filter(userInfos => !!userInfos),
    map(userInfos => JSON.parse(JSON.stringify(userInfos)))
  );

  isContributeur$ = this.store.pipe(
    select(getUserIsContributor)
  );

  isFormateur$ = this.store.pipe(
    select(getUserIsFormation)
  );

  isClientAdmin$ = this.store.pipe(
    select(getUserIsClientAdmin)
  );

  orders$ = this.store.pipe(
    select(getUserOrders)
  );

  hasRoles$ = zip(
    this.isContributeur$,
    this.isFormateur$,
    this.isClientAdmin$
  ).pipe(
    map((result: any[]) => result.indexOf(true) > -1)
  );

  menu: UserMenuItem[];

  constructor(
    private store: Store<State<any>>,
    public templatesService: TemplatesService
  ) {
  }

  ngOnInit() {
    //TODO unsubscribe
    this.userInfos$.subscribe(result => {
      //console.log('result', result)
    });

    this.store.dispatch(new LoadOrders());

    this.menu = [
      {
        label: 'Mes Informations personnelles',
        template: this.personalInfosTemplate
      }/*,
      {
        label:'Mon numéro de licence',
        template: this.licenceTemplate
      }*/,
      {
        label: 'Changer mon mot de passe',
        template: this.changePasswordTemplate
      },
      {
        label: 'Mes Rôles',
        template: this.rolesTemplate
      },
      {
        label: 'Mes Commandes',
        template: this.ordersTemplate
      },
      {
        label: 'Mes données privées',
        template: this.privateDataTemplate
      },
      {
        label: 'Mes centres d’intérêt',
        template: this.interestsTemplate
      }/*,
      {
        label:'Préférences'
      }*/
    ];

    this.currentUserMenuItem = this.menu[0];

  }

}

@NgModule({
  declarations: [
    UserWindowComponent,
    UserLicenceFormComponent,
    UserChangePasswordComponent,
    UserRolesComponent,
    UserOrdersComponent
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatButtonModule,
    InterestFormModule,
    DialogModule,
    UserInfosFormModule
  ]
})
export class UserWindowModule { }
