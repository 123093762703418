import {Pipe, PipeTransform} from '@angular/core';
import {DrupalNode} from "../../core/models/node";
import {Juge} from "../../core/models/juge";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {HtmlCleanupPipe} from "../html-cleanup.pipe";
import {JugeOrganisation} from "../../core/models/juge-organisation";
import {JugeRule, JugeRules} from "../../core/models/juge-rule";
import {Image} from "../../core/models/image";
import {Video} from "../../core/models/video";
import {JugeValidation} from "../../core/models/juge-validation";

@Pipe({
  name: 'resourceToJuge'
})
export class ResourceToJugePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToMediaPipe: ResourceToMediaPipe,
    private htmlCleanupPipe: HtmlCleanupPipe
  ) {
  }

  transform(value: any): Juge {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);

    let organisation: JugeOrganisation;

    if (value.organization) {
      organisation = {};
      if (!!value.organization.equipment) {
        organisation.equipment = this.parseJugeRule(value.organization.equipment);
      }
      if (!!value.organization.juryTasks) {
        organisation.juryTasks = this.parseJugeRule(value.organization.juryTasks);
      }
      if (!!value.organization.security) {
        organisation.security = this.parseJugeRule(value.organization.security);
      }
      if (!!value.organization.youngMission) {
        organisation.youngMission = this.parseJugeRule(value.organization.youngMission);
      }
      if (!!value.organization.misc) {
        organisation.misc = this.parseJugeRule(value.organization.misc);
      }
    }

    let rules: JugeRules;
    if (value.rules) {
      rules = {};
      if (!!value.rules.ffa) {
        rules.ffa = this.parseJugeRule(value.rules.ffa);
      }
      if (!!value.rules.simplified) {
        rules.simplified = this.parseJugeRule(value.rules.simplified);
      }
      if (!!value.rules.other) {
        rules.other = this.parseJugeRule(value.rules.other);
      }
      if (!!value.rules.unss) {
        rules.unss = this.parseJugeRule(value.rules.unss);
      }
    }

    let validation: JugeValidation;
    if (value.validation) {
      validation = {};
      if (!!value.validation.accepted) {
        validation.accepted = this.parseJugeRule(value.validation.accepted);
      }
      if (!!value.validation.performance) {
        validation.performance = this.parseJugeRule(value.validation.performance);
      }
      if (!!value.validation.refused) {
        validation.refused = this.parseJugeRule(value.validation.refused);
      }
    }

    const juge: Juge = {
      ...drupalNode
    }

    if (organisation && Object.keys(organisation).length > 0) {
      juge.organization = organisation;
    }

    if (rules && Object.keys(rules).length > 0) {
      juge.rules = rules;
    }

    if (validation && Object.keys(validation).length > 0) {
      juge.validation = validation;
    }

    return juge;
  }

  parseJugeRule(source: any): JugeRule {

    if(source?.images?.length > 0 || source?.videos?.length > 0 || !!source.text){
      return {
        images: source.images.map(image => this.resourceToMediaPipe.transform(image)) as Image[],
        videos: source.videos.map(video => this.resourceToMediaPipe.transform(video)) as Video[],
        text: source.text
      }
    }
    return null;
  }

}
