/**
 * Created by benoitplatre on 25/06/2018.
 */
import {Action} from '@ngrx/store';

export enum NodeActionTypes {
  Load = '[Node] Load',
  LoadNodeByContext = '[Node] Load node by Context',
  LoadNodesByTerm = '[Node] Load nodes by Term',
  LoadRaw = '[Node] Load Raw',
  LoadRawCollection = '[Node] Load Raw Collection',
  NodeReload = '[Node] Reload',
  LoadSuccess = '[Node] Load Success',
  LoadCollectionSuccess = '[Node] Load Collection Success',
  LoadCollectionFail = '[Node] Load Collection Fail',
  LoadFail = '[Node] Load Fail',
  LoadRelated = '[Node] Load Related',
  LoadRelatedSuccess = '[Node] Load Related Success',
  LoadRelatedFail = '[Node] Load Related Fail',
  NodeCollectionLoadSuccess = '[Node] Node Collection Load Success',
  ClearNodesStorage = '[Node] Clear Nodes'
}

export class Load implements Action {

  readonly type = NodeActionTypes.Load;

  constructor(public payload: any = null) {
  }

}

export class LoadRaw implements Action {

  readonly type = NodeActionTypes.LoadRaw;

  constructor(public payload: any = null) {
  }

}

export class LoadRawCollection implements Action {

  readonly type = NodeActionTypes.LoadRawCollection;

  constructor(public payload: any = null) {
  }

}

export class LoadRawCollectionSuccess implements Action {

  readonly type = NodeActionTypes.LoadCollectionSuccess;

  constructor(public payload: any = null) {
  }

}

export class LoadRawCollectionFail implements Action {

  readonly type = NodeActionTypes.LoadCollectionFail;

  constructor(public payload: any = null) {
  }

}

export class NodeReload implements Action {

  readonly type = NodeActionTypes.NodeReload;

  constructor() {
  }

}


export class LoadSuccess implements Action {

  readonly type = NodeActionTypes.LoadSuccess;

  constructor(public payload: any) {
  }
}

export class LoadFail implements Action {

  readonly type = NodeActionTypes.LoadFail;

  constructor(public payload: any) {
  }

}

export class LoadRelated implements Action {

  readonly type = NodeActionTypes.LoadRelated;

  constructor(public nid: any) {
  }

}

export class LoadRelatedSuccess implements Action {

  readonly type = NodeActionTypes.LoadRelatedSuccess;

  constructor(public nid: any, public payload: any) {
  }

}

export class LoadRelatedFail implements Action {

  readonly type = NodeActionTypes.LoadRelatedFail;

  constructor(public nid: any, public payload: any) {
  }

}

export class LoadNodeByContext implements Action {

  readonly type = NodeActionTypes.LoadNodeByContext;

  constructor(public payload: any) {
  }

}

export class LoadNodesByTerm implements Action {

  readonly type = NodeActionTypes.LoadNodesByTerm;

  constructor(public termId: string, public vocabularyId: string) {
  }

}

export class NodeCollectionLoadSuccess implements Action {

  readonly type = NodeActionTypes.NodeCollectionLoadSuccess;

  constructor(public nodeCollection: any[], public termId: string) {
  }
}

export class ClearNodesStorage implements Action {

  readonly type = NodeActionTypes.ClearNodesStorage;

  constructor() {
  }
}


export type NodeActions =
  Load
  | LoadSuccess
  | LoadFail
  | NodeReload
  | LoadRaw
  | LoadNodeByContext
  | LoadNodesByTerm
  | NodeCollectionLoadSuccess
  | LoadRawCollection
  | LoadRawCollectionSuccess
  | LoadRawCollectionFail
  | LoadRelated
  | LoadRelatedSuccess
  | LoadRelatedFail
  | ClearNodesStorage;
