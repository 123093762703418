import {Component, Inject, Input, OnInit} from '@angular/core';
import {DrupalMenu} from "../../models/menu";
import {domainsPath, homePath, loginPath, subscription, traineePath, trainingPath} from "../../../route-constants";
import {MenuLoad, UpdateBookmarksMenuOpen} from "../../store/actions";
import {Store} from "@ngrx/store";
import {BOOKMARKS_TOTAL, FILIERES_ONLY, IS_SUBSCRIBER} from "../../../providers/user.providers";
import {Observable} from "rxjs";
import {AUTHENTICATED} from "../../../providers/authentication.providers";
import {Logout} from "@madeinlune/components";
import {Go} from "../../../store/actions";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'ea-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {

  @Input()
  mainMenu: DrupalMenu;

  @Input()
  secondaryMenu: DrupalMenu;

  @Input()
  legalMenu: DrupalMenu;

  @Input()
  isFormation: boolean;

  @Input()
  isTrainee: boolean;

  formationUniversePath: string = trainingPath;

  traineeRoute: string = traineePath;

  logoFormation = '../../../../assets/logos/logo-formation-bleu.svg';

  domainsPath: string = domainsPath;

  loginPath: string = loginPath;

  subscriptionPath: string = subscription;

  constructor(
    private store: Store<any>,
    @Inject(BOOKMARKS_TOTAL) public readonly bookmarksCount$: Observable<number>,
    @Inject(IS_SUBSCRIBER) public readonly userSubscriber$: Observable<boolean>,
    @Inject(AUTHENTICATED) public readonly authenticated$: Observable<boolean>,
    @Inject(FILIERES_ONLY) public readonly userFilieres$: Observable<any[]>
  ) {
  }

  ngOnInit() {
    this.authenticated$.pipe(
      distinctUntilChanged()
    ).subscribe(authenticated => {
      this.store.dispatch(new MenuLoad('main-menu'));
    })
  }

  logOut() {
    this.store.dispatch(new Logout());
    this.store.dispatch(new Go({path: [homePath]}));
  }

  openBookmarks() {

    this.store.dispatch(new UpdateBookmarksMenuOpen(true));

  }

}
