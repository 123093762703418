import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import * as uuid from 'uuid';

/**
 * Injectable HTTP interceptor for Sentry.
 */
@Injectable()
export class SentryErrorInterceptor implements HttpInterceptor {
  /**
   * Initializes the sentry connected HTTP interceptor.
   *
   */
  public constructor() {
  }

  /**
   * Intercepts HTTP requests and handles any HTTP errors.
   *
   * @param - The intercepted request.
   * @returns - An observable with the request
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if(request.responseType === 'blob'){
      return next.handle(request);
    }else{
      const requestId: string = uuid.v4();
      request = request.clone({
        setHeaders: {
          'X-Request-Id': requestId
        }
      });
      return next.handle(request).pipe(
        catchError(response => {
          // log to Sentry
          if (response instanceof HttpErrorResponse && this.filter(response)) {
            Sentry.withScope((scope) => {
              scope.setTag('request_id', requestId);
              Sentry.captureException(response);
              response['_sentry_captured_'] = true;
            });
          }
          // re-throw error
          return throwError(response);
        }),
      );
    }
  }

  /**
   * Checks if the response should be sent to Sentry.
   *
   * @param - The HttpErrorResponse
   * @returns - An boolean describing if the response should be handled
   */
  private filter(response: HttpErrorResponse): boolean {
    return true;
  }

  /**
   * Builds the message for capturing.
   *
   * @param - The HTTP request object
   * @param - The HTTP response object
   * @returns - The built message to capture
   */
  private getMessage(request: HttpRequest<any>, response: HttpErrorResponse): string {
    const defaultMessage = 'Http request failed. ({method}, {status}, {url}, {message})';
    const replace = (msg: string) => {
      const map = {
        method: request.method,
        url: request.url,
        status: response.status,
        message: response.message,
      };

      // replace all keys with their values
      Object.keys(map).forEach(key => {
        msg = msg.replace(new RegExp(`{${key}}`, 'g'), map[key]);
      });

      return msg;
    };

    // use default message
    return replace(defaultMessage);
  }
}
