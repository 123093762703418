import {Pipe, PipeTransform} from '@angular/core';
import {Term} from "../../core/models/practice-content";
import {ResourceToTermPipe} from "./resource-to-term.pipe";
import {AgeLevelPipe} from "../age-level.pipe";
import {AgeLevel} from "../../core/models/age-level";

@Pipe({
  name: 'resourceToTerms'
})
export class ResourceToTermsPipe implements PipeTransform {

  constructor(
    private resourceToTermPipe: ResourceToTermPipe,
    private ageLevelPipe: AgeLevelPipe
  ) {
  }

  transform(rawValue: any): any {

    let concepts: Term[] = this.resourceToTermPipe.transform(rawValue.concept) as Term[];
    let resources: Term[] = this.resourceToTermPipe.transform(rawValue.resource) as Term[];
    let disciplines: Term[] = this.resourceToTermPipe.transform(rawValue.discipline) as Term[];
    let domains: Term[] = this.resourceToTermPipe.transform(rawValue.domain) as Term[];
    let levels: Term[] = this.resourceToTermPipe.transform(rawValue.quizLevel) as Term[];
    let conception: Term[] = this.resourceToTermPipe.transform(rawValue.conception) as Term[];
    let intention: Term[] = this.resourceToTermPipe.transform(rawValue.intention) as Term[];
    let producedBy: Term[] = this.resourceToTermPipe.transform(rawValue.producedBy) as Term[];
    let period: Term[] = this.resourceToTermPipe.transform(rawValue.period) as Term[];
    let category: Term[] = this.resourceToTermPipe.transform(rawValue.category) as Term[];
    let fonction: Term[] = this.resourceToTermPipe.transform(rawValue.function) as Term[];
    let difficulty: Term[] = this.resourceToTermPipe.transform(rawValue.difficulty) as Term[];
    let ageLevel: AgeLevel = this.ageLevelPipe.transform(rawValue.age_level);

    return {
      concepts,
      resources,
      disciplines,
      domains,
      levels,
      conception,
      intention,
      producedBy,
      period,
      category,
      function: fonction,
      ageLevel,
      difficulty
    };
  }

}
