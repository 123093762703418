import {Action} from '@ngrx/store';
import {SearchUserRequest} from "../../search/models/search-user-request";
import {SearchResult} from "../../search/models/search-result";


export interface PromotedContentsAction extends Action{

  searchUserRequest?: SearchUserRequest;
  result?: SearchResult;
  contentTypeName?: string;

}

export const LOAD_PROMOTED_CONTENTS = '[Promoted Contents Load]';
export const LOAD_PROMOTED_CONTENTS_FAIL = LOAD_PROMOTED_CONTENTS + ' > Fail';
export const LOAD_PROMOTED_CONTENTS_SUCCESS = LOAD_PROMOTED_CONTENTS + ' > Success';

export const CLEAR_PROMOTED_CONTENTS = '[Promoted Contents] Clear';



export class PromotedContentsLoadAction implements PromotedContentsAction {

  readonly type = LOAD_PROMOTED_CONTENTS;

  constructor(public searchUserRequest: SearchUserRequest = null) {
  }
}

export class PromotedContentsLoadSuccess implements PromotedContentsAction {
  readonly type = LOAD_PROMOTED_CONTENTS_SUCCESS;

  constructor(public result: SearchResult, public contentTypeName: string) {
  }
}

export class PromotedContentsLoadError implements PromotedContentsAction {
  readonly type = LOAD_PROMOTED_CONTENTS_FAIL;

  constructor(public error: any = null, public contentTypeName: string) {
  }
}

export class ClearPromotedContents implements PromotedContentsAction {
  readonly type = CLEAR_PROMOTED_CONTENTS;

  constructor() {
  }
}
