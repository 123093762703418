import {Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {Observable, Subscription} from "rxjs";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {select, State, Store} from "@ngrx/store";
import {getBreakpoint} from "../../core/store/reducers";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {Router} from "@angular/router";

@Component({
  selector: 'ea-transversal-search-field',
  templateUrl: './transversal-search-field.component.html',
  styleUrls: ['./transversal-search-field.component.scss']
})
export class TransversalSearchFieldComponent implements OnInit, OnDestroy {

  placeholder$: Observable<string>;

  breakPoint$ = this.store.pipe(select(getBreakpoint));

  _query: string;

  @Input()
  set query(query: string){
    if(this.form){
      this.form.setValue({search: query ? query : null});
    }
    this._query = query;
  }

  @Output()
  searchQueryChange:EventEmitter<string> = new EventEmitter<string>();

  searchFieldSubscription: Subscription;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store<State<any>>,
    private router: Router
  ) { }

  ngOnInit() {

    this.form = this.fb.group({
      search: [this._query ? this._query : '']
    });

    this.initializeObservable();

    this.placeholder$ = this.breakPoint$.pipe(map(result => {
      return 'Recherchez par mots-clés ou par ID';
    }));

  }

  ngOnDestroy(){
    this.searchFieldSubscription.unsubscribe();
  }

  initializeObservable(){
    if(this.searchFieldSubscription){
      this.searchFieldSubscription.unsubscribe();
    }
    this.searchFieldSubscription = this.form.get('search').valueChanges.pipe(
      distinctUntilChanged((oldValue, newValue) => {
        return oldValue === newValue;
      }),
      debounceTime(1500),
    ).subscribe(search => {
      if(search !== null){
        this.searchQueryChange.emit(search);
      }
    });
  }

  reset(){
    this.form.get('search').reset();
    //this.store.dispatch(new SetQuery(null));
    //this.store.dispatch(new SearchAction());
    this.router.navigate([], {queryParams: {q: null}});
  }

}

@NgModule({
  declarations: [
    TransversalSearchFieldComponent
  ],
  exports: [
    TransversalSearchFieldComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule
  ]
})
export class TransversalSearchFieldModule { }
