// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {getBreakpoint} from "../core/store/reducers";
import {ResizeService} from "../services/resize.service";
import {BreakpointKeys} from "../core/models/breakpoints";

export const BREAKPOINT = new InjectionToken<BreakpointKeys>(
  'A stream to get the current breakpoint'
);

export const IS_XS = new InjectionToken<Observable<boolean>>(
  'IS_XS'
);

export const RESPONSIVE_PROVIDERS: Provider[] = [
  {
    deps: [Store],
    provide: BREAKPOINT,
    useFactory: breakpointFactory
  },
  {
    provide: IS_XS,
    deps: [ResizeService],
    useFactory: isXsFactory
  }];

export function breakpointFactory(
  store: Store<any>
): Observable<BreakpointKeys> {
  return store.pipe(select(getBreakpoint)) as Observable<BreakpointKeys>;
}

export function isXsFactory(
  resizeService: ResizeService
): Observable<boolean> {
  return resizeService.isXs$
}
