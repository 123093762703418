<mat-nav-list class="p-0 flex-column flex-fill d-flex">
  <ul
    class="main-menu m-0 p-0 d-flex flex-grow-0 flex-nowrap flex-column ambiant-font font-size-headline font-weight-bold uppercase">

    <ng-template ngFor let-menuItem [ngForOf]="mainMenu?.items">

      <li
        *ngIf="menuItem.isFront"
        class="menu-item mat-background-accent-color font-size-caption"
        [ngClass]="{'training-theme':isFormation}">
        <a
          [innerHTML]="menuItem.title"
          [routerLink]="menuItem.path | menuPathToRoute">
        </a>
      </li>

    </ng-template>

    <ng-template ngFor let-menuItem [ngForOf]="mainMenu?.items">

      <ng-container *ngIf="menuItem.role=='formation' && isFormation">

        <li
          class="menu-item mat-background-accent-color training-theme">
          <a
            [innerHTML]="menuItem.title"
            [routerLink]="[formationUniversePath]">
          </a>

          <ng-container *ngIf="menuItem.children?.length > 0">

            <ul class="pl-2">

              <ng-template ngFor [ngForOf]="menuItem.children" let-subMenuItem>
                <li
                  class="menu-item mat-background-accent-color training-theme">
                  <a
                    [innerHTML]="subMenuItem.title"
                    [routerLink]="[subMenuItem?.params?.link]"
                    [queryParams]="subMenuItem?.params?.queryParams">
                  </a>
                </li>
              </ng-template>

            </ul>

          </ng-container>

        </li>

      </ng-container>

      <li
        *ngIf="!menuItem.role && !menuItem.isFront"
        class="menu-item mat-background-accent-color">
        <a
          [innerHTML]="menuItem.title"
          [routerLink]="menuItem.path | menuPathToRoute">
        </a>
      </li>

    </ng-template>

    <ng-container *ngIf="isTrainee">

      <li
        class="menu-item mat-background-accent-color training-theme">
        <a
          [innerHTML]="'Espace Stagiaire'"
          [routerLink]="[traineeRoute]">
        </a>
      </li>

    </ng-container>

  </ul>

  <ul
    class="secondary-menu d-flex justify-content-center m-0 p-0 flex-column ambiant-font font-size-title font-weight-bold uppercase">

    <li
      *ngFor="let menuItem of secondaryMenu?.items">
      <a
        [innerHTML]="menuItem.title"
        [routerLink]="menuItem.path">
      </a>
    </li>
  </ul>

  <ul
    class="user-menu d-flex justify-content-center m-0 p-0 flex-column ambiant-font font-size-title font-weight-bold uppercase mb-5">

    <li>
      <button
        class="header-button text-uppercase ffa-icon-button mr-2"
        mat-button
        (click)="openBookmarks()">
        <mat-icon [svgIcon]="'icon-bookmark'" class="bookmark-icon"></mat-icon>
        <span class="font-size-title" [matBadge]="bookmarksCount$|async" matBadgeOverlap="false"
              matBadgeSize="small">Mes Favoris</span>

      </button>
    </li>

    <ng-container *ngIf="userSubscriber$|async">

      <li>
        <a
          class="ambiant-font text-uppercase ffa-icon-button font-weight-bold"
          mat-button
          [routerLink]="[{outlets:{popUp:domainsPath}}]"
          queryParamsHandling="preserve">
          <!--
          <mat-icon class="pr-2 m-0" svgIcon="icon-preferences"></mat-icon>
          -->
          <mat-icon svgIcon="icon-preferences"></mat-icon>
          <span class="font-size-title mr-4" [matBadgeHidden]="(userFilieres$|async)?.length === 0" [matBadge]="(userFilieres$|async)?.length" matBadgeOverlap="false" matBadgeSize="small">Domaines et filières</span>

        </a>
      </li>

    </ng-container>

    <ng-container *ngIf="!(authenticated$|async)">

      <li>

        <a
          class="header-button ffa-icon-button text-uppercase"
          mat-button
          [routerLink]="[{outlets:{userLoginOutlet:loginPath}}]"
          [skipLocationChange]="true">
          <mat-icon svgIcon="icon-user"></mat-icon>
          <span class="font-size-title font-weight-bold ambiant-font">Connexion</span>
        </a>

      </li>

    </ng-container>

    <ng-container *ngIf="authenticated$|async">

      <li>

        <a
          [routerLink]="[{outlets:{userAccountOutlet:'mon-compte'}}]"
          class="ffa-icon-button text-uppercase"
          mat-button>
          <mat-icon svgIcon="icon-user"></mat-icon>
          <span class="font-size-title font-weight-bold ambiant-font">Mon Compte</span>
        </a>

      </li>

      <li>

        <button
          (click)="logOut()"
          mat-button
          class="ffa-icon-button">
          <mat-icon class="pr-2 m-0" svgIcon="icon-logout"></mat-icon>
          <span class="font-size-title font-weight-bold ambiant-font">Me déconnecter</span>
        </button>

      </li>

    </ng-container>

  </ul>

  <div class="d-flex flex-fill flex-column justify-content-center align-content-stretch">

    <a [routerLink]="'/'">
      <img class="logo-ffa d-block px-3 mw-100" [src]="logoFormation"/>
    </a>

  </div>

  <ul class="legal-menu d-flex justify-content-center m-0 flex-column">

    <li
      *ngFor="let menuItem of legalMenu?.items">
      <a
        [innerHTML]="menuItem.title"
        [routerLink]="[{outlets:{popUp:menuItem.path}}]">
      </a>
    </li>
  </ul>

</mat-nav-list>
