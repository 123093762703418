import {Pipe, PipeTransform} from '@angular/core';
import {Contexts, Page} from "../../core/models/page";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode} from "../../core/models/node";
import {Image} from "../../core/models/image";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";

@Pipe({
  name: 'resourceToPage'
})
export class ResourceToPagePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToMediaPipe: ResourceToMediaPipe
  ) {
  }

  transform(value: any): Page {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    let ambiantMedia: Image[];
    if (value?.ambiant_medias) {
      ambiantMedia = value?.ambiant_medias.map(media => {
        return this.resourceToMediaPipe.transform(media);
      });
    }
    let thumbnail: Image;
    if (value?.visuel) {
      thumbnail = this.resourceToMediaPipe.transform(value?.visuel) as Image;
    }
    const contextId: Contexts = value.app_context_id;
    const linkedNodes: DrupalNode[] = value.linked_nodes;
    return {
      ...drupalNode,
      contextId,
      ambiantMedia: ambiantMedia.filter(ambiantMedia => !!ambiantMedia),
      thumbnail,
      linkedNodes
    };
  }

}
