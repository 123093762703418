import {RouterEffects} from "./router";
import {BootEffects} from "./boot-effects";

/**
 * Created by benoitplatre on 03/08/2018.
 */

export const bootEffects: any[] = [
  BootEffects,
  RouterEffects
];

export * from './router';
