import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  AddBreadCrumb,
  AddToHistory,
  InsertBreadCrumb,
  Load,
  LoadFail,
  LoadModuleThemes,
  LoadModuleThemesSuccess,
  LoadNavigatedDiplomeNodes,
  LoadNavigatedDiplomeNodesSuccess,
  LoadNavigatedModuleNodes,
  LoadNavigatedModuleNodesSuccess,
  LoadNavigatedThemeNodes,
  LoadNavigatedThemeNodesSuccess,
  LoadSuccess,
  NodeActionTypes,
  ResetBreadCrumb,
  UIActionTypes,
  UpdateBookmarksMenuOpen,
  UpdateBreadCrumb,
  UpdateStaticContents
} from "../actions";
import {NodeService} from "../../services/node.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ContentTypesConstants} from "../../models/content-types-constants";
import {BreadcrumbsDefinition, BreadcrumbsMap} from "../../models/breadcrumbs-definition";
import {select, Store} from "@ngrx/store";
import {BreadCrumb} from "../../models/breadcrumb";
import {BreadcrumbsService} from "../../services/breadcrumbs.service";
import * as fromCore from '../reducers';
import {
  getBreadCrumbs,
  getNodeById,
  getRouterQueryParamsBc,
  getRouterQueryParamsModule,
  getUniverse
} from '../reducers';
import {DrupalNode} from "../../models/node";
import {RoutesConstants} from "../../models/routes-constants";
import {encyclopediaPath, themePath, traineePath, trainingPath} from "../../../route-constants";
import {ROUTER_NAVIGATED} from "@ngrx/router-store";

@Injectable()
export class UiEffects {

  constructor(
    private actions$: Actions,
    private nodeService: NodeService,
    private store: Store<any>,
    private breadcrumbsService: BreadcrumbsService,
    private coreStore: Store<fromCore.State>,
  ) {
  }

  
  navigated$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATED),
    switchMap(action => {
      return [new UpdateBookmarksMenuOpen(false)];
    })
  ));

  
  loadNodeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoadSuccess>(NodeActionTypes.LoadSuccess),
    tap(action => {
      this.breadcrumbsService.hasBackBreadCrumbWithNid(action.payload.id)
        .pipe(
          withLatestFrom(
            this.store.pipe(select(getUniverse),
              filter(universe => !!universe)
            ),
            this.store.pipe(select(getRouterQueryParamsBc))
          )
        )
        .subscribe(([hasBackBreadCrumbWithNid, universe, queryParamsBc]) => {
          if (hasBackBreadCrumbWithNid) {
            const node: any = action.payload;
            const {id, type, title, app_context_id} = node;
            const bcIds: string[] = Array.isArray(queryParamsBc) ? queryParamsBc?.filter(bcId => bcId !== action.payload.id):queryParamsBc !== action.payload.id ?[ queryParamsBc]:[];
            const breadCrumb: BreadCrumb = {
              queryParams: bcIds.length === 0 ? null : {bc:bcIds},
              node: {id, type, title, app_context_id}
            };
            if (node.app_context_id === 'trainee-area') {
              breadCrumb.url = '/' + traineePath;
            } else {
              let universeRoute: string;
              if (universe === 'encyclopedia') {
                universeRoute = encyclopediaPath;
              } else if (universe === 'training') {
                universeRoute = trainingPath;
              }
              const isTrainingTheme: boolean = action.payload.title?.indexOf('(FOAD)') > -1 || action.payload.title?.indexOf('(Stage)') > -1;
              if (action.payload.type === 'ffa_theme' && universe !== 'training' && !isTrainingTheme) {
                universeRoute = themePath;
              }
              const url: string = RoutesConstants.contentTypeRoutesMap[node.type]
              && RoutesConstants.contentTypeRoutesMap[node.type]['detail'] ?
                universeRoute
                + '/' +
                RoutesConstants.contentTypeRoutesMap[node.type].detail
                + '/' +
                node.id
                : null;
              if (url) {
                breadCrumb.url = '/' + url;
              }
            }
            this.store.dispatch(new UpdateBreadCrumb(breadCrumb));
          }
        });
    })
  ), {dispatch: false});

  
  loadModuleThemes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadModuleThemes>(UIActionTypes.LoadModuleThemes),
    filter(action => !!action.moduleId),
    mergeMap(action => {
      return this.nodeService.getNode(action.moduleId)
        .pipe(
          map((node: any) => {

            const foadNodeInfos = node.formation_theme_foad;
            foadNodeInfos.type = ContentTypesConstants.theme.name;
            const stageNodeInfos = node.formation_theme_stage;
            stageNodeInfos.type = ContentTypesConstants.theme.name;

            node.internal_themes = [foadNodeInfos, stageNodeInfos];

            return new LoadModuleThemesSuccess(action.moduleId, node);

          }),
          catchError((error: HttpErrorResponse) => {
            return of(new LoadFail(error.message));
          })
        );
    })
  ));

  
  loadThemeNodes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadNavigatedThemeNodes>(UIActionTypes.LoadNavigatedThemeNodes),
    filter(action => !!action.themeId),
    mergeMap(action => {
      return this.nodeService.getNode(action.themeId)
        .pipe(
          mergeMap((node: any) => {

            return this.nodeService.getNodeRelated(action.themeId).pipe(
              map(nodes => {
                node.internalRelated = nodes.referenced_contents;
                return new LoadNavigatedThemeNodesSuccess(action.themeId, node);
              }),
              catchError((error: HttpErrorResponse) => {
                return of(new LoadFail(error.message));
              })
            )

          }),
          catchError((error: HttpErrorResponse) => {
            return of(new LoadFail(error.message));
          })
        );
    })
  ));

  
  loadModuleNodes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadNavigatedModuleNodes>(UIActionTypes.LoadNavigatedModuleNodes),
    filter(action => !!action.moduleId),
    mergeMap(action => {
      return this.nodeService.getNode(action.moduleId)
        .pipe(
          map((node: any) => {

            const foadNodeInfos = node.formation_theme_foad;
            foadNodeInfos.type = ContentTypesConstants.theme.name;
            const stageNodeInfos = node.formation_theme_stage;
            stageNodeInfos.type = ContentTypesConstants.theme.name;
            const faceAFaceNodeInfos = node.formation_face_a_face;
            faceAFaceNodeInfos.type = ContentTypesConstants.faceAface.name;
            node.internal_contents = [foadNodeInfos, stageNodeInfos, faceAFaceNodeInfos];

            return new LoadNavigatedModuleNodesSuccess(action.moduleId, node);

          }),
          catchError((error: HttpErrorResponse) => {
            return of(new LoadFail(error.message));
          })
        );
    })
  ));

  
  loadDiplomeNodes$ = createEffect(() => this.actions$.pipe(
    ofType<LoadNavigatedDiplomeNodes>(UIActionTypes.LoadNavigatedDiplomeNodes),
    filter(action => !!action.diplomeId),
    mergeMap(action => {
      return this.nodeService.getNode(action.diplomeId)
        .pipe(
          map((node: any) => {

            node.internal_contents = node.formation_diplome_modules.map(formationModule => {
              return {...formationModule.formation_module, type: ContentTypesConstants.module.contentType};
            });

            return new LoadNavigatedDiplomeNodesSuccess(action.diplomeId, node);

          }),
          catchError((error: HttpErrorResponse) => {
            return of(new LoadFail(error.message));
          })
        );
    })
  ));

  
  addToHistory$ = createEffect(() => this.actions$.pipe(
    ofType<AddToHistory>(UIActionTypes.AddToHistory),
    withLatestFrom(this.store.select(getBreadCrumbs)),
    tap(([action, currentBreadCrumbs]) => {
      const node: any = action.content.node;
      const queryParams: any = action.content.queryParams;
      const url: string = action.content.url.split('?').shift();
      const breadCrumbsDefinition: { [key: string]: BreadcrumbsMap } = BreadcrumbsDefinition.breadcrumbs;

      let breadCrumb: BreadCrumb = {
        node: node,
        url: url,
        queryParams: currentBreadCrumbs.length <= 1 ? null : Object.keys(queryParams).length === 0 ? null : queryParams
      };

      if (queryParams && queryParams.bc) {
        // manages direct access
        const bcs: any[] = (queryParams.bc instanceof Array) ? queryParams.bc : [queryParams.bc];
        bcs.forEach((bcId, index) => {
          this.breadcrumbsService.hasBackBreadCrumbWithNid(bcId).pipe(take(1)).subscribe(
            hasBackBreadCrumbWithNid => {
              if (!hasBackBreadCrumbWithNid) {

                this.store.pipe(
                  select(getNodeById(bcId)),
                  take(1)
                ).subscribe((drupalNode: DrupalNode) => {
                  if (!drupalNode) {
                    this.store.dispatch(new Load(bcId));
                  }
                });

                this.store.dispatch(new InsertBreadCrumb({node: {id: bcId}}, index));
              }
            }
          );

        });
      }

      if (breadCrumbsDefinition[node.app_context_id]) {
        if (breadCrumbsDefinition[node.app_context_id].level === -1) {
          this.store.dispatch(new ResetBreadCrumb());
        } else if (breadCrumbsDefinition[node.app_context_id].level === 0) {
          this.store.dispatch(new ResetBreadCrumb(breadCrumb));
        }
      } else if (breadCrumbsDefinition[node.type]) {

        let nodeLevelInBreadcrumb: number;

        currentBreadCrumbs.forEach((currentBreadCrumb, index) => {

          if (isNaN(nodeLevelInBreadcrumb)) {
            if (currentBreadCrumb.node.type === node.type) {
              nodeLevelInBreadcrumb = index;
            } else if (breadCrumbsDefinition[currentBreadCrumb.node.type] && breadCrumbsDefinition[currentBreadCrumb.node.type].level === breadCrumbsDefinition[node.type].level) {
              nodeLevelInBreadcrumb = index;
            }
          }
        });

        const level: number = !isNaN(nodeLevelInBreadcrumb) ? nodeLevelInBreadcrumb : breadCrumbsDefinition[node.type].level;

        if ((queryParams && queryParams.bc)) {
          this.store.dispatch(new AddBreadCrumb(breadCrumb, level));
        } else {
          if (!(queryParams && queryParams.bc)) {
            this.store.dispatch(new ResetBreadCrumb(breadCrumb));
          }
        }
      }
    })
  ), {dispatch: false});

  
  loadNavigatedModuleNodesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoadNavigatedModuleNodesSuccess>(UIActionTypes.LoadNavigatedModuleNodesSuccess),
    map(action => {
      return new UpdateStaticContents(action.module.internal_contents)
    })
  ));

  
  loadNavigatedThemeNodesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoadNavigatedThemeNodesSuccess>(UIActionTypes.LoadNavigatedThemeNodesSuccess),
    map(action => {
      return new UpdateStaticContents(action.theme.internalRelated)
    })
  ));

  
  loadNavigatedDiplomeNodesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoadNavigatedDiplomeNodesSuccess>(UIActionTypes.LoadNavigatedDiplomeNodesSuccess),
    map(action => {
      return new UpdateStaticContents(action.diplome.internal_contents)
    })
  ));

  
  LoadModuleThemesSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<LoadModuleThemesSuccess>(UIActionTypes.LoadModuleThemesSuccess),
    map(action => {
      const module: any = action.module;
      return new UpdateStaticContents([module.formation_theme_foad, module.formation_theme_stage]);
    })
  ));

}
