import {Inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {SwUpdate} from '@angular/service-worker';
import {DOCUMENT} from '@angular/common';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  appVersion: string;
  deferredPwaPromptEvent: any;
  showInstallComponent: boolean = false;

  installNotNow = false;

  updateAvailable$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private dialog: MatDialog,
    private store: Store<any>,
    private swUpdate: SwUpdate,
    @Inject(DOCUMENT) private document: any
  ) {

  }

  init() {

    this.swUpdate.available.subscribe(event => {
      if (event.available.appData && event.available.appData['releaseNotes']) {
        // console.log('releaseNotes', event.available.appData['releaseNotes']);
      }
      if (event.available.appData && event.available.appData['critical'] === true) {
        this.showUpdate();
      }
      this.updateAvailable$.next(true);
    });

    // this.showUpdate();
    // this.showInstallComponent = true;

  }

  beforeInstallPrompt(event: any) {
    this.deferredPwaPromptEvent = event;
    this.showInstallComponent = true;
  }

  install() {
    this.installNotNow = false;
    if (this.deferredPwaPromptEvent) {
      this.deferredPwaPromptEvent.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPwaPromptEvent.userChoice.then((choice) => {
        if (choice.outcome === 'accepted') {
          this.showInstallComponent = false;
        } else {
        }
        // Clear the saved prompt since it can't be used again
        this.deferredPwaPromptEvent = null;
      });
    }
  }

  showUpdate() {

  }

  openAppSettings() {

  }

  reload() {
    if (this.document && this.document.location) {
      this.document.location.reload();
    }
  }

}
