import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import * as fromCore from "./core/store/reducers";
import {Go} from "./store/actions";

@Injectable({
  providedIn: 'root'
})

export class PasswordRecoveryGuard implements CanActivate {

  constructor(
    private store: Store<fromCore.State>,
    private router: Router
  ){
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    setTimeout(() => {
      this.store.dispatch(new Go({path: [{outlets: {popUp: state.url.substring(1)}}]}));
    });

    return false;

  }
}
