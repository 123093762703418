import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DrupalMenu, DrupalMenuItem} from '../models/menu';
import {map} from 'rxjs/operators';
import {AppConfigService} from "../../app-config.service";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService
  ) {
  }

  public getMenu(mid: string): Observable<DrupalMenu> {
    return this.http
      .get<{ menu: DrupalMenu }>(this.appConfig.getConfig().adminUrl + '/api/1.0/menu/' + mid).pipe(
        map((result: any) => {
          let menuItems: DrupalMenuItem[];
          if (result.tree) {
            menuItems = this.parseTree(result.tree);
          }
          return {name: result.name, items: menuItems};
        })
      );
  }

  parseTree(tree: any): DrupalMenuItem[] {
    const menuItems: DrupalMenuItem[] = [];
    for (const treeName in tree) {
      const rawMenuItem: any = tree[treeName];
      const rawMenuItemChildren: any = rawMenuItem.children;
      const rawMenuItemLink: any = rawMenuItem.link;
      const descriptionParsed: any = (rawMenuItemLink?.options?.attributes?.title) ? JSON.parse(rawMenuItemLink.options.attributes.title) : null;
      let role: string = descriptionParsed ? descriptionParsed.role : null;
      let isFront: boolean = descriptionParsed ? descriptionParsed.is_front : null;
      let isHidden: boolean = parseInt(rawMenuItemLink.hidden) === 1;
      if (isHidden != true) {
        menuItems.push(
          {
            title: rawMenuItemLink.title,
            path: rawMenuItemLink.path_alias ? rawMenuItemLink.path_alias : rawMenuItemLink.path,
            description: rawMenuItemLink.description,
            role: role,
            isFront: isFront,
            params: descriptionParsed,
            children: this.parseTree(rawMenuItemChildren)
          }
        );
      }
    }
    //console.log('menuItems', menuItems);
    return menuItems;
  }

}

//options:
// attributes:
// title: "{"role":"formation"}"
