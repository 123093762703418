import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formationDuration'
})
export class FormationDurationPipe implements PipeTransform {

  transform(formationSchedule: any): any {
    const timeStart: string = formationSchedule?.time?.start;
    const timeEnd: string = formationSchedule?.time?.end;
    const hourStart: number = this.getHour(timeStart);
    const hourEnd: number = this.getHour(timeEnd);
    return hourEnd - hourStart + ' heures';
  }

  getHour(time: string): number {
    if(time && time.indexOf(':') > -1){
      return parseInt(time.split(':')[0], 10);
    }else{
      return 0;
    }
  }

  getMinute(time: string): number {
    if(time && time.indexOf(':') > -1){
      return parseInt(time.split(':')[1], 10);
    }else{
      return 0;
    }
  }

}
