import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {select, Store} from '@ngrx/store';
import * as fromRoot from './store/reducers';
import {Observable, Subscription} from 'rxjs';
import {WindowRef} from "./window-ref";
import {AppConfigService} from "./app-config.service";
import {getRouterUrl} from "./core/store/reducers";

declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  currentUrl$: Observable<string> = this.store.pipe(select(getRouterUrl));


  currentUrlSubscription: Subscription;

  constructor(
    private windowRef: WindowRef,
    private appConfig: AppConfigService,
    private store: Store<fromRoot.State>,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {

  }

  enable() {
    const enableGA: boolean = isPlatformBrowser(this._platformId) && this.windowRef.nativeWindow.ga && !this.windowRef.nativeWindow.navigator.userAgent.match(/HeadlessChrome/);
    //console.log('enableGA', enableGA);
    //console.log('ga', ga);
    //console.log('this.windowRef.nativeWindow.navigator.userAgent.match(/HeadlessChrome/)', this.windowRef.nativeWindow.navigator.userAgent.match(/HeadlessChrome/));
    if (enableGA) {
      this.windowRef.nativeWindow.ga('create', this.appConfig.getConfig().googleAnalyticsAccount, 'auto');
      this.currentUrlSubscription = this.currentUrl$.subscribe((url: string) => {
        if(url){
          this.sendAnalytics(url);
        }
      });
    } else {
      setTimeout(() => this.enable(), 25);
    }
  }

  disable() {
    if (this.currentUrlSubscription) {
      this.currentUrlSubscription.unsubscribe();
    }
  }

  sendAnalytics(url: string) {
    //console.log('sendAnalytics()', url);
    //console.log('ga', ga);
    ga('set', 'page', url);
    ga('send', 'pageview');
  }

}
