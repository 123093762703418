import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as fromCore from '../store/reducers';
import {
  getBreakpoint,
  getCurrentNode,
  getMainMenuOpen,
  getPreferencesConsent,
  getRouterIsContent,
  getRouterIsHome,
  getRouterIsSearch,
  getRouterIsSession,
  getRouterQueryParams,
  getShowSearchBar,
  getUniverse,
  getUserAuthenticationRedirection,
  getUserInfos,
  getUserIsFormation,
  getUserIsTrainee
} from '../store/reducers';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MetaTagsService} from '../services/metatags.service';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {PathInfos} from '../models/path';
import {ClearNodesStorage, NodeReload} from '../store/actions/node';
import {MenuLoad, MenuLoadSuccess} from '../store/actions/menu';
import {DrupalMenu} from '../models/menu';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppError, DrupalAuthService, getAuthenticated, InitAuthenticationState, Logout} from '@madeinlune/components';
import {AppConfigService} from '../../app-config.service';
import {AppQueries} from '../../store/query/index';
import {ErrorWindowComponent} from '../../components/error-window/error-window.component';
import {
  AddToHistory,
  ClearHistory,
  ClearNavigatedModule,
  ClearStaticContents,
  ShowDomainsWindow,
  ShowLoginWindow,
  UpdateBookmarksMenuOpen,
  UpdateBreakpoint,
  UpdateMainMenuOpen
} from '../store/actions/ui.action';
import {Platform} from '@angular/cdk/platform';
import {BreakpointObserver} from "@angular/cdk/layout";
import {
  LoadUserInfos,
  LoadUserMeta,
  SetAuthenticationRedirection,
  SetPreferredAgeCriteria,
  SetStaticVocabulary,
  UpdateUser,
  UserResetState,
  VocabularyLoad,
  WebformResetAll
} from "../store/actions";
import * as path from "../store/actions/path";
import {DOCUMENT, Location} from "@angular/common";
import {SwUpdate} from "@angular/service-worker";
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  share,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {NodeService} from "../services/node.service";
import {DrupalNode} from "../models/node";
import {FindContentById, getUserQuery, SearchReset, SetQuery} from "../../search/store";
import {routerTransition} from "../../animations/router.animations";
import {DrupalVocabulary, DrupalVocabularyTerm} from "../models/vocabulary";
import {Go} from "../../store/actions";
import {AppUniverses} from "../models/app-universes";
import {RoutesConstants} from "../models/routes-constants";
import {AuthConfig, OAuthModuleConfig, OAuthService} from 'angular-oauth2-oidc';
import {CookiebotService} from "../../cookiebot.service";
import {TemplatesService} from "../../templates.service";
import {User} from "../models/user";
import * as Sentry from "@sentry/browser";
import {AppSettingsComponent} from "../../components/app-settings/app-settings.component";
import {ResetEcommerce} from "../store/actions/e-commerce.actions";
import {SetHeaderTitle} from "../store/actions/app-core.actions";
import {AppSnackbarComponent} from "../../components/app-snackbar/app-snackbar.component";
import {VERSION_INFO} from '../../../environments/version';
import {DbService} from "../../services/db.service";

import {
  aPropos,
  contactPath,
  encyclopediaPath,
  faq,
  homePath,
  quizSiFfa,
  themePath,
  trainingCheckIn,
  trainingPath
} from "../../route-constants";
import {PwaService} from "../../pwa/pwa.service";
import {SearchResultService} from "../../search/search-result.service";
import {IS_BOOKMARK_MENU_OPEN} from "../../providers/menu-states.providers";
import {BOOKMARKS_TOTAL, DOMAINS_ONLY, IS_SUBSCRIBER} from "../../providers/user.providers";
import {USER_READY} from "../../providers/authentication.providers";
import {SearchService} from "../../search/search.service";
import {ContentsService} from "../../contents/contents.service";
import {AgeType} from "../store/reducers/user.reducer";
import {preferredAgeCriteria} from "../store";
import {CdkScrollable, ScrollDispatcher} from "@angular/cdk/overlay";
import {AppHeaderComponent} from "../components/app-header/app-header.component";


@Component({
  selector: 'ea-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
  providers: [SearchService]
})
export class AppComponent implements OnInit, AfterViewInit {

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.pwaService.beforeInstallPrompt(e);
  }

  pathInfos$: Observable<PathInfos>;
  error$: Observable<HttpErrorResponse>;
  metatags$: Observable<any>;

  legalMenu$: Observable<DrupalMenu>;
  mainMenu$: Observable<DrupalMenu>;
  secondaryMenu$: Observable<DrupalMenu>;
  trainingMenu$: Observable<DrupalMenu>;

  routeParams$: Observable<Params>;

  routeData$: Observable<any>;

  routeUrl$: Observable<string> = this.store.pipe(select(fromCore.getRouterUrl), filter(url => !!url));

  openedMainMenu$ = this.store.pipe(select(getMainMenuOpen));

  primaryContentId: string;

  globalLoading$ = this.queries.globalLoading$.pipe(share());

  contextLoading$ = this.queries.contextLoading$.pipe(share());

  authenticationLoaded$: Observable<boolean> = this.store.pipe(
    select((state: any) => state.drupalAuth),
    map(drupalAuth => {
      return drupalAuth.loaded;
    }),
    filter(loaded => loaded === true),
    tap(() => {
      // this.store.dispatch(new Go({path: [this.location.path(true)]}))
    }),
    take(1),
    shareReplay(1)
  );

  authenticated$ = this.store.pipe(select(getAuthenticated));

  userInfos$ = this.store.pipe(select(getUserInfos));

  isFormationUser$ = this.store.pipe(select(getUserIsFormation));

  isTraineeUser$ = this.store.pipe(select(getUserIsTrainee));

  errors$ = this.queries.errors$;

  private _mediaQueryListener: () => void;

  isHome$: Observable<boolean> = this.store.pipe(select(getRouterIsHome));

  isSearch$ = this.store.pipe(select(getRouterIsSearch));

  isContent$ = this.store.pipe(select(getRouterIsContent));

  isSession$ = this.store.pipe(select(getRouterIsSession));

  userQuery$ = this.store.pipe(select(getUserQuery));

  routeQueryParams$ = this.store.pipe(select(getRouterQueryParams));

  showSearchBar$: Observable<boolean> = this.store.pipe(select(getShowSearchBar));

  versionInfo$ = of(VERSION_INFO.raw);

  appVersion$: Observable<string>;

  breakPoint$ = this.store.pipe(select(getBreakpoint));

  universe$ = this.store.pipe(select(getUniverse));

  encyclopediaNode$: Observable<any>;

  routeDataTheme$: Observable<any>;

  hideSearchField$: Observable<boolean>;

  encyclopediaNode: DrupalNode;

  preferencesConsent$: Observable<boolean> = this.store.pipe(select(getPreferencesConsent));

  isNotProd: boolean;

  currentNode$: Observable<any> = this.store.pipe(
    select(getCurrentNode),
    filter(node => !!node),
    distinctUntilKeyChanged('id'));

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.shiftKey && $event.key == 'E') {
      this.openAppSettings();
    }
  }

  @ViewChild('cookieFooter', {static: true})
  cookieFooter: TemplateRef<any>;

  @ViewChild('appHeader', {read: AppHeaderComponent})
  appHeader: AppHeaderComponent;

  constructor(
    private store: Store<fromCore.State>,
    private metatagsService: MetaTagsService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private appConfig: AppConfigService,
    private drupalAuthService: DrupalAuthService,
    private oauthModuleConfig: OAuthModuleConfig,
    private oauthService: OAuthService,
    private queries: AppQueries,
    private dialog: MatDialog,
    private platform: Platform,
    private location: Location,
    changeDetectorRef: ChangeDetectorRef,
    private swUpdates: SwUpdate,
    private nodeService: NodeService,
    private activatedRoute: ActivatedRoute,
    httpClient: HttpClient,
    @Inject(DOCUMENT) private document: any,
    private cookiebotService: CookiebotService,
    private templatesService: TemplatesService,
    private snackBar: MatSnackBar,
    private dbService: DbService,
    private pwaService: PwaService,
    private searchResultService: SearchResultService,
    @Inject(IS_BOOKMARK_MENU_OPEN) public readonly isBookmarkMenuOpen$: Observable<boolean>,
    @Inject(BOOKMARKS_TOTAL) public readonly bookmarksCount$: Observable<number>,
    @Inject(IS_SUBSCRIBER) public readonly userSubscriber$: Observable<boolean>,
    @Inject(USER_READY) public readonly userReady$: Observable<boolean>,
    @Inject(DOMAINS_ONLY) public readonly domainsOnly$: Observable<DrupalVocabularyTerm[]>,
    private breakpointObserver: BreakpointObserver,
    public searchService: SearchService,
    public contentsService: ContentsService,
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone
  ) {

    const windowRef: ElementRef = new ElementRef<any>(window);
    const windowScrollable: CdkScrollable = new CdkScrollable(windowRef, this.scrollDispatcher, this.ngZone);
    this.scrollDispatcher.register(windowScrollable);

    this.isHome$
      .pipe(
        filter(isHome => !!isHome),
        switchMap((isHome) => {
          return this.scrollDispatcher.scrolled().pipe(
            filter(scrollable => scrollable === windowScrollable),
            filter(scrollable => this.appHeader?.elementRef.nativeElement),
            map((scrollable) => {
              return window.scrollY <= 0;
            })
          );
        }),
        distinctUntilChanged()
      )
      .subscribe(showHeader => {
        if (this.appHeader) {
          this.ngZone.run(() => {
            this.appHeader.isHome = showHeader;
            this.appHeader.isHomeScrolling = !showHeader;
            changeDetectorRef.detectChanges();
          });
        }
      });

    this.dbService.clearAll(true);

    this.store.dispatch(new ClearNodesStorage());
    this.store.dispatch(new ResetEcommerce());
    this.store.dispatch(new SearchReset());
    this.store.dispatch(new ClearNavigatedModule());
    this.store.dispatch(new ClearStaticContents());
    this.store.dispatch(new ClearHistory());

    this.store.dispatch(new SetAuthenticationRedirection(null));

    if (localStorage && localStorage.getItem('app_config_key')) {
      this.isNotProd = localStorage.getItem('app_config_key') !== 'prod';
    }

    this.contextLoading$.subscribe(loading => {
      /*console.log('loading', loading)*/
    });
    this.globalLoading$.subscribe(globalLoading => {
      //console.log('globalLoading', globalLoading);
    });

    this.swUpdates.versionUpdates.pipe(
      filter((e) => e.type === 'VERSION_READY')
    ).subscribe(event => {
      //console.log('event', event);
      this.snackBar.openFromComponent(AppSnackbarComponent,
        {
          data: {message: 'Une nouvelle version de l’Application est disponible !', isPwaUpdate: true},
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: 'ffa-snackbar'
        });
    });

    const breakPoints: { key: string, media: string }[] = [
      {
        key: 'xs',
        media: '(max-width: 576px)'
      },
      {
        key: 'sm',
        media: '(max-width: 768px)'
      },
      {
        key: 'md',
        media: '(max-width: 992px)'
      },
      {
        key: 'lg',
        media: '(max-width: 1200px)'
      },
      {
        key: 'xl',
        media: '(max-width: 1600px)'
      },
      {
        key: 'xxl',
        media: '(min-width: 1600px)'
      }
    ];

    const media: string[] = breakPoints.map(breakpoint => breakpoint.media);

    breakpointObserver.observe(media).pipe(
      map((mediaQueries: { matches: boolean, breakpoints: any }) => {
        let media: string;
        for (let key in mediaQueries.breakpoints) {
          if (mediaQueries.breakpoints[key] === true) {
            media = key;
            break;
          }
        }
        return media;
      }),
      map((media: string) => {
        const breakpoint = breakPoints.filter(breakpoint => breakpoint.media === media).shift();
        if (breakpoint) {
          return breakpoint?.key;
        }
      }),
      filter(breakpoint => !!breakpoint)
    ).subscribe(breakpoint => {
      this.store.dispatch(new UpdateBreakpoint(breakpoint));
    });

    window.dispatchEvent(new Event('resize'));

    this.pathInfos$ = store.pipe(select(fromCore.getPathInfos));
    this.error$ = store.pipe(select(fromCore.getPathInfosFail));
    this.metatags$ = store.pipe(select(fromCore.getPathInfosMetaTags));

    this.legalMenu$ = store.pipe(select(fromCore.getMenuByName('menu-app-legal-corporate')));
    this.mainMenu$ = store.pipe(select(fromCore.getMenuByName('main-menu')));
    this.secondaryMenu$ = store.pipe(select(fromCore.getMenuByName('secondary-menu')));
    this.trainingMenu$ = store.pipe(select(fromCore.getMenuByName('menu-formation')));

    this.routeParams$ = store.pipe(select(fromCore.selectRouteParameters));

    this.routeData$ = store.pipe(select(fromCore.selectRouteData));

    this.routeDataTheme$ = store.pipe(select(fromCore.getRouterDataTheme));

    this.hideSearchField$ = store.pipe(select(fromCore.selectRouteHideSearchField));

    /*
    export const homePath: string = 'bienvenue';
export const trainingPath: string = 'espace-formation';
export const encyclopediaPath: string = 'espace-encyclopedique';
export const themePath: string = 'espace-thematique';
export const myAccountPath: string = 'mon-compte';
export const loginPath: string = 'me-connecter';
export const domainsPath: string = 'mes-domaines-et-filieres';
export const contactPath: string = 'contact';
export const aPropos: string = 'apropos';
export const tutoriels: string = 'tutoriels';
export const faq: string = 'faq';
export const credits: string = 'credits';
export const mentionsLegales: string = 'mentions-legales';
export const subscription: string = 'je-m-inscris';
export const myBookmarksPath: string = 'mes-favoris';
export const userAccountOutlet: string = 'userAccountOutlet';
export const userLoginOutlet: string = 'userLoginOutlet';
export const genericOutlet: string = 'popUp';
export const trainingCheckIn: string = 'formation-check-in';
export const quizSiFfa: string = 'quiz-session';

     */

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((navigationEndEvent: NavigationEnd) => {
      //console.log('navigationEndEvent', navigationEndEvent);
      let headerTitle: string;
      if (navigationEndEvent.url.indexOf(encyclopediaPath) > -1) {
        headerTitle = 'Espace Encyclopédique';
      } else if (navigationEndEvent.url.indexOf(trainingPath) > -1) {
        headerTitle = 'Espace Formation';
      } else if (navigationEndEvent.url.indexOf(homePath) > -1 || navigationEndEvent.url == '/') {
        headerTitle = 'Bienvenue';
      } else if (navigationEndEvent.url.indexOf(themePath) > -1) {
        headerTitle = 'Espace Thématique';
      } else if (navigationEndEvent.url.indexOf(contactPath) > -1) {
        headerTitle = 'Contact';
      } else if (navigationEndEvent.url.indexOf(aPropos) > -1) {
        headerTitle = 'À propos';
      } else if (navigationEndEvent.url.indexOf(faq) > -1) {
        headerTitle = 'FAQ';
      } else if (navigationEndEvent.url.indexOf(trainingCheckIn) > -1) {
        headerTitle = 'Espace évaluation';
      } else if (navigationEndEvent.url.indexOf(quizSiFfa) > -1) {
        headerTitle = 'Espace évaluation';
      }
      this.store.dispatch(new SetHeaderTitle(headerTitle));
    });

    this.currentNode$.pipe(
      withLatestFrom(this.routeUrl$, this.routeQueryParams$)
    ).subscribe(([currentNode, url, queryParams]) => {
      this.store.dispatch(new AddToHistory({url: url, node: currentNode, queryParams: queryParams}));
    });

    /*this.routeQueryParams$.pipe(
      withLatestFrom(this.routeUrl$, this.currentNode$)
    ).subscribe(([queryParams, url, currentNode]) => {
      //this.store.dispatch(new UpdateBreadCrumb({node: currentNode, queryParams: queryParams}));
    });*/

    if (document) {
      document.body.className = 'default-theme';
    }

    this.routeDataTheme$.pipe(
      filter(result => !!result),
    )
      .subscribe((theme: string) => {
        //console.log('theme', theme);
        if (document) {
          //document.body.className = theme;
        }
      });

    this.routeParams$.subscribe((params: any) => {
      this.store.dispatch(new UpdateMainMenuOpen(false));
      if (params) {
        this.primaryContentId = params.nid;
      }
    });

    if (this.platform.isBrowser) {
      this.setupOAuth();
    }

    this.userInfos$.subscribe((userInfos: User) => {
      //console.log('userInfos', userInfos);
      if (userInfos) {
        Sentry.configureScope((scope) => {
          scope.setUser({'id': userInfos.id});
        });
      } else {
        Sentry.configureScope((scope) => {
          scope.setUser(null);
        });
      }
    });

    this.errors$.subscribe((errors: Error[]) => {
      if (errors && errors.length > 0 && errors[0] !== null) {
        let error: AppError = errors[0];
        if (error.status == 403) {
          error = {
            title: 'Contenu privé',
            message: 'Désolé, vous n’êtes pas autorisé à accéder à ce contenu',
            status: error.status
          };
          //this.location.go('');
          this.store.dispatch(new path.PathLoad(''));
        }
        setTimeout(() => {
          if (error.status == 401 && error.title == 'Authentication AppError') {

            error = {
              title: 'Compte invalide',
              message: 'Désolé, vos identifiants et mot de passe ne concordent pas.<br>Merci de les vérifier et de tenter à nouveau'
            };

            const dialogRef = this.dialog.open(ErrorWindowComponent, {
              width: '500px',
              panelClass: 'user-theme',
              data: error
            });

          }
        });

      }
    });

    /*this.encyclopediaNode$ = this.nodeService.getNodeByContextId('encyclopedia').pipe(
      map(result => result && result.length > 0 ? result[0] : null),
      share()
    );*/


    /*this.encyclopediaNode$.subscribe(node => {
      this.encyclopediaNode = node;
    });*/

    /*this.store.dispatch(new LoadContext(ContentTypesConstants.universeEncyclopedia));
    this.store.dispatch(new LoadContext(ContentTypesConstants.universeTraining));
    this.store.dispatch(new LoadContext(ContentTypesConstants.universeTheme));*/

    this.store.dispatch(new VocabularyLoad(DrupalVocabulary.domains));
    this.store.dispatch(new VocabularyLoad(DrupalVocabulary.ageLevel));

    let functionsString: string = 'ENT|Entraîneur\n' +
      'EPS|Professeur d’EPS ou de STAPS\n' +
      'PRO|Professeur des écoles ou CPC CPD\n' +
      'EDU|Educateur ou Préparateur physique ou ETAPS\n' +
      'CAD|Cadre technique\n' +
      'DIR|Dirigeant\n' +
      'OFF|Officiel\n' +
      'ETU|Etudiant\n' +
      'ATH|Athlète ou élève\n' +
      'INV|Invité\n' +
      'OTH|Autre';

    let userMainFunctionVocabulary = this.getStaticVocabulary('user_static_main_functions', functionsString);
    this.store.dispatch(new SetStaticVocabulary(userMainFunctionVocabulary));

    let practiveLevelString: string = 'NOC|Non compétiteur\n' +
      'LOC|Local\n' +
      'DEP|Départemental\n' +
      'REG|Régional\n' +
      'NAT|National\n' +
      'INT|International';

    let userPracticeVocabulary = this.getStaticVocabulary('user_static_practive_level', practiveLevelString);
    this.store.dispatch(new SetStaticVocabulary(userPracticeVocabulary));

  }

  openSearchFilters() {
    this.searchService.toggleFilterPanel();
  }

  getStaticVocabulary(vocabularyName: string, stringTerms: string): any {

    let stringArray: string[] = stringTerms.split('\n');

    let items: any[] = stringArray.map(item => {
      let itemSplit: string[] = item.split('|');
      return {id: itemSplit[0], label: itemSplit[1]};
    });

    let staticVocabulary = {
      name: vocabularyName,
      items: items
    };

    return staticVocabulary;

  }

  closeBookmarks() {
    this.store.dispatch(new UpdateBookmarksMenuOpen(false));
  }

  onOpenWindow(windowId: string) {
    if (windowId == 'domains') {
      this.store.dispatch(new ShowDomainsWindow());
    } else if (windowId == 'login') {
      this.store.dispatch(new ShowLoginWindow());
    }
  }

  getState(outlet) {
    return outlet.activatedRouteData ? outlet.activatedRouteData.state : null;
  }

  openAppSettings() {
    //console.log('openAppSettings !!!');
    this.dialog.open(AppSettingsComponent, {
      width: '640px',
      panelClass: 'user-theme',
    });
  }

  onMainMenuOpenedChange(opened: boolean) {
    this.store.dispatch(new UpdateMainMenuOpen(opened));
  }

  ngOnInit(): void {

    /*console.log('version:'+versionInfo['raw']);
    if(document) {
      let aTags = document.getElementsByTagName('a');
      if (aTags) {
        let aTag:HTMLL = aTags[Math.floor(Math.random() * aTags.length)];
        if (aTag) {
          aTag.click();
        }
      }
    }*/

    this.store.dispatch(new WebformResetAll());

    this.templatesService.addTemplate('cookieFooter', this.cookieFooter);

    this.cookiebotService.init();

    this.setIconRegistry();

    this.metatags$.subscribe((metaTags: any) => {
      this.metatagsService.metaTags = metaTags;
    });

    this.domainsOnly$
      .pipe(
        withLatestFrom(this.userInfos$)
      )
      .subscribe(([domainsOnly, userInfos]) => {
        if (userInfos?.domains?.length > 0 && domainsOnly?.length > 0) {
          const userDomainFilieres: string[] = [...userInfos.domains];
          const domainsIds: string[] = domainsOnly.map(domain => domain.tid);
          const filteredUserDomainFilieres: string[] = userDomainFilieres.filter(df => domainsIds.indexOf(df) === -1);
          if (userDomainFilieres?.length !== filteredUserDomainFilieres?.length) {
            let newUserInfos: any = {...userInfos, domains: filteredUserDomainFilieres};
            this.store.dispatch(new UpdateUser(newUserInfos));
          }
        }
      });

    //this.store.dispatch(new LoadQuizAccess('439'));

    this.authenticated$.pipe(
      withLatestFrom(this.store.pipe(select(getUserAuthenticationRedirection)))
    ).subscribe(([authenticated, authenticationRedirection]: [boolean, string]) => {

      //console.log('authenticated', authenticated);

      if (!authenticated) {
        this.store.dispatch(new ClearNodesStorage());
        this.store.dispatch(new UserResetState());
        this.store.dispatch(new Logout());
      }

      if (localStorage) {
        const prefAgeCriteria: AgeType = localStorage.getItem(preferredAgeCriteria) as AgeType;
        if (prefAgeCriteria) {
          this.store.dispatch(new SetPreferredAgeCriteria(prefAgeCriteria));
        }
      }

      if (authenticated) {

        this.store.dispatch(new NodeReload());
        this.store.dispatch(new ClearNodesStorage());
        this.store.dispatch(new LoadUserInfos());
        this.store.dispatch(new LoadUserMeta());

        if (this.router.url.indexOf('userLoginOutlet') > -1 || this.location.path().indexOf('userLoginOutlet') > -1) {

          if (authenticationRedirection) {
            this.store.dispatch(new Go({
              path: [{
                outlets: {
                  primary: authenticationRedirection,
                  userLoginOutlet: null
                }
              }]
            }));
            this.store.dispatch(new SetAuthenticationRedirection(null));
          } else {
            this.store.dispatch(new Go({path: [{outlets: {userLoginOutlet: null}}]}));
          }
          //this.router.navigate(['./', {outlets:{userLoginOutlet:null}}]);
        }
        //
      } else {
        //this.store.dispatch(new SetAnonymousUserSuccess({id: 'anonymous'}));
      }

      this.dbService.getKey('menu-main-menu').subscribe((menu) => {
        if (menu) {
          this.store.dispatch(new MenuLoadSuccess(menu));
        }
      });

      this.dbService.getKey('menu-app-legal-corporate').subscribe((menu) => {
        if (menu) {
          this.store.dispatch(new MenuLoadSuccess(menu));
        }
      });

      this.dbService.getKey('secondary-menu').subscribe((menu) => {
        if (menu) {
          this.store.dispatch(new MenuLoadSuccess(menu));
        }
      });

      this.store.dispatch(new MenuLoad('main-menu'));
      this.store.dispatch(new MenuLoad('menu-app-legal-corporate'));
      this.store.dispatch(new MenuLoad('secondary-menu'));
    });


  }

  ngAfterViewInit(): void {
    this.pwaService.init();
  }

  setupOAuth() {
    const oauthTokenUrl = this.appConfig.getConfig().oauth.tokenUrl;
    const oauthIssuer = this.appConfig.getConfig().oauth.issuer;
    const oauthUserInfoUrl = this.appConfig.getConfig().oauth.userInfoUrl;
    const oauthClientId = this.appConfig.getConfig().oauth.clientId;

    this.oauthModuleConfig.resourceServer.allowedUrls = [
      this.appConfig.getConfig().apiBaseUrl,
      this.appConfig.getConfig().adminUrl
    ];

    const authConfig: AuthConfig = {
      requireHttps: false,
      tokenEndpoint: oauthTokenUrl,
      userinfoEndpoint: oauthUserInfoUrl,
      issuer: oauthIssuer,
      clientId: oauthClientId,
      scope: 'openid profile email',
      oidc: true,
      skipSubjectCheck: true
    };
    this.oauthService.configure(authConfig);

    this.oauthService.events.pipe(
      filter(event => event.type === 'token_expires'),
      tap(() => this.oauthService.refreshToken()),
    ).subscribe();

    this.store.dispatch(new InitAuthenticationState());
    //console.log('this.oauthService.getRefreshToken()', this.oauthService.getRefreshToken());
    if (!!this.oauthService.getRefreshToken()) {
      const oauthService = this.oauthService;
      this.oauthService.refreshToken().then(function () {
        oauthService.loadUserProfile();
      });
    }

  }

  setIconRegistry() {
    this.matIconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icomoon/sprite.svg'));
  }

  onLogout() {
    this.store.dispatch(new Logout());
    this.store.dispatch(new Go({path: [homePath]}));
  }

  renewCookies() {
    this.cookiebotService.renew();
  }

  onSearchQueryChange(searchQuery: string) {

    //console.log('onSearchQueryChange searchQuery', searchQuery);
    this.universe$.pipe(
      take(1)
    ).subscribe(universe => {
      let isNumber: boolean = /^\d+$/.test(searchQuery);
      if (isNumber) {
        this.store.dispatch(new FindContentById(searchQuery));
      } else {
        //this.store.dispatch(new SetQuery(searchQuery));
        //console.log('universe', universe);
        if (universe == AppUniverses.home || !universe) {
          let userSearchRequestFilters: any = {};
          userSearchRequestFilters.q = searchQuery;
          this.router.navigate([RoutesConstants.encyclopediaPath, 'rechercher'], {queryParams: userSearchRequestFilters});
        } else {
          this.store.dispatch(new SetQuery(searchQuery));
        }
      }
    });
  }

}
