import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'quizType'
})
export class QuizTypePipe implements PipeTransform {


  types:any = {
    'P':'QCM de Positionnement',
    'C':'QCM de Certification',
    'E':'QCM d’Évaluation'
  };

  transform(value: any, args?: any): any {

    return this.types[value] !== null &&  this.types[value] !== undefined ? this.types[value] : value;
  }

}
