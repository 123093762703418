import {Component, Inject, NgModule, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {getBreakpoint} from "../../core/store/reducers";
import {BreadcrumbsService} from "../../core/services/breadcrumbs.service";
import {SearchResultService} from "../../search/search-result.service";
import {Observable} from "rxjs";
import {getUserSearchFilters} from "../../search/store";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {ROUTE_UNIVERSE} from "../../providers/route.providers";
import {filter, map} from "rxjs/operators";
import {encyclopediaPath, trainingPath} from "../../route-constants";

@Component({
  selector: 'ea-back-search-button',
  templateUrl: './back-search-button.component.html',
  styleUrls: ['./back-search-button.component.scss']
})
export class BackSearchButtonComponent implements OnInit {

  breakPoint$ = this.store.pipe(select(getBreakpoint));

  userFilters$: Observable<{ [filterId: string]: string[] | string }> = this.store.pipe(select(getUserSearchFilters));

  universePath$: Observable<string> = this.routeUniverse$.pipe(
    filter(universe => !!universe),
    map(universe => {
      if (universe === 'encyclopedia') {
        return '/' + encyclopediaPath;
      } else if (universe === 'training') {
        return '/' + trainingPath;
      }
    })
  );

  constructor(
    private store: Store<any>,
    public breadcrumbsService: BreadcrumbsService,
    private searchResultService: SearchResultService,
    @Inject(ROUTE_UNIVERSE) public readonly routeUniverse$: Observable<string>
  ) {
  }

  ngOnInit() {
  }

}

@NgModule({
  declarations: [
    BackSearchButtonComponent
  ],
  exports: [
    BackSearchButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule
  ]
})
export class BackSearchButtonModule {
}
