import {Pipe, PipeTransform} from '@angular/core';
import {Document} from "../../core/models/document";
import {DrupalFile} from "../../core/models/file";

@Pipe({
  name: 'resourceToDocument'
})
export class ResourceToDocumentPipe implements PipeTransform {

  transform(file: DrupalFile): Document {
    return file;
  }

}
