import {UIActions, UIActionTypes} from '../actions/ui.action';
import {BreadCrumb} from "../../models/breadcrumb";

/**
 * Created by benoitplatre on 25/06/2018.
 */

export interface UiState {
  homeActive?: boolean;
  breakPoint?: string;
  mainMenuOpen?: boolean;
  bookmarksMenuOpen?: boolean;
  showDomainWindow?: boolean;
  breadcrumbs?: BreadCrumb[];
  showLoginWindow?: boolean;
  universe?: string;
  navigatedModule?: any;
  navigatedTheme?: any;
  history?: any[];
  staticContentList?: any[];
}

const initialState: UiState = {
  homeActive: false,
  breakPoint: null,
  mainMenuOpen: false,
  bookmarksMenuOpen: false,
  showDomainWindow: false,
  breadcrumbs: [],
  showLoginWindow: false,
  universe: null,
  history: [],
  staticContentList: null
};

export function uiStateReducer(state: UiState = initialState,
                        action: UIActions): UiState {

  let breadCrumb: BreadCrumb;

  switch (action.type) {

    case UIActionTypes.HomeActive:
      return {
        ...state,
        homeActive: action.payload
      };

    case UIActionTypes.UpdateBreakpoint:
      return {
        ...state,
        breakPoint: action.payload
      };

    case UIActionTypes.UpdateMainMenuOpen:
      return {
        ...state,
        mainMenuOpen: action.payload
      };

    case UIActionTypes.UpdateBookmarksMenuOpen:
      return {
        ...state,
        bookmarksMenuOpen: action.payload
      };

    case UIActionTypes.ShowDomainWindow:
      return {
        ...state,
        showDomainWindow: true
      };

    case UIActionTypes.HideDomainWindow:
      return {
        ...state,
        showDomainWindow: false
      };

    case UIActionTypes.ShowLoginWindow:
      return {
        ...state,
        showLoginWindow: true
      };

    case UIActionTypes.HideLoginWindow:
      return {
        ...state,
        showLoginWindow: false
      };

    case UIActionTypes.SetUniverse:
      let universe: string = action.universe;
      return {
        ...state,
        universe: universe
      };

    case UIActionTypes.AddBreadCrumb:
      breadCrumb = action.payload as BreadCrumb;
      const level: number = action.level;
      // const newArray = [...state.breadcrumbs].splice(0, level-1);

      let newArray = [];
      state.breadcrumbs.forEach((iBreadcrumb, index) => {
        if(index < action.level){
          newArray.push(iBreadcrumb);
        }
      });
      newArray.push(breadCrumb);
      // const isNew: boolean = state.breadcrumbs.filter(bc => bc.node.id === breadCrumb.node.id).length === 0;
      return {
        ...state,
        breadcrumbs: newArray
      };

    case UIActionTypes.InsertBreadCrumb:
      breadCrumb = action.payload as BreadCrumb;
      const index: number = action.index;
      return {
        ...state,
        breadcrumbs: [
          ...state.breadcrumbs.slice(0, index),
          breadCrumb,
          ...state.breadcrumbs.slice(index)
        ]
      };

    case UIActionTypes.UpdateBreadCrumb:

      breadCrumb = action.payload as BreadCrumb;

      let breadcrumbs: BreadCrumb[] = state.breadcrumbs.map((item, index) => {
        if (item.node.id !== breadCrumb.node.id) {
          // This isn't the item we care about - keep it as-is
          return item
        }
        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...breadCrumb
        }
      });

      const staticContentList: any[] = breadcrumbs.length > 1 ? state.staticContentList : null;

      return {
        ...state,
        breadcrumbs: breadcrumbs,
        staticContentList
      };

    case UIActionTypes.ResetBreadCrumb:
      breadCrumb = action.payload as BreadCrumb;
      return {
        ...state,
        breadcrumbs: breadCrumb ? [breadCrumb] : [],
        staticContentList: null
      };

    /*case UIActionTypes.LoadModuleThemesSuccess:
      return {
        ...state,
        staticContentList: [action.module.formation_theme_foad, action.module.formation_theme_stage]
      };*/

    case UIActionTypes.UpdateStaticContents:
      return {
        ...state,
        staticContentList: action.contents
      };

    case UIActionTypes.ClearStaticContents:
      return {
        ...state,
        staticContentList: null
      };

    case UIActionTypes.AddToHistory:
      const history: any[] = [...state.history, action.content];
      if(history.length > 5){
        history.shift();
      }
      return {
        ...state,
        history: history
      };

    case UIActionTypes.ClearHistory:
      return {
        ...state,
        history: [],
        breadcrumbs: []
      };

    default:
      return state;
  }
}

export const getUniverse = (state: UiState) => state.universe;
