import {Pipe, PipeTransform} from '@angular/core';
import {Term} from "../../core/models/practice-content";
import {ContentTypes} from "../../core/models/node";

@Pipe({
  name: 'resourceToTerm'
})
export class ResourceToTermPipe implements PipeTransform {

  transform(value: any): Term[] {
    let terms: Term[] = [];
    if (Array.isArray(value)) {
      value.forEach(rawTerm => {
        terms.push(this.parseTerm(rawTerm));
      });
    } else {
      terms.push(this.parseTerm(value));
    }
    return terms.filter(term => !!term);
  }

  parseTerm(rawTerm: any): Term {
    if(rawTerm){
      return {
        type: ContentTypes.term,
        id: rawTerm.entity_id,
        label: rawTerm.label
      }
    }
    return null;
  }

}
