import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'contentToExtendedType'
})
export class ContentToExtendedTypePipe implements PipeTransform {

  transform(content: any): any {
    const type: string = content.type + ':default';
    return type;
  }

}
