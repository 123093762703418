// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {getRouterFlatData, getRouterIsSearch, getUniverse} from "../core/store/reducers";

export const ROUTE_UNIVERSE = new InjectionToken<Observable<string>>(
  'A stream to get the Universe id'
);

export const IS_SEARCH_ROUTE = new InjectionToken<Observable<boolean>>(
  'A stream to know if the Search Context is Active'
);


export const ROUTE_FLAT_DATA = new InjectionToken<Observable<any>>(
  'ROUTE_FLAT_DATA'
);

export const ROUTE_PROVIDERS: Provider[] = [
  {
    deps: [Store],
    provide: ROUTE_UNIVERSE,
    useFactory: getUniverseFactory
  },
  {
    deps: [Store],
    provide: IS_SEARCH_ROUTE,
    useFactory: isSearchRouteFactory
  },
  {
    deps: [Store],
    provide: ROUTE_FLAT_DATA,
    useFactory: getRouteFlatDataFactory
  }
];

export function getUniverseFactory(
  store: Store<any>
): Observable<string> {
  return store.pipe(select(getUniverse));
}

export function isSearchRouteFactory(
  store: Store<any>
): Observable<string> {
  return store.pipe(select(getRouterIsSearch));
}

export function getRouteFlatDataFactory(
  store: Store<any>
): Observable<any> {
  return store.pipe(select(getRouterFlatData));
}
