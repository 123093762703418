import {Pipe, PipeTransform} from '@angular/core';
import {ContentType, DrupalNode} from "../../core/models/node";
import * as moment from 'moment';
import {Image} from "../../core/models/image";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {HtmlCleanupPipe} from "../html-cleanup.pipe";
import {ResourceToTermsPipe} from "./resource-to-terms.pipe";
import {Document} from "../../core/models/document";

@Pipe({
  name: 'resourceToDrupalNode'
})
export class ResourceToDrupalNodePipe implements PipeTransform {

  constructor(
    private resourceToMediaPipe: ResourceToMediaPipe,
    private resourceToTermsPipe: ResourceToTermsPipe,
    private htmlCleanupPipe: HtmlCleanupPipe
  ) {
  }


  transform(value: any): DrupalNode {
    if (value) {

      const id: string = value.nid || value.id;
      const type: ContentType = value.type;
      const sticky: boolean = value.sticky === '1';
      const published: boolean = value.status === '1' || value.status === true;
      const promoted: boolean = value.promote === '1';
      const restricted: boolean = value.hasOwnProperty('restricted') ? value.restricted === true : null;
      let changed: number = null;
      if (value.hasOwnProperty('changed')) {
        changed = (value.changed.indexOf('T') === -1) ? parseInt(value.changed ) : moment(value.changed).unix() * 1000;
      }
      const title: string = value.title;
      const editable: boolean = value.editable;
      const extendedType: string = value.extended_type;
      let body: string;
      if (value.body) {
        body = (value.body?.value) ? value.body?.value : value.body;
      } else if (value.description) {
        body = value.description;
      }
      if (body) {
        body = this.htmlCleanupPipe.transform(body);
      }
      let summary: string = (value.body?.summary) ? value.body?.summary : value.summary;
      if (summary) {
        summary = this.htmlCleanupPipe.transform(summary);
      }
      const editUrl: string = value.edit_url;
      const author: string = value.author?.label;
      const path: string = 'node/' + id;
      const thumbnail: Image = this.resourceToMediaPipe.transform(value.thumbnail) as Image;

      let files: Document[];
      if (value?.medias) {
        files = value?.medias.map(media => {
          return this.resourceToMediaPipe.transform(media);
        }).filter(file => !!file);
      }

      const nodeWithTerms: any = this.resourceToTermsPipe.transform(value);

      return {
        id,
        type,
        sticky,
        published,
        promoted,
        changed,
        title,
        body,
        editable,
        extendedType,
        editUrl,
        author,
        summary,
        path,
        thumbnail,
        files,
        restricted,
        ...nodeWithTerms
      };

    }

    return null;

  }

}
