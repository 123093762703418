import {
  SubmitActionError,
  WEBFORM_CLEAR_STATE,
  WEBFORM_RESET_ALL,
  WEBFORM_SUBMIT,
  WEBFORM_SUBMIT_ERROR,
  WEBFORM_SUBMIT_FAIL,
  WEBFORM_SUBMIT_SUCCESS,
  WebformAction,
  WebformErrors
} from '../actions/webforms.action';
import {AppError} from "@madeinlune/components";


export class WebformsState {
  [webformId: string]: WebformState;
}

export class WebformState {
  loading: boolean;
  loaded: boolean;
  success: boolean;
  validationErrors: WebformErrors;
  error: AppError;
}

const initialState: WebformsState = {};
const initialWebformState: WebformState = {
  loading: false,
  loaded: false,
  success: false,
  validationErrors: null,
  error: null
};

export function reducer(state: WebformsState = initialState, action: WebformAction): WebformsState {

  if (!action.webformId) {
    switch (action.type) {
      case WEBFORM_RESET_ALL:
        return initialState;
    }
    return state;
  }

  return {
    ...state,
    [action.webformId]: webformStateReducer(state[action.webformId], action)
  };

}

export function webformStateReducer(state: WebformState = initialWebformState, action: WebformAction): WebformState {

  switch (action.type) {

    case WEBFORM_CLEAR_STATE: {
      return initialWebformState;
    }

    case WEBFORM_SUBMIT: {
      return {
        ...state,
        loaded: false,
        loading: true,
        validationErrors: null,
        error: null
      };
    }

    case WEBFORM_SUBMIT_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        success: true,
        validationErrors: null,
        error: null
      };
    }

    case WEBFORM_SUBMIT_ERROR: {
      return {
        ...state,
        loaded: true,
        loading: false,
        success: false,
        validationErrors: (action as SubmitActionError).validationErrors,
        error: null
      };
    }

    case WEBFORM_SUBMIT_FAIL: {
      return {
        ...state,
        loaded: false,
        loading: false,
        success: false,
        validationErrors: null,
        error: {message:'WebForm error'}
      };
    }

  }

  return state;

}

export const getWebFormById = (state: WebformsState, nid: string) => state[nid];

export const getWebFormLoading = (state: WebformsState, nid: string) => {
  const loading = state && state[nid] ? state[nid].loading : false;
  return loading;
};

export const getWebFormError = (state: WebformsState, nid: string) => {
  const error = state && state[nid] ? state[nid].error : false;
  return error;
};

export const getWebFormSuccess = (state: WebformsState, nid: string) => {
  const success = state && state[nid] ? state[nid].success : false;
  return success;
};
