import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BreadCrumb} from "../models/breadcrumb";
import {select, Store} from "@ngrx/store";
import {getBackBreadCrumb, getBreadCrumbs, getRouterQueryParamsBc, hasBackBreadCrumbWithNid} from "../store/reducers";
import {filter, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  public breadCrumbs$: Observable<BreadCrumb[]> = this.store.pipe(
    select(getBreadCrumbs)
  );

  public backBreadCrumb$: Observable<BreadCrumb> = this.breadCrumbs$.pipe(
    map(breadcrumbs => {
      return (breadcrumbs && breadcrumbs.length > 1) ? breadcrumbs[breadcrumbs.length - 2] : null
    })
  );

  public backBreadCrumbIsSearch$: Observable<boolean> = this.backBreadCrumb$.pipe(
    filter(breadcrumb => !!breadcrumb && !!breadcrumb.node),
    map(breadcrumb => breadcrumb.node),
    filter(node => {
      return node.type === 'page';
    }),
    map(node => node.app_context_id === 'encyclopedia' || node.app_context_id === 'training')
  );

  public breadCrumbsToQueryParams$: Observable<any> = this.store.pipe(
    select(getBreadCrumbs),
    map(breadCrumbs => {
      return breadCrumbs.map(breadCrumb => {
        return breadCrumb.node && breadCrumb.node.id;
      })
    })
  );

  public hasBreadCrumbs$: Observable<boolean> = this.breadCrumbs$.pipe(
    map(breadCrumbs => breadCrumbs.length > 0)
  );

  public hasDeepBreadCrumbs$: Observable<boolean> = this.breadCrumbs$.pipe(
    map(breadCrumbs => breadCrumbs.length > 1)
  );

  public hasBcParams$: Observable<boolean> = this.store.pipe(
    select(getRouterQueryParamsBc)
  ).pipe(
    map(paramBc => !!paramBc)
  );

  constructor(
    private store: Store<any>,
  ) {
  }

  hasBackBreadCrumbWithNid(nid: string): Observable<boolean> {
    return this.store.pipe(
      select(hasBackBreadCrumbWithNid(nid))
    );
  }
}
