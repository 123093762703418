import {Injectable} from '@angular/core';
import {Action, select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import * as fromRoot from '../../../store/reducers';
import {
  GoNextStep,
  ResetStep,
  SetContext,
  SetMode,
  SetStep,
  SubscriptionActionTypes,
  SubscriptionFinished
} from "../actions/subscription.actions";
import {SubscriptionContext, SubscriptionMode, SubscriptionStep} from "../../models/subcription-context.model";
import {getContext, getCurrentMode, getCurrentStep} from "../reducers/subscription.reducers";
import {LOAD_USER_INFOS_SUCCESS, UPDATE_USER_SUCCESS, UserAction} from "../../../core/store/actions/user.action";
import {CheckLicenceSuccess, SiFfaActionTypes} from "../../../core/store/actions/si-ffa.actions";
import {getUserInfos} from "../../../core/store/reducers";
import {User} from "../../../core/models/user";

@Injectable()
export class SubscriptionEffects {

  
  setContext$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActionTypes.SetContext),
    withLatestFrom(this.store.pipe(select(getContext))),
    switchMap(([action, context]: [SetContext, SubscriptionContext]) => {
      let actions: any[] = [];
      if (context.name === SubscriptionContext.public) {
        actions.push(new SetMode(SubscriptionContext.modeChoice));
        actions.push(new SetStep(context.modes[SubscriptionContext.modeChoice].steps[0]));
      } else if (context.name === SubscriptionContext.ssiFfa) {
        actions.push(new SetMode(SubscriptionContext.modeSiFfa));
        actions.push(new SetStep(context.modes[SubscriptionContext.modeSiFfa].steps[0]));
      }
      return actions;
    })
  ));

  
  setMode$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActionTypes.SetMode),
    withLatestFrom(this.store.pipe(select(getContext))),
    switchMap(([action, context]: [SetMode, SubscriptionContext]) => {
      let actions: any[] = [];
      if (!action.preserveStep) {
        actions.push(new ResetStep());
        actions.push(new GoNextStep());
      }
      return actions;
    })
  ));

  
  goNextStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SubscriptionActionTypes.GoNextStep),
    withLatestFrom(
      this.store.pipe(select(getCurrentStep), filter(step => !!step)),
      this.store.pipe(select(getContext)),
      this.store.pipe(select(getCurrentMode))
    ),
    map(([action, currentStep, context, mode]: [GoNextStep, SubscriptionStep, SubscriptionContext, SubscriptionMode]) => {
      // TODO remove context, seems unnecessary
      if (!currentStep) {
        return new SetStep(mode.steps[0]);
      } else {
        const stepIndex: number = mode.steps.indexOf(currentStep);
        const nextIndex: number = Math.min(mode.steps.length - 1, stepIndex + 1);
        return new SetStep(mode.steps[nextIndex]);
      }
      return null;
    })
  ));

  //TODO check this reaction is not called everytime user authenticates
  
  userAccountCreationSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(LOAD_USER_INFOS_SUCCESS, UPDATE_USER_SUCCESS),
    withLatestFrom(
      this.store.pipe(select(getCurrentMode)),
      this.store.pipe(select(getCurrentStep)),
      this.store.pipe(select(getUserInfos))
    ),
    map(([action, mode, step, userInfos]: [UserAction, SubscriptionContext, SubscriptionStep, User]) => {
      if(step && (step.name !== SubscriptionContext.stepFinish)){
        if (userInfos.birthDate && userInfos.ffaLicenseValid == false) {
          return new SetStep(step);
        } else {
          return new GoNextStep();
        }
      }else{
        return new SubscriptionFinished();
      }
    })
  ));

  
  userLicenceValidated: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(SiFfaActionTypes.CheckLicenceSuccess),
    map((action: CheckLicenceSuccess) => {
      return new GoNextStep();
    })
  ));

  constructor(private actions$: Actions, private store: Store<fromRoot.State>) {
  }
}
