import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {InitCreateAccount} from '../../core/store/actions/user.action';

@Injectable()
export class AppQueries {

  constructor(private store: Store<any>) {
  }

  globalLoading$ = combineLatest([
      this.store.pipe(select((state: any) => state.drupalAuth && state.drupalAuth.loading)),
      this.store.pipe(select((state: any) => state.core && state.core.path.loading)),
      this.store.pipe(select((state: any) => state.core && state.core.node.loading)),
      this.store.pipe(select((state: any) => state.core && state.core.menu.loading)),
      this.store.pipe(select((state: any) => state.core && state.core.user.loading)),
      this.store.pipe(select((state: any) => state.search && state.search.loading))
    ]
  ).pipe(
    map(values => {
      return values.filter((item) => item).length > 0;
    })
  );

  contextLoading$ = combineLatest([
      this.store.pipe(select((state: any) => state.core && state.core.node.loading)),
      this.store.pipe(select((state: any) => state.search && state.search.loading))
    ]
  ).pipe(
    map(values => {
      return values.filter((item) => item).length > 0;
    })
  );

  errors$ = combineLatest([
      this.store.pipe(select((state: any) => state.drupalAuth.error)),
      this.store.pipe(select((state: any) => state.core && state.core.path.error)),
      this.store.pipe(select((state: any) => state.core && state.core.node.error)),
      this.store.pipe(select((state: any) => state.core && state.core.menu.error)),
      this.store.pipe(
        select((state: any) => state.core && state.core.user.error),
        tap((error) => {
          if (error) {
            this.store.dispatch(new InitCreateAccount());
          }
        })
      ),
      this.store.pipe(select((state: any) => state.search && state.search.error))
    ]
  ).pipe(
    map(values => {
      return values.filter((item) => !!item);
    })
  );

  authContextLoading$ = combineLatest([
      this.store.pipe(select((state: any) => state.drupalAuth && state.drupalAuth.loading)),
      this.store.pipe(select((state: any) => state.core && state.core.user.loading))
    ]
  ).pipe(
    map(values => {
      return values.filter((item) => item).length > 0;
    })
  );

}
