import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../core/models/user";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {UpdateUser} from "../../../core/store/actions";
import {State, Store} from "@ngrx/store";

@Component({
  selector: 'ea-user-licence-form',
  templateUrl: './user-licence-form.component.html',
  styleUrls: ['./user-licence-form.component.scss']
})
export class UserLicenceFormComponent implements OnInit {

  form: FormGroup;

  get userInfos(): User {
    return this._userInfos;
  }

  private _userInfos: User;

  @Input()
  set userInfos(userInfos: User) {
    this._userInfos = userInfos;
    if(this._userInfos){
      this.form.patchValue({ffaLicenseNumber: this._userInfos.ffaLicenseNumber});
      this.invalidateUserInfos();
    }
  }

  subs: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<State<any>>
  ) {
    this.form = this.fb.group({
        ffaLicenseNumber: new FormControl('', [
          Validators.required
        ])
      }
    );
  }

  ngOnInit() {

  }

  invalidateUserInfos(){
    let sub: Subscription = this.form.valueChanges.pipe(
      debounceTime(750),
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      })
    ).subscribe(formValue => {
      this.userInfos.ffaLicenseNumber = formValue.ffaLicenseNumber;
      this.store.dispatch(new UpdateUser(this.userInfos));
    });

    this.subs.push(sub);
  }

}
