import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PathService} from './services/path.service';
import {AppComponent} from './containers/app.component';
import {EffectsModule} from '@ngrx/effects';
import {RouterModule} from '@angular/router';
import {StoreModule} from '@ngrx/store';
import {HttpClient} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {MetaTagsService} from './services/metatags.service';
import {NodeService} from './services/node.service';
import {
  ContextsEffects,
  ECommerceEffects,
  MenuEffects,
  NodeEffects,
  PathEffects,
  SiFfaEffects,
  TaxonomyEffects,
  UiEffects,
  UserEffects,
  WebformsEffect
} from './store';
import {AppHeaderComponent} from "./components/app-header/app-header.component";
import {AppFooterComponent} from "./components/app-footer/app-footer.component";
import {AppConfigService} from '../app-config.service';
import {AppMenuComponent} from "./components/app-menu/app-menu.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {SecureLinkService} from './services/secure-link-service';
import {DrupalAuthModule, MilAuthenticationModule} from "@madeinlune/components";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatBadgeModule} from "@angular/material/badge";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {CONTENT_PIPES, PipesModule} from "../pipes/pipes.module";
import {TransversalSearchFieldModule} from "../components/transversal-search-field/transversal-search-field.component";
import {UserWindowModule} from "../components/user-window/user-window.component";
import {SubscriptionResolver} from "../subscription/subscription-resolver.service";
import {SubscriptionModule} from "../subscription/subscription.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {BackSearchButtonModule} from "../components/back-search-button/back-search-button.component";
import * as fromCore from './store/reducers';
import * as fromSiFFA from "./store/reducers/si-ffa.reducers";
import {MENU_STATES_PROVIDERS} from "../providers/menu-states.providers";
import {USER_PROVIDERS} from "../providers/user.providers";
import {CONTENTS_PROVIDERS} from "../providers/contents.providers";
import {AUTHENTICATED_PROVIDERS} from "../providers/authentication.providers";
import {ROUTE_PROVIDERS} from "../providers/route.providers";
import {RESPONSIVE_PROVIDERS} from "../providers/responsive.providers";
import {NodeNewEffects} from "./store/effects/node.effects";
import * as fromDrupalNode from './drupal-node/drupal-node.reducer';
import {CONTEXTS_PROVIDERS} from "../providers/contexts.providers";

export const COMPONENTS = [
  AppComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppMenuComponent
];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    StoreModule.forFeature('core', fromCore.reducers),
    StoreModule.forFeature('siFFA', fromSiFFA.reducer),
    EffectsModule.forFeature([
      ContextsEffects,
      NodeEffects,
      PathEffects,
      MenuEffects,
      TaxonomyEffects,
      UserEffects,
      WebformsEffect,
      ECommerceEffects,
      SiFfaEffects,
      UiEffects,
      NodeNewEffects
    ]),
    RouterModule,
    OAuthModule,
    DrupalAuthModule,
    PipesModule,
    MilAuthenticationModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    TransversalSearchFieldModule,
    UserWindowModule,
    SubscriptionModule,
    MatProgressSpinnerModule,
    BackSearchButtonModule,
    StoreModule.forFeature(fromDrupalNode.drupalNodesFeatureKey, fromDrupalNode.reducer)
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    SubscriptionResolver,
    MENU_STATES_PROVIDERS,
    USER_PROVIDERS,
    CONTENTS_PROVIDERS,
    AUTHENTICATED_PROVIDERS,
    ROUTE_PROVIDERS,
    RESPONSIVE_PROVIDERS,
    CONTENT_PIPES,
    CONTEXTS_PROVIDERS
  ]
})

export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {provide: PathService, useClass: PathService, deps: [HttpClient, AppConfigService]},
        {provide: NodeService, useClass: NodeService, deps: [HttpClient, AppConfigService]},
        {provide: MetaTagsService, useClass: MetaTagsService, deps: [Meta, Title]},
        {
          provide: SecureLinkService,
          useClass: SecureLinkService,
          deps: [OAuthService, HttpClient, AppConfigService]
        },
      ],
    };
  }
}
