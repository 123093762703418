import {Pipe, PipeTransform} from '@angular/core';
import {DrupalNode} from "../../core/models/node";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {HtmlCleanupPipe} from "../html-cleanup.pipe";
import {Seance} from "../../core/models/seance";
import {Situation} from "../../core/models/situation";
import {ResourceToSituationPipe} from "./resource-to-situation.pipe";

@Pipe({
  name: 'resourceToSeance'
})
export class ResourceToSeancePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToSituationPipe: ResourceToSituationPipe,
    private resourceToMediaPipe: ResourceToMediaPipe,
    private htmlCleanupPipe: HtmlCleanupPipe
  ) {
  }

  transform(value: any): Seance {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);

    const goal: string = value.description ? this.htmlCleanupPipe.transform(value.description) : null;
    const procedure: string = value.procedure ? this.htmlCleanupPipe.transform(value.procedure) : null;
    const general: string = value.general ? this.htmlCleanupPipe.transform(value.general) : null;

    let seanceBefore: Situation[] = null;
    if (value.seanceBefore) {
      seanceBefore = value.seanceBefore.map(situation => this.resourceToSituationPipe.transform(situation));
    }

    let seanceBody: Situation[] = null;
    if (value.seanceBody) {
      seanceBody = value.seanceBody.map(situation => this.resourceToSituationPipe.transform(situation));
    }

    let seanceAfter: Situation[] = null;
    if (value.seanceAfter) {
      seanceAfter = value.seanceAfter.map(situation => this.resourceToSituationPipe.transform(situation));
    }

    const seance: Seance = {
      ...drupalNode
    }

    if (goal) {
      seance.goal = goal;
    }
    if (procedure) {
      seance.procedure = procedure;
    }

    if (general) {
      seance.general = general;
    }

    if (seanceBefore) {
      seance.seanceBefore = seanceBefore;
    }

    if (seanceBody) {
      seance.seanceBody = seanceBody;
    }

    if (seanceAfter) {
      seance.seanceAfter = seanceAfter;
    }

    return seance;
  }

}
