import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'replaceNbsp'
})
@Injectable()
export class ReplaceNbspPipe implements PipeTransform {

  constructor() {

  }

  transform(text: string): any {
    const re = new RegExp('&nbsp;', 'g');
    return text.replace(re, '');
  }

}
