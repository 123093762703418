import {Pipe, PipeTransform} from '@angular/core';
import {MultiSituation, MultiType} from "../../core/models/multi-situations";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {DrupalNode} from "../../core/models/node";
import {Situation} from "../../core/models/situation";
import {ResourceToSituationPipe} from "./resource-to-situation.pipe";
import {HtmlCleanupPipe} from "../html-cleanup.pipe";
import {Image} from "../../core/models/image";

@Pipe({
  name: 'resourceToMultiSituations'
})
export class ResourceToMultiSituationsPipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToSituationPipe: ResourceToSituationPipe,
    private resourceToMediaPipe: ResourceToMediaPipe,
    private htmlCleanupPipe: HtmlCleanupPipe
  ) {
  }

  transform(value: any): MultiSituation {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    let situations: Situation[] = null;
    if (value.situations) {
      situations = value.situations.map(situation => this.resourceToSituationPipe.transform(situation));
    }
    const multiType: MultiType = value.multiType;
    const goal: string = value.description ? this.htmlCleanupPipe.transform(value.description) : null;
    let generalView: Image = null;
    if (value.general_view && Array.isArray(value.general_view) && value.general_view.length > 0) {
      generalView = this.resourceToMediaPipe.transform(value.general_view[0]) as Image;
    }

    const procedure: string = value.procedure ? this.htmlCleanupPipe.transform(value.procedure) : null;
    const general: string = value.general ? this.htmlCleanupPipe.transform(value.general) : null;
    const educativeContents: string = value.educativeContents ? this.htmlCleanupPipe.transform(value.educativeContents) : null;

    return {
      ...drupalNode,
      goal,
      multiType,
      situations,
      generalView,
      procedure,
      general,
      educativeContents
    };
  }

}
