import {Pipe, PipeTransform} from '@angular/core';
import {FieldInfos} from "../core/models/field-infos";
import {ContentTypesConstants} from "../core/models/content-types-constants";

@Pipe({
  name: 'contentTypeToLabel'
})
export class ContentTypeToLabelPipe implements PipeTransform {

  transform(value: string, practicalType?: string, args?: any): any {
    let fieldInfos: FieldInfos;
    if(value.indexOf(':') > -1){
      value = value.split(':')[0];
    }
    if(practicalType){
      fieldInfos = ContentTypesConstants.map[practicalType]
    }else{
      fieldInfos = ContentTypesConstants.map[value]
    }
    let label: string;
    if(fieldInfos){
      label = fieldInfos.label;
    }
    return label;
  }

}
