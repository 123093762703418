import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, Params} from "@angular/router";
import {map} from "rxjs/operators";

@Pipe({
  name: 'queryParamsActive'
})
export class QueryParamsActivePipe implements PipeTransform {

  queryParams$: Observable<Params> = this.activatedRoute.queryParams;

  constructor(private activatedRoute: ActivatedRoute) {

  }

  transform(value: string, key: string): Observable<boolean> {
    return this.queryParams$.pipe(
      map((queryParams: Params) => {
        let values: string[];
        if (queryParams[key]) {
          if (Array.isArray(queryParams[key])) {
            values = [...queryParams[key]];
          } else {
            values = [queryParams[key]];
          }
          return values.indexOf(value) > -1;
        } else {
          return false;
        }
      })
    );
  }

}
