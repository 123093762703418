import {Pipe, PipeTransform} from '@angular/core';
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode} from "../../core/models/node";
import {News} from "../../core/models/news";

@Pipe({
  name: 'resourceToNews'
})
export class ResourceToNewsPipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
  ) {
  }

  transform(value: any): News {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    return {
      ...drupalNode
    };
  }

}
