import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '../../app-config.service';
import {User} from "../models/user";
import {LicenceInfos} from "../models/licence-infos";


@Injectable({
  providedIn: 'root'
})

export class SiFFAService {

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService
  ) {
  }

  public checkLicence(userInfos: User, licenceInfos: LicenceInfos): Observable<any> {

    const body: any = {
      firstName: userInfos.firstName,
      lastName: userInfos.lastName,
      gender: userInfos.gender
    };

    if(licenceInfos){
      body.licenseNumber = String(licenceInfos.ffaLicenseNumber);
      body.birthDate = licenceInfos.birthDate;
    }

    return this.httpClient.post(
      this.appConfig.getConfig().apiBaseUrl + '/user/' + userInfos.id + '/license',
      body
    );
  }

}
