import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {LinesPipe} from "ngx-pipes";

@Pipe({
  name: 'cleanText'
})
@Injectable()
export class CleanTextPipe implements PipeTransform {

  constructor(
    private lines: LinesPipe
  ) {

  }

  transform(text: string): any {
    if(text){
      const lines: string[] = this.lines.transform(text).filter(
        lineText => {
          return lineText !== '<p></p>' && lineText !== '<p>&nbsp;</p>' && lineText.indexOf('<script') === -1 && lineText !== '';
        }
      );
      return lines.join('');
    }
    return null;
  }

}
