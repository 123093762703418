import {Inject, Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {RemoveAttacksPipe} from "./remove-attacks.pipe";
import {CleanTextPipe} from "./clean-texts.pipe";
import {DOCUMENT} from "@angular/common";
import {ReplaceNbspPipe} from "./replace-nbsp.pipe";
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'htmlCleanup'
})
export class HtmlCleanupPipe implements PipeTransform {

  constructor(
    private cleanTextPipe: CleanTextPipe,
    private removeAttacksPipe: RemoveAttacksPipe,
    private replaceNbspPipe: ReplaceNbspPipe,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  transform(value: string): string {
    let div: HTMLDivElement = this.document.createElement('div');
    const contentWithRemovedAttacks = this.removeAttacksPipe.transform(value);
    div.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, contentWithRemovedAttacks);
    const scriptTags = div.getElementsByTagName('script');
    let i = scriptTags.length;
    while (i--) {
      scriptTags[i].parentNode.removeChild(scriptTags[i]);
    }
    div.querySelectorAll('*').forEach(node => {
      node.removeAttribute('style');
      node.removeAttribute('class');
    });
    return div.innerHTML.replace('<p>&nbsp;</p>\n', '');
  }

}
