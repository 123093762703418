import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Store} from "@ngrx/store";
import * as fromCore from "./core/store/reducers";
import {SearchService} from "./search/search.service";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {DrupalNode} from "./core/models/node";
import {RouteProperties, RoutesConstants} from "./core/models/routes-constants";
import {SearchResult} from "./search/models/search-result";
import {map, take} from "rxjs/operators";
import {ErrorWindowComponent} from "./components/error-window/error-window.component";
import {Go} from "./store/actions";
import {homePath} from "./route-constants";

@Injectable({
  providedIn: 'root'
})
export class OldFichePathGuard implements CanActivate {

  constructor(
    private store: Store<fromCore.State>,
    private searchService: SearchService,
    private dialog: MatDialog,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const nid: string = next.params.nid;
    this.searchService.findContentById(nid).pipe(
      map((searchResult: SearchResult) => {
        let node: DrupalNode;
        let nodeType: string;
        let urlTree: UrlTree;
        let path: any[];
        if (searchResult.count === 1 && searchResult.items.length > 0) {
          node = searchResult.items[0];
          nodeType = node.type;
        }
        let routes: RouteProperties = RoutesConstants.contentTypeRoutesMap[nodeType];
        if (routes) {
          const universePath: string = routes.universe;
          const collectionPath: string = routes.list;
          const detailPath: string = routes.detail;
          const fullPath: string = '/' + universePath + '/' + collectionPath + '/' + detailPath + '/' + node.id;
          path = [universePath, collectionPath, detailPath, node.id];
        } else {
          this.dialog.open(ErrorWindowComponent, {
            width: '500px',
            panelClass: 'user-theme',
            data: {
              title: 'Aucun contenu pour cet ID',
              message: 'Désolé, aucun contenu corresponds à cet ID : ' + nid,
              actions: ['OK']
            }
          });
          path= [homePath];
        }
        this.store.dispatch(new Go({path:path}))
      }),
      take(1)
    ).subscribe();
    return true;
  }
}
