export const homePath: string = 'bienvenue';
export const trainingPath: string = 'espace-formation';
export const encyclopediaPath: string = 'espace-encyclopedique';
export const themePath: string = 'espace-thematique';
export const myAccountPath: string = 'mon-compte';
export const loginPath: string = 'me-connecter';
export const domainsPath: string = 'mes-domaines-et-filieres';
export const contactPath: string = 'contact';
export const aPropos: string = 'apropos';
export const tutoriels: string = 'tutoriels';
export const faq: string = 'faq';
export const credits: string = 'credits';
export const mentionsLegales: string = 'mentions-legales';
export const subscription: string = 'je-m-inscris';
export const myBookmarksPath: string = 'mes-favoris';
export const userAccountOutlet: string = 'userAccountOutlet';
export const userLoginOutlet: string = 'userLoginOutlet';
export const genericOutlet: string = 'popUp';
export const trainingCheckIn: string = 'formation-check-in';
export const quizSiFfa: string = 'quiz-session';
export const traineePath: string = 'mon-espace-stagiaire';
export const trainingToolsPath: string = 'outils-de-formation';
export const encyclopedicalToolsPath: string = 'outils-encyclopediques';
