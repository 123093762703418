import {Pipe, PipeTransform} from '@angular/core';
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode} from "../../core/models/node";
import {Quiz} from "../../core/models/quiz";

@Pipe({
  name: 'resourceToQuiz'
})
export class ResourceToQuizPipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe
  ) {
  }

  transform(value: any): Quiz {

    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    return {
      ...drupalNode
    };
  }

}
