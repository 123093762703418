import {DrupalNode} from "./node";
import {Video} from "./video";
import {FieldInfos, FieldInfosMap} from "./field-infos";
import {Image} from "./image";
import {PlatformContent, PracticeContent} from "./practice-content";

/**
 * Created by benoitplatre on 27/08/2018.
 */
export interface Situation extends DrupalNode, PlatformContent, PracticeContent {

  infos?: SituationParagraphs;
  video?: Video;
  videos?: Video[];
  images?: Image[];

  // deprecated
  situation_comportement?: string;
  situation_consignes?: string;
  situation_criteres?: string;
  situation_descriptif?: string;
  situation_evolution?: string;
  situation_fautes?: string;
  organisationInfos?: SituationOrganisationParagraphs;
  organisation_astuces?: string;
  organisation_infos?: string;
  organisation_materiel?: string;
  organisation_securite?: string;
  but?: string;
  variants?: SituationVariant[];
  situation_nom?: string;
  age_level_from?: string;
  age_level_to?: string;

}

export interface SituationOrganisationParagraphs {
  astuces?: SituationParagraph;
  infos?: SituationParagraph;
  materiel?: SituationParagraph;
  securite?: SituationParagraph;
}

export interface SituationParagraphs {
  comportement?: SituationParagraph;
  consignes?: SituationParagraph;
  criteres?: SituationParagraph;
  descriptif?: SituationParagraph;
  evolution?: SituationParagraph;
  fautes?: SituationParagraph;
  but?: SituationParagraph;
}

export interface SituationParagraph {
  index: number;
  label: string;
  content: string;
}

export interface SituationVariant {
  label: string;
  critere: string;
  consigne: string;
  video: Video;
}

export class SituationsConstants {

  static readonly description: FieldInfos = {name: 'situation_descriptif', label: 'Description', finalName: 'descriptif'};
  static readonly comportement: FieldInfos = {name: 'situation_comportement', label: 'Comportement', finalName: 'comportement'};
  static readonly consignes: FieldInfos = {name: 'situation_consignes', label: 'Consignes', finalName: 'consignes'};
  static readonly criteres: FieldInfos = {name: 'situation_criteres', label: 'Critères', finalName: 'criteres'};
  static readonly fautes: FieldInfos = {name: 'situation_fautes', label: 'Fautes', finalName: 'fautes'};
  static readonly but: FieldInfos = {name: 'but', label: 'But', finalName: 'but'};
  static readonly evolution: FieldInfos = {name: 'situation_evolution', label: 'Évolution', finalName: 'evolution'};
  static readonly materiel: FieldInfos = {name: 'organisation_materiel', label: 'Matériel', finalName: 'materiel'};
  static readonly astuces: FieldInfos = {name: 'organisation_astuces', label: 'Astuces', finalName: 'astuces'};
  static readonly securite: FieldInfos = {name: 'organisation_securite', label: 'Sécurité', finalName: 'securite'};
  static readonly infos: FieldInfos = {name: 'organisation_infos', label: 'En savoir plus', finalName: 'infos'};

  static readonly map:FieldInfosMap = {
    description: SituationsConstants.description,
    comportement: SituationsConstants.comportement,
    consignes: SituationsConstants.consignes,
    fautes: SituationsConstants.fautes,
    but: SituationsConstants.but,
    criteres: SituationsConstants.criteres,
    evolution: SituationsConstants.evolution,
    materiel: SituationsConstants.materiel,
    astuces: SituationsConstants.astuces,
    securite: SituationsConstants.securite,
    infos: SituationsConstants.infos
  }

}
