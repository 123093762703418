import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import * as fromCore from "./core/store/reducers";
import {Go} from "./store/actions";
import {Logout} from "@madeinlune/components";
import {trainingCheckIn} from "./route-constants";

@Injectable({
  providedIn: 'root'
})
export class SiFfaGuard implements CanActivate {

  constructor(
    private store: Store<fromCore.State>
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (next.queryParams.HCLE) {
      this.store.dispatch(new Logout());
      let hcle: string = next.queryParams.HCLE;
      this.store.dispatch(new Go({
        path: [trainingCheckIn],
        extras: {queryParams: {pass: hcle}, queryParamsHandling: 'merge'}
      }));
    }
    return true;
  }
}
