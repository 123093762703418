/**
 * Created by benoitplatre on 25/06/2018.
 */
import {RouterStateSerializer} from '@ngrx/router-store';
import {ActivatedRouteSnapshot, Params, RouterStateSnapshot} from '@angular/router';
import {Injectable} from "@angular/core";

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and query parameters from the snapshot in the store. Other items could be
 * returned such as route parameters and static route data.
 */

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  flatParams: Params;
  flatData: any;
  data: any;
}

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    //console.log('routerState', routerState);

    let route: ActivatedRouteSnapshot = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const routeParams: any = {flatParams: {}};
    this.parseChidrenRoutes(routeParams, routerState.root, 'params', 'flatParams');

    const routeData: any = {flatData: {}};
    this.parseChidrenRoutes(routeData, routerState.root, 'data', 'flatData');

    const {url, root: {queryParams}} = routerState;
    const {params} = route;
    const {flatParams} = routeParams;
    const {flatData} = routeData;
    const {data} = route;

    //console.log('route', route);

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return {url, params, queryParams, flatParams, data, flatData};

  }

  parseChidrenRoutes(routeParams: any, activatedRouteSnapshot: ActivatedRouteSnapshot, mainKey: string, flatKey: string){
    for (let i = 0; i < activatedRouteSnapshot.children.length; i++) {
      const childRoute: ActivatedRouteSnapshot = activatedRouteSnapshot.children[i];
      let childParams: any;
      if (childRoute[mainKey]) {
        childParams = childRoute[mainKey];
        Object.keys(childRoute[mainKey]).forEach(key => {
          routeParams[flatKey][key] = childRoute[mainKey][key];
        })
      }
      if(childRoute.children){
        this.parseChidrenRoutes(routeParams, childRoute, mainKey, flatKey);
      }
    }
  }

}

