import {ComponentFactoryResolver, Injectable, Injector, TemplateRef, Type} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {filter, map, shareReplay, take, tap} from "rxjs/operators";
import {ContentTypesConstants} from "./core/models/content-types-constants";
import {UserSubscriptionComponent} from "./subscription/containers/user-subscription/user-subscription.component";
import {PreferenceWindowComponent} from "./components/preference-window/preference-window.component";
import {UserWindowComponent} from "./components/user-window/user-window.component";
import {PageComponent} from "./components/page/page.component";
import {PasswordRecoveryComponent} from "./components/password-recovery/password-recovery.component";
import {PaymentFailComponent} from "./components/payment-fail/payment-fail.component";
import {PaymentSuccessComponent} from "./components/payment-success/payment-success.component";


@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  //lzl for lazy loading...
  static readonly lzlLogin: string = 'login';
  static readonly lzlSubscription: string = 'subscription';
  static readonly lzlDomains: string = 'domains';
  static readonly lzlUserWindow: string = 'userWindow';
  static readonly lzlPage: string = 'page';
  static readonly lzlPasswordRecovery: string = 'passwordRecovery';
  static readonly lzlPaymentFailed: string = 'paymentFailed';
  static readonly lzlPaymentSuccess: string = 'paymentSuccess';
  static readonly lzlTrainingTools: string = 'trainingTools';
  static readonly lzlBookmarks: string = 'bookmarks';
  static readonly lzlSubscriptionBloc: string = 'subscription-bloc';

  templates: { [templateId: string]: TemplateRef<any> } = {};

  components: { [componentId: string]: Type<any> } = {};

  components$: ReplaySubject<{ [componentId: string]: Type<any> }> = new ReplaySubject<{ [componentId: string]: Type<any> }>(1);

  public test: any;

  private _componentFunctions: { [componentId: string]: Function } = {
    [TemplatesService.lzlSubscriptionBloc]: this.createSubscriptionBloc,
    'sponsors': this.createSponsors,
    'pwa-install': this.createPWAInstall,
    [ContentTypesConstants.situation.contentType]: this.createSituation,
    [ContentTypesConstants.savoir.contentType]: this.createSavoir,
    [ContentTypesConstants.module.contentType]: this.createModule,
    [ContentTypesConstants.diplome.contentType]: this.createDiplome,
    [ContentTypesConstants.faceAface.contentType]: this.createFaceAFace,
    [ContentTypesConstants.seance.contentType]: this.createSeance,
    'enchainement_situations': this.createMultiSituations,
    [ContentTypesConstants.cycle.contentType]: this.createCycle,
    [ContentTypesConstants.situationJeunesJuges.contentType]: this.createJeuneJuge,
    [ContentTypesConstants.video.contentType]: this.createVideo,
    [ContentTypesConstants.theme.contentType]: this.createTheme,
    [TemplatesService.lzlLogin]: this.createLoginView,
    [TemplatesService.lzlSubscription]: this.createSubscriptionView,
    [TemplatesService.lzlDomains]: this.createDomainsView,
    [TemplatesService.lzlUserWindow]: this.createUserWindowView,
    [TemplatesService.lzlPage]: this.createPageView,
    [TemplatesService.lzlPasswordRecovery]: this.createPasswordRecoveryView,
    [TemplatesService.lzlPaymentFailed]: this.createPaymentFailedView,
    [TemplatesService.lzlPaymentSuccess]: this.createPaymentSuccessView,
    [TemplatesService.lzlTrainingTools]: this.createTrainingToolsView,
    [TemplatesService.lzlBookmarks]: this.createBookmarksView
  };

  constructor(
    private cfr: ComponentFactoryResolver,
    private injector: Injector
  ) {
    this.components$.next(this.components);
  }

  addTemplate(id: string, template: TemplateRef<any>) {
    this.templates[id] = template;
  }

  getTemplate(id: string): TemplateRef<any> {
    return this.templates[id];
  }

  addComponent(componentId: string, component: Type<any>) {
    this.components[componentId] = component;
    this.components$.next(this.components);
  }


  getComponent$(componentId: string): Observable<Type<any>> {
    return this.components$.pipe(
      tap(components => {
        if (!components[componentId] && !!this._componentFunctions[componentId]) {
          this._componentFunctions[componentId].apply(this);
        }
      }),
      map(components => {
        return components[componentId];
      }),
      filter(component => !!component),
      take(1),
      shareReplay(1)
    );
  }

  createVideo(){
    import('./contents/components/video/video.component').then(({VideoComponent}) => {
      this.addComponent(ContentTypesConstants.video.contentType, VideoComponent);
    });
  }

  createJeuneJuge(){
    import('./contents/components/juge/juge.component').then(({JugeComponent}) => {
      this.addComponent(ContentTypesConstants.situationJeunesJuges.contentType, JugeComponent);
    });
  }

  createCycle(){
    import('./contents/components/cycle/cycle.component').then(({CycleComponent}) => {
      this.addComponent(ContentTypesConstants.cycle.contentType, CycleComponent);
    });
  }

  createMultiSituations(){
    import('./contents/components/multi-situations/multi-situations.component').then(({MultiSituationsComponent}) => {
      this.addComponent('enchainement_situations', MultiSituationsComponent);
    });
  }

  createSeance(){
    import('./contents/components/seance/seance.component').then(({SeanceComponent}) => {
      this.addComponent(ContentTypesConstants.seance.contentType, SeanceComponent);
    });
  }


  createSituation(){
    import('./contents/components/situation/situation.component').then(({SituationComponent}) => {
      this.addComponent(ContentTypesConstants.situation.contentType, SituationComponent);
    });
  }

  createSavoir(){
    import('./contents/components/savoir/savoir.component').then(({SavoirComponent}) => {
      this.addComponent(ContentTypesConstants.savoir.contentType, SavoirComponent);
    });
  }

  createModule(){
    import('./contents/components/module/module.component').then(({ModuleComponent}) => {
      this.addComponent(ContentTypesConstants.module.contentType, ModuleComponent);
    });
  }

  createDiplome(){
    import('./contents/components/diplome/diplome.component').then(({DiplomeComponent}) => {
      this.addComponent(ContentTypesConstants.diplome.contentType, DiplomeComponent);
    });
  }

  createFaceAFace(){
    import('./contents/components/face-a-face/face-a-face.component').then(({FaceAFaceComponent}) => {
      this.addComponent(ContentTypesConstants.faceAface.contentType, FaceAFaceComponent);
    });
  }

  createTheme(){
    import('./contents/components/theme/theme.component').then(({ThemeComponent}) => {
      this.addComponent(ContentTypesConstants.theme.contentType, ThemeComponent);
    });
  }

  createSubscriptionBloc() {
    import('./components/subscription-bloc/subscription-bloc.component').then(({SubscriptionBlocComponent}) => {
      this.addComponent(TemplatesService.lzlSubscriptionBloc, SubscriptionBlocComponent);
    });
  }

  createSponsors() {
    import('./components/sponsors/sponsors.component').then(({SponsorsComponent}) => {
      this.addComponent('sponsors', SponsorsComponent);
    });
  }

  createPWAInstall() {
    import('./pwa/pwa-install/pwa-install.component').then(({PwaInstallComponent}) => {
      this.addComponent('pwa-install', PwaInstallComponent);
    });
  }

  createLoginView(){
    import('./components/user-login/containers/user-login.component').then(({UserLoginComponent}) => {
      this.addComponent(TemplatesService.lzlLogin, UserLoginComponent);
    });
  }

  createSubscriptionView(){
    import('./subscription/containers/user-subscription/user-subscription.component').then(({UserSubscriptionComponent}) => {
      this.addComponent(TemplatesService.lzlSubscription, UserSubscriptionComponent);
    });
  }

  createDomainsView(){
    import('./components/preference-window/preference-window.component').then(({PreferenceWindowComponent}) => {
      this.addComponent(TemplatesService.lzlDomains, PreferenceWindowComponent);
    });
  }

  createUserWindowView(){
    import('./components/user-window/user-window.component').then(({UserWindowComponent}) => {
      this.addComponent(TemplatesService.lzlUserWindow, UserWindowComponent);
    });
  }

  createPageView(){
    import('./components/page/page.component').then(({PageComponent}) => {
      this.addComponent(TemplatesService.lzlPage, PageComponent);
    });
  }

  createPasswordRecoveryView(){
    import('./components/password-recovery/password-recovery.component').then(({PasswordRecoveryComponent}) => {
      this.addComponent(TemplatesService.lzlPasswordRecovery, PasswordRecoveryComponent);
    });
  }

  createPaymentFailedView(){
    import('./components/payment-fail/payment-fail.component').then(({PaymentFailComponent}) => {
      this.addComponent(TemplatesService.lzlPaymentFailed, PaymentFailComponent);
    });
  }

  createPaymentSuccessView(){
    import('./components/payment-success/payment-success.component').then(({PaymentSuccessComponent}) => {
      this.addComponent(TemplatesService.lzlPaymentSuccess, PaymentSuccessComponent);
    });
  }

  createTrainingToolsView(){
    import('./components/training-tools/training-tools.component').then(({TrainingToolsComponent}) => {
      this.addComponent(TemplatesService.lzlTrainingTools, TrainingToolsComponent);
    });
  }

  createBookmarksView(){
    import('./components/bookmarks/bookmarks.component').then(({BookmarksComponent}) => {
      this.addComponent(TemplatesService.lzlBookmarks, BookmarksComponent);
    });
  }


}
