export class User{

  static readonly roleContributeur: string = 'contributeur';
  static readonly roleFormateur: string = 'formation';
  static readonly roleClientAdmin: string = 'client admin';
  static readonly roleAdmin: string = 'admin';
  static readonly roleAbonneManuel: string = 'abonné manuel';
  static readonly roleAbonne: string = 'abonné';
  static readonly roleAbonneLicence: string = 'abonné licencié';

  id?: string;
  username?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  mainFunction?: string;
  practiceLevel?: string;
  ffaLicenseNumber?: string;
  ffaLicenseValid?: boolean;
  birthDate?: string;
  roles?: string[];
  domains?: string[];
  subscription?: UserSubscription;

  isStagiaire?: boolean;
  stagiaireModules?: any[];

}

export class UserSubscription{
  valid: boolean;
}

export class UserMeta{

  metaProfile:UserMetaProfile;

}

export class UserMetaProfile extends Object{
  field_fonction_principale: UserMetaProfileField;
  field_niveau_de_pratique_scolair: UserMetaProfileField;
  field_user_sexe: UserMetaProfileField;
  field_public_cible: UserMetaProfileField;
  field_specialite: UserMetaProfileField;
  field_domain: UserMetaProfileField;
}

export class UserMetaProfileField{
  allowed_values: UserMetaProfileFieldValue[];
}

export class UserMetaProfileFieldValue{

  key: string;
  label: string;

}
