/**
 * Created by benoitplatre on 14/08/2018.
 */

export class FilterDefinition {

  name: string;
  multiple?: boolean;
  label?: string;

}

export class FiltersDefinition {

  types: FilterDefinition;

  main: FilterDefinition[];

  default: FilterDefinition[];

  advanced?: FilterDefinition[];

}

export class FiltersConstants {


  static readonly ageLevel: FilterDefinition = {name: 'field_age_level'};
  static readonly ageTo: FilterDefinition = {name: 'field_age_level_to'};
  static readonly ageFrom: FilterDefinition = {name: 'field_age_level_from'};
  static readonly domain: FilterDefinition = {name: 'field_domain'};
  static readonly oldAgeLevel: FilterDefinition = {name: 'taxonomy_vocabulary_4'};

  static readonly seanceType: FilterDefinition = {name: 'in_seance_types', label: 'Séances'};
  static readonly freeContent: FilterDefinition = {name: 'flag_free_content'};
  static readonly isEvaluation: FilterDefinition = {name: 'is_evaluation'};
  static readonly hasVideoAnalysis: FilterDefinition = {name: 'has_video_analysis'};
  static readonly practicalType: FilterDefinition = {name: 'practical_type'};
  static readonly hasSlideshow: FilterDefinition = {name: 'has_slideshow'};
  static readonly isLexique: FilterDefinition = {name: 'is_lexique'};


  static readonly extendedType: FilterDefinition = {name: 'extended_type'};
  static readonly action: FilterDefinition = {name: 'field_action'};
  static readonly epreuves: FilterDefinition = {name: 'taxonomy_vocabulary_12', multiple: false};
  static readonly ofa: FilterDefinition = {name: 'field_ofa_classification', multiple: false};
  static readonly diplome_niveau: FilterDefinition = {name: 'field_formation_niveau', multiple: false};
  static readonly ressources: FilterDefinition = {name: 'taxonomy_vocabulary_20'};
  static readonly univers: FilterDefinition = {name: 'taxonomy_vocabulary_3'};
  static readonly intention: FilterDefinition = {name: 'field_intention'};
  static readonly difficulty: FilterDefinition = {name: 'taxonomy_vocabulary_15'};
  static readonly fonction: FilterDefinition = {name: 'field_fonction_term'};
  static readonly classification: FilterDefinition = {name: 'taxonomy_vocabulary_10'};
  static readonly contraintes: FilterDefinition = {name: 'taxonomy_vocabulary_14'};
  static readonly installation: FilterDefinition = {name: 'taxonomy_vocabulary_16'};
  static readonly phases: FilterDefinition = {name: 'taxonomy_vocabulary_5'};
  static readonly bodyParts: FilterDefinition = {name: 'taxonomy_vocabulary_9'};
  static readonly period: FilterDefinition = {name: 'taxonomy_vocabulary_19'};
  static readonly acquisition: FilterDefinition = {name: 'field_acquisition'};
  static readonly destinationTo: FilterDefinition = {name: 'field_a_destination_des'};
  static readonly quizType: FilterDefinition = {name: 'field_quiz_type'};
  static readonly formationContinue: FilterDefinition = {name: 'field_formation_continue'};
  static readonly leJeune: FilterDefinition = {name: 'field_le_jeune'};
  static readonly programmation: FilterDefinition = {name: 'field_programmation'};
  static readonly conception: FilterDefinition = {name: 'field_conception'};
  static readonly gestionEntrainement: FilterDefinition = {name: 'field_gestion_entrainement'};
  static readonly reglementationLogistique: FilterDefinition = {name: 'field_reglementation_logistique'};
  static readonly sante: FilterDefinition = {name: 'field_sante'};
  static readonly sciences: FilterDefinition = {name: 'field_sciences'};
  static readonly structuresOrganisation: FilterDefinition = {name: 'field_structures_organisation'};
  static readonly formation: FilterDefinition = {name: 'field_formation'};
  static readonly optionDirection: FilterDefinition = {name: 'field_option_direction'};
  static readonly optionJury: FilterDefinition = {name: 'field_option_jury'};
  static readonly optionOrganisation: FilterDefinition = {name: 'field_option_organisation'};
  static readonly evenementiel: FilterDefinition = {name: 'field_evenementiel'};
  static readonly niveau: FilterDefinition = {name: 'field_niveau'};
  static readonly thematique: FilterDefinition = {name: 'field_thematique'};
  static readonly videoType: FilterDefinition = {name: 'field_type_de_vid_o'};
  static readonly saison: FilterDefinition = {name: 'field_saison'};
  static readonly sex: FilterDefinition = {name: 'field_sexe'};
  static readonly epreuvesCombinees: FilterDefinition = {name: 'field_combined'};
  static readonly historique: FilterDefinition = {name: 'field_contenu_historique'};
  static readonly format: FilterDefinition = {name: 'field_difficulte', label: 'Lexique'};
  static readonly status: FilterDefinition = {name: 'status', label: 'Fiches non publiées'};
  static readonly promoted: FilterDefinition = {name: 'promote', label: 'Nouveautés'};
  static readonly echelon: FilterDefinition = {name: 'field_quiz_level', label: 'Échelon'};
  static readonly evaluation: FilterDefinition = {name: 'field_evaluation', label: 'Évaluations'};

  static readonly bookmarks: FilterDefinition = {name: 'flag_bookmarks_user'};


  static readonly lexiqueOptionId: number = 434;

  static readonly map: {[filterName: string]:FilterDefinition} = {
    [FiltersConstants.status.name]: FiltersConstants.status,
    [FiltersConstants.promoted.name]: FiltersConstants.promoted,
    [FiltersConstants.ageTo.name]: FiltersConstants.ageTo,
    [FiltersConstants.ageFrom.name]: FiltersConstants.ageFrom,
    [FiltersConstants.domain.name]: FiltersConstants.domain,
    [FiltersConstants.oldAgeLevel.name]: FiltersConstants.oldAgeLevel,
    [FiltersConstants.seanceType.name]: FiltersConstants.seanceType,
    [FiltersConstants.freeContent.name]: FiltersConstants.freeContent,
    [FiltersConstants.isEvaluation.name]: FiltersConstants.isEvaluation,
    [FiltersConstants.hasVideoAnalysis.name]: FiltersConstants.hasVideoAnalysis,
    [FiltersConstants.practicalType.name]: FiltersConstants.practicalType,
    [FiltersConstants.hasSlideshow.name]: FiltersConstants.hasSlideshow,
    [FiltersConstants.action.name]: FiltersConstants.action,
    [FiltersConstants.epreuves.name]: FiltersConstants.epreuves,
    [FiltersConstants.ofa.name]: FiltersConstants.ofa,
    [FiltersConstants.diplome_niveau.name]: FiltersConstants.diplome_niveau,
    [FiltersConstants.ressources.name]: FiltersConstants.ressources,
    [FiltersConstants.univers.name]: FiltersConstants.univers,
    [FiltersConstants.intention.name]: FiltersConstants.intention,
    [FiltersConstants.difficulty.name]: FiltersConstants.difficulty,
    [FiltersConstants.fonction.name]: FiltersConstants.fonction,
    [FiltersConstants.classification.name]: FiltersConstants.classification,
    [FiltersConstants.contraintes.name]: FiltersConstants.contraintes,
    [FiltersConstants.installation.name]: FiltersConstants.installation,
    [FiltersConstants.phases.name]: FiltersConstants.phases,
    [FiltersConstants.bodyParts.name]: FiltersConstants.bodyParts,
    [FiltersConstants.period.name]: FiltersConstants.period,
    [FiltersConstants.acquisition.name]: FiltersConstants.acquisition,
    [FiltersConstants.destinationTo.name]: FiltersConstants.destinationTo,
    [FiltersConstants.quizType.name]: FiltersConstants.quizType,
    [FiltersConstants.formationContinue.name]: FiltersConstants.formationContinue,
    [FiltersConstants.leJeune.name]: FiltersConstants.leJeune,
    [FiltersConstants.programmation.name]: FiltersConstants.programmation,
    [FiltersConstants.conception.name]: FiltersConstants.conception,
    [FiltersConstants.gestionEntrainement.name]: FiltersConstants.gestionEntrainement,
    [FiltersConstants.reglementationLogistique.name]: FiltersConstants.reglementationLogistique,
    [FiltersConstants.sante.name]: FiltersConstants.sante,
    [FiltersConstants.sciences.name]: FiltersConstants.sciences,
    [FiltersConstants.structuresOrganisation.name]: FiltersConstants.structuresOrganisation,
    [FiltersConstants.formation.name]: FiltersConstants.formation,
    [FiltersConstants.optionDirection.name]: FiltersConstants.optionDirection,
    [FiltersConstants.optionJury.name]: FiltersConstants.optionJury,
    [FiltersConstants.optionOrganisation.name]: FiltersConstants.optionOrganisation,
    [FiltersConstants.evenementiel.name]: FiltersConstants.evenementiel,
    [FiltersConstants.niveau.name]: FiltersConstants.niveau,
    [FiltersConstants.thematique.name]: FiltersConstants.thematique,
    [FiltersConstants.videoType.name]: FiltersConstants.videoType,
    [FiltersConstants.saison.name]: FiltersConstants.saison,
    [FiltersConstants.sex.name]: FiltersConstants.sex,
    [FiltersConstants.epreuvesCombinees.name]: FiltersConstants.epreuvesCombinees,
    [FiltersConstants.historique.name]: FiltersConstants.historique,
    [FiltersConstants.format.name]: FiltersConstants.format,
    [FiltersConstants.echelon.name]: FiltersConstants.echelon,
    [FiltersConstants.evaluation.name]: FiltersConstants.evaluation
  }

  static readonly labels: { [filterIdr: string]: string } = {
    [FiltersConstants.seanceType.name]: 'Phase d’une séance',
    [FiltersConstants.freeContent.name]: 'Contenu gratuit',
    [FiltersConstants.isEvaluation.name]: 'Évaluation',
    [FiltersConstants.hasVideoAnalysis.name]: 'Analyse Vidéo'
  };


  static readonly blackList: string[] = [
    FiltersConstants.ageTo.name,
    FiltersConstants.ageFrom.name,
    FiltersConstants.oldAgeLevel.name,
    FiltersConstants.hasVideoAnalysis.name,
    FiltersConstants.practicalType.name,
    FiltersConstants.domain.name
  ];

  static readonly mainFilters: FilterDefinition[] = [
    FiltersConstants.freeContent
  ];

  static readonly filtersDefinition: FiltersDefinition = {
    types: FiltersConstants.extendedType,
    main: [
      ...FiltersConstants.mainFilters
    ],
    default: [
      FiltersConstants.epreuves,
      FiltersConstants.ressources,
      FiltersConstants.formation,
      FiltersConstants.evaluation,
      FiltersConstants.optionDirection,
      FiltersConstants.optionJury,
      FiltersConstants.optionOrganisation,
      FiltersConstants.destinationTo,
      FiltersConstants.quizType,
      FiltersConstants.univers,
      FiltersConstants.seanceType,
      FiltersConstants.intention,
      FiltersConstants.action,
      FiltersConstants.formationContinue,
      FiltersConstants.leJeune,
      FiltersConstants.programmation,
      FiltersConstants.conception,
      FiltersConstants.difficulty,
      FiltersConstants.echelon
    ],
    advanced: [
      FiltersConstants.gestionEntrainement,
      FiltersConstants.fonction,
      FiltersConstants.classification,
      FiltersConstants.contraintes,
      FiltersConstants.installation,
      FiltersConstants.phases,
      FiltersConstants.reglementationLogistique,
      FiltersConstants.sante,
      FiltersConstants.sciences,
      FiltersConstants.structuresOrganisation,
      FiltersConstants.bodyParts,
      FiltersConstants.period,
      FiltersConstants.acquisition,
      FiltersConstants.evenementiel,
      FiltersConstants.niveau,
      FiltersConstants.thematique,
      FiltersConstants.videoType,
      FiltersConstants.saison,
      FiltersConstants.sex
    ]
  };

}
