<form [formGroup]="form" (ngSubmit)="onSubmitForm()" class="col-12" *ngIf="form" #formDirective="ngForm" >

  <div class="row">

    <div class="col-xs-12 col-md-12 d-flex flex-column">

      <mat-form-field>
        <input
          matInput
          placeholder="Mot de passe actuel"
          required
          type="password"
          formControlName="oldPassword"
          autocomplete="off">
        <mat-error *ngIf="form.controls.oldPassword.errors?.required">
          Merci de saisir votre mot de passe actuel
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="Nouveau mot de passe"
          required
          type="password"
          formControlName="password"
          autocomplete="off">
        <mat-error *ngIf="form.controls.password.errors?.required">
          Merci de choisir votre mot de passe
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-2">
        <input
          matInput
          placeholder="Confirmez votre nouveau mot de passe"
          type="password"
          formControlName="passwordConfirm"
          autocomplete="off">
        <mat-error *ngIf="form.controls.passwordConfirm.hasError('invalid')">
          Votre mot de passe ne corresponds pas
        </mat-error>
      </mat-form-field>

    </div>

  </div>

  <div class="row">

    <div class="col-xs-12 col-md-12 d-flex flex-column">

      <button mat-raised-button>Valider</button>

    </div>

  </div>

  <ng-content></ng-content>

</form>
