/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {select, Store} from "@ngrx/store";
import {SetContext} from "./store/actions/subscription.actions";
import {SubscriptionContext, SubscriptionStep} from "./models/subcription-context.model";
import {getAuthenticated} from "@madeinlune/components";
import {take} from "rxjs/operators";


@Injectable()
export class SubscriptionResolver implements Resolve<any> {

  constructor(
    private store: Store<any>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {

    this.store.pipe(
      select(getAuthenticated),
      take(1),
    ).subscribe(authenticated => {
      const pass:any = route.queryParams.pass;
      let context: SubscriptionContext;
      if(pass){

        context = {
          name: SubscriptionContext.ssiFfa,
          modes: {
            [SubscriptionContext.modeSiFfa] : {
              name: SubscriptionContext.modeSiFfa,
              steps: [{
                name: SubscriptionContext.stepSiFfa,
              }]
            }
          }
        };

      }else{
        let commonSteps: SubscriptionStep[] = [];

        if(!authenticated){
          commonSteps.push({
            name: SubscriptionContext.stepLegal,
            label: 'Je valide les conditions d’utilisation'
          });
          commonSteps.push({
            name: SubscriptionContext.stepForm,
            label: 'Je crée mon compte'
          });
        }

        context = {
          name: SubscriptionContext.public,
          modes: {
            [SubscriptionContext.modeChoice] : {
              name: SubscriptionContext.modeChoice,
              steps: [{
                name: SubscriptionContext.stepChoice,
              }]
            },
            [SubscriptionContext.modeLicence] : {
              name: SubscriptionContext.modeLicence,
              steps: [
                ...(!authenticated ? commonSteps:[{
                  name: SubscriptionContext.stepForm,
                  label: 'Je valide ma licence'
                }]),
                {
                  name:SubscriptionContext.stepFinish,
                  label : 'C’est fini !'
                }
              ]
            },
            [SubscriptionContext.modeNoLicence] : {
              name: SubscriptionContext.modeNoLicence,
              steps: [
                ...commonSteps,
                {
                  name:SubscriptionContext.stepCart,
                  label: 'Commande puis paiement'
                }
              ]
            }
          }
        };

      }

      this.store.dispatch(new SetContext(context));
    });

  }

}


