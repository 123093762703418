import {Pipe, PipeTransform} from '@angular/core';
import {encyclopediaPath, themePath} from "../route-constants";

@Pipe({
  name: 'menuPathToRoute'
})
export class MenuPathToRoutePipe implements PipeTransform {


  urlsMap:any = {
    'node/9742':'',
    'node/9743':encyclopediaPath,
    'node/9747':'espace-stagiaire',
    'node/9745':'mediatheque-obsolete',
    'node/9744':themePath
  };

  transform(value: any, args?: any): any {

    return this.urlsMap[value] !== null &&  this.urlsMap[value] !== undefined ? this.urlsMap[value] : value;
  }

}
