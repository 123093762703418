/**
 * Created by benoitplatre on 25/06/2018.
 */
import {Action} from '@ngrx/store';

export enum VocabularyActionTypes {
  Load = '[Vocabulary] Load',
  LoadSuccess = '[Vocabulary] Load Success',
  LoadFail = '[Vocabulary] Load Fail',
  SetStaticVocabulary = '[Vocabulary] Set Static Vocabulary',
}

export class VocabularyLoad implements Action {
  readonly type = VocabularyActionTypes.Load;
  constructor(public payload: any = null) {
  }
}

export class VocabularyLoadSuccess implements Action {

  readonly type = VocabularyActionTypes.LoadSuccess;

  constructor(public payload: any) {
  }
}

export class VocabularyLoadFail implements Action {

  readonly type = VocabularyActionTypes.LoadFail;

  constructor(public payload: any) {
  }

}

export class SetStaticVocabulary implements Action {
  readonly type = VocabularyActionTypes.SetStaticVocabulary;
  constructor(public payload: any = []) {
  }
}

export type VocabularyActions = VocabularyLoad | VocabularyLoadSuccess | VocabularyLoadFail | SetStaticVocabulary;
