import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {DrupalNode} from "../core/models/node";

@Pipe({
  name: 'editUrl'
})

@Injectable()
export class EditUrlPipe implements PipeTransform {

  constructor(
    public oauthService: OAuthService
  ) {

  }

  transform(node: DrupalNode): any {
    // manage new data model from the app with old one coming directly from Drupal (ie Quiz)
    const editUrl: string = node?.editUrl || node?.edit_url
    const url: string = editUrl ? editUrl + '&access_token=' + this.oauthService.getAccessToken() : null;
    return url;
  }

}
