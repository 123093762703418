import {Pipe, PipeTransform} from '@angular/core';
import {ResourceToDocumentPipe} from "./resource-to-document.pipe";
import {DrupalFile, MediaType, MediaTypes} from "../../core/models/file";
import {ResourceToImagePipe} from "./resource-to-image.pipe";
import {ResourceToVideoPipe} from "./resource-to-video.pipe";
import {ResourceToFilePipe} from "./resource-to-file.pipe";

@Pipe({
  name: 'resourceToMedia'
})
export class ResourceToMediaPipe implements PipeTransform {

  constructor(
    private resourceToDocumentPipe: ResourceToDocumentPipe,
    private resourceToImagePipe: ResourceToImagePipe,
    private resourceToVideoPipe: ResourceToVideoPipe,
    private resourceToFilePipe: ResourceToFilePipe
  ) {
  }

  transform(rawData: any): DrupalFile {

    if(rawData){

      let file: DrupalFile = this.resourceToFilePipe.transform(rawData);
      const fileType: MediaType = file.type;
      if (fileType === MediaTypes.document) {
        file = this.resourceToDocumentPipe.transform(file);
      }else if (fileType === MediaTypes.image) {
        file = this.resourceToImagePipe.transform(file, rawData);
      }else if (fileType === MediaTypes.video) {
        file = this.resourceToVideoPipe.transform(file, rawData);
      }
      return file;

    }

    return null;

  }

}
