import {Component, HostBinding, Inject, Input, NgModule, OnInit, Optional} from '@angular/core';
import {Observable, of} from "rxjs";
import {DrupalNode} from "../../core/models/node";
import {select, Store} from "@ngrx/store";
import {getCurrentNode, getNodeById} from "../../core/store/reducers";
import {filter, take} from "rxjs/operators";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TemplatesService} from "../../templates.service";
import {Load} from "../../core/store/actions";
import {CommonModule} from "@angular/common";
import {DialogModule} from "../dialog/dialog.component";
import {PipesModule} from "../../pipes/pipes.module";

@Component({
  selector: 'ea-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  public node$: Observable<DrupalNode>;

  @HostBinding('class.window-mode') hasData: boolean;


  _page: DrupalNode;
  @Input()
  set page(value: DrupalNode){
    this._page = {...value} as DrupalNode;
    if(this._page.body){
     this._page.body = this._page.body;
    }
    this.node$ = of(this._page);
  }

  showTitle: boolean = true;

  @Input()
  nid: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    public templatesService: TemplatesService
  ) { }

  ngOnInit() {
    if(!this._page){
      if(this.data && this.data.nid) {
        this.showTitle = false;
        this.hasData = true;
        this.node$ = this.store.pipe(select(getNodeById(this.data.nid)), filter((node: DrupalNode) => !!node));
      }else if(this.nid){
        setTimeout(() => {
          this.store.pipe(
            select(getNodeById(this.nid)),
            take(1)
          ).subscribe((drupalNode: DrupalNode) => {
            if(!drupalNode){
              this.store.dispatch(new Load(this.nid));
            }
          });
        }, 0);
        this.node$ = this.store.pipe(select(getNodeById(this.nid)), filter((node: DrupalNode) => !!node));
        //this.store.dispatch(new Load(this.nid));
      }else{
        this.node$ = this.store.pipe(select(getCurrentNode), filter((node: DrupalNode) => !!node));
      }
    }
    //this.node$.pipe(share()).subscribe(node => console.log('node', node));
  }

}

@NgModule({
  declarations: [
    PageComponent
  ],
  exports: [
    PageComponent
  ],
  imports: [
    CommonModule,
    DialogModule,
    PipesModule
  ]
})
export class PageModule { }
