import {Inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {loadNodesByType, loadNodesByTypeFailure, loadNodesSuccess, loadNodeSuccess} from "../actions/node.actions";
import {catchError, map, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {ResourceToPagePipe} from "../../../pipes/data/resource-to-page.pipe";
import {ContentTypes, DrupalNode, PracticalTypes, RawContent} from "../../models/node";
import {
  updateDrupalNode,
  updateDrupalNodes,
  upsertDrupalNode,
  upsertDrupalNodes
} from "../../drupal-node/drupal-node.actions";
import {ResourceToTutorialPipe} from "../../../pipes/data/resource-to-tutorial.pipe";
import {NodeService} from "../../services/node.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {ResourceToNewsPipe} from "../../../pipes/data/resource-to-news.pipe";
import {ResourceToKnowledgePipe} from "../../../pipes/data/resource-to-knowledge.pipe";
import {ResourceToQuizPipe} from "../../../pipes/data/resource-to-quiz.pipe";
import {ResourceToVideoNodePipe} from "../../../pipes/data/resource-to-video-node.pipe";
import {ResourceToSituationPipe} from "../../../pipes/data/resource-to-situation.pipe";
import {SEARCH_SUCCESS, SearchActionSuccess} from "../../../search/store";
import {ResourceToCyclePipe} from "../../../pipes/data/resource-to-cycle.pipe";
import {
  LOAD_BOOKMARKS_FULL_SUCCESS,
  LoadBookmarksFullSuccess,
  LoadRelatedSuccess,
  LoadSuccess,
  NodeActionTypes,
  NodeCollectionLoadSuccess
} from "../actions";
import {ResourceToMultiSituationsPipe} from "../../../pipes/data/resource-to-multi-situations.pipe";
import {ResourceToSeancePipe} from "../../../pipes/data/resource-to-seance.pipe";
import {ResourceToJugePipe} from "../../../pipes/data/resource-to-juge.pipe";
import {ResourceToDiplomePipe} from "../../../pipes/data/resource-to-diplome.pipe";
import {ResourceToModulePipe} from "../../../pipes/data/resource-to-module.pipe";
import {selectDrupalNodeIds} from "../../drupal-node/drupal-node.reducer";
import {select, Store} from "@ngrx/store";
import {ResourceToFaceAFacePipe} from "../../../pipes/data/resource-to-face-a-face.pipe";
import {ResourceToThemePipe} from "../../../pipes/data/resource-to-theme.pipe";
import {ResourceToFaqQuestionPipe} from "../../../pipes/data/resource-to-faq-question.pipe";
import {ResourceToWebformPipe} from "../../../pipes/data/resource-to-webform.pipe";
import {IS_CONTRIBUTOR} from "../../../providers/user.providers";

@Injectable()
export class NodeNewEffects {


  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private resourceToPagePipe: ResourceToPagePipe,
    private resourceToTutorialPipe: ResourceToTutorialPipe,
    private resourceToNewsPipe: ResourceToNewsPipe,
    private resourceToKnowledgePipe: ResourceToKnowledgePipe,
    private resourceToQuizPipe: ResourceToQuizPipe,
    private resourceToVideoNodePipe: ResourceToVideoNodePipe,
    private resourceToSituationPipe: ResourceToSituationPipe,
    private resourceToCyclePipe: ResourceToCyclePipe,
    private resourceToMultiSituationsPipe: ResourceToMultiSituationsPipe,
    private resourceToSeancePipe: ResourceToSeancePipe,
    private resourceToJugePipe: ResourceToJugePipe,
    private resourceToDiplomePipe: ResourceToDiplomePipe,
    private resourceToModulePipe: ResourceToModulePipe,
    private resourceToFaceAFacePipe: ResourceToFaceAFacePipe,
    private resourceToThemePipe: ResourceToThemePipe,
    private resourceToFaqQuestionPipe: ResourceToFaqQuestionPipe,
    private resourceToWebformPipe: ResourceToWebformPipe,
    private nodeService: NodeService,
    @Inject(IS_CONTRIBUTOR) private isContributor$: Observable<boolean>
  ) {
  }

  loadNodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNodeSuccess),
      map(action => {
        const rawNode: RawContent = action.data;
        const node: DrupalNode = this.parseResource(rawNode);
        return upsertDrupalNode({drupalNode: node});
      })
    ));

  loadRawNodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeActionTypes.LoadSuccess),
      map((action: LoadSuccess) => {
        const rawNode: RawContent = action.payload;
        const node: DrupalNode = this.parseResource(rawNode);
        return upsertDrupalNode({drupalNode: node});
      })
    ));

  loadNodesByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNodesByType),
      switchMap(action => {
        return this.nodeService.getNodeByType(action.contentType, action.promoted, action.limit).pipe(
          map((nodes: DrupalNode[]) => {
            return loadNodesSuccess({data: nodes, itemFormat: true});
          }),
          catchError((error: HttpErrorResponse) => {
            return of(loadNodesByTypeFailure({error}));
          })
        );
      })
    ));

  loadNodeCollectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeActionTypes.NodeCollectionLoadSuccess),
      switchMap((action: NodeCollectionLoadSuccess) => {
        return [loadNodesSuccess({data: action.nodeCollection, itemFormat: true})]
      })
    ));

  searchSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SEARCH_SUCCESS),
      map((action: SearchActionSuccess) => {
        if (action.result) {
          return loadNodesSuccess({data: action.result.items, itemFormat: true});
        }
        return null;
      })
    ));

  loadBookmarkFullSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_BOOKMARKS_FULL_SUCCESS),
      tap((action: LoadBookmarksFullSuccess) => {
        // console.log('action', action);
      })
    ), {dispatch: false});

  loadRelativeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NodeActionTypes.LoadRelatedSuccess),
      withLatestFrom(this.isContributor$),
      switchMap(([action, isContributor]: [LoadRelatedSuccess, boolean]) => {
        if (action.payload) {
          //TODO improve content parsing
          let associatedContents: any[];
          if (!isContributor && Array.isArray(action.payload)) {
            associatedContents = action.payload.filter(item => item.status === true);
          } else {
            associatedContents = action.payload;
          }
          return [
            updateDrupalNode({
              drupalNode: {
                id: action.nid,
                changes: {
                  associatedContents
                }
              }
            }),
            loadNodesSuccess({data: action.payload, itemFormat: true})
          ];
        }
        return [];
      })
    ));

  loadNodesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNodesSuccess),
      withLatestFrom(this.store.pipe(select(selectDrupalNodeIds))),
      switchMap(([action, drupalNodeIds]) => {
        const ids: string[] = drupalNodeIds as string[];
        const rawNodes: RawContent[] = action.data;
        const nodes: DrupalNode[] = rawNodes.map(rawNode => this.parseResource(rawNode));
        const nodesToCreate: DrupalNode[] = nodes.filter((node: DrupalNode) => ids.indexOf(node.id) === -1);
        const nodesToUpdate: DrupalNode[] = nodes.filter(node => ids.indexOf(node.id) > -1);
        return [
          upsertDrupalNodes({drupalNodes: nodesToCreate}),
          updateDrupalNodes({
            drupalNodes: nodesToUpdate.map(node => {

              const cleanedNode: DrupalNode | any = {}

              Object.keys(node).forEach(key => {
                if (node[key] !== null && node[key] !== undefined) {
                  if (action.itemFormat) {
                    if (key === 'title' || key === 'id' || key === 'extendedType') {
                      cleanedNode[key] = node[key];
                    }
                  } else {
                    cleanedNode[key] = node[key];
                  }
                }
              });

              return {id: node.id, changes: cleanedNode}
            })
          })];
      })
    ));

  parseResource(resource: RawContent): DrupalNode {
    let node: DrupalNode;
    if (resource.type === ContentTypes.page) {
      node = this.resourceToPagePipe.transform(resource);
    } else if (resource.type === ContentTypes.tutoriel) {
      node = this.resourceToTutorialPipe.transform(resource);
    } else if (resource.type === ContentTypes.news) {
      node = this.resourceToNewsPipe.transform(resource);
    } else if (resource.type === ContentTypes.knowledge) {
      node = this.resourceToKnowledgePipe.transform(resource);
    } else if (resource.type === ContentTypes.quiz) {
      node = this.resourceToQuizPipe.transform(resource);
    } else if (resource.type === ContentTypes.video) {
      node = this.resourceToVideoNodePipe.transform(resource);
    } else if (resource.type === ContentTypes.situation) {
      node = this.resourceToSituationPipe.transform(resource);
    } else if (resource.type === ContentTypes.cycle) {
      node = this.resourceToCyclePipe.transform(resource);
    } else if (resource.type === ContentTypes.enchainementSituations) {
      if (resource.practicalType === PracticalTypes.progression) {
        node = this.resourceToMultiSituationsPipe.transform(resource);
      } else if (resource.practicalType === PracticalTypes.circuit) {
        node = this.resourceToMultiSituationsPipe.transform(resource);
      }
    } else if (resource.type === ContentTypes.jeunesJuges) {
      node = this.resourceToJugePipe.transform(resource);
    } else if (resource.type === ContentTypes.seance) {
      node = this.resourceToSeancePipe.transform(resource);
    } else if (resource.type === ContentTypes.diplome) {
      node = this.resourceToDiplomePipe.transform(resource);
    } else if (resource.type === ContentTypes.module) {
      node = this.resourceToModulePipe.transform(resource);
    } else if (resource.type === ContentTypes.faceAface) {
      node = this.resourceToFaceAFacePipe.transform(resource);
    } else if (resource.type === ContentTypes.theme) {
      node = this.resourceToThemePipe.transform(resource);
    } else if (resource.type === ContentTypes.faqQuestion) {
      node = this.resourceToFaqQuestionPipe.transform(resource);
    } else if (resource.type === ContentTypes.webform) {
      node = this.resourceToWebformPipe.transform(resource);
    } else {
      console.error('resource type not managed yet', resource.type);
      return resource as DrupalNode;
    }
    return node;
  }

}
