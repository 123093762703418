import {Pipe, PipeTransform} from '@angular/core';
import {FiltersConstants} from "../core/models/filters-constants";
import {SearchFilter} from "../search/models/search-result";

@Pipe({
  name: 'filterLabel'
})
export class FilterLabelPipe implements PipeTransform {

  transform(filter: SearchFilter): any {
    return FiltersConstants.map[filter.name] && FiltersConstants.map[filter.name].label ? FiltersConstants.map[filter.name].label : filter.label;
  }

}
