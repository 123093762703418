import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from "@ngrx/store";
import * as fromCore from "./core/store/reducers";
import {Logout} from "@madeinlune/components";

@Injectable({
  providedIn: 'root'
})

export class TrainingCheckInGuard implements CanActivate {

  constructor(
    private store: Store<fromCore.State>
  ){
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(next.queryParams && next.queryParams.pass){
      this.store.dispatch(new Logout());
      //this.store.dispatch(new AuthenticateSiFfaLink(next.queryParams.pass));
    }

    return true;

  }
}
