import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

  svgIcons: {[iconKey: string]: string} = {
    excel: 'icon-excel',
    powerpoint: 'icon-powerpoint',
    word: 'icon-word',
    pdf: 'icon-pdf',
    zip: 'icon-zip',
    video: 'icon-video',
    audio: 'icon-audio'
  }

  fileIconMap: {[extensionKey: string]: string} = {
    'application/pdf': this.svgIcons.pdf,
    'application/zip': this.svgIcons.zip,

    'application/vnd.openxmlformats-officedocument.presentationml.presentation': this.svgIcons.powerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': this.svgIcons.powerpoint,
    'application/vnd.openxmlformats-officedocument.presentationml.template': this.svgIcons.powerpoint,
    'application/vnd.ms-powerpoint': this.svgIcons.powerpoint,

    'application/msword':this.svgIcons.word,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':this.svgIcons.word,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template':this.svgIcons.word,
    'application/vnd.ms-word.document.macroEnabled.12':this.svgIcons.word,
    'application/vnd.ms-word.template.macroEnabled.12':this.svgIcons.word,

    'application/vnd.ms-excel':this.svgIcons.excel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':this.svgIcons.excel,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template':this.svgIcons.excel,

    'video/mp4': this.svgIcons.video,

    'audio/mpeg': this.svgIcons.audio,

  }

  transform(value: any, args?: any): any {
    if(!this.fileIconMap[value]){
      console.error('icon not found for mime : ', value);
    }
    return this.fileIconMap[value] ? this.fileIconMap[value] : '';
  }

}
