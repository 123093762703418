import {Pipe, PipeTransform} from '@angular/core';
import {DisplayMediaStyles, Image} from "../../core/models/image";
import {DrupalFile} from "../../core/models/file";
import {AppConfigService} from "../../app-config.service";

@Pipe({
  name: 'resourceToImage'
})
export class ResourceToImagePipe implements PipeTransform {

  constructor(private appConfigService: AppConfigService) {
  }


  transform(file: DrupalFile, rawImage: any): Image {
    const imageStyles: DisplayMediaStyles = rawImage?.file?.entity?.image_entity_styles || rawImage?.imageStyles;
    /*console.log({imageStyles});
    Object.keys(imageStyles).forEach(key => {
      const imageStyle = imageStyles[key];
      if(imageStyle.url.indexOf('.s3.') === -1){
        console.log('imageStyle.url', imageStyle.url);
      }
    })*/
    return {
      ...file,
      imageStyles
    };
  }

}
