<ng-container *ngIf="(node$|async) as node">

  <ea-dialog [title]="node.title" *ngIf="!showTitle">
    <div class="content-group">
      <ng-template
        [ngTemplateOutlet]="contentComponent"
        [ngTemplateOutletContext]="{content:node, showTitle:false}">
      </ng-template>
    </div>
  </ea-dialog>

  <ng-template
    *ngIf="!hasData"
    [ngTemplateOutlet]="contentComponent"
    [ngTemplateOutletContext]="{content:node, showTitle:true}">
  </ng-template>

</ng-container>

<ng-template #contentComponent let-content="content" let-showTitle="showTitle">

  <div class="template-content-group">

    <h1 [innerHTML]="content?.title" *ngIf="showTitle"></h1>

    <div [innerHTML]="content?.body|removeAttacks">

    </div>

  </div>

  <ng-template *ngIf="data && data.templates && data.templates.footer" #footer [ngTemplateOutlet]="templatesService.getTemplate(data.templates.footer)"></ng-template>

</ng-template>
