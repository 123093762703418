import {Injectable} from '@angular/core';
import {AppConfigService} from "../app-config.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/Rx";
import {Question} from "./models/question";
import {QuizTake} from "./models/quiz-take";
import {AnswerSubmission} from "./models/answer-submission";
import {QuizTryAgain} from "./store";
import {Store} from "@ngrx/store";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfigService,
    private store: Store<any>,
    public router: Router
  ) {
  }

  ///api/1.0/quiz_take/<id result>/questions/<index>
  public loadQuestion(resultId: string, questionIndex: string): Observable<any> {
    return this.httpClient
      .get<{ question: Question }>(this.appConfig.getConfig().adminUrl + '/api/1.0/quiz_take/' + resultId + '/questions/' + questionIndex);
  }

  loadResult(): any {
  }

  createQuizTake(quizId: string) {
    return this.httpClient
      .post<QuizTake>(
        this.appConfig.getConfig().adminUrl + '/api/1.0/quiz_take',
        {
          'quiz_id': quizId,
        },
        {
          withCredentials: true,
        });
  }

  loadQuizTake(id: string) {
    return this.httpClient
      .get<QuizTake>(
        this.appConfig.getConfig().adminUrl + '/api/1.0/quiz_take/'+id);
  }

  saveQuizAnswer(resultId: string, answerSubmission: AnswerSubmission, index: number){
    return this.httpClient
      .post(
        this.appConfig.getConfig().adminUrl + '/api/1.0/quiz_take/' + resultId + '/submit_answer/' + index,
        answerSubmission,
        {
          withCredentials: true,
        });
  }

  resetQuiz(level: string, activatedRoute: ActivatedRoute) {
    this.router.navigate([level], {relativeTo: activatedRoute}).then(()=>{
      this.store.dispatch(new QuizTryAgain());
    });
  }

}
