<div class="menu-group">

  <ul class="menu-group__items menu-group__items-legal">

    <li
      *ngFor="let menuItem of legalMenu?.items"
      class="ml-2 mr-2">
      <a
        [innerHTML]="menuItem.title"
        [routerLink]="[{outlets:{popUp:menuItem.path}}]">
      </a>
    </li>
  </ul>

  <ul class="menu-group__items menu-group__items-secondary">

    <li
      *ngFor="let menuItem of secondaryMenu?.items"
      class="ml-2 mr-2">
      <a
        [innerHTML]="menuItem.title"
        [routerLink]="menuItem.path">
      </a>
    </li>
  </ul>

</div>

<div class="copyright-layer">

  <small>copyright {{currentYear}} Fédération Française d’Athlétisme - tous droits réservés</small>

</div>

