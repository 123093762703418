import {Action} from '@ngrx/store';
import {SearchFilter, SearchFilterOption, SearchResult, UserSearchFilter} from "../models/search-result";
import {PageEvent} from "@angular/material/paginator";

export const SEARCH = '[Search] Trigger';
export const SEARCH_FAIL = SEARCH + ' > Fail';
export const SEARCH_SUCCESS = SEARCH + ' > Success';

export const SEARCH_WELCOME = '[Search] Welcome';

export const SEARCH_NEXT_PAGE = '[Search] Go next page';
export const SEARCH_NEXT_PAGE_FAIL = SEARCH_NEXT_PAGE + ' > Fail';
export const SEARCH_NEXT_PAGE_SUCCESS = SEARCH_NEXT_PAGE + ' > Success';

export const SEARCH_PREVIOUS_PAGE = '[Search] Go previous page';
export const SEARCH_PREVIOUS_PAGE_FAIL = SEARCH_PREVIOUS_PAGE + ' > Fail';
export const SEARCH_PREVIOUS_PAGE_SUCCESS = SEARCH_PREVIOUS_PAGE + ' > Success';

export const SEARCH_UPDATE_CONTENT_TYPES = '[Search] Update Content Types';
export const SEARCH_UPDATE_QUERY = '[Search] Update Query';
export const SEARCH_UPDATE_FILTERS = '[Search] Update Filters';
export const SEARCH_CLEAR = '[Search] Clear';

export const SEARCH_REMOVE_FILTER_OPTION = '[Search] Remove Filter Option';

export const SET_CONTENT_TYPES = '[Search] Set Content Types';

export const SET_QUERY = '[Search] Set Query';

export const SET_FILTERS = '[Search] Set Filters';
export const ADD_FILTER = '[Search] Add Filter';
export const REMOVE_FILTER = '[Search] Remove Filter';
export const CLEAR_ALL_FILTERS = '[Search] Clear All Filters';

export const SET_PAGER_INFOS = '[Search] Set Pager Infos';

export const USER_REQUEST_EMPTY = '[Search] User Request empty';

export const UPDATE_URL = '[Search] Update URL';

export const SET_FILTERS_FROM_QUERY_PARAMS = '[Search] Set filters from Query Params';

export const FIND_CONTENT_BY_ID = '[Search] Find content by Id';

export const SEARCH_RESET = '[Search] Reset';

export const ADD_CORE_FILTER = '[Search] Add Core Filter';
export const REMOVE_CORE_FILTER = '[Search] Remove Core Filter';

export const LOAD_META_DATA = '[Search] Load Metadata';
export const LOAD_META_DATA_SUCCESS = LOAD_META_DATA + ' > Success';
export const LOAD_META_DATA_FAIL = LOAD_META_DATA + ' > Fail';

export const LOAD_FACETS_FOR_UNIVERSE = '[Search] Load Facets for universe';
export const LOAD_FACETS_FOR_UNIVERSE_SUCCESS = LOAD_FACETS_FOR_UNIVERSE + ' > Success';
export const LOAD_FACETS_FOR_UNIVERSE_FAIL = LOAD_FACETS_FOR_UNIVERSE + ' > Fail';

export const SET_EXTENDED_TYPES = '[Search] Set Extended Types';

export class SearchAction implements Action {

  readonly type = SEARCH;

  constructor(public reset: boolean = true) {
  }
}

export class SearchWelcome implements Action {

  readonly type = SEARCH_WELCOME;

  constructor() {
  }
}

export class SearchActionFail implements Action {
  readonly type = SEARCH_FAIL;

  constructor(public payload: any) {
  }
}

export class SearchActionSuccess implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(
    public result: SearchResult,
    public filters: SearchFilter[],
    public filtersMap: {[filterName: string]:SearchFilter},
    public options: { [optionKey: string]: SearchFilterOption },
    public optionsCount: {[optionKey: string]:number}
    ) {
  }
}

export class SearchActionRemoveFilterOption implements Action {
  readonly type = SEARCH_REMOVE_FILTER_OPTION;

  constructor(public searchFilter: SearchFilter, public searchFilterOption: SearchFilterOption) {
  }
}

// TODO rename Action : called when Pager changes, not only next
export class SearchNextPageAction implements Action {
  readonly type = SEARCH_NEXT_PAGE;

  constructor(public pageEvent: PageEvent) {
  }
}

export class SearchUpdateContentTypesAction implements Action {
  readonly type = SEARCH_UPDATE_CONTENT_TYPES;

  constructor(public payload: any[] = null) {
  }
}

export class SearchUpdateQuery implements Action {
  readonly type = SEARCH_UPDATE_QUERY;

  constructor(public payload: any = null) {
  }
}

export class SearchUpdateFilters implements Action {
  readonly type = SEARCH_UPDATE_FILTERS;

  constructor(public payload: { [filterId: string]: string[] | string }) {
    //console.log('SearchUpdateFilters payload', payload);
  }
}

export class ClearAllFilters implements Action {
  readonly type = CLEAR_ALL_FILTERS;

  constructor() {
  }
}

export class SearchClear implements Action {
  readonly type = SEARCH_CLEAR;

  constructor(public payload: any = null) {
  }
}

export class SearchReset implements Action {
  readonly type = SEARCH_RESET;

  constructor() {
  }
}

export class SetContentTypes implements Action {
  readonly type = SET_CONTENT_TYPES;

  constructor(public payload: any) {
  }
}

export class SetExtendedTypes implements Action {
  readonly type = SET_EXTENDED_TYPES;

  constructor(public payload: SearchFilter) {
  }
}

export class SetFiltersfromQueryParams implements Action {
  readonly type = SET_FILTERS_FROM_QUERY_PARAMS;

  constructor(public payload: any) {
  }
}

export class SetQuery implements Action {
  readonly type = SET_QUERY;

  constructor(public payload: any) {
  }
}

export class AddFilter implements Action {
  readonly type = ADD_FILTER;

  constructor(public payload: SearchFilter) {
  }
}

export class SetPagerInfos implements Action {
  readonly type = SET_PAGER_INFOS;

  constructor(public payload: any) {
  }
}

export class RemoveFilter implements Action {
  readonly type = REMOVE_FILTER;

  constructor(public payload: UserSearchFilter) {
  }
}

export class UserRequestEmpty implements Action {

  readonly type = USER_REQUEST_EMPTY;

  constructor(public payload: any = null) {
  }
}

export class UpdateUrl implements Action {
  readonly type = UPDATE_URL;

  constructor() {
  }
}

export class FindContentById implements Action {
  readonly type = FIND_CONTENT_BY_ID;

  constructor(public payload: any) {
  }
}

export class AddCoreFilter implements Action {
  readonly type = ADD_CORE_FILTER;

  constructor(public payload: SearchFilter) {
  }
}

export class RemoveCoreFilter implements Action {
  readonly type = REMOVE_CORE_FILTER;

  constructor(public payload: SearchFilter) {
  }
}

export class LoadMetaData implements Action {
  readonly type = LOAD_META_DATA;

  constructor() {
  }
}

export class LoadMetaDataSuccess implements Action {
  readonly type = LOAD_META_DATA_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadMetaDataFail implements Action {
  readonly type = LOAD_META_DATA_FAIL;

  constructor(public error: any) {
  }
}

export class LoadFacetsForUniverse implements Action {
  readonly type = LOAD_FACETS_FOR_UNIVERSE;

  constructor(public universe: string, public universeContentTypes: string[]) {
  }
}

export class LoadFacetsForUniverseSuccess implements Action {
  readonly type = LOAD_FACETS_FOR_UNIVERSE_SUCCESS;

  constructor(
    public universe: string,
    public filters: SearchFilter[],
    public options: { [key: string]: SearchFilterOption }
  ) {
  }
}

export class LoadFacetsForUniverseFail implements Action {
  readonly type = LOAD_FACETS_FOR_UNIVERSE_FAIL;

  constructor(public error: any) {
  }
}

export type SearchActions =
  AddFilter
  | ClearAllFilters
  | RemoveFilter
  | SearchAction
  | SearchActionFail
  | SearchActionSuccess
  | SearchNextPageAction
  | SearchUpdateQuery
  | SearchClear
  | SearchUpdateFilters
  | SearchActionRemoveFilterOption
  | SearchWelcome
  | SetContentTypes
  | SetFiltersfromQueryParams
  | SetQuery
  | UserRequestEmpty
  | UpdateUrl
  | FindContentById
  | SearchReset
  | AddCoreFilter
  | RemoveCoreFilter
  | LoadMetaData
  | LoadMetaDataSuccess
  | LoadMetaDataFail
  | LoadFacetsForUniverse
  | LoadFacetsForUniverseSuccess
  | LoadFacetsForUniverseFail
  | SetExtendedTypes;
