/**
 * Resolves resources for editors and explorers.
 */
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {LoadContext, SetUniverse} from "../core/store/actions";
import {AppUniverses} from "../core/models/app-universes";
import {ContextConstants} from "../core/models/contexts-constants";
import {Contexts} from "../core/models/page";
import {loadNodesByType} from "../core/store/actions/node.actions";
import {ContentTypes} from "../core/models/node";


@Injectable()
export class HomeResolver implements Resolve<any> {

  constructor(
    private store: Store<any>,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    this.store.dispatch(new SetUniverse(AppUniverses.home));
    this.store.dispatch(new LoadContext('home'));
    this.store.dispatch(new LoadContext('encyclopedia'));
    this.store.dispatch(new LoadContext('themes'));
    this.store.dispatch(new LoadContext('tutorials'));
    this.store.dispatch(new LoadContext('training'));
    this.store.dispatch(loadNodesByType({contentType: ContentTypes.news, limit: 1, promoted: true}));
    return true;
  }

}


