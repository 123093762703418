import {Image} from "../../core/models/image";
import {Video} from "../../core/models/video";

/**
 * Created by benoitplatre on 29/08/2018.
 */

export class Question {

  static readonly multichoice: string = 'multichoice';

  static readonly truefalse: string = 'truefalse';

  id: string;
  description: string;
  alternatives?: QuestionAlternative[];
  multiple: string;
  title?: string;
  type: string;
  //truefalse question
  correctAnswer?: boolean;
  edit_url?: string;

  image?: Image;
  video?: Video;

  answersImageMode: boolean;

}

export class QuestionAlternative {
  answer?: string;
  id?: string;
  image?: Image;
}
