

export class SubscriptionContext{

  static readonly stepChoice: string = 'choice';
  static readonly stepLegal: string = 'legal';
  static readonly stepForm: string = 'form';
  static readonly stepSiFfa: string = 'siFfa';
  static readonly stepFinish: string = 'finish';
  static readonly stepCart: string = 'cart';

  static readonly modeNoLicence: string = 'noLicence';
  static readonly modeLicence: string = 'licence';
  static readonly modeSiFfa: string = 'siFfa';
  static readonly modeChoice: string = 'choice';

  static readonly ssiFfa: string = 'ssiFfa';
  static readonly public: string = 'public';

  name:string;
  modes: {[modeId: string]: SubscriptionMode};


}

export class SubscriptionMode{
  name: string;
  steps?: SubscriptionStep[];
}

export class SubscriptionStep{
  name: string;
  label?: string;
}
