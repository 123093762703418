import * as fromContexts from '../actions/contexts.action';
import {ContextsActionTypes, LoadContextSuccess} from '../actions/contexts.action';
import {AppError} from "@madeinlune/components/lib/models/app-error.model";

export class ContextsState {
  loading: boolean;
  loaded: boolean;
  error: AppError;
  contexts: {[contextId:string]:any};
}

const initialState: ContextsState = {
  loaded: false,
  loading: false,
  error: null,
  contexts: {}
};

export function contextsReducer(state: ContextsState = initialState, action: fromContexts.ContextsActions) {

  switch (action.type) {


    case ContextsActionTypes.LoadContext: {
      return {
        ...state,
        loading: true
      };
    }

    case ContextsActionTypes.LoadContextSuccess: {
      //state.contexts[(action as LoadContextSuccess).contextId] = action.payload;
      let contexts = {...state.contexts};
      contexts[(action as LoadContextSuccess).contextId] = action.payload;
      return {
        ...state,
        contexts: contexts,
        loading: false
      };
    }

    case ContextsActionTypes.LoadContextFail: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

  }

  return state;

}
