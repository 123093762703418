import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfigService} from "../app-config.service";

@Injectable()
export class SwBypassInterceptor implements HttpInterceptor {

  protected urlPrefixes: string[];

  constructor(protected appConfig: AppConfigService) {
  }

  protected initializeUrlPrefixes() {
    if (!this.urlPrefixes && !!this.appConfig.getConfig()) {
      const configKeys = [
        'rawApiBaseUrl',
        'apiBaseUrl'
      ];
      this.urlPrefixes = configKeys
        .filter(key => !!this.appConfig.getConfig()[key])
        .map(key => this.appConfig.getConfig()[key]);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.initializeUrlPrefixes();
    if (this.urlPrefixes && this.urlPrefixes.filter(prefix => req.url.startsWith(prefix)).length > 0) {
      if (!req.params.has('ngsw-bypass')) {
        return next.handle(req.clone({params: req.params.append('ngsw-bypass', '1')}));
      }
    }
    return next.handle(req);
  }
}
