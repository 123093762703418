// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {
  getBookmarkIds,
  getBookmarksCount,
  getBookmarksFull,
  getPreferredAgeCriteria,
  getUserInfos,
  getUserIsContributor,
  getUserIsFormation,
  getUserIsSubscriber,
  getUserIsTrainee,
  getVocabularyById
} from "../core/store/reducers";
import {filter, map, withLatestFrom} from "rxjs/operators";
import {UNIVERSE_ENCYCLOPEDIA_CONTENT_TYPES, UNIVERSE_TRAINING_CONTENT_TYPES} from "./contents.providers";
import {User} from "../core/models/user";
import {DrupalVocabularyTerm} from "../core/models/vocabulary";
import {AgeType} from "../core/store/reducers/user.reducer";

export const USER_ID = new InjectionToken<string>(
  'A stream to get the User id'
);

export const USER_INFOS = new InjectionToken<User>(
  'A stream to get the User Infos'
);

export const IS_SUBSCRIBER = new InjectionToken<boolean>(
  'A stream to know if the User is Subcriber'
);

export const IS_CONTRIBUTOR = new InjectionToken<boolean>(
  'A stream to know if the User is Contributor'
);

export const IS_TRAINEE = new InjectionToken<boolean>(
  'IS_TRAINEE'
);

export const HAS_FORMATION_ACCESS = new InjectionToken<boolean>(
  'A stream to know if the User can access to the Formation contents'
);

export const BOOKMARKS_TOTAL = new InjectionToken<number>(
  'A stream to know many bookmarks the user have'
);

export const BOOKMARK_IDS = new InjectionToken<string[]>(
  'A stream with the list of bookmarks Ids'
);

export const BOOKMARKS_FULL = new InjectionToken<any[]>(
  'A bookmarks stream with full contents'
);

export const BOOKMARKS_TRAINING = new InjectionToken<any[]>(
  'A bookmarks stream with full contents containing only Formation contents'
);

export const BOOKMARKS_ENCYCLOPEDIA = new InjectionToken<any[]>(
  'A bookmarks stream with full contents containing only Encyclopedia contents'
);

export const USER_DOMAINS = new InjectionToken<Observable<DrupalVocabularyTerm[]>>(
  'A stream with user Domains'
);

export const FILIERES_ONLY = new InjectionToken<Observable<DrupalVocabularyTerm[]>>(
  'A stream with user Filieres Only'
);

export const DOMAINS_ONLY = new InjectionToken<Observable<DrupalVocabularyTerm[]>>(
  'A stream with user Domains Only'
);

export const PREFERRED_AGE_CRITERIA = new InjectionToken<Observable<AgeType>>(
  'PREFERRED_AGE_CRITERIA'
);

export const USER_PROVIDERS: Provider[] = [
  {
    deps: [Store],
    provide: USER_INFOS,
    useFactory: getUserInfosFactory
  },
  {
    deps: [USER_INFOS],
    provide: USER_ID,
    useFactory: getUserIdFactory
  },
  {
    deps: [Store],
    provide: IS_SUBSCRIBER,
    useFactory: isSubscriberFactory
  },
  {
    deps: [Store],
    provide: IS_CONTRIBUTOR,
    useFactory: isContributorFactory
  },
  {
    deps: [Store],
    provide: IS_TRAINEE,
    useFactory: (store: Store<any>) => {
      return store.pipe(select(getUserIsTrainee))
    }
  },
  {
    deps: [Store],
    provide: HAS_FORMATION_ACCESS,
    useFactory: hasFormationAccessFactory
  },
  {
    deps: [Store],
    provide: BOOKMARKS_TOTAL,
    useFactory: bookmarksTotalFactory
  },
  {
    deps: [Store],
    provide: BOOKMARK_IDS,
    useFactory: bookmarkIdsFactory
  },
  {
    deps: [Store],
    provide: BOOKMARKS_FULL,
    useFactory: bookmarksFullFactory
  },
  {
    deps: [Store],
    provide: PREFERRED_AGE_CRITERIA,
    useFactory: preferredAgeCriteriaFactory
  },
  {
    deps: [BOOKMARKS_FULL, UNIVERSE_ENCYCLOPEDIA_CONTENT_TYPES],
    provide: BOOKMARKS_ENCYCLOPEDIA,
    useFactory: bookmarksFullEncyclopediaFactory
  },
  {
    deps: [BOOKMARKS_FULL, UNIVERSE_TRAINING_CONTENT_TYPES],
    provide: BOOKMARKS_TRAINING,
    useFactory: bookmarksFullTrainingFactory
  },
  {
    deps: [Store, USER_INFOS],
    provide: USER_DOMAINS,
    useFactory: userDomainsFactory
  },
  {
    deps: [USER_DOMAINS],
    provide: FILIERES_ONLY,
    useFactory: userFilieresFactory
  },
  {
    deps: [USER_DOMAINS],
    provide: DOMAINS_ONLY,
    useFactory: userDomainsOnlyFactory
  }
];

export function getUserInfosFactory(
  store: Store<any>
): Observable<User> {
  return store.pipe(select(getUserInfos));
}

export function getUserIdFactory(
  userInfos$: Observable<User>
): Observable<string> {
  return userInfos$.pipe(
    filter(userInfos => !!userInfos),
    map(userInfos => userInfos.id)
  );
}

export function isSubscriberFactory(
  store: Store<any>
): Observable<boolean> {
  return store.pipe(
    select(getUserIsSubscriber)
  );
}

export function hasFormationAccessFactory(
  store: Store<any>
): Observable<boolean> {
  return store.pipe(
    select(getUserIsFormation)
  );
}

export function isContributorFactory(
  store: Store<any>
): Observable<boolean> {
  return store.pipe(
    select(getUserIsContributor)
  );
}

export function bookmarksTotalFactory(
  store: Store<any>
): Observable<number> {
  return store.pipe(
    select(getBookmarksCount)
  );
}

export function bookmarkIdsFactory(
  store: Store<any>
): Observable<string[]> {
  return store.pipe(
    map(store => {
      return store;
    }),
    select(getBookmarkIds)
  );
}

export function bookmarksFullFactory(
  store: Store<any>
): Observable<any[]> {
  return store.pipe(
    select(getBookmarksFull)
  );
}

export function bookmarksFullEncyclopediaFactory(
  bookmarksFull$: Observable<any[]>,
  contentTypes: string[]
): Observable<any[]> {
  return bookmarksFull$.pipe(
    filter(bookmarksFull => !!bookmarksFull),
    map(bookmarksFull => {
      return bookmarksFull.filter(bookmark => contentTypes.indexOf(bookmark.type) > -1);
    })
  );
}

export function bookmarksFullTrainingFactory(
  bookmarksFull$: Observable<any[]>,
  contentTypes: string[]
): Observable<any[]> {
  return bookmarksFull$.pipe(
    filter(bookmarksFull => !!bookmarksFull),
    map(bookmarksFull => {
      return bookmarksFull.filter(bookmark => contentTypes.indexOf(bookmark.type) > -1);
    })
  );
}

export function userDomainsFactory(
  store: Store<any>,
  userInfos$: Observable<User>
): Observable<any[]> {
  return userInfos$.pipe(
    withLatestFrom(store.pipe(select(getVocabularyById('domains')))),
    map(([userInfos, vocabulary]) => {
      let userDomains = vocabulary?.items?.filter(term => userInfos?.domains?.indexOf(term?.tid) > -1);
      return userDomains;
    })
  );
}

export function userDomainsOnlyFactory(
  userDomains$: Observable<DrupalVocabularyTerm[]>
): Observable<any[]> {
  return userDomains$.pipe(
    map((terms: DrupalVocabularyTerm[]) => {
      return terms?.filter(term => term.parents[0] === '0');
    })
  );
}

export function userFilieresFactory(
  userDomains$: Observable<DrupalVocabularyTerm[]>
): Observable<any[]> {
  return userDomains$.pipe(
    map((terms: DrupalVocabularyTerm[]) => {
      return terms?.filter(term => term.parents?.length === 1 && term.parents[0] !== '0');
    })
  );
}

export function preferredAgeCriteriaFactory(
  store: Store<any>
): Observable<AgeType> {
  return store.pipe(
    select(getPreferredAgeCriteria)
  );
}
