import {ECommerceActions} from '../actions';
import {Cart} from "../../models/cart";

export interface State {
  cart: Cart;
}

const initialState: State = {
  cart: null
};

export function reducer(
  state: State = initialState,
  action: ECommerceActions.ECommerceActionsUnion
): State {
  switch (action.type) {

    case ECommerceActions.ECommerceActionTypes.LoadCartSuccess:
      return {
        ...state,
        cart: action.cart
      };

    case ECommerceActions.ECommerceActionTypes.ResetECommerce:
      return initialState;

    default:
      return state;
  }
}

export const getCart = (state: State) => state.cart;
