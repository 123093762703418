import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from "@ngrx/store";
import * as fromCore from "./core/store/reducers";
import {getUserInfos, getUserIsFormation} from "./core/store/reducers";
import {filter, map, take, withLatestFrom} from "rxjs/operators";
import {ErrorWindowComponent} from "./components/error-window/error-window.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})

export class TrainingGuard implements CanActivate {

  constructor(
    private store: Store<fromCore.State>,
    private router: Router,
    private dialog: MatDialog
  ){
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let isFormation$ = this.store.pipe(
      withLatestFrom(
        this.store.pipe(
          select(getUserInfos),
          filter(userInfos => !!userInfos)
        )
      ),
      withLatestFrom(this.store.pipe(select(getUserIsFormation))),
      map(([userInfosResult, isFormation]: [any[], boolean]) => {
        if(isFormation){
          return true;
        }
        this.dialog.open(ErrorWindowComponent, {
          width: '500px',
          panelClass:'user-theme',
          data: {
            title:'Accès privé',
            message:'Ce contenu est réservé est une certaine catégorie d’utilisateurs.<br>Merci de contacter la FFA pour plus d’informations',
            actions: ['OK']
          }
        });
        return false;
      }),
      take(1)
    );

    return isFormation$;

  }
}
