import {Inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from "@ngrx/effects";

import * as userActions from "../actions/user.action";
import {
  AddBookmark,
  AddBookmarkFail,
  AddBookmarkSuccess,
  CreateAccount,
  ForgotPassword,
  ForgotPasswordNewPassword,
  LoadBookmarks,
  LoadBookmarksFull,
  LoadBookmarksFullSuccess,
  LoadBookmarksSuccess,
  LoadOrders,
  LoadOrdersFail,
  LoadOrdersSuccess,
  LoadTraineeInfosSuccess,
  LoadUserInfos,
  LoadUserInfosFail,
  LoadUserInfosSuccess,
  LoadUserMeta,
  LoadUserMetaFail,
  LoadUserMetaSuccess,
  RemoveBookmark,
  RemoveBookmarkFail,
  RemoveBookmarkSuccess,
  RequestPassword,
  SetPreferredAgeCriteria,
  UpdateUser,
  UpdateUserFail,
  UpdateUserSuccess
} from "../actions/user.action";
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {Router} from "@angular/router";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {select, Store} from "@ngrx/store";
import {
  AUTHENTICATE_FAIL,
  AUTHENTICATE_SUCCESS,
  AuthenticateAction,
  DrupalAuthService,
  INIT_STATE_SUCCESS,
  Logout,
  LOGOUT_SUCCESS,
  selectFeature
} from '@madeinlune/components';
import {AppConfigService} from "../../../app-config.service";
import * as fromCore from '../../store/reducers';
import {getUserInfos} from '../../store/reducers';
import {Coupon} from "../../models/order";
import {User} from "../../models/user";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Go} from "../../../store/actions";
import {AppSnackbarComponent} from "../../../components/app-snackbar/app-snackbar.component";
import {setUser} from '@sentry/browser';
import {homePath, loginPath} from "../../../route-constants";
import {USER_ID} from "../../../providers/user.providers";
import {SearchService} from "../../../search/search.service";
import {AgeType} from "../reducers/user.reducer";

export const preferredAgeCriteria = 'preferredAgeCriteria';

@Injectable()
export class UserEffects {

  constructor(private actions$: Actions,
              private store: Store<fromCore.State>,
              private authService: DrupalAuthService,
              private appConfig: AppConfigService,
              private httpClient: HttpClient,
              private router: Router,
              private snackBar: MatSnackBar,
              private searchService: SearchService,
              @Inject(USER_ID) public readonly userId$: Observable<string>
  ) {
  }

  // /api/user/543/bookmarks/7023


  userLoaded$ = createEffect(() => this.userId$.pipe(
    switchMap((userId: string) => {
      return [new LoadBookmarksFull(userId)];
    })
  ));


  createAccount$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.CREATE_ACCOUNT),
    switchMap((action: CreateAccount) => {
      return this.httpClient.post(
        this.appConfig.getConfig().apiBaseUrl + '/user',
        {
          email: action.account.mail,
          password: action.account.password,
          lastName: action.account.lastname,
          firstName: action.account.firstname,
          gender: action.account.gender === 'm' ? 'M' : 'F',
          ffaLicenseNumber: action.account.ffaLicenseNumber,
          birthDate: action.account.birthDate,
        }
      ).pipe(
        tap(result => {
          this.store.dispatch(new AuthenticateAction(action.account.mail, action.account.password));
        }),
        map(result => {
          this.snackBar.openFromComponent(AppSnackbarComponent,
            {
              data: {message: 'Félicitations, vous avez désormais un compte sur Formation-Athlé !'},
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: 'ffa-snackbar'
            });
          return new userActions.CreateAccountSuccess(result['uid'])
        }),
        catchError((error: HttpErrorResponse) => of(new userActions.CreateAccountFail(error)))
      );
    })
  ));


  updateAccount$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.UPDATE_USER),
    filter((action: UpdateUser) => !!action.userInfos),
    switchMap((action: UpdateUser) => {
      const clonedUserInfos: any = JSON.parse(JSON.stringify(action.userInfos));
      const userId: string = clonedUserInfos.id;
      delete clonedUserInfos.ffaLicenseValid;
      return this.httpClient.put(
        this.appConfig.getConfig().apiBaseUrl + '/user/' + userId,
        clonedUserInfos
      ).pipe(
        switchMap(result => {
          this.snackBar.openFromComponent(AppSnackbarComponent,
            {
              data: {message: 'Vos informations ont bien été enregistrées'},
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: 'ffa-snackbar'
            });
          return [new UpdateUserSuccess(result as User), new LoadUserInfos()];
        }),
        catchError((error: HttpErrorResponse) => of(new UpdateUserFail(error)))
      );
    })
  ));

  //https://www.entrainement-athle.fr/api/user/password
  //POST
  //{{username: "benoit@icilalune.com"}}

  forgotPassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.FORGOT_PASSWORD),
    switchMap((action: ForgotPassword) => {
      return this.httpClient.post(
        this.appConfig.getConfig().apiBaseUrl + '/user/password',
        {
          username: action.userName
        }
      ).pipe(
        tap(result => {
          this.snackBar.openFromComponent(AppSnackbarComponent,
            {
              data: {message: 'Un mail vous a été envoyé pour vous permettre de réinitialiser votre mot de passe'},
              duration: 4000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: 'ffa-snackbar'
            });
        }),
        catchError((error: HttpErrorResponse) => of(new userActions.RequestPasswordFail(error.error)))
      );
    })
  ), {dispatch: false});

  //https://www.entrainement-athle.fr/api/user/543/password


  forgotPasswordNewPassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.FORGOT_PASSWORD_NEW_PASSWORD),
    switchMap((action: ForgotPasswordNewPassword) => {
      return this.httpClient.put(
        this.appConfig.getConfig().apiBaseUrl + '/user/' + action.forgotPassword.uid + '/password',
        {
          newPassword: action.forgotPassword.newPassword,
          token: action.forgotPassword.token,
          timestamp: action.forgotPassword.timestamp
        }
      ).pipe(
        map(result => {
          this.snackBar.openFromComponent(AppSnackbarComponent,
            {
              data: {message: 'Votre mot de passe a été réinitialisé, vous pouvez désormais vous connecter'},
              duration: 4000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: 'ffa-snackbar'
            });
          return new Go({path: [{outlets: {primary: homePath, userLoginOutlet: loginPath, popUp: null}}]});
        }),
        catchError((error: HttpErrorResponse) => of(new userActions.RequestPasswordFail(error.error)))
      );
    })
  ));


  requestPassword$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.REQUEST_PASSWORD),
    withLatestFrom(this.store.pipe(select(getUserInfos))),
    switchMap(([action, userInfos]: [RequestPassword, User]) => {
      return this.httpClient.put(
        this.appConfig.getConfig().apiBaseUrl + '/user/' + userInfos.id + '/password',
        action.changePassword
      ).pipe(
        map(result => {
          this.snackBar.openFromComponent(AppSnackbarComponent,
            {
              data: {message: 'Votre mot de passe a bien été mis à jour'},
              duration: 2000,
              verticalPosition: 'top',
              horizontalPosition: 'right',
              panelClass: 'ffa-snackbar'
            });
          return new userActions.RequestPasswordSuccess()
        }),
        catchError((error: HttpErrorResponse) => of(new userActions.RequestPasswordFail(error.error)))
      );
    })
  ));


  loadUserInfos$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.LOAD_USER_INFOS),
    switchMap((action: LoadUserInfos) => {
      return this.httpClient.get<any>(
        this.appConfig.getConfig().apiBaseUrl + '/me'
      ).pipe(
        switchMap((user: User) => {
          let loadUserInfosSuccess = new LoadUserInfosSuccess(user);
          let loadTraineeInfosSuccess: LoadTraineeInfosSuccess;
          if (!user.isStagiaire) {
            loadTraineeInfosSuccess = new LoadTraineeInfosSuccess(null);
          } else {
            loadTraineeInfosSuccess = new LoadTraineeInfosSuccess({modules: user.stagiaireModules});
          }
          return [loadUserInfosSuccess, new LoadBookmarks(user.id), loadTraineeInfosSuccess];
        }),
        catchError((error: HttpErrorResponse) => {
          let loadUserInfosFail = new LoadUserInfosFail(error);
          return of(loadUserInfosFail);
        })
      );
    })
  ));


  loadBookmarksFull$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.LOAD_BOOKMARKS_FULL),
    mergeMap((action: LoadBookmarksFull) => {
      return this.searchService.searchBookmarks(action.userId).pipe(
        map(result => {
          return new LoadBookmarksFullSuccess(result?.items);
        })
      )
    })
  ));


  setSentryUser$ = createEffect(() => this.actions$.pipe(
    filter(action => [INIT_STATE_SUCCESS, AUTHENTICATE_SUCCESS, AUTHENTICATE_FAIL, LOGOUT_SUCCESS].indexOf(action.type) >= 0),
    withLatestFrom(this.store.select(selectFeature)),
    tap(([action, authState]) => {
      if (!authState.loading) {
        if (authState.authenticated) {
          const claims = authState.claims;
          setUser({
            id: claims['sub'],
            username: claims['preferred_username'],
            email: claims['email'],
          });
        } else {
          setUser(null);
        }
      }
    })
  ), {dispatch: false});


  onLoadUserInfoFailure$ = createEffect(() => this.actions$.pipe(
    ofType<LoadUserInfosFail>(userActions.LOAD_USER_INFOS_FAIL),
    filter(action => (action.error instanceof HttpErrorResponse)),
    map((action) => {
      if ((action.error as HttpErrorResponse).status == 401) {
        return new Logout();
      } else {
        /*const dialogRef = this.dialog.open(ErrorWindowComponent, {
          width: '500px',
          panelClass: 'user-theme',
          data: {
            title: 'Oups',
            message: 'Désolé, une erreur est survenue dans la récupération des informations liées à votre compte.<br>Vous pouvez néanmoins continuer votre navigation.'
          }
        });*/
        return new LoadUserInfosSuccess({firstName: ''});
      }
    })
  ));


  loadUserMeta$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.LOAD_USER_META),
    switchMap((action: LoadUserMeta) => {
      return this.httpClient.get<any>(
        this.appConfig.getConfig().apiBaseUrl + '/meta'
      ).pipe(
        map(result => {
          let loadUserInfosSuccess = new LoadUserMetaSuccess(result);
          return loadUserInfosSuccess;
        }),
        catchError((error: HttpErrorResponse) => {
          let loadUserInfosFail = new LoadUserMetaFail(error);
          return of(loadUserInfosFail);
        })
      );
    })
  ));


  loadOrders$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.LOAD_ORDERS),
    switchMap((action: LoadOrders) => {
      return this.httpClient.get<any>(
        this.appConfig.getConfig().apiBaseUrl + '/order'
      ).pipe(
        filter(result => !!result),
        map(result => {

          this.parseOrderLines(result);

          let loadOrdersSuccess = new LoadOrdersSuccess(result);
          return loadOrdersSuccess;
        }),
        catchError((error: HttpErrorResponse) => {
          let loadOrdersFail = new LoadOrdersFail(error);
          return of(loadOrdersFail);
        })
      );
    })
  ));


  loadUserBookmarks$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.LOAD_BOOKMARKS),
    switchMap((action: LoadBookmarks) => {
      return this.httpClient.get<any>(
        this.appConfig.getConfig().apiBaseUrl + '/user/' + action.userId + '/bookmarks'
      ).pipe(
        switchMap((bookmarks: any[]) => {
          let loadBookmarksSuccess = new LoadBookmarksSuccess(bookmarks);
          return [loadBookmarksSuccess];
        }),
        catchError((error: HttpErrorResponse) => {
          let loadUserInfosFail = new LoadUserInfosFail(error);
          return of(loadUserInfosFail);
        })
      );
    })
  ));


  addBookmark$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.ADD_BOOKMARK),
    withLatestFrom(this.store.pipe(select(getUserInfos))),
    switchMap(([action, user]: [AddBookmark, User]) => {
      return this.httpClient.put(
        this.appConfig.getConfig().apiBaseUrl + '/user/' + user.id + '/bookmarks/' + action.contentId,
        null
      ).pipe(
        switchMap(() => {
          let addBookmarkSuccess = new AddBookmarkSuccess();
          return [addBookmarkSuccess, new LoadBookmarks(user.id), new LoadBookmarksFull(user.id)];
        }),
        catchError((error: HttpErrorResponse) => {
          let addBookmarkFail = new AddBookmarkFail(error);
          return of(addBookmarkFail);
        })
      );
    })
  ));


  removeBookmark$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.REMOVE_BOOKMARK),
    withLatestFrom(this.store.pipe(select(getUserInfos))),
    switchMap(([action, user]: [RemoveBookmark, User]) => {
      return this.httpClient.delete(
        this.appConfig.getConfig().apiBaseUrl + '/user/' + user.id + '/bookmarks/' + action.contentId
      ).pipe(
        switchMap(() => {
          let removeBookmarkSuccess = new RemoveBookmarkSuccess();
          return [removeBookmarkSuccess, new LoadBookmarks(user.id), new LoadBookmarksFull(user.id)];
        }),
        catchError((error: HttpErrorResponse) => {
          let removeBookmarkFail = new RemoveBookmarkFail(error);
          return of(removeBookmarkFail);
        })
      );
    })
  ));


  setPreferredAgeCriteria$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.SET_PREFERRED_AGE_CRITERIA),
    tap((action: SetPreferredAgeCriteria) => {
      if (localStorage) {
        localStorage.setItem(preferredAgeCriteria, action.ageType);
      }
    })
  ), {dispatch: false});


  parseOrderLines(lines: any[]) {
    for (let i = 0; i < lines.length; i++) {
      let item: any = lines[i];
      this.parseOrderLine(item);
    }
  }

  parseOrderLine(line: any) {

    try {
      let commerceLineItemIds: string[] = line.raw.commerce_line_items;
      let commerceLineItemFirstId = commerceLineItemIds.shift();
      let commerceLineItemEntity = line.raw.commerce_line_items_entities[commerceLineItemFirstId];
      //
      line.title = commerceLineItemEntity.line_item_title;
      line.orderId = commerceLineItemEntity.order_id;
      line.orderType = commerceLineItemEntity.line_item_label;
      //
      let couponsIds: string[] = commerceLineItemEntity.field_ffa_line_item_coupons;
      if (couponsIds && couponsIds.length > 0) {
        line.coupons = [];
        line.unusedCouponsCount = 0;
        for (let i = 0; i < couponsIds.length; i++) {
          let couponsId: string = couponsIds[i];
          let coupon: Coupon = commerceLineItemEntity.field_ffa_line_item_coupons_entities[couponsId];
          line.coupons.push(coupon);
          if (coupon.ffa_used == false) {
            line.unusedCouponsCount++;
          }
        }
      }
    } catch (error) {
      console.error('error in parsing orders error', error);
      console.error('error in parsing orders result.raw', line.raw);
    }

  }


}
