import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {SearchService} from "../../search/search.service";
import {Store} from "@ngrx/store";
import {catchError, mergeMap, switchMap} from "rxjs/operators";
import {SearchUserRequest} from "../../search/models/search-user-request";
import * as fromCore from "../../store/reducers";
import {
  LOAD_PROMOTED_CONTENTS,
  PromotedContentsAction,
  PromotedContentsLoadAction,
  PromotedContentsLoadError,
  PromotedContentsLoadSuccess
} from "./promoted-contents.action";
import {upsertHomePromotedContents} from "../../home/home-promoted-content/home-promoted-content.actions";
import {HomePromotedContent} from "../../home/home-promoted-content/home-promoted-content.model";
import {loadNodesSuccess} from "../../core/store/actions/node.actions";
import {of} from "rxjs";

@Injectable()
export class PromotedContentsEffects {

  constructor(private actions$: Actions,
              private searchService: SearchService,
              private store: Store<fromCore.State>) {
  }


  search$ = createEffect(() => this.actions$.pipe(
    ofType<PromotedContentsAction>(LOAD_PROMOTED_CONTENTS),
    mergeMap((action: PromotedContentsLoadAction) => {
      return this.search(action.searchUserRequest).pipe(
        switchMap((result) => {
          const homePromotedContents: HomePromotedContent[] = result?.items?.map(
            item => {
              return {id: item?.id, type: item?.type};
            }
          ).filter(item => !!item);
          return [
            new PromotedContentsLoadSuccess(result, action.searchUserRequest.filters?.extended_type as string),
            loadNodesSuccess({data: result?.items, itemFormat: true}),
            upsertHomePromotedContents({homePromotedContents})
          ];
        }),
        catchError(error => {
          console.error('error', error);
          return of(new PromotedContentsLoadError(error, action.searchUserRequest.filters?.extended_type as string));
        })
      );
    }),
  ));

  search(searchUserRequest: SearchUserRequest) {
    return this.searchService.searchHomePromoted(searchUserRequest);
  }

}
