import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {HttpErrorResponse} from "@angular/common/http";
import {NodeActions, NodeActionTypes} from "../actions/node";
import {DrupalNode} from "../../models/node";

/**
 * Created by benoitplatre on 25/06/2018.
 */

export interface NodeState extends EntityState<DrupalNode> {
  loading?: boolean;
  loaded?: boolean;
  error?: HttpErrorResponse | null;
  termsMap?: { [id: string]: string[] };
  nodeRelatedMap: { [nid: string]: any[] };
}

export const adapter: EntityAdapter<DrupalNode> = createEntityAdapter<DrupalNode>({
  selectId: (node: DrupalNode) => node.id || node.nid,
  sortComparer: false,
});

const initialState: NodeState = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  metaTags: null,
  termsMap: {},
  nodeRelatedMap: {}
});

export function reducer(state: NodeState = initialState,
                        action: NodeActions): NodeState {
  switch (action.type) {

    case NodeActionTypes.Load:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null
      };

    case NodeActionTypes.NodeReload:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: null
      };

    case NodeActionTypes.LoadSuccess:
      return adapter.upsertOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });

    case NodeActionTypes.LoadFail:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.payload
      };

    case NodeActionTypes.LoadRelatedSuccess:
      return {
        ...state,
        nodeRelatedMap: {
          ...state.nodeRelatedMap,
          [action.nid]: action.payload
        }
      };

    case NodeActionTypes.ClearNodesStorage:
      return adapter.removeAll(state);

    case NodeActionTypes.NodeCollectionLoadSuccess:
      let termsMap: { [id: string]: string[] } = {...state.termsMap};
      action.nodeCollection.forEach(node => {
        let nid: string = node.nid || node.id;
        let termNids: string[] = termsMap[action.termId];
        if (!termNids) {
          termsMap[action.termId] = [];
        }
        if (termsMap[action.termId].indexOf(nid) == -1) {
          termsMap[action.termId] = [...termsMap[action.termId], nid];
        }
      });
      return adapter.upsertMany(action.nodeCollection, {
        ...state,
        termsMap: termsMap,
        loaded: true,
        loading: false
      });

    default:
      return state;
  }
}

export const getNodeLoading = (state: NodeState) => state.loading;
export const getNodeLoaded = (state: NodeState) => state.loaded;
export const getNodeFail = (state: NodeState) => state.error;
