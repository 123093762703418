import {Pipe, PipeTransform} from '@angular/core';
import {AgeLevel} from "../core/models/age-level";

@Pipe({
  name: 'ageLevel'
})
export class AgeLevelPipe implements PipeTransform {

  transform(value: any): AgeLevel {
    let ageRange: string;
    let ageLevel: AgeLevel;
    if(value){
      ageLevel = {};
      if(value.age_range.from == value.age_range.to) {
        ageRange = `${value.age_range.from}ans`;
      }else if(parseInt(value.age_range.to) === AgeLevel.ageMax){
        ageRange = `à partir de ${value.age_range.from}ans`;
      }else{
        ageRange = `${value.age_range.from} à ${value.age_range.to}ans`;
      }
      ageLevel.ageLabel = ageRange;
      ageLevel.enLabel = value.en_name;
      ageLevel.ffaLabel = value.ffa_name;
    }

    return ageLevel;
  }

}
