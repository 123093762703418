import {Action} from '@ngrx/store';
import {Cart} from "../../models/cart";

export enum ECommerceActionTypes {
  LoadCart = '[ECommerce] Load Cart',
  LoadCartSuccess = '[ECommerce] Load Cart Sucess',
  LoadCartFail = '[ECommerce] Load Cart Fail',
  CheckOut = '[ECommerce] CheckOut',
  CheckOutSuccess = '[ECommerce] CheckOut Success',
  CheckOutFail = '[ECommerce] CheckOut fail',
  ResetECommerce = '[ECommerce] Reset',
}

export class LoadCart implements Action {
  readonly type = ECommerceActionTypes.LoadCart;

  constructor(public cartType: string) {
  }
}

export class LoadCartSuccess implements Action {
  readonly type = ECommerceActionTypes.LoadCartSuccess;

  constructor(public cart: Cart) {

  }
}

export class LoadCartFail implements Action {
  readonly type = ECommerceActionTypes.LoadCartFail;

  constructor(public error: any) {

  }
}

export class CheckOut implements Action {
  readonly type = ECommerceActionTypes.CheckOut;

  constructor(public orderId: string) {
  }
}

export class CheckOutSuccess implements Action {
  readonly type = ECommerceActionTypes.CheckOutSuccess;

  constructor(public checkOutResult: any) {

  }
}

export class CheckOutFail implements Action {
  readonly type = ECommerceActionTypes.CheckOutFail;

  constructor(public error: any) {

  }
}

export class ResetEcommerce implements Action {
  readonly type = ECommerceActionTypes.ResetECommerce;

  constructor() {

  }
}


export type ECommerceActionsUnion =
  LoadCart
  | LoadCartSuccess
  | LoadCartFail
  | CheckOut
  | CheckOutSuccess
  | CheckOutFail
  | ResetEcommerce;
