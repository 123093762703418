import {Pipe, PipeTransform} from '@angular/core';
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {DrupalNode} from "../../core/models/node";
import {FaceAFace, FormationDay, FormationSchedule, FormationTime} from "../../core/models/face-a-face";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {Document} from "../../core/models/document";

@Pipe({
  name: 'resourceToFaceAFace'
})
export class ResourceToFaceAFacePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToMediaPipe: ResourceToMediaPipe
  ) {
  }

  transform(value: any): FaceAFace {

    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);

    let documents: Document[];
    if (value?.formation_documents) {
      documents = value?.formation_documents.map(media => {
        return this.resourceToMediaPipe.transform(media);
      });
    }

    let archive: Document;
    if (value?.formation_document_archive) {
      archive = this.resourceToMediaPipe.transform(value?.formation_document_archive);
    }

    let days: FormationDay[];

    if(Array.isArray(value?.formation_journee)){
      days = value?.formation_journee.map(day => {
        return this.parseDay(day);
      })
    }

    return {
      ...drupalNode,
      documents,
      archive,
      days
    };
  }

  parseDay(raw: any): FormationDay{
    const formationDay: FormationDay = {
      title: raw.title,
      description: raw.formation_description,
      modifiable: raw.schedules_modifiable
    }
    if(Array.isArray(raw.formation_schedule)){
      formationDay.schedules = raw.formation_schedule.map(schedule => {
        return this.parseSchedule(schedule);
      });
    }
    return formationDay
  }

  parseSchedule(raw: any): FormationSchedule{
    const formationSchedule: FormationSchedule = {
      description: raw.description,
      time: raw.time
    }
    if(Array.isArray(raw.formation_documents)){
      formationSchedule.documents = raw.formation_documents.map(document => {
        return this.resourceToMediaPipe.transform(document);
      });
    }
    return formationSchedule;
  }

}
