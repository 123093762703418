// token to access a stream with the information you need
import {InjectionToken, Provider} from '@angular/core';
import {merge, Observable, of} from "rxjs";
import {OAuthService} from "angular-oauth2-oidc";
import {distinctUntilChanged, map, shareReplay} from "rxjs/operators";
import {USER_INFOS} from "./user.providers";
import {User} from "../core/models/user";

export const AUTHENTICATED = new InjectionToken<boolean>(
  'A stream to know if the user is authenticated'
);

export const HAS_REFRESH_TOKEN = new InjectionToken<boolean>(
  'To know if the user has a refresh token'
);

export const IS_ANONYMOUS = new InjectionToken<Observable<boolean>>(
  'To know if the user is anonymous'
);

export const USER_READY = new InjectionToken<Observable<boolean>>(
  'To know if the user is anonymous or authenticated. Then the app can finalize boot'
);


export const AUTHENTICATED_PROVIDERS: Provider[] = [
  {
    deps: [OAuthService, USER_READY],
    provide: AUTHENTICATED,
    useFactory: getAuthenticatedFactory
  },
  {
    deps: [OAuthService],
    provide: HAS_REFRESH_TOKEN,
    useFactory: hasRefreshTokenFactory
  },
  {
    deps: [HAS_REFRESH_TOKEN],
    provide: IS_ANONYMOUS,
    useFactory: isAnonymousFactory
  },
  {
    deps: [OAuthService, USER_INFOS],
    provide: USER_READY,
    useFactory: userReadyFactory
  }
];

export function hasRefreshTokenFactory(
  oAuthService: OAuthService
): Observable<boolean> {
  return merge([
      of(!!oAuthService.getRefreshToken()),
      oAuthService.events
    ]
  ).pipe(
    map((events) => {
      return !!oAuthService.getRefreshToken();
    }),
    distinctUntilChanged(),
    shareReplay(1)
  )
}

export function isAnonymousFactory(
  hasRefreshToken$: Observable<boolean>
): Observable<boolean> {
  return hasRefreshToken$
    .pipe(
      map(hasRefreshToken => {
        return hasRefreshToken === false;
      }),
      shareReplay(1)
    );
}

export function userReadyFactory(
  oAuthService: OAuthService,
  userInfos$: Observable<User>
): Observable<boolean> {
  return userInfos$.pipe(
    map((userInfos) => {
      return !oAuthService.getRefreshToken() || !!userInfos;
    }),
    shareReplay(1)
  );
}

export function getAuthenticatedFactory(
  oAuthService: OAuthService,
  userReady$: Observable<boolean>
): Observable<boolean> {
  return userReady$.pipe(
    map((userReady) => {
      return !!oAuthService.getAccessToken() && userReady === true;
    }),
    shareReplay(1)
  );
}
