<ng-container *ngIf="!(breadcrumbsService.backBreadCrumb$|async) ||(breadcrumbsService.backBreadCrumbIsSearch$|async)">

  <a [routerLink]="universePath$|async"
     [queryParams]="(userFilters$|async)"
     class="back-search d-flex flex-none">
    <mat-icon svgIcon="icon-search" class="search-icon"></mat-icon>
    <div class="d-flex flex-column flex-fill label-group pl-2 justify-content-center">
      <span class="font-weight-bold">Nouvelle recherche</span>
    </div>
  </a>

</ng-container>

<ng-container *ngIf="(breadcrumbsService.hasBreadCrumbs$|async)">

  <ng-container *ngIf="(breadcrumbsService.backBreadCrumb$|async) as backBreadCrumb">

    <ng-container *ngIf="!(breadcrumbsService.backBreadCrumbIsSearch$|async)">

      <a [routerLink]="backBreadCrumb.url"
         [queryParamsHandling]="'merge'"
         [queryParams]="backBreadCrumb.queryParams?backBreadCrumb.queryParams:{bc:null}"
         class="back-search d-flex flex-none align-items-center">

        <mat-icon svgIcon="icon-back" class="m-2 search-icon"></mat-icon>

        <span [innerHTML]="backBreadCrumb.node.title"></span>

      </a>

    </ng-container>

  </ng-container>

</ng-container>
