<ea-dialog [title]="data.title" [actions]="data.actions">

  <span *ngIf="data.status" class="title-group align-self-start" [innerHTML]="data.status"></span>

  <div class="content-group d-flex flex-column flex-fill align-items-center">

    <div [innerHTML]="data.message || data">

    </div>

  </div>

  <div class="actions-group d-flex flex-fill justify-content-center">

    <button mat-flat-button mat-dialog-close color="primary">OK</button>

  </div>

</ea-dialog>
