import {FieldInfos, FieldInfosMap} from "./field-infos";

/**
 * Created by benoitplatre on 14/08/2018.
 */

export enum Universes {
  encyclopedia = 'encyclopedia',
  training = 'training',
  themes = 'themes',
  home = 'home'
}

export type Universe = Universes.training | Universes.encyclopedia | Universes.training | Universes.home

export class ContentTypesConstants {

  static readonly universeEncyclopedia: string = Universes.encyclopedia;
  static readonly universeTraining: string = Universes.training;
  static readonly universeTheme: string = Universes.themes;
  static readonly universeHome: string = Universes.home;

  static readonly situation: FieldInfos = {contentType: 'fiche_peda', name: 'fiche_peda', label: 'Situation', pluralLabel: 'Situations'};
  static readonly savoir: FieldInfos = {contentType: 'theoretical_content', name: 'theoretical_content', label: 'Savoir', pluralLabel: 'Savoir'};
  static readonly cycle: FieldInfos = {contentType: 'cycle', name: 'cycle', label: 'Cycle', pluralLabel: 'Cycles'};
  static readonly faceAface: FieldInfos = {contentType: 'formation', name: 'formation', label: 'Face à Face', pluralLabel: 'Face-à-Face'};
  static readonly diplome: FieldInfos = {contentType: 'formation_diplome', name: 'formation_diplome', label: 'Diplôme', pluralLabel: 'Diplômes'};
  static readonly module: FieldInfos = {contentType: 'formation_module', name: 'formation_module', label: 'Module', pluralLabel: 'Modules'};
  static readonly enchainement: FieldInfos = {contentType: 'enchainement_situations', name: 'enchainement_situations', label: 'Enchainement', pluralLabel: 'Enchainements'};
  static readonly quiz: FieldInfos = {contentType: 'quiz', name: 'quiz', label: 'Quiz', pluralLabel: 'Quiz'};
  static readonly situationJeunesJuges: FieldInfos = {contentType: 'situation_jeunes_juges', name: 'situation_jeunes_juges', label: 'Jeunes Juges', pluralLabel: 'Jeunes Juges'};
  static readonly seance: FieldInfos = {contentType: 'seance', name: 'seance', label: 'Séance', pluralLabel: 'Séances'};
  static readonly theme: FieldInfos = {contentType: 'ffa_theme', name: 'ffa_theme', label: 'Thème', pluralLabel: 'Thèmes'};
  static readonly video: FieldInfos = {contentType: 'ffa_video_athle_tv', name: 'ffa_video_athle_tv', label: 'Video', pluralLabel: 'Video'};
  static readonly webform: FieldInfos = {contentType: 'webform', name: 'webform', label: 'WebForm'};
  static readonly faqTutos: FieldInfos = {contentType: 'faq_question', name: 'faq_question', label: 'FAQ et tutoriels'};

  static readonly progression: FieldInfos = {contentType:'enchainement_situations', name: 'progression', label: 'Progression', pluralLabel: 'Progressions', filter:{practical_type:'progression'}};
  static readonly circuit: FieldInfos = {contentType:'enchainement_situations', name: 'circuit', label: 'Circuit', pluralLabel: 'Circuits', filter:{practical_type:'circuit'}};

  static readonly map:FieldInfosMap = {
    [ContentTypesConstants.situation.name]: ContentTypesConstants.situation,
    [ContentTypesConstants.savoir.name]: ContentTypesConstants.savoir,
    [ContentTypesConstants.cycle.name]: ContentTypesConstants.cycle,
    [ContentTypesConstants.faceAface.name]: ContentTypesConstants.faceAface,
    [ContentTypesConstants.diplome.name]: ContentTypesConstants.diplome,
    [ContentTypesConstants.module.name]: ContentTypesConstants.module,
    [ContentTypesConstants.enchainement.name]: ContentTypesConstants.enchainement,
    [ContentTypesConstants.quiz.name]: ContentTypesConstants.quiz,
    [ContentTypesConstants.situationJeunesJuges.name]: ContentTypesConstants.situationJeunesJuges,
    [ContentTypesConstants.seance.name]: ContentTypesConstants.seance,
    [ContentTypesConstants.theme.name]: ContentTypesConstants.theme,
    [ContentTypesConstants.video.name]: ContentTypesConstants.video,
    [ContentTypesConstants.webform.name]: ContentTypesConstants.webform,
    [ContentTypesConstants.faqTutos.name]: ContentTypesConstants.faqTutos,
    [ContentTypesConstants.progression.name]: ContentTypesConstants.progression,
    [ContentTypesConstants.circuit.name]: ContentTypesConstants.circuit,
  }

  static readonly encyclopediaTypes: string[] = [
    ContentTypesConstants.situation.name,
    ContentTypesConstants.savoir.name,
    ContentTypesConstants.enchainement.name,
    ContentTypesConstants.quiz.name,
    ContentTypesConstants.situationJeunesJuges.name,
    ContentTypesConstants.seance.name,
    ContentTypesConstants.video.name,
    ContentTypesConstants.cycle.name
  ];

  static readonly encyclopediaFieldInfos: FieldInfos[] = [
    ContentTypesConstants.situation,
    ContentTypesConstants.progression,
    ContentTypesConstants.circuit,
    ContentTypesConstants.seance,
    ContentTypesConstants.cycle,
    ContentTypesConstants.situationJeunesJuges,
    ContentTypesConstants.savoir,
    ContentTypesConstants.video,
    ContentTypesConstants.quiz
  ];

  static readonly trainingTypes: string[] = [
    ContentTypesConstants.diplome.name,
    ContentTypesConstants.module.name
  ];

  static readonly trainingFieldInfos: FieldInfos[] = [
    ContentTypesConstants.diplome,
    ContentTypesConstants.module
  ];

  static readonly universeMap: {[universe: string]: string[]} = {
    [ContentTypesConstants.universeTraining]: ContentTypesConstants.trainingTypes,
    [ContentTypesConstants.universeEncyclopedia]: ContentTypesConstants.encyclopediaTypes
  }

}
