import {Action} from '@ngrx/store';
import {User, UserMeta} from "../../models/user";
import {ChangePassword} from "../../models/change-password";
import {ForgotPasswordModel} from "../../models/forgot-password";
import {TraineeInfos} from "../../models/trainee-infos";
import {AgeType} from "../reducers/user.reducer";

export const INIT_CREATE_ACCOUNT = '[User] Init Create account';
export const CREATE_ACCOUNT = '[User] Create account';
export const CREATE_ACCOUNT_FAIL = CREATE_ACCOUNT + ' > Fail';
export const CREATE_ACCOUNT_SUCCESS = CREATE_ACCOUNT + ' > Success';

export const INIT_STATE = '[User] Fetch initial state';
export const INIT_STATE_SUCCESS = INIT_STATE + ' > Loaded';

export const LOGOUT = '[User] Logout';
export const LOGOUT_SUCCESS = LOGOUT + ' > Success';

export const FORGOT_PASSWORD = '[User] Forgot password';

export const FORGOT_PASSWORD_NEW_PASSWORD = '[User] Forgot password New password';

export const REQUEST_PASSWORD = '[User] Request password';
export const REQUEST_PASSWORD_SUCCESS = REQUEST_PASSWORD + ' > Success';
export const REQUEST_PASSWORD_FAIL = REQUEST_PASSWORD + ' > Fail';
export const RESET_REQUEST_PASSWORD = '[User] Reset Request password';

export const LOAD_USER_INFOS = '[User] Load User Infos';
export const LOAD_USER_INFOS_SUCCESS = LOAD_USER_INFOS + ' > Success';
export const LOAD_USER_INFOS_FAIL = LOAD_USER_INFOS + ' > Fail';

export const LOAD_USER_META = '[User] Load User Meta';
export const LOAD_USER_META_SUCCESS = LOAD_USER_META + '> Success';
export const LOAD_USER_META_FAIL = LOAD_USER_META + '> Fail';

export const UPDATE_USER = '[User] Update User';
export const UPDATE_USER_SUCCESS = UPDATE_USER + '> Success';
export const UPDATE_USER_FAIL = UPDATE_USER + '> Fail';

export const RESET_STATE = '[User] Reset State';

export const LOAD_ORDERS = '[User] Load Orders';
export const LOAD_ORDERS_SUCCESS = LOAD_ORDERS + '> Success';
export const LOAD_ORDERS_FAIL = LOAD_ORDERS + '> Fail';

export const LOAD_BOOKMARKS = '[User] Load Bookmarks';
export const LOAD_BOOKMARKS_SUCCESS = LOAD_BOOKMARKS + '> Success';
export const LOAD_BOOKMARKS_FAIL = LOAD_BOOKMARKS + '> Fail';

export const ADD_BOOKMARK = '[User] Add Bookmark';
export const ADD_BOOKMARK_SUCCESS = ADD_BOOKMARK + '> Success';
export const ADD_BOOKMARK_FAIL = ADD_BOOKMARK + '> Fail';

export const REMOVE_BOOKMARK = '[User] Remove Bookmark';
export const REMOVE_BOOKMARK_SUCCESS = REMOVE_BOOKMARK + '> Success';
export const REMOVE_BOOKMARK_FAIL = REMOVE_BOOKMARK + '> Fail';

export const SET_ANONYMOUS_USER = '[User] Set Anonymous User';
export const SET_ANONYMOUS_USER_SUCCESS = SET_ANONYMOUS_USER + '> Success';

export const SET_AUTHENTICATION_REDIRECTION = '[User] Set Authentication Redirection';

export const LOAD_TRAINEE_INFOS_SUCCESS = '[User] Load Trainee Infos Success';

export const LOAD_BOOKMARKS_FULL = '[User] Load Bookmarks Full';
export const LOAD_BOOKMARKS_FULL_SUCCESS = LOAD_BOOKMARKS_FULL + '> Success';
export const LOAD_BOOKMARKS_FULL_FAIL = LOAD_BOOKMARKS_FULL + '> Fail';

export const SET_PREFERRED_AGE_CRITERIA = '[User] Set Preferred Age Criteria';

export interface AccountCreationParameters {
  mail: string;
  password: string;
  firstname: string;
  lastname: string;
  gender: 'm' | 'f';
  ffaLicenseNumber?: any;
  birthDate?: string;
}

export class InitCreateAccount implements Action {
  readonly type = INIT_CREATE_ACCOUNT;

  constructor() {
  }
}

export class SetPreferredAgeCriteria implements Action {
  readonly type = SET_PREFERRED_AGE_CRITERIA;

  constructor(public ageType: AgeType) {
  }
}

export class CreateAccount implements Action {
  readonly type = CREATE_ACCOUNT;

  constructor(public account: AccountCreationParameters) {
  }
}

export class CreateAccountFail implements Action {
  readonly type = CREATE_ACCOUNT_FAIL;

  constructor(public error: any) {
  }
}

export class CreateAccountSuccess implements Action {
  readonly type = CREATE_ACCOUNT_SUCCESS;

  constructor(public id: string) {
  }
}

export class SetAuthenticationRedirection implements Action {
  readonly type = SET_AUTHENTICATION_REDIRECTION;

  constructor(public url: string) {
  }
}


export class ForgotPassword implements Action {
  readonly type = FORGOT_PASSWORD;

  constructor(public userName: string) {
  }
}

export class ForgotPasswordNewPassword implements Action {
  readonly type = FORGOT_PASSWORD_NEW_PASSWORD;

  constructor(public forgotPassword: ForgotPasswordModel) {
  }
}


export class RequestPassword implements Action {
  readonly type = REQUEST_PASSWORD;

  constructor(public changePassword: ChangePassword) {
  }
}

export class ResetRequestPassword implements Action {
  readonly type = RESET_REQUEST_PASSWORD;

  constructor() {
  }
}

export class RequestPasswordFail implements Action {
  readonly type = REQUEST_PASSWORD_FAIL;

  constructor(public error: any) {
  }
}

export class RequestPasswordSuccess implements Action {
  readonly type = REQUEST_PASSWORD_SUCCESS;

  constructor() {
  }
}

export class LoadUserInfos implements Action {
  readonly type = LOAD_USER_INFOS;

  constructor() {
  }
}

export class LoadUserInfosFail implements Action {
  readonly type = LOAD_USER_INFOS_FAIL;

  constructor(public error: any) {
  }
}

export class LoadUserInfosSuccess implements Action {
  readonly type = LOAD_USER_INFOS_SUCCESS;

  constructor(public user: User) {
  }
}

export class UserResetState implements Action {
  readonly type = RESET_STATE;

  constructor() {
  }
}

export class LoadUserMeta implements Action {
  readonly type = LOAD_USER_META;

  constructor() {
  }
}

export class LoadUserMetaFail implements Action {
  readonly type = LOAD_USER_META_FAIL;

  constructor(public error: any) {
  }
}

export class LoadUserMetaSuccess implements Action {

  readonly type = LOAD_USER_META_SUCCESS;

  constructor(public userMeta: UserMeta) {
  }

}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;

  constructor(public userInfos: User) {
  }
}

export class UpdateUserFail implements Action {
  readonly type = UPDATE_USER_FAIL;

  constructor(public error: any) {
  }
}

export class UpdateUserSuccess implements Action {

  readonly type = UPDATE_USER_SUCCESS;

  constructor(public userInfos: User) {
  }

}

export class LoadOrders implements Action {
  readonly type = LOAD_ORDERS;

  constructor() {
  }
}

export class LoadOrdersFail implements Action {
  readonly type = LOAD_ORDERS_FAIL;

  constructor(public error: any) {
  }
}

export class LoadOrdersSuccess implements Action {

  readonly type = LOAD_ORDERS_SUCCESS;

  constructor(public orders: any[]) {
  }

}

export class SetAnonymousUser implements Action {

  readonly type = SET_ANONYMOUS_USER;

  constructor() {
  }

}

export class SetAnonymousUserSuccess implements Action {

  readonly type = SET_ANONYMOUS_USER_SUCCESS;

  constructor(public userInfos: User) {
  }

}

export class LoadBookmarks implements Action {
  readonly type = LOAD_BOOKMARKS;

  constructor(public userId: string) {
  }
}

export class LoadBookmarksFail implements Action {
  readonly type = LOAD_BOOKMARKS_FAIL;

  constructor(public error: any) {
  }
}

export class LoadBookmarksSuccess implements Action {

  readonly type = LOAD_BOOKMARKS_SUCCESS;

  constructor(public bookmarks: any[]) {
  }

}

export class LoadBookmarksFull implements Action {
  readonly type = LOAD_BOOKMARKS_FULL;

  constructor(public userId: string) {
  }
}

export class LoadBookmarksFullFail implements Action {
  readonly type = LOAD_BOOKMARKS_FULL_FAIL;

  constructor(public error: any) {
  }
}

export class LoadBookmarksFullSuccess implements Action {

  readonly type = LOAD_BOOKMARKS_FULL_SUCCESS;

  constructor(public bookmarks: any[]) {
  }

}

export class AddBookmark implements Action {
  readonly type = ADD_BOOKMARK;

  constructor(public contentId: string) {
  }
}

export class AddBookmarkSuccess implements Action {
  readonly type = ADD_BOOKMARK_SUCCESS;

  constructor() {
  }
}

export class AddBookmarkFail implements Action {
  readonly type = ADD_BOOKMARK_FAIL;

  constructor(public error: any) {
  }
}

export class RemoveBookmark implements Action {
  readonly type = REMOVE_BOOKMARK;

  constructor(public contentId: string) {
  }
}

export class RemoveBookmarkSuccess implements Action {
  readonly type = REMOVE_BOOKMARK_SUCCESS;

  constructor() {
  }
}

export class RemoveBookmarkFail implements Action {
  readonly type = REMOVE_BOOKMARK_FAIL;

  constructor(public error: any) {
  }
}

export class LoadTraineeInfosSuccess implements Action {
  readonly type = LOAD_TRAINEE_INFOS_SUCCESS;

  constructor(public traineeInfos: TraineeInfos) {
  }
}


export type UserAction =

  | CreateAccount
  | CreateAccountFail
  | CreateAccountSuccess
  | RequestPassword
  | RequestPasswordFail
  | RequestPasswordSuccess
  | LoadUserInfos
  | LoadUserInfosSuccess
  | LoadUserInfosFail
  | UserResetState
  | LoadUserMeta
  | LoadUserMetaFail
  | LoadUserMetaSuccess
  | UpdateUser
  | UpdateUserFail
  | UpdateUserSuccess
  | SetAnonymousUser
  | SetAnonymousUserSuccess
  | LoadBookmarks
  | LoadBookmarksSuccess
  | LoadBookmarksFail
  | AddBookmark
  | AddBookmarkSuccess
  | AddBookmarkFail
  | RemoveBookmark
  | RemoveBookmarkSuccess
  | RemoveBookmarkFail
  | ResetRequestPassword
  | ForgotPassword
  | ForgotPasswordNewPassword
  | LoadTraineeInfosSuccess
  | LoadBookmarksFull
  | LoadBookmarksFullSuccess
  | LoadBookmarksFullFail
  | SetPreferredAgeCriteria
  ;
