import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from "@ngrx/store";
import * as fromCore from "./core/store/reducers";
import {isUserInfosLoaded} from "./core/store/reducers";
import {filter, map, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserLoadedGuard implements CanActivate {

  constructor(
    private store: Store<fromCore.State>
  ) {
    //console.log('constructor');
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let infosLoaded$ = this.store.pipe(
      select(isUserInfosLoaded),
      filter(infosLoaded => infosLoaded === true),
      map(infosLoaded => {
        return true;
      }),
      take(1)
    );

    infosLoaded$.subscribe();

    return infosLoaded$;
  }

}
