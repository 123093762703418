import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tableWrapper'
})
export class TableWrapperPipe implements PipeTransform {

  transform(value: string, args?: any): any {

    /*let test = value.replace(/<table border="0">((?:.*?\r?\n?)*)<\/table>/gi, (tables) => {
      console.log('tables', tables);
      return tables;
    });*/

    return value;
  }

}
