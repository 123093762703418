import {Pipe, PipeTransform} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Pipe({
  name: 'queryParamsMulti'
})
export class QueryParamsMultiPipe implements PipeTransform {

  queryParams$: Observable<Params> = this.activatedRoute.queryParams;

  constructor(private activatedRoute: ActivatedRoute) {

  }

  transform(value: string, key: string): Observable<string[]> {
    return this.queryParams$.pipe(
      map((queryParams: Params) => {
        let values: string[];
        if(queryParams[key]){
          if (Array.isArray(queryParams[key])) {
            values = [...queryParams[key]];
          } else {
            values = [queryParams[key]];
          }
          if(values.indexOf(value) > -1){
            values = values.filter(v => {
              return v !== value;
            });
            if(values.length === 0){
              values = null;
            }
          }else{
            values.push(value);
          }
        }else{
          values = [value];
        }
        return values;
      })
    );
  }

}
