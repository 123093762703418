import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {HttpErrorResponse} from '@angular/common/http';
import {DrupalVocabulary} from "../../models/vocabulary";
import {VocabularyActions, VocabularyActionTypes} from "../actions";

/**
 * Created by benoitplatre on 25/06/2018.
 */

export interface TaxonomyState extends EntityState<DrupalVocabulary> {
  loading: boolean;
  loaded: boolean;
  error: HttpErrorResponse|null;
}

export const adapter: EntityAdapter<DrupalVocabulary> = createEntityAdapter<any>({
  selectId: (vocabulary: DrupalVocabulary) => vocabulary.name,
  sortComparer: false
});

const initialState: TaxonomyState = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});

export function reducer(state: TaxonomyState = initialState,
                        action: VocabularyActions): TaxonomyState {
  switch (action.type) {

    case VocabularyActionTypes.Load:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null
      };

    case VocabularyActionTypes.LoadSuccess:
      return adapter.addOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });

    case VocabularyActionTypes.LoadFail:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.payload
      };

    case VocabularyActionTypes.SetStaticVocabulary:
      return adapter.addOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });

    default:
      return state;
  }
}

export const {
  selectAll,
  selectEntities,
  selectIds,
  selectTotal

} = adapter.getSelectors();

