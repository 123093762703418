import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from '../../app-config.service';
import {map, mergeMap} from "rxjs/operators";
import {Cart} from "../models/cart";

export class CartIdentifier {
  orderId: string;
}

export class CheckOutEntity {
  order_id: string;
  redirectUrl: string;
}

@Injectable({
  providedIn: 'root'
})

export class EcommerceService {

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService
  ) {
  }

  public loadCart(type: string): Observable<Cart> {
    return this.http
      .post(
        this.appConfig.getConfig().apiBaseUrl + '/order',
        {
          checkoutParameters: {type: type}
        }).pipe(
        mergeMap((cartIdentifier: CartIdentifier) => {
          return this.http.get(this.appConfig.getConfig().apiBaseUrl + '/order/' + cartIdentifier.orderId).pipe(
            map((cart: Cart) => {
              return cart;
            })
          )
        })
      );
  }

  public checkOut(orderId: string): Observable<CheckOutEntity> {
    return this.http
      .post(
        this.appConfig.getConfig().apiBaseUrl + '/order/' + orderId + '/checkout',
        {}
      ).pipe(
        map((checkOutEntity: CheckOutEntity) => {
          return checkOutEntity;
        })
      );
  }

}
