import {Component, EventEmitter, Input, NgModule, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {User} from "../../../core/models/user";

import * as moment from 'moment';
import {Moment} from 'moment';
import {debounceTime, distinctUntilChanged, map, skip} from "rxjs/operators";
import {select, State, Store} from "@ngrx/store";
import {Observable, Subscription} from "rxjs";
import {UpdateUser} from "../../../core/store/actions";
import {getUserLoading} from "../../../core/store/reducers";
import {VERSION_INFO} from "../../../../environments/version";
import {CommonModule} from "@angular/common";
import {MatRadioModule} from "@angular/material/radio";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

export function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) {
    return;
  }
  const pwd = c.parent.get('password');
  const cpwd = c.parent.get('passwordConfirm');

  if (!pwd || !cpwd) {
    return;
  }

  if (pwd.value !== cpwd.value) {
    return {invalid: true};
  }

}

@Component({
  selector: 'ea-user-infos-form',
  templateUrl: './user-infos-form.component.html',
  styleUrls: ['./user-infos-form.component.scss']
})

export class UserInfosFormComponent implements OnInit, OnDestroy {

  get creationMode(): boolean {
    return this._creationMode;
  }

  get userInfos(): User {
    return this._userInfos;
  }

  private _userInfos: User;

  @Input()
  set userInfos(userInfos: User) {
    /*if(this._userInfos){
      if(this._userInfos.id === userInfos.id){
        return;
      }
    }*/
    this._userInfos = JSON.parse(JSON.stringify(userInfos));
    if (userInfos) {
      const formUserInfos: any = {};
      formUserInfos.firstname = userInfos.firstName;
      formUserInfos.lastname = userInfos.lastName;
      formUserInfos.civility = userInfos.gender.toLocaleLowerCase();
      formUserInfos.email = userInfos.email;
      if (userInfos.birthDate) {
        // Parse only the date components of the ISO8601 formatted string.
        // It'll be treated as a local date.
        formUserInfos.birthDate =  moment(userInfos.birthDate, 'YYYY-MM-DD');
      }
      formUserInfos.ffaLicenseNumber = userInfos.ffaLicenseNumber;
      const hasLicence: boolean = (!!userInfos.ffaLicenseNumber || !!userInfos.birthDate);
      this.form.patchValue({hasLicence: hasLicence});
      formUserInfos.hasLicence = hasLicence;

      this.form.reset(formUserInfos);

      this.invalidateUserInfos();
      this.needLicence = hasLicence;

    }
  }


  form: FormGroup;

  @ViewChild('formComponent')
  formComponent: FormGroupDirective;

  subs: Subscription[] = [];

  checkingLicence: boolean;

  loading$: Observable<boolean> = this.store.pipe(select(getUserLoading));

  private formValueSubscription: Subscription;

  //215578
  private hasLicenceSubscription: Subscription;

  private _creationMode: boolean;
  @Input()
  set creationMode(value: boolean) {
    this._creationMode = value;
    this.invalidateCreationMode();
  }

  _needLicence: boolean;
  @Input()
  set needLicence(value: boolean) {
    this._needLicence = value;
    if (value != null) {
      this.invalidateNeedLicence();
    }
  }

  @Output('hasLicence')
  hasLicenceEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  birthDateStart = moment(new Date()).add(-18, 'year').utc().toISOString();

  version: string = VERSION_INFO.raw;

  constructor(
    private fb: FormBuilder,
    private store: Store<State<any>>
  ) {
    this.form = this.fb.group({
        firstname: new FormControl('', [
          Validators.required
        ]),
        lastname: new FormControl('', [
          Validators.required
        ]),
        civility: new FormControl('', [
          Validators.required
        ]),
        email: new FormControl({value: '', disabled: true}, [
          Validators.required,
          Validators.email
        ]),
        birthDate: new FormControl(''),
        ffaLicenseNumber: new FormControl(''),
        hasLicence: new FormControl('')
      }
    );

  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  ngOnInit() {

  }

  invalidateUserInfos() {

    if (this.formValueSubscription) {
      this.formValueSubscription.unsubscribe();
    }

    this.formValueSubscription = this.form.valueChanges.pipe(
      distinctUntilChanged((oldValue, newValue) => {
        return JSON.stringify(oldValue) === JSON.stringify(newValue);
      }),
      skip(1),
      debounceTime(40),
      map(formValue => {
        let updatedUserInfos: any = {...formValue};
        if (updatedUserInfos.birthDate) {
          //let momentBirthDate: Moment = moment(updatedUserInfos.birthDate).utc();
          //console.log('momentBirthDate', momentBirthDate);
          //updatedUserInfos.birthDate = (formValue.birthDate as Moment).utc(true).format('YYYY-MM-DD');
        }
        return updatedUserInfos;
      })
    ).subscribe(formValue => {

      this._userInfos = {
        ...this.userInfos,
        firstName: formValue.firstname,
        lastName: formValue.lastName,
        gender: formValue.civility.toLocaleUpperCase(),
        email: formValue.email
      };
      // Set the date to UTC without converting time and format to ISO8601.
      const birthDate: Moment = formValue.birthDate as Moment;
      if(birthDate){
        this.userInfos.birthDate = birthDate.utc(true).format();
      }
      this.userInfos.ffaLicenseNumber = formValue.ffaLicenseNumber ? formValue.ffaLicenseNumber.toString() : '';
      if(this.userInfos.ffaLicenseNumber){
        this.checkingLicence = true;
      }else{
        this.checkingLicence = false;
      }
    });

    this.subs.push(this.formValueSubscription);

    this.invalidateNeedLicence();
  }

  save() {
    /*if(this.userInfos.ffaLicenseNumber){
      this.checkingLicence = true;
    }else{
      this.checkingLicence = false;
    }*/
    this.store.dispatch(new UpdateUser(this.userInfos));
  }

  invalidateNeedLicence() {
    let hasLicence: FormControl = this.form.get('hasLicence') as FormControl;
    if(this.hasLicenceSubscription){
      this.hasLicenceSubscription.unsubscribe();
    }
    this.hasLicenceSubscription = hasLicence.valueChanges.pipe().subscribe(hasLicence => {
      const licenceControl: FormControl = this.form.get('ffaLicenseNumber') as FormControl;
      const birthDateControl: FormControl = this.form.get('birthDate') as FormControl;
      //console.log('hasLicence', hasLicence);
      if (hasLicence) {
        licenceControl.setValidators(Validators.required);
        birthDateControl.setValidators(Validators.required);
      } else {
        licenceControl.setValidators(null);
        birthDateControl.setValidators(null);
      }
      licenceControl.updateValueAndValidity();
      birthDateControl.updateValueAndValidity();
      //console.log('this.form', this.form);
    });
    hasLicence.setValue(this._needLicence);
    this.subs.push(this.hasLicenceSubscription);
  }

  invalidateCreationMode() {
    let eMailControl: FormControl;
    eMailControl = this.form.get('email') as FormControl;
    if (this.creationMode) {
      eMailControl.enable();
      //
      this.form.addControl('password', new FormControl('', [
        Validators.required,
      ]));
      this.form.addControl('passwordConfirm', new FormControl('', [
        passwordConfirming, Validators.required
      ]));
    } else {
      eMailControl.disable();
      if (this.form.contains('password')) {
        this.form.removeControl('password');
      }
      if (this.form.contains('passwordConfirm')) {
        this.form.removeControl('passwordConfirm');
      }
    }
  }

  get birthDateControl():FormControl{
    return this.form.get('birthDate') as FormControl;
  }

}

@NgModule({
  declarations: [
    UserInfosFormComponent,
  ],
  exports: [
    UserInfosFormComponent
  ],
  imports: [
    CommonModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule
  ]
})
export class UserInfosFormModule { }
