import {Injectable} from '@angular/core';
import {OAuthService, TokenResponse} from 'angular-oauth2-oidc';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {QuizAccess, QuizAccessFull} from "../models/quiz-access";
import {AppConfigService} from "../../app-config.service";
import {AuthenticateSiFfaLinkMode} from '../store/actions/si-ffa.actions';


@Injectable()
export class SecureLinkService {

  constructor(
    protected oauthService: OAuthService,
    protected http: HttpClient,
    protected appConfig: AppConfigService
  ) {
  }

  fetchToken(payload: string, mail = null, password = null, mode: AuthenticateSiFfaLinkMode): Observable<TokenResponse> {

    let params = new HttpParams()
      .set('client_id', this.oauthService.clientId)
      .set('grant_type', 'ffa_secure_link')
      .set('scope', this.oauthService.scope)
      .set('payload', payload)
      .set('mode', mode);
    if (mail) {
      params = params.set('username', encodeURIComponent(mail));
    }
    if (password) {
      params = params.set('password', encodeURIComponent(password));
    }

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    return this.http
      .post<TokenResponse>(this.oauthService.tokenEndpoint, params, {headers});

  }

  loadContentSecureLinkInfos(payload: string): Observable<QuizAccess> {
    return this.http
      .get<QuizAccess>(this.appConfig.getConfig().adminUrl + '/api/1.0/secure-link/' + payload);
  }

  loadQuizAccess(quizAccessId: string): Observable<QuizAccessFull> {
    return this.http
      .get<QuizAccessFull>(this.appConfig.getConfig().adminUrl + '/api/1.0/quiz-access/' + quizAccessId);
  }

}
