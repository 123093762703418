import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {LinesPipe} from "ngx-pipes";

@Pipe({
  name: 'hackedTextPipe'
})
@Injectable()
export class HackedTextPipe implements PipeTransform {

  constructor(
    private lines: LinesPipe
  ){

  }

  transform(text: string): any {
    const lines: string[] = this.lines.transform(text).filter(
      lineText => {
        return lineText !== '<p>&nbsp;</p>' && lineText.indexOf('<script') === -1 && lineText!== '' && lineText!== '&nbsp;';
      }
    );
    return lines.length > 0;
  }

}
