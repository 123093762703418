import {Pipe, PipeTransform} from '@angular/core';
import {ContentTypesConstants} from "../core/models/content-types-constants";

@Pipe({
  name: 'contentTypeTheme'
})
export class ContentTypeThemePipe implements PipeTransform {

  transform(contentType: string): any {
    if (ContentTypesConstants.trainingTypes.indexOf(contentType) > -1) {
      if (contentType == 'ffa_theme') {
        return 'encyclopedia-theme';
      }
      return 'training-theme';
    } else if (contentType == ContentTypesConstants.savoir.name) {
      return 'savoir-theme';
    } else if (contentType == ContentTypesConstants.quiz.name) {
      return 'quiz-theme';
    } else if (contentType == ContentTypesConstants.video.name) {
      return 'video-theme';
    }
    return 'encyclopedia-theme';
  }

}
