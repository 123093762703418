import {SubscriptionActions} from '../actions';
import {SubscriptionContext, SubscriptionStep} from "../../models/subcription-context.model";
import {createFeatureSelector, createSelector} from "@ngrx/store";


/*
context :
 */

export interface State {
  currentStep: SubscriptionStep;
  currentMode: string;
  context: SubscriptionContext;
  steps: SubscriptionStep[];
}

const initialState: State = {
  currentStep: null,
  currentMode: null,
  context: null,
  steps: null
};

export function reducer(
  state: State = initialState,
  action: SubscriptionActions.SubscriptionActionsUnion
): State {
  switch (action.type) {

    case SubscriptionActions.SubscriptionActionTypes.SetContext:
      return {
        ...state,
        context: action.context
      };

    case SubscriptionActions.SubscriptionActionTypes.SetStep:
      return {
        ...state,
        currentStep: action.step
      };

    case SubscriptionActions.SubscriptionActionTypes.SetMode:
      return {
        ...state,
        currentMode: action.mode
      };

    case SubscriptionActions.SubscriptionActionTypes.ResetStep:
      return {
        ...state,
        currentStep: null
      };

    case SubscriptionActions.SubscriptionActionTypes.Reset:
      return initialState;

    default:
      return state;
  }
}

export const getSubscriptionState = createFeatureSelector<State>('subscription');

export const getContext = createSelector(
  getSubscriptionState,
  (state: State) => {
    return state.context;
  }
);

export const getCurrentStep = createSelector(
  getSubscriptionState,
  (state: State) => {
    return state.currentStep;
  }
);

export const getCurrentMode = createSelector(
  getSubscriptionState,
  (state: State) => {
    return state.context && state.context.modes[state.currentMode];
  }
);
