import {Action} from '@ngrx/store';
import {SubscriptionContext, SubscriptionStep} from "../../models/subcription-context.model";

export enum SubscriptionActionTypes {
  GoNextStep = '[Subscription] Go Next step',
  SetStep = '[Subscription] Set step',
  ResetStep = '[Subscription] Reset step',
  Reset = '[Subscription] Reset',
  SetContext = '[Subscription] Set Context',
  SetMode = '[Subscription] Set Mode',
  SubscriptionFinished = '[Subscription] Finished',
}

export class GoNextStep implements Action {

  readonly type = SubscriptionActionTypes.GoNextStep;

  constructor() {

  }
}

export class ResetSubscription implements Action {
  readonly type = SubscriptionActionTypes.Reset;

  constructor() {

  }
}

export class ResetStep implements Action {
  readonly type = SubscriptionActionTypes.ResetStep;

  constructor() {

  }
}

export class SubscriptionFinished implements Action {
  readonly type = SubscriptionActionTypes.SubscriptionFinished;

  constructor() {

  }
}

export class SetStep implements Action {
  readonly type = SubscriptionActionTypes.SetStep;

  constructor(public step: SubscriptionStep) {

  }
}

export class SetContext implements Action {
  readonly type = SubscriptionActionTypes.SetContext;

  constructor(public context: SubscriptionContext) {

  }
}

export class SetMode implements Action {
  readonly type = SubscriptionActionTypes.SetMode;

  constructor(public mode: string, public preserveStep: boolean = null) {

  }
}

export type SubscriptionActionsUnion =
  GoNextStep
  | SetContext
  | SetStep
  | SetMode
  | ResetStep
  | ResetSubscription;
