import {createFeatureSelector, createReducer, createSelector, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as DrupalNodeActions from './drupal-node.actions';
import {ContentTypes, DrupalNode} from "../models/node";
import {Context, Page} from "../models/page";

export const drupalNodesFeatureKey = 'drupalNodes';

export interface State extends EntityState<DrupalNode> {
  // additional entities state properties
}

export const adapter: EntityAdapter<DrupalNode> = createEntityAdapter<DrupalNode>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});


export const reducer = createReducer(
  initialState,
  on(DrupalNodeActions.addDrupalNode,
    (state, action) => adapter.addOne(action.drupalNode, state)
  ),
  on(DrupalNodeActions.upsertDrupalNode,
    (state, action) => adapter.upsertOne(action.drupalNode, state)
  ),
  on(DrupalNodeActions.addDrupalNodes,
    (state, action) => adapter.addMany(action.drupalNodes, state)
  ),
  on(DrupalNodeActions.upsertDrupalNodes,
    (state, action) => adapter.upsertMany(action.drupalNodes, state)
  ),
  on(DrupalNodeActions.updateDrupalNode,
    (state, action) => adapter.updateOne(action.drupalNode, state)
  ),
  on(DrupalNodeActions.updateDrupalNodes,
    (state, action) => adapter.updateMany(action.drupalNodes, state)
  ),
  on(DrupalNodeActions.deleteDrupalNode,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(DrupalNodeActions.deleteDrupalNodes,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(DrupalNodeActions.loadDrupalNodes,
    (state, action) => adapter.setAll(action.drupalNodes, state)
  ),
  on(DrupalNodeActions.clearDrupalNodes,
    state => adapter.removeAll(state)
  ),
);

export const selectDrupalNodeState = createFeatureSelector<State>(drupalNodesFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectDrupalNodeIds = createSelector(
  selectDrupalNodeState,
  selectIds
);

export const selectAllDrupalNodes = createSelector(
  selectDrupalNodeState,
  selectAll
);

export const selectDrupalNodeEntities = createSelector(
  selectDrupalNodeState,
  selectEntities
);

export const selectPageByContext = (context: Context) => createSelector(
  selectAllDrupalNodes,
  (nodes) => {
    return nodes.filter((page: Page) => page.contextId === context).shift();
  }
);

export const selectDrupalNodeById = (id: string) => createSelector(
  selectDrupalNodeEntities,
  (nodes) => {
    return nodes[id];
  }
);

export const selectDrupalNodesByIds = (ids: string[]) => createSelector(
  selectDrupalNodeEntities,
  (nodes) => {
    return ids.map(id => nodes[id]);
  }
);

export const selectNews = createSelector(
  selectAllDrupalNodes,
  (nodes) => {
    return nodes.filter((drupalNode) => drupalNode.type === ContentTypes.news);
  }
);
