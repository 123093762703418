import {Pipe, PipeTransform} from '@angular/core';
import {Cycle} from "../../core/models/cycle";
import {DrupalNode} from "../../core/models/node";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {ResourceToTermsPipe} from "./resource-to-terms.pipe";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {HtmlCleanupPipe} from "../html-cleanup.pipe";
import {Seance} from "../../core/models/seance";
import {ResourceToSituationPipe} from "./resource-to-situation.pipe";

@Pipe({
  name: 'resourceToCycle'
})
export class ResourceToCyclePipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToTermsPipe: ResourceToTermsPipe,
    private resourceToMediaPipe: ResourceToMediaPipe,
    private resourceToSituationPipe: ResourceToSituationPipe,
    private htmlCleanupPipe: HtmlCleanupPipe
  ) {
  }

  transform(value: any): Cycle {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);

    let seances: Seance[] = null;
    if (value.seances) {
      // a linked seance is represented by the first situation of the seance body
      seances = value.seances.map(seance => {
        return this.resourceToSituationPipe.transform(seance);
      });
    }

    const goal: string = value.description ? this.htmlCleanupPipe.transform(value.description) : null;
    const procedure: string = value.procedure ? this.htmlCleanupPipe.transform(value.procedure) : null;
    const educativeContents: string = value.educativeContents ? this.htmlCleanupPipe.transform(value.educativeContents) : null;

    const cycle: Cycle = {
      ...drupalNode
    }

    if (goal) {
      cycle.goal = goal;
    }
    if (procedure) {
      cycle.procedure = procedure;
    }
    if (educativeContents) {
      cycle.educativeContents = educativeContents;
    }
    if (seances) {
      cycle.seances = seances;
    }
    return cycle;
  }

}
