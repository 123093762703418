import {Injectable, Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'punctuation'
})
@Injectable()
export class PunctuationPipe implements PipeTransform {

  constructor() {
  }


  transform(text: string): string {
    return text;
  }
}
