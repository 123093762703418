import { Pipe, PipeTransform } from '@angular/core';
import {Tutorial} from "../../core/models/tutorial";
import {ResourceToDrupalNodePipe} from "./resource-to-drupal-node.pipe";
import {ResourceToMediaPipe} from "./resource-to-media.pipe";
import {DrupalNode} from "../../core/models/node";
import {Video} from "../../core/models/video";

@Pipe({
  name: 'resourceToTutorial'
})
export class ResourceToTutorialPipe implements PipeTransform {

  constructor(
    private resourceToDrupalNodePipe: ResourceToDrupalNodePipe,
    private resourceToMediaPipe: ResourceToMediaPipe
  ) {
  }

  transform(value: any): Tutorial {
    const drupalNode: DrupalNode = this.resourceToDrupalNodePipe.transform(value);
    const video: Video = this.resourceToMediaPipe.transform(value.video) as Video;
    return {
      ...drupalNode,
      video
    };
  }

}
