

export class AgeLevel{

  static readonly ageMin = 4;
  static readonly ageMax = 120;

  // Education Nationale Label
  enLabel?: string;

  ffaLabel?: string;

  ageLabel?: string

}

export class AgeRange{

  from: string;
  to: string;

}
