<ea-dialog [title]="'Mon Compte'">

  <div class="d-flex flex-column content-group flex-fill overflow-y-auto">

    <mat-toolbar class="flex-none" *ngIf="(breakPoint$|async) == 'xs'">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 [innerHTML]="currentUserMenuItem.label"></h1>
    </mat-toolbar>

    <mat-sidenav-container
      class="d-flex flex-fill flex-column"
      [style.marginTop.px]="(breakPoint$|async) == 'xs' ? 0 : 0">

      <mat-sidenav #snav [opened]="(breakPoint$|async) != 'xs'" [mode]="(breakPoint$|async) == 'xs' ? 'over' : 'side'"
                   [fixedInViewport]="(breakPoint$|async) == 'xs'" [fixedTopGap]="48+56">

        <mat-list class="menu-group flex-none ea-vertical-menu p-0">

          <ng-template ngFor [ngForOf]="menu" let-menuItem>

            <mat-list-item
              *ngIf="(breakPoint$|async) == 'xs'"
              [ngClass]="{'active':currentUserMenuItem==menuItem}"
              (click)="currentUserMenuItem=menuItem;snav.toggle()">
              {{menuItem.label}}
            </mat-list-item>

            <mat-list-item
              *ngIf="(breakPoint$|async) != 'xs'"
              [ngClass]="{'active':currentUserMenuItem==menuItem}"
              (click)="currentUserMenuItem=menuItem">
              {{menuItem.label}}
            </mat-list-item>

          </ng-template>

        </mat-list>

      </mat-sidenav>

      <mat-sidenav-content class="px-4">

        <div *ngIf="(breakPoint$|async) != 'xs'" class="row">

          <h2 class="col" [innerHTML]="currentUserMenuItem.label"></h2>

        </div>

        <div class="row template-group">

          <ng-template
            [ngTemplateOutlet]="currentUserMenuItem.template">
          </ng-template>

        </div>

      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>

</ea-dialog>

<ng-template #personalInfos>

  <ea-user-infos-form
    #userInfosForm
    [userInfos]="userInfos$|async">
  </ea-user-infos-form>

  <div class="d-flex justify-content-center">
    <button mat-raised-button (click)="userInfosForm.save()" color="primary">Enregistrer</button>
  </div>

</ng-template>

<ng-template #licence>
  <ea-user-licence-form
    [userInfos]="userInfos$|async">
  </ea-user-licence-form>
</ng-template>

<ng-template #changePassword>
  <ea-user-change-password>
  </ea-user-change-password>
</ng-template>

<ng-template #roles>
  <ea-user-roles
    *ngIf="hasRoles$|async"
    [isContributeur]="isContributeur$|async"
    [isFormateur]="isFormateur$|async"
    [isClientAdmin]="isClientAdmin$|async">
  </ea-user-roles>
</ng-template>

<ng-template #orders>
  <div class="col">
    <ea-user-orders
      *ngIf="(orders$|async)?.length > 0"
      [orders]="(orders$|async)">
    </ea-user-orders>
  </div>
</ng-template>

<ng-template #interests>
  <div class="col">
    <ea-interest-form class="content">
    </ea-interest-form>
  </div>
</ng-template>

<ng-template #privateData>

  <div class="col">
    <ng-template [ngTemplateOutlet]="templatesService.getTemplate('cookieFooter')">
    </ng-template>
  </div>

</ng-template>
