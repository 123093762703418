import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'difficultyLabelToLevel'
})
export class DifficultyLabelToLevelPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? value.length : null;
  }

}
