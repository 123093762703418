/**
 * Created by benoitplatre on 25/06/2018.
 */
import {Action} from '@ngrx/store';
import {Contexts} from "../../../providers/contexts.providers";

export enum ContextsActionTypes {
  LoadContext = '[ContextsActionTypes] Load Context',
  LoadContextSuccess = '[ContextsActionTypes] Load > Success',
  LoadContextFail = '[ContextsActionTypes] Load > Fail'
}

export class LoadContext implements Action {

  readonly type = ContextsActionTypes.LoadContext;

  constructor(public payload: Contexts) {
  }

}

export class LoadContextSuccess implements Action {

  readonly type = ContextsActionTypes.LoadContextSuccess;

  constructor(public contextId: string, public payload: any) {
  }

}

export class LoadContextFail implements Action {

  readonly type = ContextsActionTypes.LoadContextFail;

  constructor(public payload: any) {
  }

}


export type ContextsActions =
  LoadContext
  | LoadContextSuccess
  | LoadContextFail;
