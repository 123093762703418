import {Action} from '@ngrx/store';
import {CookieBotConsent} from '../reducers/app-core.reducers';

export enum AppCoreActionTypes {
  SetCookieBotConsent = '[AppCore] Set CookieBotConsent',
  SetPreference = '[AppCore] Set Preference',
  SetHeaderTitle = '[AppCore] Set header title',
  SetError = '[AppCore] Set error'
}

export class SetCookieBotConsent implements Action {
  readonly type = AppCoreActionTypes.SetCookieBotConsent;

  constructor(public cookieBotConsent: CookieBotConsent) {

  }
}

export class SetPreference implements Action {
  readonly type = AppCoreActionTypes.SetPreference;

  constructor(public name: string, public value: any) {

  }
}

export class SetHeaderTitle implements Action {

  readonly type = AppCoreActionTypes.SetHeaderTitle;

  constructor(public title: string) {

  }
}

export class SetError implements Action {

  readonly type = AppCoreActionTypes.SetError;

  constructor(public error: any, meta: any) {

  }
}


export type AppCoreActionsUnion =
  SetCookieBotConsent
  | SetPreference
  | SetHeaderTitle
  | SetError;
